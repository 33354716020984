import { Mutation } from '@apollo/client/react/components';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import { validatePassword } from '@arnold/core';
import { Modal } from '@arnold/common';
import { Modals } from '../../chat/ChatWindow';
import { changePasswordMutation } from '../../graphql/mutations';
import auth, { SET_PASSWORD_ACCESS_TOKEN, USER_KEY } from '../../lib/auth';
import { ChangePasswordMutation } from '../../generated/hooks';

interface IProps extends RouteComponentProps {
  show: boolean;
  showModal: (modal: Modals, show: boolean) => () => void;
  history: any;
}

interface IFormValues {
  password: string;
  responseError?: string | undefined;
}

const SetPasswordModal = ({ show, showModal, history }: IProps) => {
  const { t } = useTranslation('setPasswordScreen');
  const query = new URLSearchParams(history.location.search);
  const email = query.get('userEmail');

  const SetPasswordFormSchema = Yup.object().shape({
    password: Yup.string().test(
      'is-password-valid',
      t('invalidPassword'),
      (value) => value && validatePassword(value, email || undefined),
    ),
  });

  const handleOnSubmit = (changePasswordFunc: (arg: any) => void) => async (values: FormikValues) => {
    const newPassword = values.password;
    localStorage.removeItem(USER_KEY);
    const accessToken = localStorage.getItem(SET_PASSWORD_ACCESS_TOKEN);
    localStorage.removeItem(SET_PASSWORD_ACCESS_TOKEN);
    if (accessToken == null) throw new Error("Can't reset password without access token");
    auth.saveUser(accessToken);
    await changePasswordFunc({ variables: { newPassword, deviceToken: auth.getDeviceToken() } });
    if (email) {
      await auth.login(email, values.password);
      history.push('overview');
    } else {
      showModal(Modals.LOGIN, true)();
      history.replace();
    }
  };

  const initValues: IFormValues = { password: '', responseError: undefined };

  return (
    <Mutation<ChangePasswordMutation> mutation={changePasswordMutation}>
      {(changePasswordFunc: (arg: any) => void, { data, error }) => {
        const showError = data && !data.changePassword;
        return (
          <Formik
            initialValues={initValues}
            onSubmit={handleOnSubmit(changePasswordFunc)}
            validationSchema={SetPasswordFormSchema}
          >
            {(formikProps: FormikProps<IFormValues>) => (
              <Modal
                show={show}
                onHide={() => null}
                hideCloseButton
                onSubmit={() => handleOnSubmit(changePasswordFunc)(formikProps.values)}
                buttons={{
                  submit: { title: t('setPassword') },
                }}
                title={t('setPasswordTitle')}
                content={
                  <Form>
                    <div className="form-group">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder={t('password')}
                      />
                      {formikProps.errors.password && (
                        <small className="form-text text-danger">{formikProps.errors.password}</small>
                      )}
                      {(showError || error) && <small className="form-text text-danger">{t('passwordError')}</small>}
                    </div>
                  </Form>
                }
              />
            )}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default withRouter<IProps, any>(SetPasswordModal);
