import { FC, useEffect, useState } from 'react';
import { BodyMedium, Check, Checkbox, Line, Modal, theme, useToast } from '@arnold/common';
import { useTranslation, Trans } from 'react-i18next';
import { DayPicker } from 'react-day-picker';
import styled from '@emotion/styled';
import { addDays } from 'date-fns';
import {
  GetProcessReportRespondentsQuery,
  useDeleteRespondentMutation,
  useRemoveRespondentFromSurveyGroupWithDateMutation,
} from '../../generated/hooks';
import Loading from '../Loading';

interface IProps {
  show: boolean;
  showModal: (show: boolean) => void;
  respondent: GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0]['respondents'][0] | null;
  dateFrom?: Date;
  surveyGroupId: string;
  accessToken: string;
  onReload: (shoudReload?: boolean) => void;
  surveysWithAnswers?: GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0]['surveys'] | null;
}

const CheckboxContainer = styled.div`
  margin-bottom: ${theme.spacing.g};
`;

export const RemoveRespondentModal: FC<IProps> = ({
  show,
  showModal,
  respondent,
  dateFrom,
  surveyGroupId,
  accessToken,
  onReload,
  surveysWithAnswers,
}) => {
  const { t } = useTranslation('removeRespondent');
  const { addToast } = useToast();

  const [dateInputValue, setDateInputValue] = useState<Date>(new Date());
  const [removeRespondentModalCheckboxChecked, setRemoveRespondentModalCheckboxChecked] = useState(false);

  const [removeRespondentFromSurveyGroupWithDate, { loading }] = useRemoveRespondentFromSurveyGroupWithDateMutation();
  const [deleteRespondentMutation] = useDeleteRespondentMutation();

  useEffect(() => {
    const sortedSurveys = (surveysWithAnswers || []).sort(
      (a, b) => new Date(b.endsAt).getTime() - new Date(a.endsAt).getTime(),
    );
    setDateInputValue(sortedSurveys.length > 0 ? addDays(new Date(sortedSurveys[0].endsAt), 1) : new Date());
  }, [surveysWithAnswers]);

  const handleRemove = async () => {
    await removeRespondentFromSurveyGroupWithDate({
      variables: {
        surveyGroupId,
        respondentId: respondent!.id,
        dateFrom: dateInputValue,
        accessToken,
      },
      onCompleted: async () => {
        addToast(t('respondentDeleted'));
        if (removeRespondentModalCheckboxChecked) {
          await deleteRespondentMutation({
            variables: {
              respondentId: respondent!.id,
              accessToken,
            },
          });
        }
        onReload(true);
        setDateInputValue(new Date());
        setRemoveRespondentModalCheckboxChecked(false);
        showModal(false);
      },
      onError: () => {
        showModal(false);
      },
    });
  };

  const handleDateChange = (selectedDay: Date) => {
    setDateInputValue(selectedDay);
  };

  if (loading) {
    return <Loading />;
  }

  // HACK to fix the issue with the modal not closing https://github.com/react-bootstrap/react-bootstrap/issues/6153
  if (show) {
    return (
      <Modal
        show={show}
        onHide={() => {
          showModal(false);
        }}
        onSubmit={handleRemove}
        title={t('removeRespondentTitle')}
        text={
          <Trans
            i18nKey="removeRespondent:removeRespondentText"
            values={{ name: `${respondent?.firstname} ${respondent?.surname}` }}
            components={[<strong />]}
          />
        }
        buttons={{
          cancel: { title: t('cancel') },
          submit: { title: t('remove') },
        }}
        content={
          <>
            <BodyMedium>{t('chooseDate')}</BodyMedium>
            <DayPicker
              mode={'single'}
              selected={dateInputValue ? new Date(dateInputValue) : new Date()}
              toDate={new Date()}
              fromDate={dateFrom}
              onDayClick={handleDateChange}
            />
            <CheckboxContainer>
              <Checkbox
                text={t('removeRespondentModalCheckboxText')}
                selected={removeRespondentModalCheckboxChecked}
                partlySelected={false}
                onToggle={() => setRemoveRespondentModalCheckboxChecked(!removeRespondentModalCheckboxChecked)}
                checkIcon={<Check />}
                lineIcon={<Line />}
              />
            </CheckboxContainer>
          </>
        }
      />
    );
  }
  return null;
};
