import { flatten, uniqBy } from 'ramda';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import { Filter, getRootTeams, getTeamsToShow, ReportRespondentsMap } from '../reportHelper';
import { AggregatedData, AggregatedTeams, Message, Team, TeamWithAggregatedResults } from './aggregateData';
import { IReport } from './base';

const filterMessagesForTeam = (
  team: TeamWithAggregatedResults,
  questionDefinitionIndex: string,
  filteredAnswers: string[],
) => {
  if (!team) {
    throw new Error(`Team not found`);
  }
  const answers = team.questions[questionDefinitionIndex] || {};

  return uniqBy((msg) => msg.id, flatten<Message>(filteredAnswers.map((answerIndex) => answers[answerIndex] || [])));
};

export const isTeamNonempty = (teams: AggregatedTeams, team: Team): boolean => {
  if (!team) {
    throw new Error(`Team not found`);
  }
  return (
    !!team.directMembers?.length ||
    (team.memberTeams || []).some((subTeam) => isTeamNonempty(teams, teams[subTeam].team))
  );
};

export const hasTeamDirectResponses = (teams: AggregatedTeams, team: Team): boolean => {
  return Object.keys(teams[team.id].questions).length > 0;
};

export const hasTeamResponses = (teams: AggregatedTeams, team: Team): boolean => {
  if (!team) {
    throw new Error(`Team not found`);
  }
  return (
    hasTeamDirectResponses(teams, team) ||
    (team.memberTeams || []).some((subTeam) => hasTeamResponses(teams, teams[subTeam].team))
  );
};

export const filterMessagesForTeamAndValue = (
  teams: AggregatedTeams,
  team: Team,
  questionDefinitionIndex: string,
  filteredAnswers: string[],
  onlyDirectMembers: boolean,
) => {
  const messages = filterMessagesForTeam(teams[team.id], questionDefinitionIndex, filteredAnswers);
  if (onlyDirectMembers) {
    return messages;
  }

  for (let i = 0, subTeamsLength = team.subTeams.length; i < subTeamsLength; i++) {
    const subTeamMessages = filterMessagesForTeam(teams[team.subTeams[i]], questionDefinitionIndex, filteredAnswers);
    Array.prototype.push.apply(messages, subTeamMessages);
  }
  return messages;
};

const getAllMessagesForQuestion = (
  team: TeamWithAggregatedResults,
  questionDefinitionIndex: string,
  reportRespondents: ReportRespondentsMap,
) => {
  const answers = team.questions[questionDefinitionIndex] || {};

  return uniqBy(
    (msg) => msg.id,
    flatten<Message>(Object.keys(answers).map((answerIndex) => answers[answerIndex] || [])),
  ).filter((msg) => reportRespondents[msg.respondentId]);
};

export const calculateCount = (
  teams: AggregatedTeams,
  team: TeamWithAggregatedResults,
  questionDefinitionIndex: string,
  onlyDirectMembers: boolean,
  reportRespondents: ReportRespondentsMap,
) => {
  const messages = getAllMessagesForQuestion(team, questionDefinitionIndex, reportRespondents);
  let total = messages.length;
  if (onlyDirectMembers) {
    return total;
  }
  for (let i = 0, subTeamsLength = team.team.subTeams.length; i < subTeamsLength; i++) {
    const subTeamMessages = getAllMessagesForQuestion(
      teams[team.team.subTeams[i]],
      questionDefinitionIndex,
      reportRespondents,
    );
    total += subTeamMessages.length;
  }
  return total;
};

export type TeamOnLevel = { type: string; id: string };

const getTeamsOnLevelWithDirectMembers = (
  report: IReport,
  level: number,
  currentLevel: number,
  team?: NonNullable<ReportSubscriptionSubscription['getReport']['teams']>[0],
): TeamOnLevel[] => {
  return ++currentLevel === level
    ? (team && [{ type: 'unit', id: team.id }]) || []
    : team
      ? flatten<TeamOnLevel>([
          [{ type: 'team', id: team.id }],
          ...(team.memberTeams || []).map((mt) =>
            getTeamsOnLevelWithDirectMembers(report, level, currentLevel, report.teams?.find((t) => t.id === mt)),
          ),
        ])
      : [];
};

export type TeamWithType = { type: string; team: Team };

const getSubTeamsWithDirectMembersTillLevel = (
  report: IReport,
  aggregatedData: AggregatedData,
  level: number,
): TeamWithType[] =>
  flatten<TeamOnLevel>(getRootTeams(report)?.map((t) => getTeamsOnLevelWithDirectMembers(report, level, 0, t))!).map(
    (filteredTeam) => ({
      type: filteredTeam.type,
      team: aggregatedData.teams[filteredTeam.id].team,
    }),
  );

export const getTeamsForReversedGraph = (
  aggregatedData: AggregatedData,
  report: IReport,
  compareLevelFilter: Filter | undefined,
) => {
  if (!compareLevelFilter) {
    return getTeamsToShow(report).map((team) => ({
      team: aggregatedData.teams[team.id].team,
      type: 'unit',
    }));
  }

  const level = compareLevelFilter.values ? Number.parseInt(compareLevelFilter.values[0]!, 10) : 1;

  return getSubTeamsWithDirectMembersTillLevel(report, aggregatedData, level);
};
