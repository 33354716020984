import * as React from 'react';

const SendButton = ({ color, size }) => (
  <svg
    version="1.1"
    id="Vrstva_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 83.53 96.968"
    xmlSpace="preserve"
    fill={color}
  >
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default SendButton;
