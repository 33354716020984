import { HeadingLarge, useIsVisible } from '@arnold/common';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ReactComponent as ArnoldLoading } from '../../assets/images/ArnoldLoading.svg';
import { ArchivedSection, CenterView } from '../../components';
import {
  ReportSequenceSubscriptionSubscription,
  ReportSubscriptionSubscription,
  SurveyStatus,
} from '../../generated/hooks';
import { composeSearchParamsString } from '../../lib/common';
import { getPrintableReportMonth, getTopicGroupTranslation } from '../../lib/reportHelper';
import { AggregatedData, Team } from '../../lib/reports/aggregateData';
import { AggregatedReportDataContext } from '../../lib/reports/aggregatedReportDataContext';
import { aggregateDataForReportSequenceDetail } from '../../lib/reportSequenceHelper';
import Contacts from '../components/Contacts';
import Footer from '../components/Footer';
import GlobalSequenceFilters from '../components/GlobalSequenceFilters';
import { useMetadataSetter, useSendOpenReportToIntercom } from '../components/hooks';
import { PhasesSection } from '../components/PhasesSection';
import ReportContainer from '../components/ReportContainer';
import { ReportMetrics } from '../components/ReportMetrics';
import ReportSection from '../components/ReportSection';
import { SideMenu, SideMenuContextProvider } from '../components/SideMenu';
import { NavbarFilter } from '../components/NavbarFilter';
import { FilterModal } from '../components/StyledComponents';
import { useClickOutside } from '../../lib/reports/hooks';
import ConslusionTeams from './ConclusionTeams';
import IntroWidgets from './IntroWidgets';
import ParticipantTable from './ParticipantTable';

export type ReportSequenceData = NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

interface IReportSequenceProps extends RouteComponentProps<any> {
  accessToken: string;
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void;
  setAllowedLanguages: (languages: string[]) => void;
  allowedLanguages?: string[];
  reportSequence: ReportSequenceData;
  publicAccess?: boolean;
  reloading?: boolean;
  setTeamsFilter: (teams: string[]) => void;
  teamsFilter: string[];
  usedTeamsFilter: string[] | null;
  addedOnFrom: string | null | undefined;
  addedOnTo: string | null | undefined;
  setAddedOnFrom: (newValue: string | null) => void;
  setAddedOnTo: (newValue: string | null) => void;
  setAddedOnAndLoad: (from: string | null, to: string | null) => void;
  onConfirm: (shoudReload?: boolean) => void;
  setNavbarContent?: (content: React.ReactNode) => void;
  navbarContent?: React.ReactNode;
  usedAddedOnFrom: string | null | undefined;
  usedAddedOnTo: string | null | undefined;
}

const ReportSequence = ({
  accessToken,
  reportSequence,
  selectedLanguage,
  changeLanguage,
  reloading,
  setReportRespondent,
  location,
  history,
  setAllowedLanguages,
  allowedLanguages,
  publicAccess,
  addedOnFrom,
  addedOnTo,
  usedAddedOnFrom,
  usedAddedOnTo,
  teamsFilter,
  usedTeamsFilter,
  setAddedOnFrom,
  setAddedOnTo,
  setAddedOnAndLoad,
  setTeamsFilter,
  onConfirm,
  setNavbarContent,
}: IReportSequenceProps) => {
  const { t } = useTranslation(['Report', 'header']);
  useMetadataSetter(
    changeLanguage,
    selectedLanguage,
    reportSequence.metadata.language && reportSequence.metadata.language.code,
    reportSequence.metadata.respondent as NonNullable<
      ReportSubscriptionSubscription['getReport']['metadata']
    >['respondent'],
    setReportRespondent,
  );
  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showFilters, setShowFilters] = useState(false);

  useClickOutside([modalRef, buttonRef], setShowFilters);

  const handleVisibility = useCallback(
    (isVisible: boolean) => {
      if (!isVisible) {
        setNavbarContent &&
          setNavbarContent(
            <NavbarFilter
              pillNumber={Number(usedAddedOnFrom || usedAddedOnTo ? 1 : 0) + Number(usedTeamsFilter?.length ? 1 : 0)}
              title={topicGroupTranslation.value}
              addedOnFrom={usedAddedOnFrom}
              addedOnTo={usedAddedOnTo}
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              ref={buttonRef}
            />,
          );
      } else {
        setNavbarContent && setNavbarContent(null);
        setShowFilters(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usedAddedOnFrom, usedAddedOnTo, usedTeamsFilter, showFilters],
  );

  useIsVisible(ref, handleVisibility);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    return () => {
      setNavbarContent && setNavbarContent(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSendOpenReportToIntercom(!!publicAccess, reportSequence.metadata.respondent);

  useEffect(() => {
    if (reportSequence.teams?.length) {
      setAggregatedDataForReport(aggregateDataForReportSequenceDetail(reportSequence.teams));
    }
  }, [reportSequence]);

  useEffect(() => {
    if (
      (!reportSequence.metadata.language?.code || reportSequence.metadata.language.code === 'en') &&
      (!allowedLanguages || !allowedLanguages.length)
    ) {
      setAllowedLanguages(['en']);
    } else if (reportSequence.metadata.language?.code && reportSequence.metadata.language.code !== 'en') {
      setAllowedLanguages([reportSequence.metadata.language.code, 'en']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSequence]);

  const [aggregatedDataForReport, setAggregatedDataForReport] = useState<AggregatedData | null>(null);

  const handleUnitTeamChange = (teams: string[]) => {
    if (teams.length) {
      params.set('unit-teams', teams.join(',') || '');
    } else {
      params.delete('unit-teams');
    }
    setTeamsFilter(teams);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const rootTeams: Team[] = reportSequence.reportRoots
    .map((rootTeamId) => aggregatedDataForReport?.teams[rootTeamId]?.team)
    .filter(Boolean) as Team[];

  const menuSections = [
    { id: 'intro', headingLocal: t('intro') },
    { id: 'topics', headingLocal: t('topics') },
    { id: 'about', headingLocal: t('about') },
  ];
  if (reportSequence?.surveyGroup?.topicGroup && !reportSequence?.surveyGroup?.topicGroup.hideOnboardingParts) {
    // insert on third position into menuSections
    menuSections.splice(2, 0, { id: 'respondentsTable', headingLocal: t('respondentsTable') });
  }

  if (!reportSequence.surveyGroup) {
    return null;
  }

  const topicGroupTranslation = getTopicGroupTranslation(
    reportSequence.surveyGroup.topicGroup,
    reportSequence.metadata.language,
  );

  const searchParams = {
    added_on_from: addedOnFrom,
    added_on_to: addedOnTo,
    'unit-teams': teamsFilter.join(','),
  };

  const isAllDataAvailable = reportSequence.rootTeamsStatistics && !reloading;
  const reportIsNotEmpty =
    isAllDataAvailable && reportSequence.rootTeamsStatistics?.some((st) => st.statistics.responseRate.denominator > 0);
  return (
    <SideMenuContextProvider sections={menuSections}>
      <AggregatedReportDataContext.Provider value={aggregatedDataForReport}>
        <ReportContainer
          date={`${getPrintableReportMonth(
            reportSequence,
            reportSequence.metadata.language && reportSequence.metadata.language.code,
          )}`}
          rootTeams={rootTeams.filter((rootTeam) => {
            if (isAllDataAvailable && reportSequence.rootTeamsStatistics) {
              if (!reportIsNotEmpty) {
                return true;
              }
              const stats = reportSequence.rootTeamsStatistics.find((st) => st.teamId === rootTeam.id);
              if (stats) {
                return stats.statistics.responseRate.denominator > 0;
              }
            }
            return false;
          })}
          title={topicGroupTranslation.value}
          about={topicGroupTranslation.description!}
          breadcrumb={[{ title: topicGroupTranslation.value }]}
          organizationName={reportSequence.metadata.organization.name}
        >
          <>
            {reportSequence.metadata.status !== SurveyStatus.Archived ? (
              <section className={'section'}>
                <FilterModal showFilters={showFilters}>
                  <GlobalSequenceFilters
                    typeCode={reportSequence.surveyGroup.topicGroup.typeCode}
                    sequenceStart={new Date(reportSequence.surveyGroup.from)}
                    sequenceEnd={new Date(reportSequence.surveyGroup.to)}
                    onAddedOnFromChange={setAddedOnFrom}
                    onAddedOnToChange={setAddedOnTo}
                    setAddedOnAndLoad={setAddedOnAndLoad}
                    respondentId={reportSequence.metadata.respondent?.id}
                    allRespondentsCount={reportSequence.totalRespondents ?? 0}
                    filteredRespondentsCount={reportSequence.filteredRespondents ?? 0}
                    rootTeamsIds={reportSequence.reportRoots}
                    aggregatedData={aggregatedDataForReport}
                    setTeamsFilter={handleUnitTeamChange}
                    addedOnFrom={addedOnFrom}
                    addedOnTo={addedOnTo}
                    teamsFilter={teamsFilter}
                    onConfirm={onConfirm}
                    loading={!isAllDataAvailable}
                    ref={modalRef}
                  />
                </FilterModal>
                {publicAccess && <ReportMetrics reportAccessKey={accessToken} trackTime />}
                <div className={'container container--md position-relative'}>
                  <SideMenu sections={menuSections} />
                  <GlobalSequenceFilters
                    typeCode={reportSequence.surveyGroup.topicGroup.typeCode}
                    sequenceStart={new Date(reportSequence.surveyGroup.from)}
                    sequenceEnd={new Date(reportSequence.surveyGroup.to)}
                    onAddedOnFromChange={setAddedOnFrom}
                    onAddedOnToChange={setAddedOnTo}
                    setAddedOnAndLoad={setAddedOnAndLoad}
                    respondentId={reportSequence.metadata.respondent?.id}
                    allRespondentsCount={reportSequence.totalRespondents ?? 0}
                    filteredRespondentsCount={reportSequence.filteredRespondents ?? 0}
                    rootTeamsIds={reportSequence.reportRoots}
                    aggregatedData={aggregatedDataForReport}
                    setTeamsFilter={handleUnitTeamChange}
                    addedOnFrom={addedOnFrom}
                    addedOnTo={addedOnTo}
                    teamsFilter={teamsFilter}
                    onConfirm={onConfirm}
                    loading={!isAllDataAvailable}
                    ref={ref}
                    classNames={'mb-9'}
                  />
                  {isAllDataAvailable && reportSequence.surveyGroup && reportSequence.widgets && (
                    <IntroWidgets
                      surveyGroup={reportSequence.surveyGroup}
                      metadata={reportSequence.metadata}
                      widgets={reportSequence.widgets}
                    />
                  )}
                  {!isAllDataAvailable && (
                    <CenterView>
                      <HeadingLarge>{t('ReportSequence:loading')}</HeadingLarge>
                      <p>{t('ReportSequence:loadingDescription')}</p>

                      <ArnoldLoading />
                    </CenterView>
                  )}
                  {isAllDataAvailable && (
                    <ReportSection id={'topics'} header={t('topics')} icomTag={'process-report-topics-title'}>
                      <PhasesSection
                        accessToken={accessToken}
                        reportSequence={reportSequence}
                        teamsFilter={teamsFilter}
                        getTopicUrl={(topicId) =>
                          `/${publicAccess ? 'results' : 'report'}/${accessToken}${composeSearchParamsString({
                            topic_group_id: topicId,
                            phasesChecked: params.get('phasesChecked'),
                            phasesActiveTab: params.get('phasesActiveTab'),
                            timeRangePeriod1: params.get('timeRangePeriod1'),
                            timeRangePeriod2: params.get('timeRangePeriod2'),
                            timeRangePeriod3: params.get('timeRangePeriod3'),
                            ...searchParams,
                          })}`
                        }
                      />
                    </ReportSection>
                  )}
                  {isAllDataAvailable && !reportSequence.surveyGroup.topicGroup.hideOnboardingParts && (
                    <ParticipantTable
                      accessToken={accessToken}
                      reportSequence={reportSequence}
                      publicAccess={publicAccess}
                      teamsFilter={usedTeamsFilter}
                      urlParams={composeSearchParamsString(searchParams)}
                      onReload={onConfirm}
                    />
                  )}
                  {isAllDataAvailable && reportSequence.teams && (
                    <ConslusionTeams
                      metadata={reportSequence.metadata}
                      rootTeamsStatistics={reportSequence.rootTeamsStatistics!}
                      teams={reportSequence.teams}
                      reportRoots={reportSequence.reportRoots}
                    />
                  )}
                  <hr className={'mb-8'} />
                  <Contacts adminEmails={reportSequence.metadata.adminEmails!} id={'contacts'} />
                </div>
              </section>
            ) : (
              <ArchivedSection className={'container container--md'} data-cy="report-archived-label">
                {t('reportArchived')}
              </ArchivedSection>
            )}
            <Footer />
          </>
        </ReportContainer>
      </AggregatedReportDataContext.Provider>
    </SideMenuContextProvider>
  );
};

export default ReportSequence;
