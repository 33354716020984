import * as React from 'react';

const UserIcon = ({ color, size }) => (
  <svg
    version="1.1"
    id="Vrstva_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 93.886 94.935"
    enableBackground="new 0 0 93.886 94.935"
    xmlSpace="preserve"
    fill={color}
  >
    <g>
      <path
        d="M0.097,69.269l4.046,22c0.384,2.215,2.293,3.666,4.506,3.666h76.609c2.216,0,4.125-1.529,4.506-3.666l4.05-22
        c0.38-2.137-0.765-4.273-2.827-5.116c-9.778-3.819-20.012-5.881-28.108-7.102c-2.062-0.306-12.074,4.82-15.935,4.82
        c-3.792,0-13.927-5.12-15.99-4.82c-8.094,1.172-18.334,3.282-28.033,7.102C0.859,64.917-0.363,67.058,0.097,69.269L0.097,69.269z
        M0.097,69.269"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.846,51.028c5.108,5.726,12.098,6.887,12.098,6.887s6.988-1.161,12.097-6.887
        c0.65-1.25,1.591-2.686,2.929-4.322c6.517-7.965,7.059-20.637,5.431-30.413C65.77,6.519,56.537,0,46.943,0
        S28.115,6.519,26.487,16.294c-1.628,9.775-1.086,22.448,5.431,30.413C33.256,48.343,34.196,49.778,34.846,51.028z"
      />
    </g>
  </svg>
);

export default UserIcon;
