// eslint-disable-next-line id-blacklist
import { any, contains, reverse, sort, sum } from 'ramda';
import { GraphData, GraphRow } from '../../reports/components/Graph';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import {
  filterReport,
  getAnswersForQuestionWithValue,
  getFilterForRootTeams,
  getFullName,
  getGraphLabel,
  getMessagesWithQuestionIndex,
  getQuestionDefinitionByIndex,
  getQuestionType,
  getRespondentById,
  getTeamLabel,
  getTeamName,
  getTeamsToShow,
  getType,
  notEmpty,
} from '../reportHelper';
import { IReport } from './base';

export const sortAndFilterRows = (teamRows: GraphRow[]) => {
  return reverse(
    teamRows.sort((rowA, rowB) => {
      if (rowA.count && !rowB.count) {
        return 1;
      }
      if (!rowA.count && rowB.count) {
        return -1;
      }
      if (rowA.count && rowB.count) {
        return (
          rowA.count / rowA.totalRespondentsCount - rowB.count / rowB.totalRespondentsCount || rowA.count - rowB.count
        );
      }
      return 0;
    }),
  ).filter(notEmpty);
};

const getSegmentSeverities = (row: GraphRow) =>
  row.segments.reduce((severities: number[], segment) => {
    if (segment.segmentSeverity) {
      return [...severities, segment.segmentSeverity];
    }
    return severities;
  }, []);

const getPercentageOfSegmentsWithSeverity = (row: GraphRow, severity: number) =>
  row.segments
    .filter((segment) => segment.segmentSeverity === severity)
    .reduce((acc, segment) => (segment.percentage || 0) + acc, 0);

export const hasGraphEmptyRows = (graphData: GraphData | null) =>
  !!graphData && graphData.rows.every((row) => row.segments.every((segment) => !segment.values.length));

export const sortAndFilterRowsWithAlerts = (teamRows: GraphRow[]) => {
  const sortedRows = teamRows.sort((rowA, rowB) => {
    if (rowA.count && !rowB.count) {
      return 1;
    }
    if (!rowA.count && rowB.count) {
      return -1;
    }
    if (rowA.count && rowB.count) {
      const differenceInTotalPercentage =
        rowA.count / rowA.totalRespondentsCount - rowB.count / rowB.totalRespondentsCount;
      if (differenceInTotalPercentage) {
        return differenceInTotalPercentage;
      }
      const rowASeverities = getSegmentSeverities(rowA);
      const rowBSeverities = getSegmentSeverities(rowB);
      if (rowASeverities.length && !rowBSeverities.length) {
        return 1;
      }
      if (rowBSeverities.length && !rowASeverities.length) {
        return -1;
      }
      if (rowASeverities.length && rowBSeverities.length) {
        const allSeveritiesSet = new Set(rowASeverities.concat(rowBSeverities));
        const allSeverities = Array.from(allSeveritiesSet).sort((a, b) => b - a);

        for (const severity of allSeverities) {
          const rowAPercentage = getPercentageOfSegmentsWithSeverity(rowA, severity);
          const rowBPercentage = getPercentageOfSegmentsWithSeverity(rowB, severity);
          if (rowAPercentage > rowBPercentage) {
            return 1;
          }

          if (rowBPercentage > rowAPercentage) {
            return -1;
          }
        }
      }

      return rowA.count - rowB.count;
    }
    return 0;
  });
  return reverse(sortedRows).filter(notEmpty);
};

export const getGraphData = (
  report: IReport,
  widget: NonNullable<
    NonNullable<NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']>[0]['widgets']
  >[0],
  t: any,
) => {
  if (widget.questionDefinitionIndex === null) {
    return null;
  }

  const question = getQuestionDefinitionByIndex(report, widget.questionDefinitionIndex!);

  if (!question || !question.answersLocal) {
    return null;
  }
  const type = getQuestionType(question);
  const teams = getTeamsToShow(report);
  const traversable = teams.length !== 1 && !any((df) => df.operator === 'team', widget.displayFilters || []);

  const graphData: GraphData = {
    ...getGraphLabel(report, widget, widget.questionDefinitionIndex || '', traversable, t),
    rows: [],
  };

  const totalCount = getMessagesWithQuestionIndex(report, widget.questionDefinitionIndex || '').length;

  graphData.rows = question.answersLocal.map((answer, index) => ({
    description: answer,
    type: getType(index.toString(), widget.answersToHighlight || []),
    get count() {
      return sum(this.segments.map((s) => s.values.length));
    },
    totalRespondentsCount: type === 'MULTISELECT' ? totalCount : undefined,
    segments: teams
      .map((team) => {
        const directMembers = report.reportRoots.length === 1 && contains(team.id, report.reportRoots);
        return {
          id: team.id,
          header: `${getTeamLabel(team, t, directMembers)}: ${getTeamName(team.teamName, t)}`,
          traversable,
          directMembers,
          values: getAnswersForQuestionWithValue(
            filterReport(report, getFilterForRootTeams(report, team)),
            widget.questionDefinitionIndex || '',
            [index.toString()],
            type,
          ).map((message) =>
            message.anonymous ? t('ANONYM') : getFullName(getRespondentById(report, message.respondentId)),
          ),
        };
      })
      .filter((segment) => segment.values.length),
  }));
  if (type === 'MULTISELECT') {
    graphData.rows.sort((rowA, rowB) => {
      if (rowA.count && !rowB.count) {
        return -1;
      }
      if (!rowA.count && rowB.count) {
        return 1;
      }
      if (rowA.count && rowB.count) {
        return rowB.count - rowA.count;
      }
      return 0;
    });
  }
  return graphData;
};

export const aggregateGraphData = (rows: GraphRow[]) =>
  rows.reduce(
    (acc, row, index) => ({
      ...acc,
      [index + 1]: {
        id: index + 1,
        name: row.description,
        type: row.type,
        totalRespondentsCount: row.totalRespondentsCount,
        values: row.segments.reduce(
          (acc2, segment, i) => ({
            ...acc2,
            [segment.id]: {
              id: segment.id,
              originalIndex: i,
              name: segment.header,
              values: segment.values,
              color: segment.color,
              hoverColor: segment.hoverColor,
              traversable: segment.traversable,
              directMembers: segment.directMembers,
              traversableInfo: segment.traversableInfo,
            },
          }),
          {},
        ),
      },
    }),
    {},
  );

export const getTotalRespondentCount = (rows: GraphRow[]) =>
  rows.reduce((acc1, r) => acc1 + r.segments.reduce((acc2, s) => acc2 + s.values.length, 0), 0);

export const getGraphRowsFromAggregatedData = (aggregatedData: any, totalCount: number, segmentSorting: boolean) =>
  Object.keys(aggregatedData).map((key) => {
    const count = Object.keys(aggregatedData[key].values).reduce(
      (acc: any, val: any) => acc + aggregatedData[key].values[val].values.length,
      0,
    );
    let totalResiduum = 0;
    const residuum = {};

    const sortedSegmentKeys = sort((a, b) => {
      if (!segmentSorting) {
        return aggregatedData[key].values[a].originalIndex - aggregatedData[key].values[b].originalIndex;
      }
      return aggregatedData[key].values[b].values.length - aggregatedData[key].values[a].values.length;
    }, Object.keys(aggregatedData[key].values));
    const rowData = {
      id: key,
      name: aggregatedData[key].name,
      type: aggregatedData[key].type,
      values: sortedSegmentKeys.map((val: any) => {
        const floatPercents =
          (aggregatedData[key].values[val].values.length / (aggregatedData[key].totalRespondentsCount || totalCount)) *
          100;
        const roundedPercents = floatPercents < 1 ? floatPercents : Math.floor(floatPercents);
        const difference = floatPercents - roundedPercents;

        if (difference !== 0) {
          residuum[aggregatedData[key].values[val].id] = difference;
          totalResiduum += difference;
        }

        return {
          id: aggregatedData[key].values[val].id,
          color: aggregatedData[key].values[val].color,
          val: aggregatedData[key].values[val].values.length,
          percent: roundedPercents,
          traversable: aggregatedData[key].values[val].traversable,
        };
      }, {}),
      label: [Math.round((count / (aggregatedData[key].totalRespondentsCount || totalCount)) * 100), count],
    };

    if (Math.round(totalResiduum)) {
      const sortedKeys = sort((a, b) => residuum[a] - residuum[b], Object.keys(residuum));

      let residuumIndex = sortedKeys.length - 1;
      for (let j = Math.round(totalResiduum); j > 0; j--) {
        // eslint-disable-next-line no-loop-func
        const value = rowData.values.find((v) => v.id === sortedKeys[residuumIndex]);
        if (value) {
          value.percent++;
        }
        residuumIndex -= 1;
        if (residuumIndex < 0) {
          residuumIndex = sortedKeys.length - 1;
        }
      }
    }

    return rowData;
  });
