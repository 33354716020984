import { contains } from 'ramda';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react/macro';
import { InputConsumer } from '../chat/InputContext';

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 12px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
`;

const Label = styled.p`
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #3f3f3f;
`;

const Item = styled.button`
  background-color: #04b7ef;
  padding: 3px 31px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #04b7ef;
  border-radius: 23px;
  margin: 6px;
  display: ${(props: IStyledProps) => (props.displayNone ? 'none' : 'auto')};
  cursor: pointer;
  outline: 0;

  :hover,
  :focus {
    background-color: #04a5d8;
    border-color: #04a5d8;
  }
  :active {
    background-color: #03c3ff;
    border-color: #03c3ff;
  }

  :disabled {
    background-color: #a1a1a1;
    border-color: #a1a1a1;
    cursor: auto;
  }

  ${(props: IStyledProps) => (props.itemType === 'secondary' ? ItemSecondary : undefined)}
`;

const ItemSecondary = css`
  color: #04b7ef;
  background-color: #fff;

  :hover,
  :focus,
  :active {
    background-color: #fff;
  }

  :hover,
  :focus {
    color: #04a5d8;
    border-color: #04a5d8;
  }
  :active {
    color: #03c3ff;
    border-color: #03c3ff;
  }
`;

interface IStyledProps {
  displayNone?: boolean;
  itemType?: string;
}

interface IProps extends WithTranslation {
  choices: string[];
  onChoose: (message: string, index: number) => void;
  noneDisplay?: number[];
  selectedRange: [number, number];
  disabled?: boolean;
  t: any;
}

const SelectChoices = ({ choices, onChoose, noneDisplay, selectedRange, t, disabled }: IProps) => (
  /* tslint:disable */
  <Wrap>
    <Label>{selectedRange[1] > 1 ? t('selectAll') : choices.length > 1 ? t('selectOne') : ''}</Label>
    {choices.map((item, index) => (
      <Item
        itemType={selectedRange[1] > 1 ? 'secondary' : ''}
        onClick={() => onChoose(item, index)}
        disabled={disabled}
        key={item}
        displayNone={noneDisplay && contains(index, noneDisplay)}
      >
        {item}
      </Item>
    ))}
  </Wrap>
);

const GetPropsFromProvider = ({
  choices,
  selectedRange,
  t,
  i18n,
  tReady,
  disabled,
}: Omit<IProps, 'onChoose' | 'noneDisplay'>) => (
  <InputConsumer>
    {(context) => (
      <SelectChoices
        disabled={disabled}
        onChoose={context.addChoice}
        choices={choices}
        noneDisplay={context.state.values}
        selectedRange={selectedRange}
        t={t}
        i18n={i18n}
        tReady={tReady}
      />
    )}
  </InputConsumer>
);

export default withTranslation('selectChoices')(GetPropsFromProvider);
