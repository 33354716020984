import { useTranslation } from 'react-i18next';
import { Modal } from '@arnold/common';
import { Modals } from '../../chat/ChatWindow';

interface IProps {
  show: boolean;
  showModal: (modal: Modals, show: boolean) => () => void;
}

const EmailSendModal = ({ show, showModal }: IProps) => {
  const { t } = useTranslation('emailSend');
  const hide = showModal(Modals.LOGIN, false);

  return (
    <Modal
      show={show}
      onHide={hide}
      title={t('checkEmail')}
      text={t('checkEmailDescription')}
      buttons={{
        cancel: { title: t('close') },
        submit: { show: false },
      }}
    />
  );
};

export default EmailSendModal;
