import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import Loading from './Loading';

const LoadingComponent = forwardRef((_, ref) => {
  const [load, setLoad] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    setLoading(loading: boolean) {
      setLoad(loading);
    },
  }));

  return load ? <Loading /> : <></>;
});

export default memo(LoadingComponent);
