import { FC, useContext, useState } from 'react';
import { disableScrollingPrevention } from '@arnold/common';
import { CloseMenu, Menu } from '../../../assets/images';
import type { Sections } from './types';
import { SideMenuContext } from './SideMenuContext';

type SideMenuProps = Readonly<{
  sections: Sections;
}>;

export const SideMenu: FC<SideMenuProps> = ({ sections }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { visibleSectionId } = useContext(SideMenuContext);
  const handleSetOpen = () => setOpen(!open);

  return (
    <div className={'nav-aside ' + (open ? 'nav-aside--open' : '')}>
      <button type="button" className={'nav-aside__toggle btn btn-link shadow-sm'} onClick={handleSetOpen}>
        {open ? <img src={CloseMenu} alt="close menu" /> : <img src={Menu} alt="menu" />}
      </button>
      <ul className="nav-aside__menu shadow-sm" data-icom={'report-side-menu'}>
        {sections.map((section: { id: string; headingLocal: string }) => (
          <li key={section.id} className={visibleSectionId === section.id ? 'active' : ''}>
            <a href={'#' + section.id.replace(/\W/g, '_')} onClick={() => disableScrollingPrevention(1000)}>
              {section.headingLocal || section.id}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
