import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import { ReportSubscriptionSubscription } from '../generated/hooks';
import { URLs } from '../lib/common';
import ReportSequenceDetail from './ReportSequenceDetail';
import RespondentDetail from './RespondentDetail';
import { Error } from './components/Error';

interface IReportSequenceLoaderProps extends RouteComponentProps<any> {
  accessToken: string;
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void;
  setAllowedLanguages: (languages: string[]) => void;
  allowedLanguages?: string[];
  setNavbarContent?: (content: React.ReactNode) => void;
}

const ReportSequenceLoader = (props: IReportSequenceLoaderProps) => {
  const { t } = useTranslation('Report');
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact={true} path={path}>
        <ReportSequenceDetail {...props} />
      </Route>
      <Route exact={true} path={[`${path}/${URLs.REPORT_RESPONDENT_DETAIL}/:respondentId`]}>
        <RespondentDetail {...props} />
      </Route>
      <Route path="*" exact={true}>
        <Error>{t('errorLoading')}</Error>
      </Route>
    </Switch>
  );
};

export default withRouter(ReportSequenceLoader);
