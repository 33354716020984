import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IFilterDescription } from '../../lib/reportHelper';
import FormatQuestion from './FormatQuestion';

export enum FilterTitleType {
  REPORT_SECTION = 'REPORT_SECTION',
  REPORT_WIDGET = 'REPORT_WIDGET',
}

interface IProps {
  filterTitleType: FilterTitleType;
  hideNumbers?: boolean;
}

const FilterDescription = ({ answers, question, hideNumbers, filterTitleType }: IProps & IFilterDescription) => {
  const { t } = useTranslation(['Report']);

  return (
    <>
      <Trans
        i18nKey={
          filterTitleType === FilterTitleType.REPORT_SECTION
            ? 'Report:SECTION_FILTER_DESCRIPTION'
            : 'Report:WIDGET_FILTER_DESCRIPTION'
        }
        components={[
          <React.Fragment key="0">
            {answers.map(({ answer, responses }, index) => (
              <React.Fragment key={`filter-answer_${answer}`}>
                <span key={answer} className={'font-weight-bold'}>
                  {answer}
                </span>{' '}
                {!hideNumbers && `(${responses})`}
                {index + 1 < answers.length && t('ANSWER_SEPARATOR')}
              </React.Fragment>
            ))}
          </React.Fragment>,
          <FormatQuestion text={question} key="1" />,
        ]}
        values={{
          question,
        }}
      >
        Odpověděli
        <span>{answers && answers[0] && answers[0].answer}</span>
        na otázku
        <span className={'font-weight-bold'}>
          <FormatQuestion text={question} />
        </span>
        .
      </Trans>
    </>
  );
};

export default FilterDescription;
