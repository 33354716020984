import { prop, sortBy } from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReportSequenceSubscriptionSubscription } from '../../generated/hooks';
import {
  filterTeamsToShow,
  getTeamLabel,
  getTeamName,
  getTeamsById,
  prepareAnonymityRate,
  prepareFinishedRate,
  prepareResponseRate,
} from '../../lib/reportHelper';
import { ContentBox } from '../components/ContentBox';
import ReportSection from '../components/ReportSection';
import ReportTable from '../components/Table';

export type ReportSequenceData = NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

interface IConslusionTeamsProps {
  teams: NonNullable<ReportSequenceData['teams']>;
  rootTeamsStatistics: NonNullable<ReportSequenceData['rootTeamsStatistics']>;
  metadata: NonNullable<ReportSequenceData['metadata']>;
  reportRoots: NonNullable<ReportSequenceData['reportRoots']>;
}

const ConslusionTeams = ({ metadata, rootTeamsStatistics, teams, reportRoots }: IConslusionTeamsProps) => {
  const { t } = useTranslation(['Report', 'header']);

  const rootTeams = getTeamsById(teams, reportRoots);
  const conclusionTeams = sortBy(prop('name'))(
    filterTeamsToShow(rootTeams, teams, reportRoots.length)
      .map((team) => {
        const stats = rootTeamsStatistics.find((rootTeam) => rootTeam.teamId === team.id)?.statistics;
        return {
          name: getTeamName(team.teamName, t),
          id: team.id,
          teamLabel: getTeamLabel(team, t),
          respondentsCount: stats?.responseRate.denominator ?? 0,
          responded: prepareResponseRate(
            stats?.responseRate.denominator!,
            stats?.responseRate.numerator!,
            t,
            metadata.status,
          ),
          finished: prepareFinishedRate(
            stats?.finishedRate.denominator!,
            stats?.finishedRate.numerator!,
            t,
            metadata.status,
          ),
          anonymity: prepareAnonymityRate(
            stats?.anonymityRate.denominator!,
            stats?.anonymityRate.numerator!,
            false,
            t,
            metadata.status,
          ),
        };
      })
      .filter((team) => team.respondentsCount),
  );

  return (
    <ReportSection id={'about'} header={t('about')} icomTag={'process-report-about-title'}>
      {conclusionTeams.length > 0 && (
        <ContentBox header={t('return')} disabled={false} titleIcomTag={'process-report-about-title'}>
          <ReportTable teams={conclusionTeams} />
        </ContentBox>
      )}
    </ReportSection>
  );
};

export default ConslusionTeams;
