import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Features, ReportWidgetType, SurveyStatus } from '../../../generated/hooks';
import {
  IFilterDescription,
  filterReport,
  getComments,
  getEmptyState,
  getFilterDescriptions,
  getWidgetDescription,
} from '../../../lib/reportHelper';
import { AggregatedReportDataContext } from '../../../lib/reports/aggregatedReportDataContext';
import { CommentBox } from '../CommentBox';
import { ContentBox } from '../ContentBox';
import { StackedChart } from './StackedChart';
import { ReportWidgetProps } from './types';
import { getWidgetTitle, isWidgetDisabled } from './utils';

export const ReportWidget: FC<ReportWidgetProps> = (props) => {
  const {
    widget,
    hideHeader,
    section,
    sectionReport,
    globalFilters,
    loading,
    originalReport,
    handleDownload,
    hideDownload,
    hasFilter,
  } = props;
  const { t } = useTranslation('Report');

  const report = loading ? sectionReport : filterReport(sectionReport, widget.displayFilters);

  switch (widget.type) {
    case ReportWidgetType.StackedChart: {
      return (
        <AggregatedReportDataContext.Consumer>
          {(aggregatedData) => (
            <StackedChart
              {...{ ...props, widgetReport: report, aggregatedData, handleDownload, hideDownload, hasFilter }}
            />
          )}
        </AggregatedReportDataContext.Consumer>
      );
    }
    case ReportWidgetType.CommentTable:
      const comments = getComments(report, widget.questionDefinitionIndex!, t);
      if (comments.length === 0 && widget.displayFilters && widget.displayFilters.length > 0) {
        return null;
      }
      return (
        <CommentBox
          header={getWidgetTitle(report, widget)}
          subHeader={getWidgetDescription(sectionReport, report, widget, section.displayFilters!, t, loading)}
          filterDescriptions={getFilterDescriptions(widget.titleFilters || [], report) as IFilterDescription[]}
          loading={loading}
          key={widget.id}
          comments={comments}
          questionIndex={parseInt(widget.questionDefinitionIndex || '0', 10)}
          questionLabels={report.questionLabels?.find(
            (question) => question.questionDefinitionIndex === widget.questionDefinitionIndex,
          )}
          emptyState={getEmptyState(globalFilters, t)}
          keywordsEnabled={
            !!report.metadata?.organization.featureNames?.includes(Features.AiKeywords) &&
            originalReport.surveyKeywordsGenerated &&
            globalFilters?.length === 0 &&
            report.metadata?.status === SurveyStatus.Completed
          }
          organizationName={report.metadata?.organization.name}
          reportAccess={report.identifier}
          reportId={report.id}
          respondentId={report.metadata?.respondent?.id}
        />
      );
    default:
      return (
        <ContentBox
          header={hideHeader ? undefined : getWidgetTitle(report, widget)}
          subHeader={getWidgetDescription(sectionReport, report, widget, section.displayFilters!, t)}
          key={widget.id}
          disabled={isWidgetDisabled(report, widget)}
          organizationName={report.metadata!.organization.name}
          titleIcomTag={'report-question-text'}
          handleDownload={hideDownload ? undefined : handleDownload}
          classnames={'hide-from-export'}
        >
          {(widget.values || []).join(', ')}
        </ContentBox>
      );
  }
};
