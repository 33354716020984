import { ThemeType } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import LoadingLine from '../../components/LoadingLine';
import { IConclusionBox } from './Conclusion';

interface IProps {
  teams: Array<{
    name: string;
    id: string;
    teamLabel: string;
    responded?: IConclusionBox;
    finished?: IConclusionBox;
    anonymity?: IConclusionBox;
  }>;
  loading?: boolean;
}

const TableHeaderColumn = styled.th`
  font-weight: 400;
  color: ${({ theme }: { theme?: ThemeType }) => theme && theme.colors.text.primary};
`;

const Strong = styled.strong`
  font-weight: ${({ theme }: { theme?: ThemeType }) => theme && theme.typography?.body?.medium?.bold?.fontWeight};
`;

const ReportTable = ({ teams, loading }: IProps) => {
  const { t } = useTranslation('Report');

  return (
    <div className="table-responsive">
      <table className="table mb-0">
        <thead>
          <tr>
            <TableHeaderColumn className="w-50">{t('team')}</TableHeaderColumn>
            <TableHeaderColumn data-icom={'report-about-response-rate'}>{t('RESPONSE_RATE')}</TableHeaderColumn>
            <TableHeaderColumn data-icom={'report-about-finished-rate'}>{t('FINISHED_RATE')}</TableHeaderColumn>
            <TableHeaderColumn data-icom={'report-about-anonymity-rate'}>{t('ANONYMITY_RATE')}</TableHeaderColumn>
          </tr>
        </thead>
        <tbody>
          {teams &&
            teams.map((team) => (
              <tr key={team.id}>
                <td>{team.name.trim().length > 0 ? `${team.teamLabel}: ${team.name}` : t('withoutSupervisor')}</td>
                <td>
                  {!!loading && <LoadingLine />}
                  {team.responded ? (
                    <>
                      <Strong>{team.responded.figure}</Strong> ({team.responded.explanation})
                    </>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {!!loading && <LoadingLine />}
                  {team.finished ? (
                    <>
                      <Strong>{team.finished.figure}</Strong> ({team.finished.explanation})
                    </>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {!!loading && <LoadingLine />}
                  {team.anonymity ? (
                    <>
                      <Strong>{team.anonymity.figure}</Strong> ({team.anonymity.explanation})
                    </>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
