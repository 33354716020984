import { ApolloLink, Observable } from '@apollo/client';
import isPlainObject from 'is-plain-object';

export type Transformer = {
  parseValue: (value: any) => any;
  serialize: (value: any) => any;
};

export type TransformerMap = {
  [index: string]: Transformer;
};

export type Transformers = {
  [typename: string]: TransformerMap;
};

export function parseObject(transformers: Transformers, object: any): any {
  if (!object) {
    return;
  }

  if (Array.isArray(object)) {
    object.forEach((i) => parseObject(transformers, i));
    return;
  }

  if (!isPlainObject(object)) {
    return;
  }

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      parseObject(transformers, object[key]);
    }
  }

  const typename = object.__typename;

  if (typename && transformers[typename]) {
    parseObjectValues(transformers[typename], object);
  }
}

export function parseObjectValues(map: TransformerMap, object: any) {
  for (const key in map) {
    if (object[key]) {
      object[key] = map[key].parseValue(object[key]);
    }
  }
}

export function createTransformerLink(transformers: Transformers) {
  return new ApolloLink((operation, forward) => {
    if (forward == null) {
      return null;
    }
    if (forward(operation).map != null) {
      return forward(operation).map((response) => {
        if (response.data) {
          parseObject(transformers, response.data);
        }
        return response;
      });
    }

    return Observable.from(forward(operation)).map((response) => {
      if (response.data) {
        parseObject(transformers, response.data);
      }
      return response;
    });
  });
}
