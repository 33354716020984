import { cleanArray, logInIntercomUser, pushToDataLayer, updateIntercomUser } from '@arnold/common';
import { calculateNPS, getLocale } from '@arnold/core';
import { format, min } from 'date-fns';
import i18n from 'i18next';
// eslint-disable-next-line id-blacklist
import { all, any, contains, flatten, intersection, memoizeWith, prop, sort, sortBy, sortWith, uniq } from 'ramda';
import {
  ContactSource,
  ContactType,
  ProcessStatus,
  QuestionType,
  ReportSequenceRespondentDetailQueryQuery,
  ReportSequenceSubscriptionSubscription,
  ReportSubscriptionSubscription,
  ReportWidgetType,
  SurveyStatus,
  UserOrgRole,
  UserSysRole,
} from '../generated/hooks';
import { AnswerType } from '../lib/reports/question';
import { IConclusionBox } from '../reports/components/Conclusion';
import { IAlertBlock, IAnswer } from '../reports/components/RespondentsAnswers';
import { AggregatedTeams } from './reports/aggregateData';
import { IReport, getFullName, getMessagesWithQuestionIndex, notEmpty } from './reports/base';
import { getDirectMembersFromTeamIds, getRespondentIdsForTeamIds } from './reports/filterUtilities';
import { prepareAnswersForQuestion } from './reports/question';

export { getFullName, getMessagesWithQuestionIndex, notEmpty };

type ProcessReport = NonNullable<ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail']>;
type ReportDefinition = NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>;
type Report = ReportSubscriptionSubscription['getReport'];

export const setupIntercom = (
  respondent: NonNullable<ProcessReport['metadata']['respondent']>,
  language: string,
  payload: object,
) => {
  const baseEntity = respondent.user ? respondent.user : respondent;
  const userHash = baseEntity.intercomHash || undefined;
  const userId = respondent.user ? respondent.user.id : `respondent_${respondent.id}`;
  const email = respondent.user
    ? respondent.user.username
    : respondent.contacts?.find(
        (contact) => contact.type === ContactType.Email && contact.source === ContactSource.Respondent,
      )?.value ||
      respondent.contacts?.find(
        (contact) => contact.type === ContactType.Email && contact.source === ContactSource.Primary,
      )?.value;
  const organizationRole = respondent.user
    ? respondent.user.systemRole === UserSysRole.SysAdmin ||
      respondent.user.respondents?.find((r) => r.organizationRole === UserOrgRole.OrgAdmin)
      ? UserOrgRole.OrgAdmin
      : UserOrgRole.OrgRespondent
    : respondent.organizationRole;
  logInIntercomUser(userId, userHash, new Date(baseEntity.createdAt));
  updateIntercomUser({
    user_id: userId,
    user_hash: userHash,
    name: `${baseEntity.firstname} ${baseEntity.surname}`,
    email,
    language,
    language_override: language,
    'Organization role': organizationRole,
    companies: [
      {
        id: respondent.organization.id,
        name: respondent.organization.name,
        'Company ID': respondent.organization.companyId,
      },
    ],
  });

  pushToDataLayer({
    userId,
    orgId: respondent.organization.id,
    ...payload,
  });
};

export const FILTER_OPERATOR = {
  SOURCE: 'source-id',
};

type HighlightedQuestion = {
  questionIndex: string | null;
  highlighted?: string[] | null;
};

export const getHighlightedQuestions = (
  report: IReport | ProcessReport,
  specifiedReportDefinition?: ReportDefinition | null,
): HighlightedQuestion[] => {
  // eslint-disable-next-line @typescript-eslint/dot-notation, dot-notation
  const reportDefinition = specifiedReportDefinition || report['reportDefinition'];
  const allWidgets: NonNullable<NonNullable<Report['reportDefinition']>['sections']>[0]['widgets'] =
    reportDefinition?.sections.reduce(
      (
        acc: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>,
        section: NonNullable<ReportDefinition['sections']>[0],
      ) => [...acc, ...(section.widgets || [])],
      [],
    );

  return (report.questions || [])
    .map((question) => {
      const widget = allWidgets?.find(
        (widget) => widget.questionDefinitionIndex === (question.reportIndex || question.index),
      );
      return {
        questionIndex: question.index,
        highlighted: widget ? widget.answersToHighlight : question.answersToHighlight,
      };
    })
    .filter((answers) => (answers.highlighted || []).length > 0);
};

export const isAnswerHighlighted = (
  message: NonNullable<NonNullable<Report['responses']>[0]['messages']>[0],
  highlightedQuestions: HighlightedQuestion[],
) =>
  !!highlightedQuestions.find(
    (h) =>
      h.questionIndex === message.questionDefinitionIndex &&
      intersection(h.highlighted || [], (message.value || '').split(',')).length > 0,
  );

export const getLastAddedOn = (report: IReport | ProcessReport) => {
  const addedOnDates = report.respondents?.map((respondent) => respondent.addedOn);
  return addedOnDates?.length ? addedOnDates.reduce((a, b) => (a > b ? a : b)) : report.surveyGroup?.to;
};

export const getPrintableReportDate = (report: IReport, t: any) => {
  const [start, end] = [new Date(report.metadata?.surveyStart), new Date(report.metadata?.surveyEnd || undefined)];
  if (!isNaN(end.getTime())) {
    if (start.getMonth() === end.getMonth()) {
      return `${format(start, 'd. ', { locale: getLocale(i18n.language) })}-${format(end, ' d. MMMM yyyy', {
        locale: getLocale(i18n.language),
      })}`;
    }

    if (start.getFullYear() === end.getFullYear()) {
      return `${format(start, 'd. MMMM ', { locale: getLocale(i18n.language) })}-${format(end, ' d. MMMM yyyy', {
        locale: getLocale(i18n.language),
      })}`;
    }

    return `${format(start, 'd. MMMM yyyy ', { locale: getLocale(i18n.language) })}-${format(end, ' d. MMMM yyyy', {
      locale: getLocale(i18n.language),
    })}`;
  }
  return t('periodFrom', { from: format(start, 'd. MMMM yyyy ', { locale: getLocale(i18n.language) }) });
};

export const getPrintableReportMonth = (
  report: IReport | ProcessReport | ReportSequenceSubscriptionSubscription['getProcessReport'],
  langCode?: string | null,
) => {
  const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

  const dateFrom = report.surveyGroup?.from;

  const dateTo = report.surveyGroup?.to;

  const dateFormat = langCode === 'en' ? 'MMM yyyy' : 'MMMM yyyy';

  const dateFromString = capitalizeFirstLetter(
    format(new Date(dateFrom), dateFormat, { locale: getLocale(i18n.language) }),
  );
  const dateToString = capitalizeFirstLetter(
    format(new Date(dateTo), dateFormat, { locale: getLocale(i18n.language) }),
  );

  if (dateFromString === dateToString) {
    return dateFromString;
  }
  return `${dateFromString} - ${dateToString}`;
};

export const getPrintableSequenceDate = (report: IReport, topicGroupId: string, t: any) => {
  if (!report.surveyGroup) {
    return '';
  }

  const stepForTopicGroup = report.surveyGroup.steps.find(
    (step) => step.processStep.stepTopicGroup.id === topicGroupId,
  );

  const daysText =
    stepForTopicGroup &&
    t(stepForTopicGroup.daysFromStart < 0 ? 'daysBeforeLongerVersion' : 'daysAfterLongerVersion', {
      postProcess: 'interval',
      count: Math.abs(stepForTopicGroup.daysFromStart),
    });

  return `${getPrintableReportMonth(
    report,
    report?.metadata?.language && report?.metadata?.language.code,
  )}, ${daysText}`;
};

export const prepareResponseRate = (total: number, answered: number, t: any, status: SurveyStatus) => {
  return {
    header: t('RESPONSE_RATE'),
    description: t('RESPONSE_RATE_DESCRIPTION'),
    type: 'response-rate',
    figure: `${total === 0 ? 0 : Math.round((answered / total) * 100)} %`,
    explanation: `${answered} / ${total}`,
    surveyStatus: status,
  };
};

export const calculateResponseRate = (responses: Report['responses'], status: SurveyStatus, t: any) => {
  const total = responses?.length || 0;
  const answered = getRespondentsWithMessage(responses).length;

  return prepareResponseRate(total, answered, t, status);
};

export const prepareFinishedRate = (total: number, finished: number, t: any, status: SurveyStatus) => {
  return {
    type: 'finished-rate',
    header: t('FINISHED_RATE'),
    description: t('FINISHED_RATE_DESCRIPTION'),
    figure: `${total === 0 ? 0 : Math.round((finished / total) * 100)} %`,
    explanation: `${finished} / ${total}`,
    surveyStatus: status,
  };
};

export const calculateFinishedRate = (responses: Report['responses'] | null, status: SurveyStatus, t: any) => {
  const total = getRespondentsWithMessage(responses).length;
  const finished = responses ? responses.filter((response) => response.finished).length : 0;
  return prepareFinishedRate(total, finished, t, status);
};

export const prepareAnonymityRate = (
  total: number,
  anonymous: number,
  additionallyAnonymized: boolean,
  t: any,
  status: SurveyStatus,
) => {
  return {
    type: 'anonymity-rate',
    header: t('ANONYMITY_RATE'),
    description: t('ANONYMITY_RATE_DESCRIPTION'),
    figure: `${total === 0 ? 0 : Math.round((anonymous / total) * 100)} %`,
    explanation: `${anonymous} / ${total}`,
    additionallyAnonymized,
    surveyStatus: status,
  };
};

export const calculateAnonymityRate = (responses: Report['responses'] | null, status: SurveyStatus, t: any) => {
  const additionallyAnonymized = any((response) => response.additionallyAnonymized, responses || []);
  const total = getRespondentsWithMessage(responses).length;
  const anonymous = responses
    ? responses.filter((response) => response.originalAnonymous && response.messages && response.messages.length > 0)
        .length
    : 0;
  return prepareAnonymityRate(total, anonymous, additionallyAnonymized, t, status);
};

export const prepareWithoutIssuesRate = (total: number, withIssues: number, t: any, status: SurveyStatus) => {
  return {
    type: 'wo-issues-rate',
    header: t('phasesNeedsAttention'),
    description: t('phasesNeedsAttentionDescription'),
    figure: `${total === 0 ? 0 : Math.round((withIssues / total) * 100)} %`,
    explanation: `${withIssues} / ${total}`,
    surveyStatus: status,
  };
};

export const prepareFilterWidget = (
  total: number,
  filtered: number,
  surveyStatus: SurveyStatus,
  header: string,
  description: string,
) => {
  return {
    type: 'filter',
    header,
    description,
    figure: `${total === 0 ? 0 : Math.round((filtered / total) * 100)} %`,
    explanation: `${filtered} / ${total}`,
    surveyStatus,
  };
};
export const prepareNpsWidget = (values: number[], surveyStatus: SurveyStatus, header: string, description: string) => {
  return {
    type: 'nps',
    header,
    description,
    figure: `${values.join(', ')}`,
    explanation: ``,
    surveyStatus,
  };
};

export const getStaticIntroWidgetData = (
  report: IReport,
  widgetType: string,
  t: any,
  widget?: NonNullable<ReportDefinition['intro']>[0],
): IConclusionBox | undefined => {
  switch (widgetType) {
    case ReportWidgetType.ResponseRate: {
      return calculateResponseRate(report.responses, report.metadata?.status!, t);
    }
    case ReportWidgetType.AnonymityRate: {
      return calculateAnonymityRate(report.responses, report.metadata?.status!, t);
    }
    case ReportWidgetType.FinishedRate: {
      return calculateFinishedRate(report.responses, report.metadata?.status!, t);
    }
    case ReportWidgetType.Filter: {
      const respondentsWithMessagesFromFilter = getRespondentsWithMessage(report.responses).filter(
        (response) =>
          widget?.displayFilters?.every(
            (filter) =>
              response.messages?.some((message) => message.questionDefinitionIndex === filter.questionDefinitionIndex),
          ),
      ).length;
      const filteredResponses = report.responses!.filter((response) =>
        response.messages && response.messages.length > 0
          ? widget?.displayFilters?.every((filter) =>
              any((message) => {
                if (filter.questionDefinitionIndex === message.questionDefinitionIndex) {
                  const question = report.questions?.find((q) => q.index === filter.questionDefinitionIndex);

                  if (!question) {
                    return false;
                  }
                  return question.type === 'MULTISELECT'
                    ? any((val) => contains(val, (message.value || '').split(',')), filter.values || [])
                    : contains(message.value, filter.values || []);
                }
                return false;
              }, response.messages || []),
            )
          : false,
      ).length;
      return prepareFilterWidget(
        respondentsWithMessagesFromFilter,
        filteredResponses,
        report.metadata?.status!,
        widget?.headingLocal ?? '',
        widget?.descriptionLocal ?? '',
      );
    }
    case ReportWidgetType.Nps: {
      const npsAnswers =
        report.responses
          ?.map((response) =>
            response.messages && response.messages.length > 0
              ? response.messages.filter((m) => widget?.questionDefinitionIndex === m.questionDefinitionIndex)
              : undefined,
          )
          .filter(Boolean) ?? [];
      return prepareNpsWidget(
        [
          calculateNPS(
            npsAnswers
              .map((npsA) => npsA!.map((m) => parseInt(m.value ?? '', 10)))
              .flat()
              .filter((value) => !isNaN(value)),
          ),
        ],
        report.metadata?.status!,
        widget?.headingLocal ?? '',
        widget?.descriptionLocal ?? '',
      );
    }
    case ReportWidgetType.WithoutIssuesRate: {
      const answered = getRespondentsWithMessage(report.responses).length;
      const highlighted = getHighlightedQuestions(report);

      const withAlertRespondents = (report.responses || []).filter((response) =>
        (response.messages || []).some((message) => isAnswerHighlighted(message, highlighted)),
      );
      return prepareWithoutIssuesRate(answered, withAlertRespondents.length, t, report.metadata?.status!);
    }
    default:
      return undefined;
  }
};

export const getTopicGroupTranslation = (
  topicGroup: NonNullable<Report['surveyGroup']>['topicGroup'],
  language: ProcessReport['metadata']['language'],
  topicName?: string | null,
) => {
  const topicGroupTranslation =
    topicGroup.translations &&
    topicGroup.translations.find((translation) => !!language && translation.language.id === language.id);
  if (topicGroupTranslation) {
    return topicGroupTranslation;
  }
  const sortedTranslations =
    topicGroup &&
    (topicGroup.translations || []).sort((tr1, tr2) => {
      if (tr1.id < tr2.id) {
        return -1;
      }
      if (tr1.id > tr2.id) {
        return 1;
      }
      return 0;
    });
  return sortedTranslations && sortedTranslations.length
    ? sortedTranslations[0]
    : {
        value: topicName || '',
        description: '',
      };
};

export const getIntroWidgetData = (
  report: IReport,
  introWidget: NonNullable<ReportDefinition['intro']>[0],
  t: any,
): IConclusionBox | null => {
  const defaultBox = {
    type: introWidget.type,
    header: introWidget.headingLocal,
    figure: introWidget.values ? introWidget.values.join(', ') : '',
    explanation: introWidget.descriptionLocal || '',
    surveyStatus: report.metadata?.status!,
  };
  if (introWidget.numerator === null || introWidget.denominator === null || report.responses !== null) {
    return getStaticIntroWidgetData(report, introWidget.type, t, introWidget) || defaultBox;
  }

  switch (introWidget.type) {
    case ReportWidgetType.ResponseRate: {
      return prepareResponseRate(introWidget.denominator!, introWidget.numerator!, t, report.metadata?.status!);
    }
    case ReportWidgetType.AnonymityRate: {
      if (!report.anonymityEnabled) return null;
      return prepareAnonymityRate(
        introWidget.denominator!,
        introWidget.numerator!,
        !!introWidget.additionallyAnonymized,
        t,
        report.metadata?.status!,
      );
    }
    case ReportWidgetType.FinishedRate: {
      return prepareFinishedRate(introWidget.denominator!, introWidget.numerator!, t, report.metadata?.status!);
    }
    case ReportWidgetType.WithoutIssuesRate: {
      return prepareWithoutIssuesRate(introWidget.denominator!, introWidget.numerator!, t, report.metadata?.status!);
    }
    case ReportWidgetType.Filter: {
      return {
        type: 'filter',
        header: introWidget.headingLocal,
        figure: `${
          introWidget.denominator === 0 ? 0 : Math.round((introWidget.numerator! / introWidget.denominator!) * 100)
        } %`,
        explanation: `${introWidget.numerator} / ${introWidget.denominator} ` + introWidget.descriptionLocal || '',
        surveyStatus: report.metadata?.status!,
      };
    }
    default:
      return defaultBox;
  }
};

export const getType = (answerIndex: string, alertOptions: string[]) => {
  const alertOptionsForValue = alertOptions.filter((option) => answerIndex === option);
  if (alertOptionsForValue.length === 1) {
    return AnswerType.WARNING;
  }
  if (alertOptionsForValue.length > 1) {
    return AnswerType.ALERT;
  }
  return AnswerType.NEUTRAL;
};

const prepareAnswerFromIndex = (
  answerIndex: string,
  question: NonNullable<Report['questions']>[0],
  alertOptions: string[],
): IAnswer => {
  if (!question.answersLocal) {
    return {
      text: '',
      type: getType(answerIndex, alertOptions),
    };
  }
  return {
    text: question.answersLocal[answerIndex] || '',
    type: getType(answerIndex, alertOptions),
  };
};
const prepareAnswerFromText = (text: string): IAnswer => {
  return {
    text,
    type: AnswerType.NEUTRAL,
  };
};

export const ANONYMOUS_RESPONDENT_ID = 'anonymous';
export const getAlertsData = (
  report: IReport | ProcessReport,
  t: any,
  withAnonymous: boolean = false,
  onlyOriginalAnonymity: boolean = false,
): IAlertBlock[] => {
  const highlighted = getHighlightedQuestions(report);

  const alertAnswers = (report.responses || []).map((response) => ({
    ...response,
    allAnonymous: all((m) => (onlyOriginalAnonymity ? m.originalAnonymous : m.anonymous), response.messages || []),
    messages: response.messages || [],
  }));

  const prepareMessage = (message: NonNullable<NonNullable<Report['responses']>[0]['messages']>[0]) => {
    const question = getQuestionDefinitionByIndex(report, message.questionDefinitionIndex);
    const highlightedAnswers = highlighted.find((h) => h.questionIndex === message.questionDefinitionIndex);
    const alertOptions = highlightedAnswers ? highlightedAnswers.highlighted || [] : [];
    const preparedMessage = {
      id: message.id,
      date: new Date(message.date),
      question: '',
      questionDefinitionIndex: message.questionDefinitionIndex,
      answers: [] as IAnswer[],
      weight: highlightedAnswers
        ? (highlightedAnswers.highlighted || []).filter((h) => contains(h, (message.value || '').split(','))).length
        : 0,
    };
    if (!question) {
      return preparedMessage;
    }

    preparedMessage.question = question.text;
    const questionType = getQuestionType(question);

    if (message.text) {
      if (questionType === 'SELECT' && question.answersLocal && message.value) {
        preparedMessage.answers = [prepareAnswerFromIndex(message.value, question, alertOptions)];
      } else if (questionType === 'MULTISELECT') {
        preparedMessage.answers =
          question.answersLocal && message.value
            ? message.value.split(',').map((answerIndex) => prepareAnswerFromIndex(answerIndex, question, alertOptions))
            : message.text.split(',').map((text) => prepareAnswerFromText(text));
      } else {
        preparedMessage.answers = [prepareAnswerFromText(message.text)];
      }
    }
    return preparedMessage;
  };

  const alerts = alertAnswers.map((answer) => {
    const teamName = getTeamNameByRespondentId(report, answer.respondentId, t);
    return {
      respondentId: answer.respondentId,
      surveyId: answer.surveyId,
      topicGroupId: answer.topicGroupId,
      name: getFullName(getRespondentById(report, answer.respondentId)),
      team: teamName.teamName,
      date: min(answer.messages.map((m) => new Date(m.date))),
      messages: sortBy(
        prop<string, any>('date'),
        answer.messages
          .filter((message) => (onlyOriginalAnonymity ? !message.originalAnonymous : !message.anonymous))
          .map(prepareMessage),
      ),
    };
  });

  const sortedAnswers = sortWith(
    [
      (a, b) => {
        return a.name.localeCompare(b.name);
      },
    ],
    alerts,
  );

  if (withAnonymous) {
    const numberOfRespondents = alertAnswers.reduce((acc, cur) => {
      if (cur.messages.some((message) => message.anonymous)) {
        return acc + 1;
      }
      return acc;
    }, 0);
    const messages = alertAnswers.reduce(
      (acc, cur) => {
        return [...acc, ...cur.messages.filter((message) => message.anonymous)];
      },
      [] as NonNullable<NonNullable<Report['responses']>[0]['messages']>,
    );
    if (messages.length > 0) {
      sortedAnswers.unshift({
        respondentId: ANONYMOUS_RESPONDENT_ID,
        surveyId: ANONYMOUS_RESPONDENT_ID,
        topicGroupId: ANONYMOUS_RESPONDENT_ID,
        name: `${t('anonymousAnswers')} (${numberOfRespondents})`,
        team: t('allTeams'),
        messages: messages.map(prepareMessage),
        date: min(messages.map((m) => new Date(m.date))),
      });
    }
  }

  return sortedAnswers;
};
// I spend an hour on trying to come up with a better type then T extends any, but shows errors where it's used
export const getRespondentsWithMessage = <T extends any>(responses: T[] | null | undefined): T[] => {
  return responses
    ? (responses as any).filter((response: any) => (response.messages ? response.messages.length > 0 : false))
    : [];
};

export const getRootTeams = (report: IReport): Report['teams'] => {
  return getTeamsById(report.teams!, report.reportRoots);
};

export const filterTeamsToShow = (
  rootTeams: NonNullable<Report['teams']>,
  allTeams: NonNullable<Report['teams']>,
  originalRootTeamsCount: number,
) => {
  const memberTeams = getTeamsById(allTeams, flatten<string>(rootTeams.map((root) => root.memberTeams || [])));
  return concatTeamsToShow(rootTeams, memberTeams, originalRootTeamsCount);
};

export const concatTeamsToShow = (
  rootTeams: NonNullable<Report['teams']>,
  memberTeams: NonNullable<Report['teams']>,
  originalRootTeamsCount: number,
) => {
  return originalRootTeamsCount === 1
    ? memberTeams.length === 0
      ? rootTeams
      : [...memberTeams, ...rootTeams]
    : rootTeams;
};

export const getTeamsToShow = (report: IReport, includeSubTeams = false) => {
  const rootTeams = getRootTeams(report);
  return filterTeamsToShow(rootTeams!, report.teams!, includeSubTeams ? 1 : report.reportRoots.length);
};

export type ReportRespondentsMap = { [key: string]: boolean };

export const getRespondentsMap = (report: IReport): ReportRespondentsMap => {
  return report.respondents.reduce((acc, current) => {
    acc[current.id] = true;
    return acc;
  }, {});
};

export const isWidgetTraversable = (
  teams: any[],
  widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0],
) => {
  return teams.length !== 1 && !any((df) => df.operator === 'team', widget.displayFilters || []);
};

export const getTeamsById = (allTeams: NonNullable<Report['teams']>, parents: string[]) => {
  return allTeams.filter((team) => contains(team.id, parents));
};

export const getTeamsByTeamLeaderId = (report: IReport, leaders: string[]) => {
  return report.teams?.filter((team) => contains(team.teamLeader, leaders));
};

export const isTeamUnit = (team: NonNullable<Report['teams']>[0]): boolean =>
  !!team.memberTeams && team.memberTeams.length > 0;

export const getTeamLabel = (team: NonNullable<Report['teams']>[0], t: any, directMembers: boolean = false) =>
  isTeamUnit(team) ? (directMembers ? t('directMembers') : t('unit')) : t('team');

export const getTeamsLabel = (teams: NonNullable<Report['teams']>, t: any) => {
  return teams.length > 0
    ? teams
        .map((team) =>
          team.teamName.trim().length > 0 ? `${getTeamLabel(team, t)}: ${team.teamName}` : t('withoutSupervisor'),
        )
        .join(', ')
    : '';
};

export const getSectionInfo = (section: NonNullable<ReportDefinition['sections']>[0], t: any) => {
  return {
    id: section.id,
    headingLocal: section.headingLocal.trim().length > 0 ? section.headingLocal : t('results'),
    descriptionLocal: section.descriptionLocal ?? '',
  };
};

const isGlobalFilterActive = (globalFilters: Filter[] | null) =>
  (globalFilters || []).some((filter) =>
    ['unit-teams', 'response', 'position', 'recruiter', 'manager'].includes(filter.operator),
  );

export const isDataFiltered = (globalFilters: Filter[] | null, hasLocalFilters?: boolean) =>
  !!hasLocalFilters || isGlobalFilterActive(globalFilters);

export const getEmptyState = (globalFilters: Filter[] | null, t: any, hasLocalFilters?: boolean) =>
  isDataFiltered(globalFilters, hasLocalFilters) ? t('x') : t('noData');

export const getMessagesWithQuestionIndex2 = (
  report: IReport,
  questionIndex: string,
  options: { filterEmpty?: boolean } = {},
) => {
  const responses = report.responses
    ? flatten<
        NonNullable<NonNullable<Report['responses']>[0]['messages']>[0] & {
          respondentId: string;
        }
      >(
        report.responses
          .map((response) => {
            const answers = response.messages
              ? response.messages.filter((message) => message.questionDefinitionIndex === questionIndex)
              : undefined;
            return answers
              ? answers.map((answer) => ({
                  ...answer,
                  respondentId: response.respondentId,
                }))
              : undefined;
          })
          .filter(notEmpty),
      ).filter(notEmpty)
    : [];

  return options.filterEmpty ? responses.filter((comment) => (comment.text || '').trim().length > 0) : responses;
};

export const getQuestionDefinitionByIndex = memoizeWith(
  (report: IReport | ProcessReport, questionIndex: string) => {
    return report.identifier + (report.metadata?.language?.code || '') + questionIndex;
  },
  (report: IReport | ProcessReport, questionIndex: string) => {
    const question = report.questions?.find((currentQuestion) => currentQuestion.index === questionIndex);
    if (!question) {
      return null;
    }
    if (!question.answersLocal) {
      return question;
    }

    return question;
  },
);

export const getQuestionDefinitionWithAnswers = memoizeWith(
  (report: IReport, widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0]) => {
    return report.identifier + (report.metadata?.language?.code || '') + widget.questionDefinitionIndex;
  },
  (report: IReport, widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0]) => {
    const question = getQuestionDefinitionByIndex(report, widget.questionDefinitionIndex || '');

    if (question) {
      question.answersToHighlight = widget.answersToHighlight;
    }
    return { question, answers: prepareAnswersForQuestion(question) };
  },
);

export const getRespondentById = (report: IReport | ProcessReport, respondentId: string) =>
  report.respondents.find((respondent) => respondent.id === respondentId);

const getMessageById = (report: IReport, messageId: string) =>
  flatten<NonNullable<NonNullable<Report['responses']>[0]['messages']>[0]>(
    (report.responses || []).map((r) => r.messages || []),
  )
    .filter(notEmpty)
    .find((m) => m.id === messageId);

const getResponseByRespondentId = (report: IReport, respondentId: string) =>
  (report.responses || []).find((response) => response.respondentId === respondentId);

export const getTeamName = (teamName: string, t: any) => {
  return teamName.trim().length > 0 ? teamName : t('withoutSupervisor');
};

export const getTeamNameByRespondentId = (report: IReport | ProcessReport, respondentId: string, t: any) => {
  const respondentsTeam = report.teams?.find((team) => contains(respondentId, team.directMembers || [])) || '';
  return respondentsTeam
    ? {
        teamId: respondentsTeam.id,
        teamName: getTeamName(respondentsTeam.teamName, t),
        teamLabel: getTeamLabel(respondentsTeam, t),
      }
    : { teamId: '', teamName: '', teamLabel: '' };
};

export const getComments = (report: IReport, questionIndex: string | null, t: any) => {
  if (questionIndex === null) {
    return [];
  }

  const comments = getMessagesWithQuestionIndex(report, questionIndex, {
    filterEmpty: true,
  });

  const sortFn = (
    a: NonNullable<NonNullable<Report['responses']>[0]['messages']>[0],
    b: NonNullable<NonNullable<Report['responses']>[0]['messages']>[0],
  ) => (b.text || '').trim().length - (a.text || '').trim().length;

  return sort(sortFn, comments).map((comment) => {
    const { teamId, teamName } = getTeamNameByRespondentId(report, comment.respondentId, t);

    return {
      id: comment.id,
      author: !comment.anonymous ? getFullName(getRespondentById(report, comment.respondentId)) : undefined,
      teamId,
      teamName,
      text: comment.text || '',
      anonymous: comment.anonymous,
      nonAnonymizedRespondentId: comment.respondentId,
    };
  });
};

export const getAnswersForQuestionWithValue = (
  report: IReport,
  questionDefinitionIndex: string,
  values: string[] | null,
  type: string,
) => {
  const messages = getMessagesWithQuestionIndex(report, questionDefinitionIndex);

  return type === 'MULTISELECT'
    ? messages.filter((message) => any((val) => contains(val, values || []), (message.value || '').split(',')))
    : messages.filter((message) => contains(message.value, values || []));
};

export interface IFilterDescription {
  answers: Array<{ answer: string; responses: number }>;
  question: string;
}

export const getFilterDescriptions = (
  titleFilters: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0]['titleFilters'],
  report: IReport,
) =>
  titleFilters &&
  titleFilters
    .map((filter) => {
      const localQuestion = getQuestionDefinitionByIndex(report, filter.questionDefinitionIndex);
      if (!localQuestion) {
        return undefined;
      }

      const localAnswers = localQuestion.answersLocal
        ? localQuestion.answersLocal
            .map((answer, idx) => ({
              answer,
              idx,
              valueEqual: contains(idx, (filter.values || []).map((value) => Number.parseInt(value, 10)) || []),
            }))
            .filter((ans) => ans.valueEqual)
            .map((ans) => ({
              answer: ans.answer,
              responses: getAnswersForQuestionWithValue(
                report,
                localQuestion.index,
                [ans.idx.toString()],
                getQuestionType(localQuestion),
              ).length,
            }))
        : [];

      return {
        answers: localAnswers,
        question: localQuestion.text,
      };
    })
    .filter(Boolean);

export const getWidgetDescription = (
  sectionReport: IReport,
  widgetReport: IReport,
  widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0],
  displayFilters: Filter[] | null,
  t: any,
  loading?: boolean,
) => {
  if (widget.descriptionLocal) {
    return widget.descriptionLocal;
  }

  if (!widget.questionDefinitionIndex || widget.descriptionLocal === null) {
    return '';
  }

  const questionDefinition = getQuestionDefinitionByIndex(widgetReport, widget.questionDefinitionIndex);
  if (!questionDefinition) {
    return '';
  }

  const type = getQuestionType(questionDefinition);

  const options = questionDefinition.answersLocal ? questionDefinition.answersLocal.length : '';
  let questionDescription = '';
  if (type === 'SELECT') {
    questionDescription = t('WIDGET_DESCRIPTION_SELECT', { options });
  }
  if (type === 'MULTISELECT') {
    questionDescription = t('WIDGET_DESCRIPTION_MULTISELECT', { options });
  }
  if (loading) {
    return questionDescription;
  }

  const sectionFilter = displayFilters
    ? displayFilters.find((filter) => filter.operator === 'response' && !!filter.questionDefinitionIndex)
    : undefined;
  const sectionQuestionDefinition = sectionFilter
    ? getQuestionDefinitionByIndex(widgetReport, sectionFilter.questionDefinitionIndex || '')
    : null;
  const answered = uniq(
    getMessagesWithQuestionIndex(widgetReport, questionDefinition.index, {
      filterEmpty: type === 'FREETEXT',
    }).map((answer) => answer.responseId),
  ).length;

  const total = sectionQuestionDefinition
    ? getAnswersForQuestionWithValue(
        sectionReport,
        sectionQuestionDefinition.index,
        cleanArray(sectionFilter?.values),
        type,
      ).length
    : getRespondentsWithMessage(widgetReport.responses).map((response) => response.respondentId).length;

  const percent = total === 0 ? 0 : Math.round((answered / total) * 100);

  const baseStatistics = t('WIDGET_DESCRIPTION', { percent, answered, total });

  return baseStatistics + questionDescription;
};

export const getQuestionType = (question: NonNullable<Report['questions']>[0]) => {
  if (question.type === QuestionType.Select && (question.selectedRange === '1' || question.selectedRange === '0-1')) {
    return QuestionType.Select;
  }
  if (question.type === QuestionType.Select) {
    return QuestionType.Multiselect;
  }
  return question.type;
};

export const getTeamsOnLevel = (
  report: IReport,
  level: number,
  currentLevel: number,
  team?: NonNullable<Report['teams']>[0],
): string[] => {
  return ++currentLevel === level
    ? (team && team.memberTeams) || []
    : team
      ? flatten<string>(
          (team.memberTeams || []).map((mt) =>
            getTeamsOnLevel(report, level, currentLevel, report.teams?.find((t) => t.id === mt)),
          ),
        )
      : [];
};

export const getRootTeamsForLevel = (report: IReport, nextLevel: number): string[] => {
  if (nextLevel - 1 === 0) {
    return report.reportRoots;
  }

  if (nextLevel - 1 > 0) {
    return flatten<string>(getRootTeams(report)?.map((rt) => getTeamsOnLevel(report, nextLevel - 1, 0, rt))!);
  }
  return [];
};

export const getTeamsUntilLevel = (
  report: IReport,
  level: number,
  currentLevel: number,
  team?: NonNullable<Report['teams']>[0],
): string[] => {
  return (
    (team && [
      team.id,
      ...(team.memberTeams || []),
      ...((currentLevel < level &&
        flatten<string>(
          (team.memberTeams || []).map((mt) =>
            getTeamsUntilLevel(report, level, currentLevel++, report.teams?.find((t) => t.id === mt)),
          ),
        )) ||
        []),
    ]) ||
    []
  );
};

// when we have mix of root teams and second layer teams in one graph
// , we have to filter out root team subtree
export const getFilterForRootTeams = (report: IReport, team: NonNullable<Report['teams']>[0]) => {
  return report.reportRoots.length === 1 && contains(team.id, report.reportRoots)
    ? [{ operator: 'respondent', values: team.directMembers || [] }]
    : [{ operator: 'unit', values: [team.id] }];
};

const getSubteamsRespondentIdsForTeamIds = (report: IReport, teamIds: string[]) => {
  const respondentIds: string[] = [];
  let teams = getTeamsById(report.teams!, teamIds);

  while (true) {
    teams = getTeamsById(report.teams!, flatten<string>(teams.map((team) => team.memberTeams || [])));

    respondentIds.push(...flatten<string>(teams.map((team) => team.directMembers || [])));

    if (teams.length <= 0) {
      break;
    }
  }

  return respondentIds;
};

export const getAllSubTeams = (teams: AggregatedTeams, team: NonNullable<Report['teams']>[0]): any[] => {
  if (!team.memberTeams) {
    return [];
  }
  return [team.memberTeams, ...team.memberTeams.map((memberTeamId) => getAllSubTeams(teams, teams[memberTeamId].team))];
};

export const getQuestionsForGlobalFilter = (globalReport: IReport, filters: Filter[] | null): FilterOption[] => {
  const filterOptions = globalReport.questions
    ?.filter(
      (question) =>
        question.type === 'SELECT' &&
        !question.reportIndex &&
        globalReport.reportDefinition?.sections?.some(
          (section) => section.widgets?.some((widget) => widget.questionDefinitionIndex === question.index),
        ),
    )
    .map((question) => {
      const report = filterReport(
        globalReport,
        (filters || []).filter((filter) => filter.questionDefinitionIndex !== question.index),
      );
      return {
        section: question.text,
        index: question.index,
        values: (question.answersLocal || []).map((answer, index) => ({
          id: index,
          name: answer,
          count: getAnswersForQuestionWithValue(
            (filters || []).some(
              (f) =>
                f.operator === 'unit-teams' ||
                f.operator === 'position' ||
                f.operator === 'recruiter' ||
                f.operator === 'manager',
            )
              ? report
              : globalReport,
            question.index,
            [index.toString()],
            getQuestionType(question),
          ).length,
          selected: filters
            ? filters.filter(
                (filter) =>
                  filter.operator === 'response' &&
                  filter.questionDefinitionIndex === question.index &&
                  contains(index.toString(), filter.values || []),
              ).length > 0
            : false,
        })),
      };
    });
  filterOptions?.sort((q1, q2) => {
    const section1 = globalReport.reportDefinition?.sections?.find(
      (s) => s.widgets?.some((w) => w.questionDefinitionIndex === q1.index),
    );
    const section2 = globalReport.reportDefinition?.sections?.find(
      (s) => s.widgets?.some((w) => w.questionDefinitionIndex === q2.index),
    );
    if (!section1 || !section2) {
      return section1 ? 1 : section2 ? -1 : 0;
    }
    if (section1.id !== section2.id) {
      return parseInt(section1.id, 10) - parseInt(section2.id, 10);
    }

    const widgetIndex1 = section1.widgets?.findIndex((w) => w.questionDefinitionIndex === q1.index);
    const widgetIndex2 = section2.widgets?.findIndex((w) => w.questionDefinitionIndex === q2.index);
    if (widgetIndex1 === undefined || widgetIndex1 === -1 || widgetIndex2 === undefined || widgetIndex2 === -1) {
      return 0;
    }

    return widgetIndex1 - widgetIndex2;
  });

  return filterOptions || [];
};

const getAllSubwidgetsForWidget = (
  widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0],
  globalWidgets: WidgetType[],
): NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0][] => {
  const widgetsToAdd = globalWidgets.filter((w) => w.afterWidgetId === widget.id);
  if (widgetsToAdd) {
    return [
      widget,
      ...flatten<NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0]>(
        widgetsToAdd.map((widgetToAdd) => getAllSubwidgetsForWidget(widgetToAdd.widget, globalWidgets)),
      ),
    ];
  }

  return [];
};

const flattenWidgetsTree = (
  sectionWidgets: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0][],
  globalWidgets: WidgetType[],
) => {
  return flatten<NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0]>(
    sectionWidgets.map((widget) => getAllSubwidgetsForWidget(widget, globalWidgets)),
  );
};

export type WidgetType = {
  afterWidgetId: string;
  subSectionId?: string;
  organizationLevel?: number;
  widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0];
};

export const addWidgets = (report: IReport, widgets: WidgetType[] | null) => {
  if (!widgets || widgets.length === 0) {
    return report;
  }

  const newReport: IReport = JSON.parse(JSON.stringify(report));

  if (newReport.reportDefinition?.sections) {
    newReport.reportDefinition.sections = newReport.reportDefinition.sections.map((section) => ({
      ...section,
      widgets: section.widgets ? flattenWidgetsTree(section.widgets, widgets) : [],
    }));
  }

  newReport.widgets = JSON.stringify(
    sortBy((w) => w.afterWidgetId)(uniq([...JSON.parse(newReport.widgets || '[]'), ...widgets])),
  );

  return newReport;
};

export const anonymizeReport = (report: IReport) => {
  const newReport: IReport = JSON.parse(JSON.stringify(report));

  newReport.questions?.forEach((question) => {
    const messages = sortBy(prop('respondentId'), getMessagesWithQuestionIndex(report, question.index));
    if (messages.filter((m) => m.anonymous).length === 1) {
      const messageToBeAnonymized = messages.find((m) => !m.anonymous);
      if (messageToBeAnonymized) {
        const reportMessage = getMessageById(newReport, messageToBeAnonymized.id);
        if (reportMessage) {
          reportMessage.anonymous = true;
          const respondentToBeAnonymized = getResponseByRespondentId(report, messageToBeAnonymized.respondentId);
          if (respondentToBeAnonymized) {
            respondentToBeAnonymized.anonymous = true;
            respondentToBeAnonymized.additionallyAnonymized = true;
          }
        }
      }
    }
  });

  if ((newReport.responses || []).filter((r) => r.anonymous).length === 1) {
    const respondentToBeAnonymized = (newReport.responses || []).find((r) => !r.anonymous);
    if (respondentToBeAnonymized) {
      respondentToBeAnonymized.anonymous = true;
      respondentToBeAnonymized.additionallyAnonymized = true;
    }
  }

  return newReport;
};

const getParentWidgetId = (
  widgetId: string,
  widgets: WidgetType[],
  parentMap: {
    [widgetId: string]: string;
  },
): string => {
  const parentWidgetId = parentMap[widgetId];
  if (parentWidgetId) {
    return parentWidgetId;
  }
  const addedWidget = widgets.find((checkedWidget) => checkedWidget.widget.id === widgetId);
  parentMap[widgetId] = addedWidget ? getParentWidgetId(addedWidget.afterWidgetId, widgets, parentMap) : widgetId;
  return parentMap[widgetId];
};

export type ReducedWidgets = {
  groupedWidgets: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0][][];
  parentWidgetMap: {
    [widgetId: string]: string;
  };
  widgetArrayIndexMap: {
    [widgetId: string]: number;
  };
};

export type GroupedWidgetsWithIndexes = {
  widgets: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0][];
  baseIndex: number;
};

export const groupWidgetsIntoGroups = (
  widgets: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0][],
  globalWidgets: WidgetType[],
) => {
  const reducedWidgets = widgets.reduce(
    (acc: ReducedWidgets, widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0]) => {
      const parentWidgetId = getParentWidgetId(widget.id, globalWidgets, acc.parentWidgetMap);

      const parentArray = acc.groupedWidgets[acc.widgetArrayIndexMap[parentWidgetId]];

      if (!parentArray) {
        acc.widgetArrayIndexMap[parentWidgetId] = acc.groupedWidgets.length;
        acc.groupedWidgets.push([widget]);
      } else {
        parentArray.push(widget);
      }
      return acc;
    },
    {
      groupedWidgets: [],
      parentWidgetMap: {},
      widgetArrayIndexMap: {},
    },
  );
  // Calculate count of widgets in all previous groups for z-indexes
  const groupsWithPreparedIndexes = reducedWidgets.groupedWidgets.reduce(
    (
      acc: GroupedWidgetsWithIndexes[],
      currentWidgets: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0][],
      currentIndex,
    ) => {
      const baseIndex =
        currentIndex > 0 ? acc[currentIndex - 1].baseIndex + acc[currentIndex - 1].widgets.length + 1 : 0;
      acc.push({
        widgets: currentWidgets,
        baseIndex,
      });
      return acc;
    },
    [],
  );
  return groupsWithPreparedIndexes;
};

export type FilterOption = {
  section: string;
  index: string;
  values: Array<{
    id: number;
    name: string;
    count: number;
    selected: boolean;
  }>;
};

export type Filter = {
  editable?: boolean;
  values?: (string | null | undefined)[] | null;
  questionDefinitionIndex?: string;
  operator: string;
};

export const filterReport = memoizeWith(
  (report: IReport, filters?: Filter[] | null) => {
    return (
      report.identifier +
      (report.metadata?.language?.code || '') +
      JSON.stringify(sortBy((f) => f.operator)(uniq([...JSON.parse(report.filters || '[]'), ...(filters || [])]))) +
      report.widgets +
      !!report.responses
    );
  },
  (report: IReport, filters?: Filter[] | null) => {
    if (!filters || filters.length === 0) {
      return report;
    }

    const newReport: IReport = JSON.parse(JSON.stringify(report));

    // global filter without filter value
    const emptyWidgetFilters = filters.filter((filter) => filter.operator === 'empty-widgets');
    if (emptyWidgetFilters.length > 0 && newReport.reportDefinition?.sections) {
      newReport.reportDefinition.sections = newReport.reportDefinition.sections.map((section) => ({
        ...section,
        widgets: (section.widgets || []).filter(
          (widget) =>
            widget.questionDefinitionIndex &&
            getMessagesWithQuestionIndex(newReport, widget.questionDefinitionIndex, { filterEmpty: true }).length > 0,
        ),
      }));
    }

    const responseValueFilters = filters.filter((filter) => filter.operator === 'response-value');
    // AND for filter, OR for values
    if (newReport.responses && responseValueFilters.length > 0) {
      newReport.responses = newReport.responses
        .map((response) => ({
          ...response,
          messages: response.messages
            ? response.messages.filter((message) =>
                all(
                  (filter) =>
                    message.questionDefinitionIndex === filter.questionDefinitionIndex &&
                    contains(message.value, filter.values || []),
                  responseFilters,
                ),
              )
            : [],
        }))
        .filter((response) => response.messages.length > 0);
    }
    const anonymityFilters = filters.filter((filter) => filter.operator === 'anonymity-filter');
    // AND for filter, OR for values
    if (newReport.responses && anonymityFilters.length > 0) {
      newReport.responses = newReport.responses
        .map((response) => ({
          ...response,
          messages: response.messages ? response.messages.filter((message) => message.anonymous) : [],
        }))
        .filter((response) => response.messages.length > 0);
    }

    // filter respondents with response
    const responseFilters = filters.filter((filter) => filter.operator === 'response');
    // AND for filter, OR for values
    if (newReport.responses && responseFilters.length > 0) {
      newReport.responses = newReport.responses.filter((response) =>
        response.messages && response.messages.length > 0
          ? all(
              (filter) =>
                any((message) => {
                  if (filter.questionDefinitionIndex === message.questionDefinitionIndex) {
                    const question = getQuestionDefinitionByIndex(newReport, filter.questionDefinitionIndex);
                    if (!question) {
                      return false;
                    }
                    return getQuestionType(question) === 'MULTISELECT'
                      ? any((val) => contains(val, (message.value || '').split(',')), filter.values || [])
                      : contains(message.value, filter.values || []);
                  }
                  return false;
                }, response.messages || []),
              responseFilters,
            )
          : false,
      );
      newReport.respondents = newReport.respondents.filter((respondent) =>
        contains(
          respondent.id,
          (newReport.responses || []).map((response) => response.respondentId),
        ),
      );
    }

    // filter rootTeams
    const rootTeamsFilters = filters.filter((filter) => filter.operator === 'root');
    if (rootTeamsFilters.length > 0) {
      newReport.reportRoots = cleanArray(
        rootTeamsFilters.reduce((acc, f2) => intersection(acc, f2.values || []), rootTeamsFilters[0].values || []),
      );
      filters.push({
        operator: 'unit',
        values: newReport.reportRoots,
      });
    }
    // take only team direct members
    // convert team filters to respondentFilter
    const teamFilters = filters.filter((filter) => filter.operator === 'team');
    // values as teamId, OR for values
    teamFilters.forEach((filter) => {
      filters.push({
        operator: 'respondent',
        values: getDirectMembersFromTeamIds(newReport.teams || [], cleanArray<string>(filter.values)),
      });
    });
    // converts unit filter to respondents filter
    const unitFilters = filters.filter((filter) => filter.operator === 'unit');
    unitFilters.forEach((filter) => {
      filters.push({
        operator: 'respondent',
        values: getRespondentIdsForTeamIds(newReport.teams || [], cleanArray<string>(filter.values)),
      });
    });
    // converts unit-teams filter (OR) to respondents filter
    const unitTeamsFilters = filters.filter((filter) => filter.operator === 'unit-teams');
    unitTeamsFilters.forEach((filter) => {
      if (filter.values) {
        const teamValues = filter.values
          .filter((value) => value?.startsWith('team_')!)
          .map((value) => value?.replace('team_', '')!);
        const unitValues = filter.values
          .filter((value) => value?.startsWith('unit_')!)
          .map((value) => value?.replace('unit_', '')!);
        const respondentIds = [
          ...getDirectMembersFromTeamIds(newReport.teams || [], teamValues),
          ...getRespondentIdsForTeamIds(newReport.teams || [], unitValues),
        ];

        filters.push({
          operator: 'respondent',
          values: respondentIds,
        });
      }
    });

    // take only subteams for this team
    // converts subteams filter to respondents filter
    const subteamsFilters = filters.filter((filter) => filter.operator === 'subteams');
    subteamsFilters.forEach((filter) => {
      filters.push({
        operator: 'respondent',
        values: getSubteamsRespondentIdsForTeamIds(newReport, cleanArray(filter.values)),
      });
    });

    // filter respondents
    const respondentsFilters = filters.filter((filter) => filter.operator === 'respondent');
    respondentsFilters.forEach((filter) => {
      const respondentIds = filter.values || [];
      newReport.respondents = newReport.respondents.filter((respondent) => contains(respondent.id, respondentIds));
      newReport.teams = newReport.teams
        ?.map((team) => ({
          ...team,
          directMembers: (team.directMembers || []).filter((member) => contains(member, respondentIds)),
        }))
        .filter((team) => !(team.memberTeams && team.memberTeams.length === 0 && team.directMembers.length === 0));
      newReport.responses = (newReport.responses || []).filter((response) =>
        contains(response.respondentId, respondentIds),
      );
    });

    // filter position, recruiter, manager
    const filteredPositions = filters.find((filter) => filter.operator === 'position')?.values;
    const filteredRecruiters = filters.find((filter) => filter.operator === 'recruiter')?.values;
    const filteredManagers = filters.find((filter) => filter.operator === 'manager')?.values;
    if (
      report.additionalRespondentData?.length! > 0 &&
      (filteredPositions?.length! > 0 || filteredRecruiters?.length! > 0 || filteredManagers?.length! > 0)
    ) {
      const filteredRespondentData = report.additionalRespondentData!.filter((data) => {
        if (filteredPositions?.length! > 0 && !filteredPositions!.includes(data.position)) {
          return false;
        }
        if (filteredRecruiters?.length! > 0 && !filteredRecruiters!.includes(data.recruiter)) {
          return false;
        }
        if (filteredManagers?.length! > 0 && !filteredManagers!.some((manager) => data.managers?.includes(manager!))) {
          return false;
        }
        return true;
      });
      const filteredChannelIds = filteredRespondentData.map((data) => data.channelId);
      newReport.responses = (newReport.responses || []).filter((response) => filteredChannelIds.includes(response.id));
      const filteredRespondentIds = uniq(
        newReport.responses
          .filter((response) => (response.messages || []).length > 0)
          .map((response) => response.respondentId),
      );
      newReport.respondents = newReport.respondents.filter((respondent) =>
        filteredRespondentIds.includes(respondent.id),
      );
    }

    // filter source-id
    const sourceIdFilters = filters.filter((filter) => filter.operator === FILTER_OPERATOR.SOURCE);
    sourceIdFilters.forEach((filter) => {
      const sourceIds = filter.values || [];
      const respondentIds =
        sourceIds.length > 0
          ? report.source
              ?.filter(({ sourceId }) => sourceIds.includes(sourceId))
              .map(({ respondentId }) => respondentId) || []
          : newReport.respondents.map(({ id }) => id);

      newReport.respondents = newReport.respondents.filter((respondent) => contains(respondent.id, respondentIds));
      newReport.teams = newReport.teams
        ?.map((team) => ({
          ...team,
          directMembers: (team.directMembers || []).filter((member) => contains(member, respondentIds)),
        }))
        .filter((team) => !(team.memberTeams && team.memberTeams.length === 0 && team.directMembers.length === 0));
      newReport.responses = (newReport.responses || []).filter((response) =>
        contains(response.respondentId, respondentIds),
      );
    });

    newReport.filters = JSON.stringify(
      sortBy((f) => f.operator)(uniq([...JSON.parse(newReport.filters || '[]'), ...filters])),
    );

    return newReport;
  },
);

export const getGraphLabel = (
  report: IReport,
  widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0],
  questionIndex: string,
  traversable: boolean,
  t: any,
) => {
  const total = getRespondentsWithMessage(report.responses).length;
  const answered = getMessagesWithQuestionIndex(report, questionIndex).length;

  const percent = total === 0 ? 0 : Math.round((answered / total) * 100);

  const organizationLevelFilter = (widget.displayFilters || []).find((f) => f.operator === 'organization-level');

  const rootTeamFilters = (widget.displayFilters || []).find(
    (f) => f.operator === 'root' || f.operator === 'team' || (!organizationLevelFilter && f.operator === 'unit'),
  );

  const labelTeam = rootTeamFilters && getTeamsById(report.teams!, rootTeamFilters.values || [])[0];
  const directMembers = !!(
    rootTeamFilters &&
    rootTeamFilters.operator === 'team' &&
    labelTeam &&
    labelTeam.memberTeams &&
    labelTeam.memberTeams.length
  );

  return {
    label: labelTeam
      ? `${getTeamLabel(labelTeam, t, directMembers)}: ${labelTeam.teamName}`
      : (!rootTeamFilters &&
          organizationLevelFilter &&
          organizationLevelFilter.values &&
          t('teamsInLevel', {
            postProcess: 'interval',
            count: Number.parseInt(organizationLevelFilter.values[0], 10),
          })) ||
        undefined,
    description: rootTeamFilters ? ', ' + t('WIDGET_DESCRIPTION', { percent, answered, total }) : undefined,
  };
};

export const calculateWithoutIssuesRate = (
  respondents: NonNullable<Report['respondents']>,
  responses: ProcessReport['responses'],
  onlyRespondentsWithAnswers?: boolean,
) => {
  let filteredRespondents = respondents;

  if (onlyRespondentsWithAnswers) {
    const respondentsIdsWithAnswer = responses.map((response) => response.respondentId);
    filteredRespondents = respondents.filter((responsent) => respondentsIdsWithAnswer.includes(responsent.id));
  }

  const filteredRespondentsIds = filteredRespondents.map((respondent) => respondent.id);
  const withIssueRespondents = Array.from(
    new Set(
      responses
        .filter(
          (resp) =>
            resp.messages?.find((message) => message.hasAlert) && filteredRespondentsIds.includes(resp.respondentId),
        )
        .map((resp) => resp.respondentId),
    ),
  );

  const answered = uniq(getRespondentsWithMessage(responses).map((response) => response.respondentId)).length;
  return {
    answered,
    withProblems: withIssueRespondents.length,
  };
};

export const getStatus = (surveys: any[]): ProcessStatus => {
  const result = surveys.reduce(
    (acc, survey) => {
      if ([SurveyStatus.Completed, SurveyStatus.Archived].includes(survey.status)) {
        acc.finished++;
      } else if (survey.status === SurveyStatus.Prepared) {
        acc.planned++;
      } else if (survey.status === SurveyStatus.Notstarted) {
        acc.notstarted++;
      } else {
        acc.inProgress++;
      }
      return acc;
    },
    { finished: 0, planned: 0, notstarted: 0, inProgress: 0 },
  );
  if (result.finished === surveys.length) {
    return ProcessStatus.Completed;
  } else if (result.planned === surveys.length) {
    return ProcessStatus.Prepared;
  } else if (result.notstarted + result.planned === surveys.length) {
    return ProcessStatus.Notstarted;
  }
  return ProcessStatus.Ongoing;
};
