import { WithTranslation, withTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { getAnonymityIcon, getAnonymityName } from '../lib/common';
import { GetMessagesQuery } from '../generated/hooks';
import { AnonymityName } from './StyledComponents';

interface IAnswerAnonymity {
  selectedAnonymity: NonNullable<GetMessagesQuery['messages']>[0]['anonymityLevel'];
  respondentName: string;
}

interface IProps extends WithTranslation {
  t: any;
}

const Icon = styled.span`
  display: inline-flex;
  margin-left: 7px;
  vertical-align: middle;

  svg {
    > path {
      fill: #bdbdbd;
    }
  }
`;

const Block = styled.span`
  font-size: 12px;
  color: #a2a1a1;
  white-space: nowrap;
`;

const AnswerAnonymity = ({ selectedAnonymity, respondentName, t }: IAnswerAnonymity & IProps) => (
  <Block>
    <AnonymityName>{getAnonymityName(selectedAnonymity, respondentName, t)}</AnonymityName>
    <Icon>{getAnonymityIcon(selectedAnonymity, 17)}</Icon>
  </Block>
);

export default withTranslation('header')(AnswerAnonymity);
