import * as React from 'react';
import { Modals } from '../chat/ChatWindow';

interface IProps {
  showModal: (
    modalName: Modals,
    show: boolean,
    redirectTo?: string,
  ) => (e: React.MouseEvent<HTMLElement> | null) => void;
  modal: Modals;
}

const Logout = (props: IProps) => {
  if (props.modal !== Modals.RESET_PASSWORD && props.modal !== Modals.EMAIL_SEND) {
    props.showModal(Modals.LOGIN, true)(null);
  }
  return <></>;
};

export default Logout;
