import { FC } from 'react';
import { Filter } from '../../../../lib/reportHelper';
import { SelectFilterSelected } from '../../../../components/SelectFilterHelpers';

interface IProps {
  placeholder: string;
  operator: string;
  filters: Filter[];
}

const CustomPlaceholder: FC<IProps> = ({ placeholder, operator, filters }) => {
  const selectedFilter = filters.find((filter) => filter.operator === operator);

  return (
    <SelectFilterSelected emptyPlaceholder={placeholder} selectedAnswers={(selectedFilter?.values as string[]) || []} />
  );
};

export default CustomPlaceholder;
