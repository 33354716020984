import { BodyMedium, useIsVisible } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { FC, Fragment, useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterDescription } from '../../lib/reportHelper';
import FilterDescription, { FilterTitleType } from './FilterDescription';
import { SideMenuContext } from './SideMenu';

interface IProps {
  id: string;
  header?: string | undefined;
  subHeader?: string | undefined;
  filterDescriptions?: IFilterDescription[];
  children: any;
  loading?: boolean;
  icomTag?: string;
  sectionHeading?: string;
  passedRef?: React.RefObject<HTMLDivElement>;
}

const ReportSection: FC<IProps> = ({
  id,
  header,
  subHeader,
  filterDescriptions,
  children,
  loading,
  icomTag,
  sectionHeading,
  passedRef,
}) => {
  const { t } = useTranslation('Report');

  const { handleSectionVisibility } = useContext(SideMenuContext);

  const sectionId = header && header.replace(/\W/g, '_');
  const ref = useRef(null);

  const handleVisibility = useCallback(
    (isVisible: boolean) => {
      handleSectionVisibility(id, isVisible);
    },
    [handleSectionVisibility, id],
  );

  useIsVisible(ref, handleVisibility);

  return (
    <Wrapper className={'mb-9'} id={id} data-cy={`report-section-${id}`} ref={passedRef}>
      {header && (
        <h2 ref={ref} className={'mb-0'} id={sectionId} data-icom={icomTag} data-section-id={sectionHeading}>
          {header}
        </h2>
      )}
      {subHeader && <BodyMedium className="mt-5">{subHeader}</BodyMedium>}
      <p className={'mt-4'}>
        {filterDescriptions?.map((fd, index) => (
          <Fragment key={fd?.answers?.[0]?.answer}>
            {!index ? t('SECTION_FILTER_RESPONDED') : t('SECTION_FILTER_RESPONDED').toLowerCase()}
            <FilterDescription
              answers={fd.answers}
              question={fd.question}
              hideNumbers={loading}
              filterTitleType={FilterTitleType.REPORT_SECTION}
            />
            {index + 1 < filterDescriptions.length ? t('ANSWER_SEPARATOR') : ''}
          </Fragment>
        ))}
      </p>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  scroll-margin-top: 80px;
`;

export default ReportSection;
