import { BarChart, CleanButton, FeelingValue, theme } from '@arnold/common';
import { CustomBarProps } from '@arnold/common/lib/components/charts/BarChart/BarChart';
import { ReactComponent as AddIcon } from '@arnold/common/src/assets/icons/AddIcon.svg';
import { ReactComponent as Close } from '@arnold/common/src/assets/icons/Close.svg';
import { ReactComponent as Refresh } from '@arnold/common/src/assets/icons/Refresh.svg';
import styled from '@emotion/styled/macro';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Rectangle } from 'recharts';
import Loading from '../../../components/Loading';
import {
  DateRangeInput,
  GetComparsionDataForProcessReportQuery,
  ReportSequenceSubscriptionSubscription,
  useGetComparsionDataForProcessReportLazyQuery,
} from '../../../generated/hooks';
import { BarLabel, PhaseTabs, calculatePercentage } from '../PhasesSection';
import TimeRangeInput from './TimeRangeInput';

interface IProps {
  accessToken: string;
  sequenceStart: Date;
  visible: boolean;
  activeTab: PhaseTabs;
  sequenceEnd: Date;
  getTopicUrl: (topicId: string) => string;
  teamsFilter: string[] | null;
  surveyGroup: NonNullable<NonNullable<ReportSequenceSubscriptionSubscription['getProcessReport']>['surveyGroup']>;
}

const BarChartContainer = styled.div<{ bars: number }>`
  padding-top: ${theme.spacing.g};
  overflow-x: auto;
  & > div {
    min-width: ${({ bars }) => Math.max(bars, 8) * 100}px !important;
  }
  & .recharts-surface {
    overflow: visible !important;
  }
  & .recharts-bar-rectangle {
    cursor: pointer;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FiltersContainer = styled.div`
  max-height: 70px;
  margin-bottom: ${theme.spacing.h};
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000000;

  & > div {
    width: 210px;
  }
`;

const RefreshButton = styled(Button)`
  position: relative;
  top: -${theme.spacing.c};
  padding-left: ${theme.spacing.g};
  padding-right: ${theme.spacing.g};
  & svg {
    margin-right: ${theme.spacing.d};
  }
  &:disabled path {
    fill: ${theme.colors.actionPrimary.disabled};
  }
`;
const AddButton = styled(CleanButton)`
  color: ${theme.colors.actionPrimary.default};
  text-decoration: none;

  & svg {
    margin-right: ${theme.spacing.d};
  }
  & path {
    fill: ${theme.colors.actionPrimary.default};
  }
  &:hover {
    color: ${theme.colors.actionPrimary.hover};
    path {
      fill: ${theme.colors.actionPrimary.hover};
    }
  }
  &:focus {
    color: ${theme.colors.actionPrimary.focused};
    path {
      fill: ${theme.colors.actionPrimary.focused};
    }
  }
`;

const getCountKeyFromDataKey = (dataKey: string) => dataKey.replace('y', 'count');
const getTotalKeyFromDataKey = (dataKey: string) => dataKey.replace('y', 'total');
const BarColors = {
  y1: theme.colors.actionPrimary.default,
  y2: theme.colors.actionInvertedSecondary.default,
  y3: theme.colors.chart.warning[1],
};
const HoveredBarColors = {
  y1: theme.colors.actionPrimary.active,
  y2: theme.colors.actionInvertedSecondary.active,
  y3: theme.colors.chart.warning[3],
};

const getBarChartData = (
  surveyGroup: NonNullable<NonNullable<ReportSequenceSubscriptionSubscription['getProcessReport']>['surveyGroup']>,
  userLanguages: string,
  activeTab: PhaseTabs,
  statistics?: NonNullable<GetComparsionDataForProcessReportQuery['getComparsionDataForProcessReport']>,
) => {
  return surveyGroup.steps.map((step, i) => {
    const stepTitle =
      step.processStep.stepTopicGroup.translations?.find((trans) => userLanguages.includes(trans.language.code))
        ?.value || step.processStep.stepTopicGroup.translations![0].value;
    const defaltCount = activeTab === PhaseTabs.Feelings ? 0.1 : 1;
    const defaltTotal = activeTab === PhaseTabs.Feelings ? 7 : 100;
    let count1 = defaltCount;
    let total1 = defaltTotal;
    let count2 = defaltCount;
    let total2 = defaltTotal;
    let count3 = defaltCount;
    let total3 = defaltTotal;

    if (statistics) {
      const stats1 = statistics.stepStatistics.find(
        (stats) => stats.stepId === step.processStep.id && stats.periodIndex === 0,
      );
      const stats2 = statistics.stepStatistics.find(
        (stats) => stats.stepId === step.processStep.id && stats.periodIndex === 1,
      );
      const stats3 = statistics.stepStatistics.find(
        (stats) => stats.stepId === step.processStep.id && stats.periodIndex === 2,
      );
      switch (activeTab) {
        case PhaseTabs.Responses:
          total1 = stats1?.statistics.responseRate.denominator ?? 0;
          count1 = stats1?.statistics.responseRate.numerator ?? 0;
          total2 = stats2?.statistics.responseRate.denominator ?? 0;
          count2 = stats2?.statistics.responseRate.numerator ?? 0;
          total3 = stats3?.statistics.responseRate.denominator ?? 0;
          count3 = stats3?.statistics.responseRate.numerator ?? 0;
          break;
        case PhaseTabs.Anonymous:
          total1 = stats1?.statistics.anonymityRate.denominator ?? 0;
          count1 = stats1?.statistics.anonymityRate.numerator ?? 0;
          total2 = stats2?.statistics.anonymityRate.denominator ?? 0;
          count2 = stats2?.statistics.anonymityRate.numerator ?? 0;
          total3 = stats3?.statistics.anonymityRate.denominator ?? 0;
          count3 = stats3?.statistics.anonymityRate.numerator ?? 0;
          break;
        case PhaseTabs.Problems:
          total1 = stats1?.statistics.withoutProblems.denominator ?? 0;
          count1 = stats1?.statistics.withoutProblems.numerator ?? 0;
          total2 = stats2?.statistics.withoutProblems.denominator ?? 0;
          count2 = stats2?.statistics.withoutProblems.numerator ?? 0;
          total3 = stats3?.statistics.withoutProblems.denominator ?? 0;
          count3 = stats3?.statistics.withoutProblems.numerator ?? 0;
          break;
        case PhaseTabs.Feelings:
          count1 = stats1?.avgMetric ? Math.round(stats1.avgMetric * 10) / 10 : 0;
          count2 = stats2?.avgMetric ? Math.round(stats2.avgMetric * 10) / 10 : 0;
          count3 = stats3?.avgMetric ? Math.round(stats3.avgMetric * 10) / 10 : 0;
          break;
      }
    }

    const y1 = activeTab === PhaseTabs.Feelings ? count1 : calculatePercentage(count1, total1);
    const y2 = activeTab === PhaseTabs.Feelings ? count2 : calculatePercentage(count2, total2);
    const y3 = activeTab === PhaseTabs.Feelings ? count3 : calculatePercentage(count3, total3);

    return {
      x: stepTitle,
      id: step.processStep.stepTopicGroup.id,
      step: i,
      y1: y1 === 0 ? defaltCount : y1,
      y2: y2 === 0 ? defaltCount : y2,
      y3: y3 === 0 ? defaltCount : y3,
      count1,
      total1,
      count2,
      total2,
      count3,
      total3,
    };
  });
};

export const filterValidRange = (f: DateRange | null) => (f?.to ? [{ ...f } as DateRangeInput] : []);

const getPeriodFromUrl = (params: URLSearchParams, baseKey: string) => {
  const period = params.get(baseKey);
  if (period && period !== 'null') {
    const [from, to] = period.split(',');
    return { from: new Date(from), to: new Date(to) };
  }
  return null;
};

const SequenceComparsion = ({
  accessToken,
  sequenceStart,
  sequenceEnd,
  surveyGroup,
  visible,
  activeTab,
  getTopicUrl,
  teamsFilter,
}: IProps) => {
  const { t, i18n } = useTranslation('SequenceComparsion');
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const inputRef = useRef<HTMLInputElement>(null);
  const TabTitles = {
    [PhaseTabs.Responses]: t('Report:RESPONSE_RATE'),
    [PhaseTabs.Anonymous]: t('Report:ANONYMITY_RATE'),
    [PhaseTabs.Problems]: t('Report:phasesNeedsAttention'),
    [PhaseTabs.Feelings]: t('Report:phasesFeeling'),
  };
  const [period, setPeriod] = useState<DateRange | null>(getPeriodFromUrl(params, 'timeRangePeriod1'));
  const [period2, setPeriod2] = useState<DateRange | null>(getPeriodFromUrl(params, 'timeRangePeriod2'));
  const [period3, setPeriod3] = useState<DateRange | null>(getPeriodFromUrl(params, 'timeRangePeriod3'));
  const [displayThird, setDisplayThird] = useState<boolean>(false);
  const periods = [period, period2, period3].flatMap(filterValidRange);
  const skip = periods.length <= 1;
  const [download, { data, loading, error }] = useGetComparsionDataForProcessReportLazyQuery();

  useEffect(() => {
    if (period?.from && period.to) {
      params.set('timeRangePeriod1', `${period.from.toISOString()},${period.to.toISOString()}`);
    }
    if (period2?.from && period2.to) {
      params.set('timeRangePeriod2', `${period2.from.toISOString()},${period2.to.toISOString()}`);
    }
    if (period3?.from && period3.to) {
      params.set('timeRangePeriod3', `${period3.from.toISOString()},${period3.to.toISOString()}`);
    }
    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, period2, period3]);

  useEffect(() => {
    if (!skip && !data && !loading) {
      download({
        variables: {
          accessToken,
          periods,
          teamsFilter,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, data, loading]);

  const filterRefCallback = useCallback((node: HTMLDivElement | null) => {
    // this is needed for cypress tests
    if (node) {
      (node as any).setPeriods = [setPeriod, setPeriod2, setPeriod3];
    }
  }, []);

  if (!visible) {
    return null;
  }

  if (error) {
    return <div>{t('Report:errorLoading')}</div>;
  }

  if (loading) {
    return <Loading />;
  }

  const barChartData = getBarChartData(
    surveyGroup,
    i18n.language,
    activeTab,
    !loading ? data?.getComparsionDataForProcessReport : undefined,
  );

  const StyledRectangle = styled(Rectangle)<{ fill: string; fillHovered: string }>`
    fill: ${(props) => props.fill};
    &:hover {
      fill: ${(props) => props.fillHovered};
    }
  `;

  const CustomBar = (props: CustomBarProps) => {
    let { fill } = props;
    let fillHovered = fill;
    const dk = props.tooltipPayload?.[0]?.dataKey;
    if (dk) {
      fill = BarColors[dk];
      fillHovered = HoveredBarColors[dk];
    }

    return <StyledRectangle {...props} fill={fill} fillHovered={fillHovered} />;
  };
  const calculatedWidthOfBarChart = surveyGroup.steps.length * (displayThird ? 120 : 100) + 140;
  const isWideChart = calculatedWidthOfBarChart > 896;

  return (
    <div className="py-6" data-cy="report-sequence-comparsion-filter" data-icom={'report-sequence-comparsion-filter'}>
      <Controls>
        <FiltersContainer ref={filterRefCallback} data-cy="report-sequence-period-filters">
          <TimeRangeInput
            sequenceStart={sequenceStart}
            sequenceEnd={new Date()}
            otherPeriods={[period2, period3].flatMap(filterValidRange)}
            value={period}
            onDateChange={(date) => {
              setPeriod(date);
              inputRef.current?.focus();
            }}
            label={`${t('period')} 1`}
          />
          <TimeRangeInput
            sequenceStart={sequenceStart}
            sequenceEnd={new Date()}
            value={period2}
            otherPeriods={[period, period3].flatMap(filterValidRange)}
            onDateChange={setPeriod2}
            label={`${t('period')} 2`}
            ref={inputRef}
          />
          {displayThird ? (
            <>
              <TimeRangeInput
                sequenceStart={sequenceStart}
                sequenceEnd={new Date()}
                value={period3}
                otherPeriods={[period, period2].flatMap(filterValidRange)}
                onDateChange={setPeriod3}
                label={`${t('period')} 3`}
              />
              <CleanButton className="mt-7" onClick={() => setDisplayThird(false)}>
                <Close />
              </CleanButton>
            </>
          ) : (
            <AddButton className="mt-6" onClick={() => setDisplayThird(true)} data-cy="report-sequence-add-period">
              <AddIcon /> {t('addPeriod')}
            </AddButton>
          )}
        </FiltersContainer>
        <div>
          <RefreshButton
            variant="primary"
            data-cy={`btn-modal-submit`}
            onClick={() => {
              download({
                variables: {
                  accessToken,
                  periods,
                },
              });
            }}
            disabled={skip}
          >
            <Refresh />
            {t('SequenceComparsion:refresh')}
          </RefreshButton>
        </div>
      </Controls>
      <BarChartContainer bars={surveyGroup.steps.length}>
        <BarChart
          height={300}
          width={isWideChart ? calculatedWidthOfBarChart : undefined}
          legendAllignment={isWideChart ? 'left' : 'center'}
          xAxisHeight={50}
          barSize={30}
          noBackground
          showGrid
          yTicks={activeTab === PhaseTabs.Feelings ? [1, 2, 3, 4, 5, 6, 7] : [25, 50, 75, 100]}
          topLabel
          onClick={(data) => history.push(getTopicUrl(data.id))}
          CustomBar={CustomBar}
          CustomYAxisTick={
            activeTab === PhaseTabs.Feelings
              ? (props) => {
                  return (
                    <svg x={props.x - 16} y={props.y - 8}>
                      <foreignObject width="16" height="16">
                        <FeelingValue smaller value={Number(props.payload.value)}>
                          {props.payload.value}
                        </FeelingValue>
                      </foreignObject>
                    </svg>
                  );
                }
              : undefined
          }
          CustomXAxisTick={(props) => {
            const data = barChartData.find((d) => d.x === props.payload.value)!;
            let label = props.payload.value;
            if (label.length > 25) {
              label = label.substring(0, 24) + '…';
            }
            return (
              <BarLabel
                {...props}
                width={78}
                textAnchor="middle"
                onClick={() => history.push(getTopicUrl(data.id))}
                fill={theme.colors.text.primary}
                fontSize={12}
                className="recharts-cartesian-axis-tick-value"
              >
                {label}
              </BarLabel>
            );
          }}
          dataKeys={
            displayThird &&
            data?.getComparsionDataForProcessReport.stepStatistics.some((stepStat) => stepStat.periodIndex === 2)
              ? ['y1', 'y2', 'y3']
              : ['y1', 'y2']
          }
          barColors={BarColors}
          barNames={[`${t('period')} 1`, `${t('period')} 2`, `${t('period')} 3`]}
          valueFormatter={(value) => {
            if (typeof value === 'string') {
              return value;
            }
            return activeTab === PhaseTabs.Feelings ? value : value + '%';
          }}
          dataRange={activeTab === PhaseTabs.Feelings ? [0, 7] : [0, 100]}
          valueAccessor={(data, dataKey) => {
            if (
              skip ||
              (filterValidRange(period).length === 0 && dataKey === 'y1') ||
              (filterValidRange(period2).length === 0 && dataKey === 'y2') ||
              (filterValidRange(period3).length === 0 && dataKey === 'y3')
            ) {
              return '';
            }
            if (
              activeTab === PhaseTabs.Feelings
                ? data[getCountKeyFromDataKey(dataKey)] === 0
                : data[getTotalKeyFromDataKey(dataKey)] === 0
            ) {
              return t('SequenceComparsion:notAvailable');
            }
            return activeTab === PhaseTabs.Feelings
              ? data[getCountKeyFromDataKey(dataKey)]
              : calculatePercentage(data[getCountKeyFromDataKey(dataKey)], data[getTotalKeyFromDataKey(dataKey)]);
          }}
          tooltipContent={
            skip
              ? undefined
              : (data, dataKey) => {
                  if (
                    !dataKey ||
                    (activeTab === PhaseTabs.Feelings
                      ? data[getCountKeyFromDataKey(dataKey)] === 0
                      : data[getTotalKeyFromDataKey(dataKey)] === 0)
                  ) {
                    return t('SequenceComparsion:notAvailableDescription');
                  }
                  return `${t(TabTitles[activeTab])} ${data[getCountKeyFromDataKey(dataKey)]} ${t(
                    'Report:phasesOutOf',
                  )} ${data[getTotalKeyFromDataKey(dataKey)]}`;
                }
          }
          data={barChartData}
        />
      </BarChartContainer>
    </div>
  );
};

export default SequenceComparsion;
