import { addBreadcrumb, captureException, captureMessage, Severity } from '@sentry/browser';

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getFrontendConfigValue } from '@arnold/common';
import { createClient } from 'graphql-ws';
import fetch from 'unfetch';
import { createTransformerLink } from './lib/ApolloClientTransformer';
import auth from './lib/auth';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      // this is in fact NOT an error object despite ts type so use captureMessage instead
      captureMessage(`GraphQL error: ${error.message}`, Severity.Error);
      // tslint:disable-next-line no-console
      console.error('[GraphQL error]:', error);
    });
  }
  if (networkError) {
    // tslint:disable-next-line no-console
    captureException(networkError);
    console.error('[Network error]:', networkError);
  }
});

const fetchWithAuth = async (uri: string, options: any) => {
  const user = auth.getUser();
  if (user != null) {
    options.headers.Authorization = `Bearer ${user.accessToken}`;
  }
  return fetch(uri, options);
};

const GRAPHQL_URI = getFrontendConfigValue('API_URL') + '/graphql';

const httpLink = new HttpLink({
  uri: GRAPHQL_URI,
  fetch: fetchWithAuth as any,
  fetchOptions: {
    mode: 'cors',
  },
});

const getCorrectProtocol = () => (window.location.protocol === 'http:' ? 'ws' : 'wss');

const getWsUri = (path: string) => `${getCorrectProtocol()}://${getFrontendConfigValue('API_HOST')}/${path}`;

const wsLink = new GraphQLWsLink(
  createClient({
    url: getWsUri('graphql'),
  }),
);

const requestLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const captureBread = (operation: any, data: any) => {
  addBreadcrumb({
    message: `Ending request for ${operation.operationName}`,
    category: 'graphql',
    data: {
      variables: operation.variables,
      result: data,
    },
    level: Severity.Info,
  });
  return data;
};

const loggingLink = new ApolloLink((operation, forward) => {
  addBreadcrumb({
    message: `Starting request for ${operation.operationName}`,
    category: 'graphql',
    data: { variables: operation.variables },
    level: Severity.Info,
  });
  if (forward == null) {
    return null;
  }
  if (forward(operation).map != null) {
    return forward(operation).map((data) => {
      return captureBread(operation, data);
    });
  }

  return Observable.from(forward(operation)).map((data) => {
    return captureBread(operation, data);
  });
});

const SelectRangeTransformer: any = {
  parseValue(expr: string) {
    const ranges = expr.split('-');
    return [Number(ranges[0]), Number(ranges[ranges.length - 1])];
  },
};

const SelectPersonTransformer: any = {
  parseValue(expr: string) {
    const ranges = expr.split('-');
    return [Number(ranges[0]), Number(ranges[1])];
  },
};

const transformers = {
  SelectQuestionDefinition: {
    selectedRange: SelectRangeTransformer,
  },
  PersonMultiselectQuestionDefinition: {
    selectedRange: SelectPersonTransformer,
  },
};

const transformerLink = createTransformerLink(transformers);

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      QuestionDefinition: [
        'SelectQuestionDefinition',
        'FreeTextQuestionDefinition',
        'EndingQuestionDefinition',
        'TellQuestionDefinition',
        'PersonMultiselectQuestionDefinition',
        'SmsContactQuestionDefinition',
        'EmailContactQuestionDefinition',
      ],
      Answerable: [
        'SelectQuestionDefinition',
        'FreeTextQuestionDefinition',
        'PersonMultiselectQuestionDefinition',
        'SmsContactQuestionDefinition',
        'EamilContactQuestionDefinition',
      ],
      AnswersConnection: ['SelectAnswersConnection', 'FreeTextAnswersConnection'],
      AnswersEdge: ['SelectAnswersEdge', 'FreeTextAnswersEdge'],
      Answer: ['FreeTextAnswer', 'SelectAnswer'],
      Message: ['FreeTextAnswer', 'Question', 'SelectAnswer', 'OtherMessage', 'EndingMessage'],
      ChannelTarget: ['Organization', 'Team', 'Respondent'],
      Rule: ['FreeTextRule', 'AlwaysMatchingRule'],
    },
  }),
  link: ApolloLink.from([errorLink, loggingLink, transformerLink, requestLink]),
});

export default client;
