import { BubbleContainer, BubbleView, formatText as markdownLinkToHtml } from '@arnold/common';
import { getLocale } from '@arnold/core';
import { formatDistanceToNow } from 'date-fns';
import i18n from 'i18next';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { ArnoldChatting } from '../assets/images';
import { IChannel } from '../types';
import { GetMessagesQuery } from '../generated/hooks';
import AnswerAnonymity from './AnswerAnonymity';
import { AnonymIcon, TeamIcon, UserIcon } from './icons';
import { View } from './StyledComponents';
import { EditBubble } from '.';

const Avatar = styled.div`
  display: flex;
  width: 24px;
  justify-content: center;
  align-items: center;

  @media (min-width: 576px) {
    width: 32px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 0.25;
`;

const Placeholder = styled.div`
  flex: 1;
`;

const ErrorMess = styled.button`
  color: #af4c62;
  font-size: 12px;
  line-height: 140%;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  padding: 0;
  background: none;
  outline-color: transparent;
  outline: none;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Events = styled.div`
  text-align: right;
  clear: both;
  float: none;
`;

const DateWrap = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  top: 20px;
  transform: translate(0, -50%);
  color: #bdbdbd;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;

  @media (min-width: 576px) {
    top: 40px;
  }
`;

interface IProps extends WithTranslation {
  message?: NonNullable<GetMessagesQuery['messages']>[0];
  showDate?: boolean;
  anonymityLevel?: string;
  channel?: IChannel;
  error?: boolean;
  isLastAnswer?: boolean;
  sentRepeat?: (message: string, index?: number, values?: number[], id?: string) => void;
  index?: number;
  t: any;
  editDisabled?: boolean;
  hideEdit?: boolean;
}

class Bubble extends React.PureComponent<IProps, any> {
  static formatText(typename: string, text: string): string {
    return text.replace(/\|/g, ' ');
  }

  handleRepeat = () => {
    const { message, sentRepeat, index, error } = this.props;
    if (message && sentRepeat && index && error) {
      sentRepeat(message.text ?? '', index, (message as any).values, message.id); // TODO fix this better
    }
  };

  renderIcon = (type: string | undefined, color: string) => {
    switch (type) {
      case 'TEAM':
        return <TeamIcon size="40px" color={color} />;
      case 'NONE':
        return <UserIcon size="37px" color={color} />;
      case 'ORGANIZATION':
        return <AnonymIcon size="37px" color={color} />;
      default:
        return false;
    }
  };

  render() {
    const { message, showDate, error, isLastAnswer, channel, hideEdit, t, editDisabled } = this.props;
    const myMessage = message && message.createdBy === 'RESPONDENT';
    if (myMessage) {
      return (
        <View data-cy="chat-my-bubble" margin={showDate || error} onClick={this.handleRepeat} touchCursor={!!error}>
          <BubbleContainer>
            <BubbleView
              isSending={(message && message.id === '-1') || error}
              runAnimation={message && message.id === '-1'}
            >
              {message &&
                message.text
                  ?.split('\n')
                  .filter((item: string) => item !== '')
                  .map((text: string, index: number) => (
                    <span key={index}>
                      {Bubble.formatText(message.__typename!, text)}
                      <br />
                    </span>
                  ))}
            </BubbleView>
            <Events>
              {error && <ErrorMess>{this.props.t('sendingError')}</ErrorMess>}
              {channel && message && (
                <AnswerAnonymity
                  respondentName={`${channel.respondent ? channel.respondent.firstname : t('me')} ${
                    channel.respondent ? channel.respondent.surname : ''
                  }`}
                  selectedAnonymity={message.anonymityLevel}
                />
              )}
              {isLastAnswer &&
                message &&
                channel &&
                (!message.isLast || channel.testing) &&
                !channel.isFinished &&
                !hideEdit && <EditBubble message={message} channel={channel} disabled={editDisabled} />}
            </Events>
          </BubbleContainer>
          {showDate && (
            <DateWrap>
              {formatDistanceToNow(new Date(message?.createdAt || ''), { locale: getLocale(i18n.language) })}
            </DateWrap>
          )}
        </View>
      );
    }
    return (
      <View left={true} margin={showDate} data-cy="chat-arnold-bubble">
        <Row>
          <Column>
            <Placeholder />
            <Avatar>
              <object data={ArnoldChatting} width={35} height={35} aria-label="Arnold chatting" />
            </Avatar>
          </Column>
          <BubbleContainer>
            <BubbleView left={true} runAnimation={true}>
              <span>{message && markdownLinkToHtml(message.text?.replace(/\|/g, ' ') ?? '')}</span>
            </BubbleView>
          </BubbleContainer>
        </Row>
        {showDate && (
          <DateWrap>
            {formatDistanceToNow(new Date(message?.createdAt || ''), { locale: getLocale(i18n.language) })}
          </DateWrap>
        )}
      </View>
    );
  }
}

export default withTranslation('bubble')(Bubble);
