import { ChildProps, graphql, withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import styled from '@emotion/styled/macro';
import compose from 'lodash.flowright';
import { path } from 'ramda';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CenterView, ErrorMessage, FullScreenWindow, SnackBar } from '../components';
import { GetMessagesQuery, GetMessagesQueryVariables, QuestionType } from '../generated/hooks';
import { messagesQuery } from '../graphql/queries';
import auth from '../lib/auth';
import { definitionTypes, getBrowserLanguages } from '../lib/common';
import { IChannel, IUserChannel } from '../types';
import { Modals } from './ChatWindow';
import { InputConsumer } from './InputContext';
import InputForm, { ProgressBar } from './InputForm';
import Messages from './Messages';
import Specials from './Specials';

const RefreshButton = styled.button`
  outline: none;
  padding: 8px;
  color: white;
  background-color: #3533cc;
  border: 1px solid #3533cc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const EmptyMessage = styled.h1`
  text-align: center;
  margin-top: 14px;
  color: #bdbdbd;
`;

interface IComponentProps extends WithTranslation {
  channel: IChannel;
  values: number[];
  changeLastMessage: (message: any) => void;
  lastMessage: NonNullable<GetMessagesQuery['messages']>[0] | null;
  sendMessage: (message: string, index?: number, values?: number[], id?: string) => void;
  showModal: (
    modalName: Modals,
    show: boolean,
    redirectTo?: string,
  ) => (e: React.MouseEvent<HTMLElement> | null) => void;
  notDeliveredMessages: GetMessagesQuery['messages'];
  toggleLanguageSelector: (value: boolean) => void;
  selectedLanguage: string;
  typing: boolean;
  hideControls: boolean;
  err: any;
  t: any;
  remainingQuestions: number | null;
  hasStartingMessages: boolean;
}

interface IState {
  messages: any[];
  hasAnswered: boolean;
  previousRemainingQuestions: number;
}

type Props = ChildProps<WithApolloClient<IComponentProps>, GetMessagesQuery>;

class Chat extends React.Component<Props, IState> {
  static getDerivedStateFromProps(nextProps: Props, prevState: IState) {
    const { data } = nextProps;
    const messages = data && data.messages;
    const hasAnswered = messages ? messages.some((message) => message.createdBy === 'RESPONDENT') : false;
    if (
      messages &&
      messages.length > 0 &&
      messages !== prevState.messages &&
      messages[messages.length - 1].createdBy === 'BOT'
    ) {
      return {
        messages,
        hasAnswered,
      };
    }
    return { hasAnswered };
  }

  scrollDiv: any;
  refRemainingQuestions = this.props.remainingQuestions ?? this.props.channel.remainingQuestions;

  state: IState = {
    messages: [],
    hasAnswered: false,
    previousRemainingQuestions: this.props.remainingQuestions ?? this.props.channel.remainingQuestions,
  };

  componentDidUpdate(prevProps: Props) {
    const { channel, showModal, data, changeLastMessage, toggleLanguageSelector, selectedLanguage } = this.props;
    window.addEventListener('focus', () => this.handleWindowFocus());
    const prevData = prevProps.data;
    const prevMessages = path<GetMessagesQuery['messages'] | null>(['messages'], prevData);
    const messages = path<GetMessagesQuery['messages'] | null>(['messages'], data);
    const localLanguage = localStorage.getItem('sessionLanguage');

    this.scrollToBottom();

    const language = getBrowserLanguages()[0];
    if (
      channel &&
      channel.language &&
      ((!channel.public && language !== channel.language.code) || (channel.public && !localLanguage)) &&
      !channel.respondent?.language &&
      channel.allowedLanguages.length > 1 &&
      !prevMessages &&
      !messages?.some((m) => m.createdBy === 'RESPONDENT')
    ) {
      showModal(Modals.CHANGE_LANGUAGE, true)(null);
    }

    if (
      (prevMessages == null && messages != null) ||
      (prevMessages && messages && prevMessages.length !== messages.length) ||
      (!prevProps.lastMessage && messages)
    ) {
      changeLastMessage(messages[messages.length - 1]);
      this.scrollToBottom();

      if (this.state.hasAnswered) {
        toggleLanguageSelector(true);
      } else {
        toggleLanguageSelector(false);
      }
    }

    if (selectedLanguage !== prevProps.selectedLanguage) {
      this.handleRefresh();
    }
    const rmActual = this.props.remainingQuestions ?? this.props.channel.remainingQuestions;
    const rmPrev = prevProps.remainingQuestions ?? prevProps.channel.remainingQuestions;
    if (rmPrev !== rmActual) {
      this.refRemainingQuestions = rmActual;
    }
    if (this.props.lastMessage?.id !== prevProps.lastMessage?.id) {
      if (
        [
          definitionTypes[QuestionType.Select],
          definitionTypes[QuestionType.Freetext],
          definitionTypes[QuestionType.PersonMultiselect],
          definitionTypes[QuestionType.SmsContact],
          definitionTypes[QuestionType.EmailContact],
        ].includes(path(['definition', '__typename'], this.props.lastMessage)!)
      ) {
        this.refRemainingQuestions = rmActual;
        setTimeout(() => {
          this.setState({ previousRemainingQuestions: this.refRemainingQuestions });
        }, 1500);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', () => this.handleWindowFocus());
  }

  handleWindowFocus() {
    this.props?.data?.refetch({
      channelId: this.props.channel.id,
    });
  }

  scrollToBottom = () => {
    if (this.scrollDiv) {
      setTimeout(() => {
        if (this.scrollDiv) {
          this.scrollDiv.scroll({
            top: this.scrollDiv.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 200);
    }
  };

  handleRefresh = () => {
    const { data } = this.props;
    if (data) {
      setTimeout(() => data.refetch(), 100);
    }
  };

  handleRef = (scroll: any) => {
    this.scrollDiv = scroll;
  };

  render() {
    const {
      data,
      channel,
      sendMessage,
      notDeliveredMessages,
      values,
      typing,
      hideControls,
      err,
      t,
      hasStartingMessages,
    } = this.props;
    const messages = data && data.messages;

    if (data && data.error) {
      return (
        <FullScreenWindow>
          <CenterView>
            <ErrorMessage>{t('default')}</ErrorMessage>
            <RefreshButton onClick={this.handleRefresh}>{t('tryAgain')}</RefreshButton>
          </CenterView>
        </FullScreenWindow>
      );
    }
    const totalQuestions = channel.totalQuestions || 1;
    const remainingQuestions = this.state.previousRemainingQuestions;

    return (
      <>
        <FullScreenWindow ref={this.handleRef} data-cy="talk-page">
          {err !== null && <SnackBar message="Vyskytl se problém, pracujeme na opravě" />}
          {messages && (
            <Messages
              messages={messages as any}
              typing={typing}
              notDeliveredMessages={notDeliveredMessages!}
              channel={channel}
              sendMessage={sendMessage}
              disabled={data?.loading}
            />
          )}
          {(!messages || messages.length === 0) &&
            data &&
            !data.loading &&
            auth.isLoggedIn() &&
            !hasStartingMessages &&
            (channel.isFinished ? (
              <EmptyMessage className="h1">{t('ClosedChannelAccess')}</EmptyMessage>
            ) : (
              <EmptyMessage className="h1">{t('noMessages')}</EmptyMessage>
            ))}
          <div data-cy="chat-specials">
            {[definitionTypes[QuestionType.Select], definitionTypes[QuestionType.PersonMultiselect]].includes(
              path(['definition', '__typename'], this.props.lastMessage)!,
            ) && <ProgressBar width={((totalQuestions - remainingQuestions) / totalQuestions) * 100} />}
            {!channel.isFinished && !hideControls && (
              <Specials
                data={data}
                values={values}
                scrollToBottom={this.scrollToBottom}
                disabled={err != null || channel == null || channel.isFinished || data?.loading!!}
              />
            )}
            {!channel.isFinished && (
              <InputForm
                disabled={err != null || channel == null || channel.isFinished || data?.loading!!}
                progressWidth={((totalQuestions - remainingQuestions) / totalQuestions) * 100}
              />
            )}
          </div>
        </FullScreenWindow>
      </>
    );
  }
}

const WithMessagesOperations = compose(
  graphql<{ channel: IUserChannel }, GetMessagesQuery, GetMessagesQueryVariables>(messagesQuery, {
    options: ({ channel }) => ({
      variables: { channelId: channel.id },
      notifyOnNetworkStatusChange: true,
    }),
  }),
);

const ChatBodyWithOperations = withTranslation('error')(withApollo<Props>(WithMessagesOperations(Chat)));

const GetPropsFromProvider = ({
  channel,
  toggleLanguageSelector,
  selectedLanguage,
  showModal,
  hasStartingMessages,
}: {
  channel: IChannel;
  toggleLanguageSelector: (value: boolean) => void;
  selectedLanguage: string;
  showModal: (
    modalName: Modals,
    show: boolean,
    redirectTo?: string,
  ) => (e: React.MouseEvent<HTMLElement> | null) => void;
  hasStartingMessages: boolean;
}) => (
  <InputConsumer>
    {(context) => (
      <ChatBodyWithOperations
        channel={channel}
        showModal={showModal}
        hideControls={context.state.hideControls}
        sendMessage={context.sendMessage}
        values={context.state.values}
        typing={context.state.typing}
        remainingQuestions={context.state.remainingQuestions}
        err={context.state.err}
        changeLastMessage={context.changeLastMessage}
        lastMessage={context.state.lastMessage}
        notDeliveredMessages={context.state.notDeliveredMessages}
        toggleLanguageSelector={toggleLanguageSelector}
        selectedLanguage={selectedLanguage}
        hasStartingMessages={hasStartingMessages}
      />
    )}
  </InputConsumer>
);

export default GetPropsFromProvider;
