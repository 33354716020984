import { prop, sortBy } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  filterReport,
  getStaticIntroWidgetData,
  getTeamLabel,
  getTeamName,
  getTeamsToShow,
} from '../../lib/reportHelper';
import { IReport } from '../../lib/reports/base';
import { ContentBox } from './ContentBox';
import ReportTable from './Table';

interface IProps {
  report: IReport;
  loading?: boolean;
}

const TeamsOverview = ({ report, loading }: IProps) => {
  const { t } = useTranslation(['Report']);

  const conclusionTeams = sortBy(prop('name'))(
    getTeamsToShow(report, true)
      .map((team) => {
        const onlyDirectMembers = report.reportRoots.length === 1 && team.id === report.reportRoots[0];
        const teamBasicInfo = {
          name: getTeamName(team.teamName, t),
          id: team.id,
          respondentsCount: null,
          teamLabel: getTeamLabel(team, t, onlyDirectMembers),
        };
        if (loading) {
          return teamBasicInfo;
        }

        const filteredReport = onlyDirectMembers
          ? filterReport(report, [{ operator: 'team', values: [team.id] }])
          : filterReport(report, [{ operator: 'unit', values: [team.id] }]);
        return {
          ...teamBasicInfo,
          respondentsCount: filteredReport.respondents.length,
          responded: getStaticIntroWidgetData(filteredReport, 'RESPONSE_RATE', t),
          finished: getStaticIntroWidgetData(filteredReport, 'FINISHED_RATE', t),
          anonymity: getStaticIntroWidgetData(filteredReport, 'ANONYMITY_RATE', t),
        };
      })
      .filter((team) => team.respondentsCount === null || team.respondentsCount),
  );

  return conclusionTeams.length > 0 ? (
    <ContentBox
      header={t('return')}
      disabled={false}
      titleIcomTag={'report-about-title'}
      boxIcomTag={'report-return-by-teams'}
    >
      <ReportTable teams={conclusionTeams} loading={loading} />
    </ContentBox>
  ) : null;
};

export default TeamsOverview;
