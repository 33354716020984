import { gql } from '@apollo/client';
import { QuestionDefinitionFragment } from './fragments';

export const sendTextAnswerMutation = gql`
  mutation sendTextAnswer($channelId: ID!, $text: String!, $previousQuestionId: ID!) {
    sendTextAnswer(channelId: $channelId, text: $text, previousQuestionId: $previousQuestionId) {
      id
      text
      createdAt
      createdBy
      isLast
      anonymityLevel
      remainingQuestions
      __typename
      response {
        id
        __typename
        text
        createdAt
        createdBy
        isLast
        anonymityLevel
        sentOn {
          id
          anonymityLevel
          anonymityLocked
          isFinished
        }
        ... on Question {
          definition {
            optional
            ...QuestionDefinition
          }
        }
      }
    }
  }
  ${QuestionDefinitionFragment}
`;

export const sendSelectAnswerMutation = gql`
  mutation sendSelectAnswer($channelId: ID!, $values: [Int!]!, $message: String!, $previousQuestionId: ID!) {
    sendSelectAnswer(
      channelId: $channelId
      values: $values
      message: $message
      previousQuestionId: $previousQuestionId
    ) {
      id
      text
      createdAt
      createdBy
      isLast
      anonymityLevel
      remainingQuestions
      __typename
      response {
        id
        __typename
        text
        createdAt
        createdBy
        isLast
        anonymityLevel
        sentOn {
          id
          anonymityLevel
          anonymityLocked
          isFinished
        }
        ... on Question {
          definition {
            optional
            ...QuestionDefinition
          }
        }
      }
      value
      question {
        definition {
          selectedRange
        }
      }
    }
  }
  ${QuestionDefinitionFragment}
`;

export const openChannelMutation = gql`
  mutation openChannel($channelId: ID!) {
    openChannel(channelId: $channelId) {
      channel {
        id
        isFinished
        isOpen
        createdAt
        endsAt
        sendAt
        topicName
        surveyFinished
        anonymityLevel
        anonymityLocked
        allowedAnonymities
        testing
        public
        allowedLanguages {
          id
          code
        }
        language {
          id
          code
        }
        remainingQuestions
        totalQuestions
        respondent {
          firstname
          surname
          language {
            id
            code
          }
          user {
            username
          }
          organization {
            id
            name
          }
          contacts {
            value
            type
            source
          }
        }
      }
      startingMessages {
        id
        __typename
        text
        createdAt
        createdBy
        isLast
        anonymityLevel
        sentOn {
          id
          anonymityLevel
          anonymityLocked
          isFinished
        }
        ... on Question {
          definition {
            optional
            ...QuestionDefinition
          }
        }
      }
    }
  }
  ${QuestionDefinitionFragment}
`;

export const createChannelMutation = gql`
  mutation createChannel($accessKey: String!, $sourceId: String) {
    createChannel(accessKey: $accessKey, sourceId: $sourceId) {
      channel {
        id
        isFinished
        anonymityLevel
        allowedAnonymities
        allowedLanguages {
          id
          code
        }
        anonymityLocked
        public
        language {
          id
          code
        }
        remainingQuestions
        totalQuestions
      }
      startingMessages {
        id
        __typename
        text
        createdAt
        createdBy
        isLast
        anonymityLevel
        sentOn {
          id
          anonymityLevel
          anonymityLocked
          isFinished
        }
        ... on Question {
          definition {
            optional
            ...QuestionDefinition
          }
        }
      }
    }
  }
  ${QuestionDefinitionFragment}
`;

export const verifyPhoneNumberMutation = gql`
  mutation verifyPhoneNumbers($phoneNumbers: [String!]!, $organizationId: ID) {
    verifyPhoneNumbers(phoneNumbers: $phoneNumbers, organizationId: $organizationId) {
      specifiedPhoneNumber
      phoneNumber
      twilioError {
        code
      }
    }
  }
`;

export const logFEEvent = gql`
  mutation logFEEvent($eventType: FEEventType!, $payload: FEEventPayload!) {
    logFEEvent(eventType: $eventType, payload: $payload)
  }
`;

export const setLanguageForRespondentMutation = gql`
  mutation setLanguageForRespondent($channelId: ID!, $language: String!) {
    setLanguageForRespondent(channelId: $channelId, language: $language) {
      id
      language {
        id
        code
      }
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword($username: String!, $origin: Origin!) {
    resetPassword(username: $username, origin: $origin)
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($newPassword: String!, $deviceToken: String) {
    changePassword(newPassword: $newPassword, deviceToken: $deviceToken) {
      username
    }
  }
`;

export const updateReportAccessMetricsMutation = gql`
  mutation updateReportAccessMetrics(
    $reportAccessKey: String!
    $topicGroupId: Int
    $topSeen: Boolean
    $bottomSeen: Boolean
    $questionsSeen: Int
    $questionsTotal: Int
    $timeSpentInSec: Int
  ) {
    updateReportAccessMetrics(
      reportAccessKey: $reportAccessKey
      topicGroupId: $topicGroupId
      topSeen: $topSeen
      bottomSeen: $bottomSeen
      questionsSeen: $questionsSeen
      questionsTotal: $questionsTotal
      timeSpentInSec: $timeSpentInSec
    )
  }
`;

export const generateOtpMutation = gql`
  mutation generateOtp($username: String!) {
    generateOtp(username: $username)
  }
`;

export const removeRespondentFromSurveyGroupWithDate = gql`
  mutation removeRespondentFromSurveyGroupWithDate(
    $surveyGroupId: ID!
    $respondentId: ID!
    $dateFrom: Date!
    $accessToken: String!
  ) {
    removeRespondentFromSurveyGroupWithDate(
      surveyGroupId: $surveyGroupId
      respondentId: $respondentId
      dateFrom: $dateFrom
      accessToken: $accessToken
    )
  }
`;

export const deleteRespondentMutation = gql`
  mutation DeleteRespondent($respondentId: ID!, $accessToken: String) {
    deleteRespondent(respondentId: $respondentId, accessToken: $accessToken)
  }
`;

export const reopenSurveyMutation = gql`
  mutation ReopenSurvey($input: ReopenSurveyInput!) {
    reopenSurvey(input: $input) {
      id
    }
  }
`;

export const createSurveyMutation = gql`
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      id
    }
  }
`;

export const sendInvitationAgainMutation = gql`
  mutation SendInvitationAgain($surveyId: ID!, $respondentId: ID!, $templateUid: String, $accessToken: String) {
    sendInvitationAgain(
      surveyId: $surveyId
      respondentId: $respondentId
      templateUid: $templateUid
      accessToken: $accessToken
    )
  }
`;

export const updateSurveyMutation = gql`
  mutation UpdateSurvey($surveyId: ID!, $input: UpdateSurveyInput!) {
    updateSurvey(surveyId: $surveyId, input: $input) {
      id
    }
  }
`;

export const sendReportOpenMetricsMutation = gql`
  mutation sendReportOpenMetrics(
    $reportAccessKey: String!
    $channelIds: [ID!]!
    $reportView: ReportOpenMetricsView!
    $sysAdmin: Boolean!
    $orgAdmin: Boolean!
  ) {
    sendReportOpenMetrics(
      reportAccessKey: $reportAccessKey
      channelIds: $channelIds
      reportView: $reportView
      sysAdmin: $sysAdmin
      orgAdmin: $orgAdmin
    )
  }
`;

export const updateRespondent = gql`
  mutation UpdateRespondent($id: ID!, $input: UpdateRespondentFromReportInput!, $accessToken: String!) {
    updateRespondentFromReport(respondentId: $id, input: $input, accessToken: $accessToken) {
      id
      firstname
      surname
    }
  }
`;
