import styled from '@emotion/styled/macro';

interface ISelectWrapper {
  selectSize: number;
}

export const SelectWrapper = styled.div<ISelectWrapper>`
  min-width: 22px;
  margin-right: 10px;

  @media (min-width: 576px) {
    min-width: ${(props: ISelectWrapper) => props.selectSize + 'px;'};
  }

  .react-select {
    &__control {
      border-color: transparent;
      box-shadow: none;
      cursor: pointer;

      &--is-disabled {
        background: none;
        filter: grayscale(1);
        opacity: 0.7;
      }
    }

    &__value-container {
      padding-left: 0;
      padding-right: 0;
    }

    &__single-value {
      max-width: 100%;

      > div {
        > div,
        > span {
          color: #04b7ef;
        }
      }
    }

    &__indicator {
      display: none;
      @media (min-width: 576px) {
        display: block;
      }
      padding: 0;
      color: #04b7ef !important;

      > svg {
        width: 12px;
        height: 12px;
        fill: #3f3f3f;
      }

      &-separator {
        display: none;
      }
    }

    &__menu {
      width: calc(${(props: ISelectWrapper) => props.selectSize + 'px'} + 20px);
      left: calc(${(props: ISelectWrapper) => -props.selectSize / 2 + 'px'} - 10px);
      top: calc(100% + 9px);
      box-shadow: 0 10px 14px rgba(0, 0, 0, 0.01);

      &:last-of-type {
        left: calc(${(props: ISelectWrapper) => -props.selectSize / 2 + 'px'} - 44px);
      }

      @media (min-width: 576px) {
        top: calc(100% + 13px);
        left: -10px;

        &:last-of-type {
          left: -10px;
        }
      }
    }

    &__option {
      width: 100%;
      cursor: pointer;
      color: #3f3f3f svg {
        > path {
          fill: #3f3f3f;
        }
      }

      &--is-selected {
        background: #f9f9f9;
        color: #3f3f3f !important;
        font-weight: 700;

        svg {
          > path {
            fill: #3f3f3f !important;
          }
        }
      }

      &--is-focused {
        background: #f9f9f9;
        color: #04a5d8;

        svg {
          > path {
            fill: #04a5d8;
          }
        }
      }
    }
  }
`;
