import styled from '@emotion/styled/macro';
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modals } from '../chat/ChatWindow';
import { GetChannelQuery, IsAccessTokenValidQuery, ReportSubscriptionSubscription } from '../generated/hooks';
import auth, { getUserEmail } from '../lib/auth';
import { vanillaCookieConsent } from './CookieConsent';

interface IProps extends WithTranslation {
  reportRespondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'];
  channel?: NonNullable<GetChannelQuery['channel']> | null;
  showModal: (modalName: Modals, show: boolean) => (e: React.MouseEvent<HTMLElement> | null) => void;
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  t: any;
}

const UserMenu = ({ channel, showModal, user, reportRespondent, t }: IProps) => {
  const handleLogout = () => auth.logout();
  const openCookieSettings = () => {
    // @ts-ignore
    vanillaCookieConsent?.showSettings(0);
    return false;
  };

  const handleLogin = () => {
    if (channel && channel.respondent && !channel.respondent.user) {
      showModal(Modals.RESET_PASSWORD, true)(null);
    } else {
      showModal(Modals.LOGIN, true)(null);
    }
  };

  const handleChangeLanguage = () => {
    showModal(Modals.CHANGE_LANGUAGE, true)(null);
  };

  const respondent = channel ? channel.respondent : reportRespondent || null;

  const currentUser = respondent || (user && user.respondents![0]) || null;

  const organizations =
    respondent && respondent.organization
      ? respondent.organization.name
      : user
        ? user.respondents!.map((res) => res.organization.name).join(', ')
        : '';

  const name = currentUser ? [currentUser.firstname, currentUser.surname].join(' ') : '';
  const username = name.length > 20 ? name.substr(0, 20) + '...' : name;

  const loggedIn = auth.isLoggedIn()
    ? respondent
      ? respondent.user && respondent.user.username === getUserEmail()
      : true
    : false;

  return (
    <DropdownButton
      title={
        currentUser && loggedIn
          ? [currentUser.firstname, currentUser.surname].filter(Boolean).map((n) => (n ? n.charAt(0) : null))
          : ''
      }
      className={'user-menu ' + (loggedIn ? 'logged' : '')}
    >
      {loggedIn && (
        <MenuHeader>
          <Dropdown.Item disabled={true} title={name}>
            {username}
          </Dropdown.Item>
          <Dropdown.Item disabled={true}>
            {organizations.length > 23 ? organizations.substr(0, 20) + ' ...' : organizations}
          </Dropdown.Item>
          <Dropdown.Divider />
        </MenuHeader>
      )}
      <MenuButtons>
        {channel && (
          <Dropdown.Item as="button" onClick={handleChangeLanguage}>
            {t('languageChangeButton')}
          </Dropdown.Item>
        )}
        {loggedIn ? (
          <Dropdown.Item as="button" onClick={handleLogout}>
            {t('logoutButton')}
          </Dropdown.Item>
        ) : (
          <Dropdown.Item as="button" onClick={handleLogin}>
            {t('loginButton')}
          </Dropdown.Item>
        )}
        <Dropdown.Item as="a" onClick={openCookieSettings} href="#">
          {t('cookieSettings')}
        </Dropdown.Item>
      </MenuButtons>
    </DropdownButton>
  );
};

const MenuHeader = styled.div`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 140%;
`;

const MenuButtons = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 140%;
  letter-spacing: 0.05em;
`;

export default withTranslation('userMenu')(UserMenu);
