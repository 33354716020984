import { FC } from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as UpChevron } from '@arnold/common/lib/assets/icons/Up-Chevron.svg';
import { ReactComponent as DownChevron } from '@arnold/common/lib/assets/icons/Down-Chevron.svg';
import { Button } from 'react-bootstrap';

const LinkButton = styled(Button)`
  :focus,
  :hover {
    border: none !important;
    box-shadow: unset;
  }
`;

type ExpandLinkProps = {
  text: string;
  expanded: boolean;
  click: any;
  dataIcom?: string;
};

export const ExpandLink: FC<ExpandLinkProps> = ({ text, expanded, click, dataIcom }) => (
  <div>
    <LinkButton data-icom={dataIcom} variant={'link'} className="no-btn font-size-s mr-5" onClick={click}>
      {text}
    </LinkButton>
    {expanded ? <UpChevron /> : <DownChevron />}
  </div>
);
