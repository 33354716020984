import { CleanButton, DateInput, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import { forwardRef } from 'react';
import LoadingLine from '../../components/LoadingLine';
import { TopicGroupTypeCode } from '../../generated/hooks';
import { AggregatedData } from '../../lib/reports/aggregateData';
import GlobalTeamFilter from './GlobalTeamFilter';
import { FiltersContainer } from './StyledComponents';

const SequenceTeamFilterContainer = styled.div`
  min-width: 200px;
  width: calc((100% - 2rem) / 3);
  max-width: 300px;
  flex-grow: 1;
`;

const CustomLoadingLine = styled(LoadingLine)`
  position: relative;
  top: 2px;
  width: 150px;
`;

export const StyledDateInput = styled(DateInput)`
  min-width: 200px;
  width: calc((100% - 2rem) / 3);
  max-width: 300px;
  flex-grow: 1;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${theme.spacing.f};
  margin-top: ${theme.spacing.f};
  margin-bottom: ${theme.spacing.d};
  align-items: center;
`;

interface IProps {
  sequenceStart: Date;
  sequenceEnd: Date;
  allRespondentsCount: number;
  filteredRespondentsCount: number;
  rootTeamsIds: string[];
  respondentId?: string | null;
  aggregatedData: AggregatedData | null;
  setTeamsFilter: (teams: string[]) => void;
  teamsFilter: string[];
  addedOnFrom: string | null | undefined;
  addedOnTo: string | null | undefined;
  onAddedOnFromChange: (newValue: string | null) => void;
  onAddedOnToChange: (newValue: string | null) => void;
  setAddedOnAndLoad: (from: string | null, to: string | null) => void;
  typeCode?: TopicGroupTypeCode | null;
  onConfirm: () => void;
  loading: boolean;
  classNames?: string;
}

const ResultsInfo = styled.div`
  text-align: right;
  color: ${theme.colors.text.secondary};
  margin-top: ${theme.spacing.c};
`;

const PredefinedFilter = styled(CleanButton)`
  color: ${theme.colors.actionPrimary.default};
  margin-left: ${theme.spacing.c};
  margin-right: ${theme.spacing.f};
  margin-top: ${theme.spacing.e};
  margin-bottom: ${theme.spacing.f};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.actionPrimary.hover};
  }
  &:focus {
    color: ${theme.colors.actionPrimary.focused};
  }
`;

const GlobalSequenceFilter = forwardRef<HTMLDivElement, IProps>(
  (
    {
      onAddedOnFromChange,
      onAddedOnToChange,
      sequenceStart,
      sequenceEnd,
      allRespondentsCount,
      filteredRespondentsCount,
      rootTeamsIds,
      respondentId,
      aggregatedData,
      setTeamsFilter,
      teamsFilter,
      addedOnFrom,
      addedOnTo,
      setAddedOnAndLoad,
      typeCode,
      loading,
      onConfirm,
      classNames,
    },
    ref,
  ) => {
    const { t } = useTranslation('Report');

    return (
      <div
        ref={ref}
        className={`bg-white rounded-lg shadow-sm px-8 py-6 ${classNames}`}
        data-cy="report-sequence-filter"
        data-icom={
          typeCode === TopicGroupTypeCode.Onboarding
            ? 'onboarding-report-filters'
            : typeCode === TopicGroupTypeCode.Exit
              ? 'exit-report-filters'
              : 'process-report-filters'
        }
      >
        <div>
          <PredefinedFilter
            onClick={() => {
              const date = new Date();
              const to = date.toISOString().split('T')[0];
              date.setDate(date.getDate() - 30);
              const from = date.toISOString().split('T')[0];
              setAddedOnAndLoad(from, to);
            }}
            data-icom={'seq-filter-last30Days'}
          >
            {t('ReportSequence:lastDays', { count: 30 })}
          </PredefinedFilter>
          <PredefinedFilter
            onClick={() => {
              const date = new Date();
              const to = date.toISOString().split('T')[0];
              date.setDate(date.getDate() - 90);
              const from = date.toISOString().split('T')[0];
              setAddedOnAndLoad(from, to);
            }}
            data-icom={'seq-filter-last90Days'}
          >
            {t('ReportSequence:lastDays', { count: 90 })}
          </PredefinedFilter>
          <PredefinedFilter
            onClick={() => {
              const date = new Date();
              date.setDate(1);
              date.setDate(date.getDate() - 1);
              const to = date.toISOString().split('T')[0];
              date.setDate(1);
              const from = date.toISOString().split('T')[0];
              setAddedOnAndLoad(from, to);
            }}
            data-icom={'seq-filter-lastMonth'}
          >
            {t('ReportSequence:lastMonth')}
          </PredefinedFilter>
          <PredefinedFilter
            onClick={() => {
              const date = new Date();
              date.setFullYear(date.getFullYear() - 1);
              date.setMonth(11);
              date.setDate(31);
              const to = date.toISOString().split('T')[0];
              date.setMonth(0);
              date.setDate(1);
              const from = date.toISOString().split('T')[0];
              setAddedOnAndLoad(from, to);
            }}
            data-icom={'seq-filter-lastYear'}
          >
            {t('ReportSequence:lastYear')}
          </PredefinedFilter>
          <PredefinedFilter
            onClick={() => {
              setAddedOnAndLoad(null, null);
            }}
            data-icom={'seq-filter-wholePeriod'}
          >
            {t('ReportSequence:wholePeriod')}
          </PredefinedFilter>
        </div>
        <FiltersContainer>
          <>
            <StyledDateInput
              fromYear={sequenceStart}
              toYear={sequenceEnd}
              value={addedOnFrom}
              onDateChange={onAddedOnFromChange}
              dataCy="report-date-filter"
              baseTranslationPath="Report"
              placeholder={t('dateFilterPlaceholder')}
              label={t(typeCode === TopicGroupTypeCode.Onboarding ? 'labelAddedOnFromOnb' : 'labelAddedOnFrom')}
            />
            <StyledDateInput
              fromYear={sequenceStart}
              toYear={sequenceEnd}
              value={addedOnTo}
              onDateChange={onAddedOnToChange}
              dataCy="report-date-filter"
              baseTranslationPath="Report"
              placeholder={t('dateFilterPlaceholder')}
              label={t(typeCode === TopicGroupTypeCode.Onboarding ? 'labelAddedOnToOnb' : 'labelAddedOnTo')}
            />
            <SequenceTeamFilterContainer>
              <GlobalTeamFilter
                rootTeamsIds={rootTeamsIds}
                setFilters={(filters) => {
                  setTeamsFilter((filters[0]?.values as string[]) ?? []);
                }}
                respondentId={respondentId}
                filters={
                  teamsFilter.length > 0
                    ? [
                        {
                          operator: 'unit-teams',
                          values: teamsFilter,
                        },
                      ]
                    : []
                }
                isSequenceFilter={true}
                aggregatedData={aggregatedData}
              />
            </SequenceTeamFilterContainer>
          </>
        </FiltersContainer>
        <InfoContainer>
          <ResultsInfo>
            {loading ? (
              <CustomLoadingLine />
            ) : (
              t('displayedRespondents', {
                filtered: filteredRespondentsCount,
                all: allRespondentsCount ? allRespondentsCount : '-',
              })
            )}
          </ResultsInfo>
          <Button
            variant="outline-primary"
            data-cy={`seq-filter-refresh`}
            data-icom={'seq-filter-refresh'}
            onClick={onConfirm}
          >
            {t('ReportSequence:refresh')}
          </Button>
        </InfoContainer>
      </div>
    );
  },
);

export default GlobalSequenceFilter;
