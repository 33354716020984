import { forwardRef, useEffect, useRef } from 'react';
import { components } from 'react-select';
import { ItemWithCheckboxContainer, Checkbox, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { FilterBox } from '../../StyledComponents';
import { Filter } from '../../../../lib/reportHelper';
import { toggleFilter } from './utils';

interface IProps {
  options: string[];
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
  setFilteredOptions: (filteredOptions: string[]) => void;
  filteredOptions: string[];
}

const FilterGroupTeamHeader = styled(ItemWithCheckboxContainer)`
  padding: 9px;
  padding-left: ${theme.spacing.f};
`;

const SourceMenu = forwardRef<number, IProps>(
  ({ options, filters, setFilters, setFilteredOptions, filteredOptions, ...props }: IProps, ref) => {
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      menuRef.current?.scrollTo({ top: typeof ref === 'function' || !ref?.current ? 0 : ref?.current });
      const handle = () => {
        if (menuRef.current && ref && typeof ref !== 'function') {
          ref.current = menuRef.current.scrollTop;
        }
      };
      if (menuRef.current) {
        menuRef.current.addEventListener('scroll', handle);
      }
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        menuRef?.current?.removeEventListener('scroll', handle);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <components.Menu {...props}>
        <FilterBox ref={menuRef} className={'bg-white rounded-lg shadow-sm position-absolute'}>
          {options?.map((option) => (
            <FilterGroupTeamHeader key={option}>
              <Checkbox
                onToggle={() => toggleFilter(option, filteredOptions, setFilteredOptions, filters, setFilters)}
                selected={filteredOptions.includes(option)}
                text={option}
              />
            </FilterGroupTeamHeader>
          ))}
        </FilterBox>
      </components.Menu>
    );
  },
);

export default SourceMenu;
