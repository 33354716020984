import { MutationFunction } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { ChildProps, graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from '@emotion/styled/macro';
import { IChannel } from '../types';
import { ChannelBanner } from '../components';
import { createChannelMutation, openChannelMutation } from '../graphql/mutations';
import { messagesQuery } from '../graphql/queries';
import { parseUrlParams } from '../lib/common';
import {
  CreateChannelMutationResult,
  CreateChannelMutationVariables,
  GetMessagesQuery,
  OpenChannelMutationResult,
  OpenChannelMutationVariables,
} from '../generated/hooks';
import Chat from './Chat';
import ErrorHandling from './ErrorHandling';
import { InputProvider } from './InputContext';

const PlaceHolder = styled.div``;

export enum Modals {
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  EMAIL_SEND = 'EMAIL_SEND',
  SET_PASSWORD = 'SET_PASSWORD',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  REMOVE_RESPONDENT = 'REMOVE_RESPONDENT',
}

interface IDirectProps extends RouteComponentProps<any> {
  channelId?: string;
  accessKey?: string;
}

interface IProps extends IDirectProps {
  surveyChat?: boolean;
  location: any;
  showModal: (
    modalName: Modals,
    show: boolean,
    redirectTo?: string,
  ) => (e: React.MouseEvent<HTMLElement> | null) => void;
  selectedLanguage: string;
  changeLanguage: (language: string) => void;
  toggleLanguageSelector: (hasAnswered: boolean) => void;
  setChannel: (channel: IChannel) => void;
}

interface IMutation {
  openChannel: MutationFunction<OpenChannelMutationResult, OpenChannelMutationVariables>;
  createChannel: MutationFunction<CreateChannelMutationResult, CreateChannelMutationVariables>;
  client: any;
}

interface IState {
  err: string | ErrorResponse | null;
  channel: IChannel | null;
  startingMessages: GetMessagesQuery['messages'] | null;
  isError: boolean | null;
  isOffline: boolean;
}

class ChatWindow extends React.Component<ChildProps<IProps & IMutation>, IState> {
  state: IState = {
    isError: null,
    err: null,
    channel: null,
    startingMessages: null,
    isOffline: false,
  };

  componentDidMount() {
    this.openChannel();
  }

  saveChannel = (channel: IChannel | null, startingMessages: GetMessagesQuery['messages'] | null) => {
    // Write empty result to Apollo cache, so we can add starting messages with typing effect
    if (startingMessages && channel) {
      this.props.client.writeQuery({
        query: messagesQuery,
        data: { messages: null },
        variables: { channelId: channel.id },
      });
    }

    this.setState(() => ({
      isError: false,
      err: null,
      channel,
      startingMessages,
    }));
  };

  openChannel = async () => {
    const { openChannel, channelId, accessKey, createChannel, location } = this.props;

    const params = parseUrlParams(location.search);
    const sourceId = params.getParam('sourceId');

    if (channelId || accessKey) {
      try {
        const localChannelId = localStorage.getItem(`channelForSurvey:${accessKey}${sourceId ? '/' + sourceId : ''}`);
        if ((accessKey && localChannelId == null) || localChannelId === '') {
          const queryResults: any = await createChannel({
            variables: { accessKey: accessKey ?? '', sourceId },
          });
          this.saveChannel(queryResults.data.createChannel.channel, queryResults.data.createChannel.startingMessages);

          localStorage.setItem(
            `channelForSurvey:${accessKey}${sourceId ? '/' + sourceId : ''}`,
            queryResults.data.createChannel != null ? queryResults.data.createChannel.channel.id : '',
          );
          if (queryResults.data?.createChannel?.channel?.language) {
            this.props.changeLanguage(queryResults.data?.createChannel?.channel?.language.code);
          }
        } else {
          const queryResults: any = await openChannel({
            variables: {
              channelId: localChannelId != null && localChannelId !== '' ? localChannelId : channelId ?? '',
            },
          });

          if (queryResults.data.openChannel && queryResults.data.openChannel.channel.language) {
            this.props.changeLanguage(queryResults.data.openChannel.channel.language.code);
          }
          this.saveChannel(queryResults.data.openChannel.channel, queryResults.data.openChannel.startingMessages);

          this.props.setChannel(queryResults.data.openChannel.channel);
        }
      } catch (err) {
        this.setState(() => ({
          isError: true,
          err: ((err as any).graphQLErrors && (err as any).graphQLErrors[0]) || err,
        }));
      }
    } else {
      this.setState(() => ({
        isError: true,
        err: 'noURL',
      }));
    }
  };

  render() {
    const { isError, channel, startingMessages, err } = this.state;
    const { client, toggleLanguageSelector, selectedLanguage } = this.props;

    return (
      <>
        {channel && channel.surveyFinished && !channel.public && (
          <ChannelBanner channel={channel} showModal={this.props.showModal} />
        )}
        <ErrorHandling err={err} refresh={this.openChannel} />
        <InputProvider channel={channel} startingMessages={startingMessages} client={client}>
          {!isError && channel ? (
            <Chat
              channel={channel}
              showModal={this.props.showModal}
              selectedLanguage={selectedLanguage}
              toggleLanguageSelector={toggleLanguageSelector}
              hasStartingMessages={(startingMessages || []).length > 0}
            />
          ) : (
            <PlaceHolder />
          )}
        </InputProvider>
      </>
    );
  }
}

const WithChannelOperations = compose(
  graphql(openChannelMutation, {
    name: 'openChannel',
  }),
  graphql(createChannelMutation, {
    name: 'createChannel',
  }),
  withApollo,
);

export default withRouter<IDirectProps, any>(WithChannelOperations(ChatWindow));
