import styled from '@emotion/styled/macro';
import { intersection } from 'ramda';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { cleanArray } from '@arnold/common';
import {
  getFilterDescriptions,
  getQuestionDefinitionWithAnswers,
  getQuestionType,
  getWidgetDescription,
  IFilterDescription,
  isDataFiltered,
} from '../../../lib/reportHelper';
import { IReport } from '../../../lib/reports/base';
import { getGraphData, hasGraphEmptyRows } from '../../../lib/reports/graphHelpers';
import { LocalFilterContext } from '../../../lib/reports/localFilterContext';
import { prepareReversedAnswerGraph } from '../../../lib/reports/reversedAnswerGraph';
import { prepareReversedTeamGraph } from '../../../lib/reports/reversedTeamGraph';
import LoadingLine from '../../../components/LoadingLine';
import { ContentBox } from '../ContentBox';
import Graph from '../Graph';
import { ReportWidgetProps } from './types';
import { addWidget, getWidgetTitle, isGraphRemovable, isWidgetDisabled } from './utils';

type StackedChartProps = ReportWidgetProps & {
  aggregatedData: any;
  widgetReport: IReport;
  handleDownload?: (classname?: string) => void;
};
const LoadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;
const AnswerLabel = styled.span`
  width: 33%;
  display: inline-block;
  text-align: right;
  margin-right: 8px;
  font-size: 12px;
  color: rgb(63, 63, 63);
`;
const LoadingBar = styled(LoadingLine)`
  height: 22px;
  width: 320px;
  border-radius: 0;
  background: linear-gradient(
    90deg,
    rgba(48, 208, 242, 0.2) 25%,
    rgba(1, 106, 129, 0.24) 37%,
    rgba(48, 208, 242, 0.2) 63%
  );

  background-size: 400% 100%;
`;
export const StackedChart: FC<StackedChartProps> = ({
  widget,
  hideHeader,
  filters,
  section,
  sectionReport,
  widgetReport,
  globalFilters,
  addedWidgets,
  setWidgets,
  removeWidgets,
  widgetFilter,
  loading,
  firstWidget,
  aggregatedData,
  i,
  handleDownload,
  hideDownload,
  hasFilter,
  disableGraphClick,
}) => {
  const { t } = useTranslation('Report');
  const compareLevelFilter = (globalFilters || []).find((filter) => filter.operator === 'organization-level');
  const localCompareLevelFilter = (widget.displayFilters || []).find(
    (filter) => filter.operator === 'organization-level',
  );
  const { question, answers } = getQuestionDefinitionWithAnswers(widgetReport, widget);

  if (!question || !question.answersLocal) {
    return null;
  }

  const questionType = getQuestionType(question);

  if (loading) {
    return (
      <ContentBox
        header={getWidgetTitle(widgetReport, widget)}
        subHeader={getWidgetDescription(
          sectionReport,
          widgetReport,
          widget,
          cleanArray(section.displayFilters),
          t,
          loading,
        )}
        filterDescriptions={getFilterDescriptions(widget.titleFilters || [], widgetReport) as IFilterDescription[]}
        index={i}
        disabled={false}
        loading={true}
        organizationName={widgetReport.metadata?.organization.name}
        titleIcomTag={'report-question-text'}
        handleDownload={hideDownload ? undefined : handleDownload}
      >
        {answers.map((answer) => (
          <LoadingRow key={answer.index}>
            <AnswerLabel>{answer.text}</AnswerLabel>
            <LoadingBar animationLength={3} />
          </LoadingRow>
        ))}
      </ContentBox>
    );
  }
  const useWidgetFilter = !!widgetFilter && !!widgetFilter.values && widgetFilter.values.length > 0;

  const removable = isGraphRemovable(widget.id, addedWidgets);

  const graphData = compareLevelFilter
    ? questionType === 'SELECT'
      ? prepareReversedAnswerGraph(
          answers,
          widgetReport,
          firstWidget ? compareLevelFilter : localCompareLevelFilter,
          firstWidget,
          widget,
          useWidgetFilter ? intersection(filters, cleanArray(widgetFilter?.values)) : filters,
          aggregatedData,
          t,
        )
      : prepareReversedTeamGraph(
          answers,
          widgetReport,
          firstWidget ? compareLevelFilter : localCompareLevelFilter,
          firstWidget,
          widget,
          useWidgetFilter ? intersection(filters, cleanArray(widgetFilter?.values)) : filters,
          aggregatedData,
          t,
        )
    : getGraphData(widgetReport, widget, t);
  const emptyRows = hasGraphEmptyRows(graphData);

  const isDataFilteredOut = isDataFiltered(globalFilters, compareLevelFilter && filters.length === 0);

  if (!graphData || (emptyRows && (!isDataFilteredOut || removable))) {
    return null;
  }
  return (
    ((!emptyRows || isDataFilteredOut) && (
      <ContentBox
        header={removable || hideHeader ? undefined : getWidgetTitle(widgetReport, widget)}
        subHeader={getWidgetDescription(sectionReport, widgetReport, widget, section.displayFilters!, t)}
        filterDescriptions={getFilterDescriptions(widget.titleFilters || [], widgetReport) as IFilterDescription[]}
        index={i}
        disabled={isWidgetDisabled(widgetReport, widget)}
        organizationName={widgetReport.metadata?.organization.name}
        titleIcomTag={'report-question-text'}
        handleDownload={hideDownload ? undefined : handleDownload}
        classnames={`hide-from-export hide-from-export-${i + Number(hasFilter || false)}`}
        questionDefinitionIndex={widget.questionDefinitionIndex}
      >
        {emptyRows ? (
          <span className={'no-data'}>{t('withoutResponse')}</span>
        ) : (
          <LocalFilterContext.Consumer>
            {({ setHighlightedAnswerIndex }) => (
              <Graph
                data={graphData}
                addWidget={addWidget(widgetReport, section, widget, setWidgets)}
                removable={removable}
                disableSegmentSorting={compareLevelFilter && questionType === 'SELECT'}
                setHighlightedAnswer={
                  compareLevelFilter && questionType === 'SELECT' ? setHighlightedAnswerIndex : undefined
                }
                removeWidgets={removeWidgets([widget.id])}
                handleDownload={hideDownload ? handleDownload : undefined}
                index={(i + (hasFilter ? 1 : 0)).toString()}
                disableGraphClick={disableGraphClick}
              />
            )}
          </LocalFilterContext.Consumer>
        )}
      </ContentBox>
    )) || <span className={'no-data'}>{t('noDataWithFilter')}</span>
  );
};
