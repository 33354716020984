import { theme } from '@arnold/common/lib';
import styled from '@emotion/styled/macro';

export const PlaceHolder = styled.div<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? theme.colors.text.disabled : 'hsl(0, 0%, 20%)')};
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
`;

export const DisabledPlaceholder = styled.span`
  color: ${theme.colors.text.disabled};
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PillContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  align-items: center;
`;

const Pill = styled.div`
  background-color: ${theme.colors.backgroundBasic.active};
  border-radius: 4px;
  margin: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  box-sizing: border-box;
  padding: 4px 8px;
`;

const RemainingCount = styled.span`
  margin-left: 8px;
  align-items: center;
`;

interface IProps {
  selectedAnswers: string[];
  emptyPlaceholder: string;
  disabled?: boolean;
}

export const SelectFilterSelected = ({ selectedAnswers, emptyPlaceholder, disabled }: IProps) => {
  if (selectedAnswers.length === 0) {
    return <PlaceHolder disabled={disabled}>{emptyPlaceholder}</PlaceHolder>;
  }
  return (
    <PillContainer>
      <Pill>{selectedAnswers[0]}</Pill>
      {selectedAnswers.length !== 1 && <RemainingCount>(+ {selectedAnswers.length - 1})</RemainingCount>}
    </PillContainer>
  );
};
