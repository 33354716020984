import { ChildProps } from '@apollo/client/react/hoc';
import styled from '@emotion/styled/macro';
import { Form } from 'react-bootstrap';
import * as flags from '../assets/images';
import { IChannel } from '../types';

export interface ILanguageSelector {
  allowedLanguages: IChannel['allowedLanguages'];
  selectedLanguage: string;
  handleChange: (languageCode: string) => any;
  disabled: boolean;
}

const Flag = styled.img`
  width: 14px;
  height: 14px;
  object-fit: cover;
  border-radius: 50%;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  .react-select__control--is-disabled & {
    color: #bdbdbd !important;
  }
`;

const LanguageName = styled.div`
  margin-left: 8px;
  text-transform: capitalize;
  padding-top: 5px;
`;

const LanguageWrapper = styled.div`
  .form-check-input[disabled] ~ .form-check-label img {
    display: none;
  }

  input[type='radio'] {
    margin-top: 7px;
  }
`;

const LanguageSelector = ({
  selectedLanguage,
  handleChange,
  allowedLanguages,
  disabled = false,
}: ChildProps<ILanguageSelector>) => {
  const getFlagCode = (code: string) => {
    if (code === 'en') {
      const enFlags = ['gb', 'us', 'au', 'ca', 'ie', 'il', 'nz', 'sg'];
      const countryCode = selectedLanguage;
      return countryCode.length === 2 && enFlags.find((enabledCode) => enabledCode === countryCode)
        ? countryCode
        : code;
    }

    return code;
  };

  const values = allowedLanguages.map((language) => {
    const intl = Intl as any;
    const languageNames =
      intl && intl.DisplayNames
        ? new intl.DisplayNames([language.code], { type: 'language' })
        : { of: (code: string) => code };
    return {
      value: language.code,
      label: (
        <ValueWrapper>
          <Flag src={(flags as any)[getFlagCode(language.code)]} />
          <LanguageName>{languageNames.of(language.code)}</LanguageName>
        </ValueWrapper>
      ),
      name: languageNames.of(language.code),
    };
  });

  const getSortedValues = () => values.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const handleChangeLanguage = (value: string) => () => {
    handleChange(value);
  };

  return (
    <LanguageWrapper>
      <Form style={{ marginLeft: '25px' }}>
        {getSortedValues().map((value) => (
          <Form.Check
            className={'languageRadio'}
            type="radio"
            key={value.value}
            id={`lang-${value.value}`}
            label={value.label}
            checked={value.value === selectedLanguage}
            disabled={disabled}
            onChange={handleChangeLanguage(value.value)}
          />
        ))}
      </Form>
    </LanguageWrapper>
  );
};

export default LanguageSelector;
