import { useTranslation } from 'react-i18next';
import { AnonymityLevel, ReportSequenceSubscriptionSubscription } from '../../generated/hooks';
import { prepareAnonymityRate, prepareResponseRate, prepareWithoutIssuesRate } from '../../lib/reportHelper';
import Conclusion from '../components/Conclusion';
import ReportSection from '../components/ReportSection';

export type ReportSequenceData = NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

interface IIntroWidgetsProps {
  widgets: NonNullable<ReportSequenceData['widgets']>;
  surveyGroup: NonNullable<ReportSequenceData['surveyGroup']>;
  metadata: NonNullable<ReportSequenceData['metadata']>;
}

const IntroWidgets = ({ widgets, surveyGroup, metadata }: IIntroWidgetsProps) => {
  const { t } = useTranslation(['Report', 'header']);

  const hasAnonymousTopics = surveyGroup?.steps.some((step) =>
    step.processStep.stepTopicGroup.allowedAnonymities.includes(AnonymityLevel.Organization),
  );
  const conclusions = [
    prepareResponseRate(widgets.responseRate.denominator, widgets.responseRate.numerator, t, metadata.status),
  ];

  hasAnonymousTopics &&
    conclusions.push(
      prepareAnonymityRate(
        widgets.anonymityRate.denominator,
        widgets.anonymityRate.numerator,
        false,
        t,
        metadata.status,
      ),
    );
  widgets.withoutProblems.numerator &&
    conclusions.push(
      prepareWithoutIssuesRate(
        widgets.withoutProblems.denominator,
        widgets.withoutProblems.numerator,
        t,
        metadata.status,
      ),
    );

  return (
    <ReportSection id={'intro'} header={t('intro')} icomTag={'process-report-intro-title'}>
      <Conclusion conclusions={conclusions} />
    </ReportSection>
  );
};

export default IntroWidgets;
