import { sum } from 'ramda';
import { GraphData } from '../../reports/components/Graph';
import {
  Filter,
  getGraphLabel,
  getRespondentsMap,
  getTeamLabel,
  getTeamName,
  isWidgetTraversable,
  ReportRespondentsMap,
} from '../reportHelper';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import { AggregatedData } from './aggregateData';
import { IReport } from './base';
import { sortAndFilterRowsWithAlerts } from './graphHelpers';
import {
  calculateCount,
  filterMessagesForTeamAndValue,
  getTeamsForReversedGraph,
  TeamWithType,
} from './queryAggregatedData';
import { Answer, AnswerType } from './question';

const prepareRow = (
  origTeam: NonNullable<ReportSubscriptionSubscription['getReport']['teams']>[0],
  allAnswers: Answer[],
  traversable: boolean,
  displayOnlyDirect: boolean,
  reportRespondents: ReportRespondentsMap,
  questionDefinitionIndex: string,
  filteredAnswers: string[],
  aggregatedData: AggregatedData,
  t: any,
  segmentsWithoutCounts = false,
) => {
  const team = aggregatedData.teams[origTeam.id].team;

  const answersToDisplay = allAnswers.filter((answer) => filteredAnswers.includes(answer.index));

  const totalRespondentsCount = calculateCount(
    aggregatedData.teams,
    aggregatedData.teams[team.id],
    questionDefinitionIndex,
    displayOnlyDirect,
    reportRespondents,
  );
  const segments = answersToDisplay.map((answer) => {
    const rawValues = filterMessagesForTeamAndValue(
      aggregatedData.teams,
      team,
      questionDefinitionIndex,
      [answer.index],
      displayOnlyDirect,
    ).filter((msg) => reportRespondents[msg.respondentId]);

    const values = rawValues.map((msg) => msg.respondentName);

    return {
      id: `answer#${answer.index}`,
      header: segmentsWithoutCounts ? answer.text : `${answer.text} (${values.length}/${totalRespondentsCount})`,
      traversable,
      traversableInfo: {
        teamId: team.id,
        teamName: `${getTeamLabel(team, t, displayOnlyDirect)}: ${getTeamName(team.teamName, t)}`,
      },
      color: answer.color,
      segmentSeverity: answer.count,
      percentage: totalRespondentsCount === 0 ? 0 : values.length / totalRespondentsCount,
      hoverColor: answer.hoverColor,
      values,
    };
  });

  return {
    description: `${getTeamLabel(team, t, displayOnlyDirect)}: ${getTeamName(team.teamName, t)}`,
    totalRespondentsCount,
    type: AnswerType.NEUTRAL,
    segments,
    get count() {
      return sum(this.segments.map((s) => s.values.length));
    },
  };
};

export const prepareReversedAnswerGraph = (
  answers: Answer[],
  report: IReport,
  compareLevelFilter: Filter | undefined,
  firstWidget: boolean,
  widget: NonNullable<
    NonNullable<NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']>[0]['widgets']
  >[0],
  filteredAnswers: string[],
  aggregatedData: AggregatedData,
  t: any,
  segmentsWithoutCounts = false,
  filterTeamWithoutAnswers: boolean = false,
): GraphData | null => {
  if (widget.questionDefinitionIndex === null) {
    return null;
  }
  const answersToDisplay = answers.filter((answer) => filteredAnswers.includes(answer.index));

  const teams = getTeamsForReversedGraph(aggregatedData, report, compareLevelFilter);

  const traversable = firstWidget || isWidgetTraversable(teams, widget);

  const graphData: GraphData = {
    ...getGraphLabel(report, widget, widget.questionDefinitionIndex || '', traversable, t),
    rows: [],
  };

  const reportRespondents = getRespondentsMap(report);
  const teamRows = teams
    .map((team: TeamWithType) => {
      const displayOnlyDirectMembers =
        team.type === 'team' ||
        team.team.teamLeader === null ||
        (!firstWidget && report.reportRoots.length === 1 && team.team.id === report.reportRoots[0]);
      return prepareRow(
        team.team,
        answersToDisplay,
        traversable && !displayOnlyDirectMembers,
        displayOnlyDirectMembers,
        reportRespondents,
        widget.questionDefinitionIndex || '',
        filteredAnswers,
        aggregatedData,
        t,
        segmentsWithoutCounts,
      );
    })
    .filter((r) => r.totalRespondentsCount);

  graphData.rows = sortAndFilterRowsWithAlerts(
    filterTeamWithoutAnswers ? teamRows.filter((r) => r.totalRespondentsCount) : teamRows,
  );
  return graphData;
};
