import { contains } from 'ramda';
import { ReportSubscriptionSubscription, ReportWidgetType } from '../../../generated/hooks';
import { getMessagesWithQuestionIndex, IReport } from '../../../lib/reports/base';
import { getQuestionDefinitionByIndex, WidgetType } from '../../../lib/reportHelper';
import { Filters } from './types';

type ReportDefinition = NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>;

export const addWidget =
  (
    report: IReport,
    section: NonNullable<ReportDefinition['sections']>[0],
    widget: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>[0],
    setWidgets: (widgets: WidgetType[]) => void,
  ) =>
  (team: { id: string; name: string }, displayDirectPeople?: boolean) => {
    const displayFilters: Filters = [
      {
        __typename: 'PubDisplayFilter',
        operator: displayDirectPeople ? 'team' : 'root',
        questionDefinitionIndex: '',
        values: [team.id],
      },
    ];
    setWidgets([
      {
        afterWidgetId: widget.id,
        subSectionId: section.id + widget.questionDefinitionIndex,
        widget: {
          __typename: 'PubWidget',
          id: widget.id + team.id,
          questionDefinitionIndex: widget.questionDefinitionIndex,
          headingLocal: '',
          type: ReportWidgetType.StackedChart,
          descriptionLocal: '',
          displayFilters,
          titleFilters: null,
          answersToHighlight: widget.answersToHighlight,
          values: widget.values,
        },
      },
    ]);
  };

export const isWidgetDisabled = (
  report: IReport,
  widget: NonNullable<
    NonNullable<NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']>[0]['widgets']
  >[0],
) => {
  if (!widget.questionDefinitionIndex || widget.descriptionLocal === null) {
    return true;
  }

  return (
    getMessagesWithQuestionIndex(report, widget.questionDefinitionIndex, {
      filterEmpty: true,
    }).length <= 0
  );
};

export const isGraphRemovable = (widgetId: string, addedWidgets: WidgetType[] | null) => {
  return contains(
    widgetId,
    (addedWidgets || []).map((ad) => ad.widget.id),
  );
};

export const getWidgetTitle = (
  report: IReport,
  widget: NonNullable<
    NonNullable<NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']>[0]['widgets']
  >[0],
) => {
  if (widget.headingLocal && widget.headingLocal.trim().length > 0) {
    return widget.headingLocal;
  }

  if (widget.questionDefinitionIndex) {
    const question = getQuestionDefinitionByIndex(report, widget.questionDefinitionIndex);
    return question ? question.text : '';
  }

  return '';
};
