import { ENVIRONMENT } from '@arnold/core';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import { getFrontendConfigValue } from '@arnold/common';
import bg from './locales/bg.json';
import cs from './locales/cs.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fi from './locales/fi.json';
import fr from './locales/fr.json';
import hu from './locales/hu.json';
import it from './locales/it.json';
import lt from './locales/lt.json';
import pl from './locales/pl.json';
import ro from './locales/ro.json';
import ru from './locales/ru.json';
import sk from './locales/sk.json';
import sr from './locales/sr.json';
import uk from './locales/uk.json';

const resources = { bg, cs, en, es, hu, pl, ro, ru, sk, sr, lt, fi, fr, it, uk };

i18n
  .use(intervalPlural)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lowerCaseLng: true,
    debug: getFrontendConfigValue('ENVIRONMENT') !== ENVIRONMENT.LOCAL,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'li', 'ul', 'strong', 'i'], // don't convert to <1></1> if simple react elements
    },
  });

export default i18n;
