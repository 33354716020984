import { Tooltip, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { omit } from 'ramda';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { ReactComponent as MetricQuestionIcon } from '../../assets/images/ChartResult.svg';
import { ReactComponent as ChatIcon } from '../../assets/images/Chat.svg';
import { ReactComponent as ProblemsIcon } from '../../assets/images/CommentWarning.svg';
import { ReactComponent as AnonymityIcon } from '../../assets/images/VisibilityOff.svg';
import { ReportWidgetType, SurveyStatus } from '../../generated/hooks';

interface IProps {
  conclusions: IConclusionBox[];
}

export interface IConclusionBox {
  type?: string;
  header?: string;
  description?: string;
  figure?: string;
  explanation?: string;
  additionallyAnonymized?: boolean;
  surveyStatus: SurveyStatus;
}

const Conclusion = ({ conclusions }: IProps) => {
  return (
    <div className={'mt-7'} data-icom="report-conclusion">
      <CircleContainer className={'row mx-0'} id="report-conclusion">
        {conclusions.map(({ type, header, description, figure, explanation }, index) => {
          let tooltipText;
          const match = explanation?.match(/(\d+\s*\/\s*\d+)\s+/); // extract number / number from the explanation
          if (match) {
            tooltipText = explanation;
            explanation = match[1];
          }
          let Icon;
          switch (type) {
            case 'response-rate':
              Icon = ChatIcon;
              break;
            case 'anonymity-rate':
              Icon = AnonymityIcon;
              break;
            case 'wo-issues-rate':
              Icon = ProblemsIcon;
              break;
            default:
              Icon = MetricQuestionIcon;
          }
          const value = Number.parseInt(figure!, 10);
          const color =
            type === 'wo-issues-rate' || (type === 'response-rate' && value < 40)
              ? theme.colors.chart.alert[0]
              : type === ReportWidgetType.Nps
                ? theme.colors.actionInvertedPrimary.default
                : theme.colors.brand.primary;
          const data = value
            ? [
                { value: 100 - value, color: theme.colors.actionInvertedPrimary.default },
                { value, color },
              ]
            : [{ value: 100, color: theme.colors.actionInvertedPrimary.default }];
          return (
            <CircleCard
              cards={conclusions.length}
              index={index}
              key={index}
              className={'px-2 d-flex px-3 flex-grow-1'}
              data-cy={`intro-${type}`}
              data-icom={`report-intro-${type}`}
            >
              <Tooltip
                title={description}
                className={'d-flex flex-grow-1 bg-white rounded-lgshadow-sm px-7 py-7 mb-3 justify-content-between'}
              >
                <div>
                  <Icon width="28" height="28" />
                  <CircleTitle
                    className={'mb-7'}
                    data-icom={`report-intro-${type}-title`}
                    data-pptx-id="report-intro-title"
                  >
                    {header}
                  </CircleTitle>
                </div>
                <Tooltip title={tooltipText}>
                  <PieChart width={145} height={145}>
                    <Pie
                      data={data}
                      dataKey="value"
                      startAngle={90}
                      endAngle={450}
                      cx="50%"
                      cy="50%"
                      innerRadius={55}
                      outerRadius={70}
                      blendStroke={true}
                    >
                      {data.map((d, index) => (
                        <Cell key={index} fill={d.color} />
                      ))}
                    </Pie>
                    <Legend
                      content={
                        <div>
                          <div className={'mt-auto'}>
                            <CircleValue> {figure?.replace(' ', '')} </CircleValue>
                            <CircleDescription>{explanation}</CircleDescription>
                          </div>
                        </div>
                      }
                      align={'center'}
                      verticalAlign={'middle'}
                    />
                  </PieChart>
                </Tooltip>
              </Tooltip>
            </CircleCard>
          );
        })}
      </CircleContainer>
    </div>
  );
};

const CircleTitle = styled.div({
  ...omit(['family'], theme.typography.body.medium.bold),
  marginTop: theme.spacing.d,
  marginRight: theme.spacing.e,
  wordBreak: 'keep-all',
});

const CircleValue = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const CircleDescription = styled.div({
  color: theme.colors.text.secondary,
});

const CircleContainer = styled.div`
  flex-wrap: wrap;
`;

const countFlexBasis = (length: number, index: number) => {
  const array = Array.from(Array(length).keys());
  if (length % 3 === 0) {
    return '33%';
  } else if (length % 3 === 1) {
    if (array.slice(-4).includes(index)) return '50%';
  } else if (length % 3 === 2) {
    if (array.slice(-2).includes(index)) return '50%';
  }
  return '33%';
};

const CircleCard = styled.div<{ cards: number; index: number }>`
  flex-basis: ${(props) => countFlexBasis(props.cards, props.index)};
`;

export default Conclusion;
