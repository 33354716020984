import { ApolloProvider } from '@apollo/client';
import { getFrontendConfigValue, theme } from '@arnold/common';
import { ENVIRONMENT } from '@arnold/core';
import { Global, ThemeProvider, css } from '@emotion/react/macro';
import '@lmc-eu/cookie-consent-manager/LmcCookieConsentManager.min.css';
import { init } from '@sentry/browser';
import { contains } from 'ramda';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/scss/main.scss';
import apolloClient from './createApollo';
import './index.css';
import './polyfill';
import { unregister } from './registerServiceWorker';

const appEnvironment = getFrontendConfigValue('ENVIRONMENT');
const isSentryEnabled = contains(appEnvironment, [ENVIRONMENT.PROD]);

if (isSentryEnabled) {
  init({
    dsn: getFrontendConfigValue('TALK_SENTRY_DSN'),
    release: process.env.REACT_APP_DOCKER_VERSION,
    environment: appEnvironment,
  });
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          .barCellClass--neutral {
            fill: ${theme.colors.chart.neutral[0]};
          }

          .barCellClass--neutral:hover,
          .barCellClass--neutral.hovered {
            fill: ${theme.colors.emotionInformative.hover};
          }

          .barCellClass--warning {
            fill: ${theme.colors.chart.warning[0]};
          }

          .barCellClass--warning:hover,
          .barCellClass--warning.hovered {
            fill: ${theme.colors.emotionWarning.hover};
          }

          .barCellClass--alert {
            fill: ${theme.colors.chart.alert[0]};
          }

          .barCellClass--alert:hover,
          .barCellClass--alert.hovered {
            fill: ${theme.colors.emotionDanger.hover};
          }

          .red-list::before {
            background-color: ${theme.colors.chart.alert[0]};
          }

          .orange-list::before {
            background-color: ${theme.colors.chart.warning[0]};
          }

          .blue-list::before {
            background-color: ${theme.colors.chart.neutral[0]};
          }
        `}
      />
      <App />
    </ThemeProvider>
  </ApolloProvider>,
);
unregister();
