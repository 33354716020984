import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { getLocale } from '@arnold/core';
import i18n from 'i18next';
import { ReactComponent as ClosedChat } from '../assets/images/ClosedChat.svg';
import { ReactComponent as NewChat } from '../assets/images/NewChat.svg';
import { ReactComponent as UnfinishedChat } from '../assets/images/UnfinishedChat.svg';
import { Modals } from '../chat/ChatWindow';
import auth from '../lib/auth';
import { IChannel, IUserChannel } from '../types';
import { Button } from './Button';
import Banner from './Banner';

interface IProps {
  channel: IChannel | IUserChannel;
  showModal: (
    modalName: Modals,
    show: boolean,
    redirectTo?: string,
  ) => (e: React.MouseEvent<HTMLElement> | null) => void;
}

const ChannelBanner = ({ channel, showModal }: IProps) => {
  const { t } = useTranslation('channelBanner');
  const isNew = !channel.isOpen && !channel.isFinished;
  const isOpened = channel.isOpen && !channel.isFinished;
  const isClosed = channel.isFinished;
  const isLoggedIn = auth.isLoggedIn();
  const translationPrefix = !isLoggedIn ? 'not_logged' : isNew ? 'new' : isOpened ? 'opened' : 'closed';
  return (
    <Banner
      image={
        <>
          {isNew && <NewChat width={'176px'} />}
          {isOpened && <UnfinishedChat width={'176px'} />}
          {isClosed && <ClosedChat width={'176px'} />}
        </>
      }
      text={
        <>
          <h2>{t(translationPrefix + '_header')}</h2>
          <p>
            {t(translationPrefix + '_text_1')}
            <strong>{channel.topicName}</strong>
            {t(translationPrefix + '_text_2')}
            <strong>{t(translationPrefix + '_text_3')}</strong>
            {t(translationPrefix + '_text_4')}
            <strong>
              {format(new Date(channel.endsAt), 'HH:mm, eeee, d. MMMM.', { locale: getLocale(i18n.language) })}
            </strong>
            {t(translationPrefix + '_text_5')}
          </p>
          {isLoggedIn && (
            <Link to={isClosed ? '/overview' : `/${channel.id}`}>
              <Button>{t(translationPrefix + '_button')}</Button>
            </Link>
          )}
          {!isLoggedIn && (
            <Button onClick={showModal(Modals.LOGIN, true, '/overview')}>{t(translationPrefix + '_button')}</Button>
          )}
        </>
      }
    />
  );
};

export default ChannelBanner;
