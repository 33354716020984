import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { differenceInMinutes } from 'date-fns';
import { findLast, path, propEq } from 'ramda';
import styled from '@emotion/styled/macro';
import { ChatBubble } from '../components';
import TypingBubble from '../components/TypingBubble';
import { channelQuery } from '../graphql/queries';
import { IChannel } from '../types';
import { GetChannelQuery, GetMessagesQuery } from '../generated/hooks';

const Content = styled.div`
  max-width: 960px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 40px;
  flex: 1 0 auto;
`;

interface IProps {
  typing: boolean;
  messages: NonNullable<GetMessagesQuery['messages']>;
  notDeliveredMessages: NonNullable<GetMessagesQuery['messages']>;
  channel: IChannel;
  sendMessage: (message: string, index?: number, values?: number[], id?: string) => void;
  disabled?: boolean;
}

const Messages = ({ typing, messages, notDeliveredMessages, channel, sendMessage, disabled }: IProps) => {
  return (
    <Query<any, any> query={channelQuery} variables={{ channelId: channel.id }}>
      {({ data }: QueryResult<GetChannelQuery>) => {
        let prevDate = '1970-01-01'; // to show datetime in first message
        const chan = path<GetChannelQuery['channel']>(['channel'], data);
        const anonymityLevel = (chan && chan.anonymityLevel) || channel.anonymityLevel;
        let lastRespondentMessage: NonNullable<GetMessagesQuery['messages']>[0] | undefined;
        if (messages) {
          lastRespondentMessage = findLast(propEq('createdBy', 'RESPONDENT'), messages);
        }
        const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
        // Arnold is waiting for answer if last message is type of Question
        const editDisabled =
          lastMessage != null
            ? lastMessage.__typename !== 'Question' &&
              // allow editing last answer for testing channels
              (!channel.testing || lastMessage.__typename !== 'EndingMessage')
            : true;
        return (
          <Content>
            {messages.map((message: any, index: number) => {
              const showDate =
                differenceInMinutes(new Date(message.createdAt).getTime(), new Date(prevDate).getTime()) > 10;
              prevDate = message.createdAt;
              return (
                <ChatBubble
                  key={message.id}
                  message={message}
                  showDate={showDate}
                  anonymityLevel={anonymityLevel}
                  index={index}
                  channel={channel}
                  isLastAnswer={lastRespondentMessage && lastRespondentMessage.id === message.id}
                  // Enable editing only when user is supposed to answer some question,
                  // otherwise we could delete some messages while arnold is sending messages
                  // which is not supported
                  hideEdit={editDisabled}
                  editDisabled={disabled}
                />
              );
            })}
            {notDeliveredMessages.map((message: any, index: number) => {
              return (
                <ChatBubble
                  key={message.id}
                  message={message}
                  anonymityLevel={anonymityLevel}
                  error={true}
                  sentRepeat={sendMessage}
                  editDisabled={disabled}
                  hideEdit={editDisabled}
                  index={index + 1}
                />
              );
            })}
            {typing && <TypingBubble />}
          </Content>
        );
      }}
    </Query>
  );
};

export default Messages;
