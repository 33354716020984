import { gql, MutationFunction } from '@apollo/client';
import { ChildProps, graphql } from '@apollo/client/react/hoc';
import styled from '@emotion/styled/macro';
import { WithTranslation, withTranslation } from 'react-i18next';
import { selectControlStyles, selectMenuStyles, SelectWrapper as Select, theme } from '@arnold/common';
import { getAnonymityIcon, getAnonymityName } from '../lib/common';
import { IChannel } from '../types';
import { AnonymityLevel } from '../generated/hooks';
import { SelectWrapper } from './SelectWrapper';
import { AnonymityName } from './StyledComponents';

interface IAnonymitySelector extends WithTranslation {
  allowedAnonymities: AnonymityLevel[];
  selectedAnonymity: AnonymityLevel;
  id: string;
  respondentName: string;
  disabled: boolean;
}

interface IMutation {
  setChannelAnonymity: TSetChannelAnonymitytMutation;
}

export type TSetChannelAnonymitytMutation = MutationFunction<MutationInputProps, MutationVariables>;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-select__control--is-disabled & {
    color: #bdbdbd !important;
  }
`;

const AnonymitySelector = ({
  selectedAnonymity,
  allowedAnonymities,
  id,
  respondentName,
  setChannelAnonymity,
  t,
  disabled,
}: ChildProps<IMutation & IAnonymitySelector>) => {
  const values = allowedAnonymities.map((anonymity) => ({
    value: anonymity,
    label: (
      <ValueWrapper>
        <Icon>{getAnonymityIcon(anonymity, 18)} </Icon>
        <AnonymityName>{getAnonymityName(anonymity, respondentName, t)}</AnonymityName>
      </ValueWrapper>
    ),
    name: anonymity,
  }));

  const handleChangeAnonymity = ({ value }: { value: string; name: string }) => {
    if (setChannelAnonymity) {
      setChannelAnonymity({
        variables: {
          channelId: id,
          level: value,
        },
      });
    }
  };

  const select = (
    <Select
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (provided: any, state: any) => ({
          ...selectControlStyles(provided, state),
          border: state.isFocused ? `1px solid ${theme.colors.borderMain.active} !important` : 'none',
          cursor: 'pointer',
          '&:hover': {
            border: `1px solid ${theme.colors.borderMain.active} !important`,
          },
        }),
        menu: (provided: any, state: any) => ({
          ...provided,
          ...selectMenuStyles,
          fontSize: `${theme.typography.body.small?.regular} !important`,
          fontWeight: `${theme.typography.body.small?.regular} !important`,
        }),
      }}
      value={values.find((value) => value.value === selectedAnonymity)}
      onChange={handleChangeAnonymity}
      placeholder="..."
      isSearchable={false}
      options={values}
      menuPlacement="auto"
      isDisabled={values.length <= 1 || disabled}
    />
  );

  return (
    <SelectWrapper selectSize={110} data-cy="chat-anonymity-select">
      {select}
    </SelectWrapper>
  );
};

const setChannelAnonymityMutation = gql`
  mutation setChannelAnonymity($channelId: ID!, $level: AnonymityLevel!) {
    setChannelAnonymity(channelId: $channelId, level: $level) {
      id
      anonymityLevel
    }
  }
`;

type MutationInputProps = {
  channel: IChannel;
  level: string;
};

type MutationVariables = {
  channelId: string;
  level: string;
};

export default withTranslation('header')(
  graphql<IAnonymitySelector, any, MutationVariables, IMutation & IAnonymitySelector>(setChannelAnonymityMutation, {
    name: 'setChannelAnonymity',
  })(AnonymitySelector),
);
