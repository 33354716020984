import { flatten } from 'ramda';
import { Filter, getTeamsById } from '../reportHelper';
import { ReportSubscriptionSubscription } from '../../generated/hooks';

export const getDirectMembersFromTeamIds = (
  reportTeams: NonNullable<ReportSubscriptionSubscription['getReport']['teams']>,
  teamIds: string[] | null,
): string[] => flatten<string>(getTeamsById(reportTeams, teamIds || []).map((team) => team.directMembers || []));

export const getRespondentIdsForTeamIds = (
  allTeams: NonNullable<ReportSubscriptionSubscription['getReport']['teams']>,
  teamIds: string[],
) => {
  const respondentIds: string[] = [];
  let teams = getTeamsById(allTeams, teamIds);

  while (true) {
    respondentIds.push(...flatten<string>(teams.map((team) => team.directMembers || [])));

    teams = getTeamsById(allTeams, flatten<string>(teams.map((team) => team.memberTeams || [])));

    if (teams.length <= 0) {
      break;
    }
  }

  return respondentIds;
};

export const hasFilterWithOperator = (operator: string, filters: Filter[]) =>
  filters.some((f) => f.operator === operator);

export const getFilterSingleValue = (operator: string, filters: Filter[]) => {
  const filter = filters.find((f) => f.operator === operator);
  return filter !== undefined ? (filter.values ? filter.values[0] : null) : undefined;
};
