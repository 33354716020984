import styled from '@emotion/styled/macro';
import { theme } from '@arnold/common';

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: ${theme.spacing.e};
`;

export const FilterBox = styled.div`
  border: 1px solid #ededed;
  max-height: 500px;
  min-width: 277.33px;
  overflow-y: auto;
  z-index: 2147483640;
  padding: 9px 0;
  color: ${theme.colors.text.primary};
`;

export const FilterGroupHeader = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.div`
  color: ${theme.colors.text.secondary};
  padding-left: ${theme.spacing.f};
  padding-bottom: ${theme.spacing.c};
`;

export const SequenceFilterContainer = styled.div`
  width: calc((100% - 2rem) / 3);
  margin-right: 1rem;
  position: relative;
`;

type Props = {
  showFilters: boolean;
};

export const FilterModal = styled.div<Props>`
  position: absolute;
  z-index: 100000;
  top: calc(${theme.spacing.l} - ${theme.spacing.a});
  left: calc(50% - 7px);
  transform: translate(-50%);
  box-shadow: 0px 5px 30px -20px #00000080;
  border: ${theme.spacing.a} solid ${theme.colors.borderMain.default};
  padding: 0;
  max-width: calc(60rem - ${theme.spacing.h});
  width: 100%;
  transition: all 6.3s ease;
  display: ${(props) => (props.showFilters ? 'block' : 'none')};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${theme.background.primary};
`;
