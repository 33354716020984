import { captureException, withScope } from '@sentry/browser';
import * as React from 'react';
import styled from '@emotion/styled/macro';

interface IState {
  hasError: boolean;
}

interface IProps {
  children: any;
}

const RefreshButton = styled.button`
  outline: none;
  padding: 8px;
  color: white;
  background-color: #3533cc;
  border: 1px solid #3533cc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ErrorMess = styled.span`
  text-align: center;
  margin: 16px;
  font-size: 18px;
  font-weight: 100;
  margin: 16px;
`;

export default class ErrorBoundary extends React.Component<IProps, IState> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
    withScope((scope) => {
      scope.setExtras(errorInfo);
      captureException(error);
      this.setState({ hasError: true });
    });
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <ErrorMess>Něco se pokazilo</ErrorMess>
          <RefreshButton onClick={this.handleRefresh}>Načíst znovu</RefreshButton>
        </Wrapper>
      );
    }
    return this.props.children;
  }
}
