import { useEffect, RefObject } from 'react';

export const useClickOutside = (refs: RefObject<HTMLDivElement>[], handler: (value: boolean) => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        handler(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, handler]);
};
