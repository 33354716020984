import { flatten } from 'ramda';
import { ReportSubscriptionSubscription } from '../../generated/hooks';

type GetReport = NonNullable<NonNullable<ReportSubscriptionSubscription>['getReport']>;

export interface IReport extends GetReport {
  identifier: string;
  responses: GetReport['responses'];
  questions: GetReport['questions'];
  respondents: NonNullable<GetReport['respondents']>;
  reportRoots: string[];
  metadata: GetReport['metadata'];
  teams: GetReport['teams'];
  sequence: GetReport['surveyGroup'] | null;
  questionLabels: GetReport['questionLabels'] | null;
  reportDefinition: GetReport['reportDefinition'];
  filters: string | undefined;
  widgets: string | undefined;
  surveyKeywordsGenerated: boolean;
}

export type ReportData = {
  getReport: IReport;
};

export type WidgetFilters = {
  [key: string]: string[];
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const getFullName = (respondent: NonNullable<GetReport['respondents']>[0] | undefined) =>
  respondent ? [respondent.firstname, respondent.surname].join(' ') : '';

export const getMessagesWithQuestionIndex = (
  report: IReport,
  questionIndex: string,
  options: { filterEmpty?: boolean } = {},
) => {
  const responses = report.responses
    ? flatten<
        NonNullable<NonNullable<GetReport['responses']>[0]['messages']>[0] & {
          respondentId: string;
          responseId: string;
        }
      >(
        report.responses
          .map((response) => {
            const answers = response.messages
              ? response.messages.filter((message) => message.questionDefinitionIndex === questionIndex)
              : undefined;
            return answers
              ? answers.map((answer) => ({
                  ...answer,
                  responseId: response.id,
                  respondentId: response.respondentId,
                }))
              : undefined;
          })
          .filter(notEmpty),
      ).filter(notEmpty)
    : [];

  return options.filterEmpty ? responses.filter((comment) => (comment.text || '').trim().length > 0) : responses;
};
