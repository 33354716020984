import { ApolloClient, MutationFunction } from '@apollo/client';
import { ChildProps, graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import { clone, path, remove } from 'ramda';
import { Component, ReactNode, createContext } from 'react';
import { AnonymityLevel, GetMessagesQuery, QuestionType } from '../generated/hooks';
import { sendSelectAnswerMutation, sendTextAnswerMutation } from '../graphql/mutations';
import { messagesQuery } from '../graphql/queries';
import { definitionTypes } from '../lib/common';
import { organizationRespondent } from '../types';
import { CHATTING_ANIMATION_LENGTH } from './constants';

interface IProviderState {
  message: string;
  index: number;
  remainingQuestions: number | null;
  values: number[];
  bounce: boolean;
  typing: boolean;
  err: any;
  typingStart: number;
  hideControls: boolean;
  lastMessage: NonNullable<GetMessagesQuery['messages']>[0] | null;
  inputInitValue: string;
  notDeliveredMessages: any[];
  selectedPersons: organizationRespondent[];
  lastKey: string | undefined;
}

interface IProps {
  channel: any;
  startingMessages: any;
  client: any;
  sendSelectAnswer: () => void;
  children: ReactNode;
}

interface ITextMutationPayload {
  sendTextAnswer: {
    id: string;
    text: string;
    createdAt: string;
    createdBy: string;
    anonymityLevel: AnonymityLevel;
    isLast: boolean;
    __typename: string;
  };
}

interface ISelectMutationPayload {
  sendSelectAnswer: {
    id: string;
    text: string;
    createdAt: string;
    createdBy: string;
    anonymityLevel: AnonymityLevel;
    isLast: boolean;
    __typename: string;
  };
}

interface ISendTextMutationInput {
  channelId: string;
  text: string;
}

interface ISendChoiceMutationInput {
  channelId: string;
  value: number[];
}

interface IMutation {
  sendTextAnswer: MutationFunction<ITextMutationPayload, ISendTextMutationInput>;
  sendSelectAnswer: MutationFunction<ISelectMutationPayload, ISendChoiceMutationInput>;
}

/* tslint:disable */

interface IInputContextValue {
  state: IProviderState;
  organizationId?: string;
  changeMessage: (msg: string) => void;
  removeChoice: (index: number) => void;
  sendMessage: (message: string | null, index?: number) => void;
  addChoice: (message: string, values: number) => void;
  rangeChoosed: (message: string, index: number) => void;
  changeLastMessage: (message: any) => void;
  makeBounce: (makeBounce?: boolean) => void;
  editInputValue: (type: string, message: string, values?: number[]) => void;
  handleSetMessage: (message: string) => void;
  handleSelectPerson: (respondent: organizationRespondent) => void;
  handleRemoveRespondent: (respondent: organizationRespondent) => void;
  setLastKey: (key: string | undefined) => void;
  setClearInputFunction: (clearInput: () => void) => void;
  clearInput: () => void;
  setRemainingQuestions: (remainingQuestions: number) => void;
}

const defaultContextValue: IInputContextValue = {
  state: {
    index: 0,
    values: [],
    message: '',
    bounce: false,
    lastMessage: null,
    notDeliveredMessages: [],
    remainingQuestions: null,
    inputInitValue: '',
    hideControls: false,
    selectedPersons: [],
    typing: false,
    typingStart: 0,
    err: null,
    lastKey: undefined,
  },

  /* eslint-disable */
  changeMessage: (message: string) => {},
  removeChoice: (index: number) => {},
  sendMessage: (message: string | null, index?: number) => {},
  addChoice: (message: string, index: number) => {},
  rangeChoosed: (message: string, index: number) => {},
  changeLastMessage: (message: any) => {},
  makeBounce: (makeBounce?: boolean) => {},
  editInputValue: (type: string, message: string) => {},
  handleSetMessage: (message: string) => {},
  handleSelectPerson: (respondent: organizationRespondent) => {},
  handleRemoveRespondent: (respondent: organizationRespondent) => {},
  setLastKey: (key: string | undefined) => {},
  setClearInputFunction: (clearInput: () => void) => {},
  clearInput: () => {},
  setRemainingQuestions: () => {},
  /* eslint-enable */
};

/* tslint:enable */

const InputContext = createContext<IInputContextValue>(defaultContextValue);

class InputProviderComponent extends Component<
  ChildProps<IProps & IMutation, ITextMutationPayload & ISelectMutationPayload>,
  IProviderState
> {
  state = defaultContextValue.state;

  bounce: any = null;
  clearInputFunction?: () => void;

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.startingMessages && this.props.startingMessages && this.props.startingMessages.length) {
      const { startingMessages, client } = this.props;
      this.setState({ typing: true });
      setTimeout(() => {
        this.addGraduallyMessages(client, this.props.channel.id, 0, startingMessages);
      }, CHATTING_ANIMATION_LENGTH);
    }
  }

  handleRangeChoosed = (message: string, index: number) => {
    this.setState({ message, values: [index] });
    this.makeBounce();
  };

  setLastKey = (key: string | undefined) => {
    this.setState({ lastKey: key });
  };

  handleSelectPerson = async (person: organizationRespondent) => {
    await this.setState((oldState) => ({
      selectedPersons: [...oldState.selectedPersons, person],
    }));
    this.setState({ inputInitValue: '' });
  };

  handleRemoveRespondent = (respondent: organizationRespondent) => {
    this.setState((oldState) => ({
      selectedPersons: oldState.selectedPersons.filter((res) => res.id !== respondent.id),
    }));
  };

  makeBounce = (makeBounce?: boolean) => {
    if (makeBounce != null) {
      this.setState({ bounce: false });
    } else {
      this.setState({ bounce: true });
      setTimeout(() => this.setState({ bounce: false }), 2800);
    }
  };

  prepareBounce = () => {
    clearTimeout(this.bounce);
    this.bounce = setTimeout(this.makeBounce, 3000);
  };

  handleChangeLastMessage = (message: any) => {
    this.setState({ lastMessage: message });
  };

  changeInputInitValue = (type: string, message: string, values?: number[]) => {
    if (
      type === 'FreeTextAnswer' ||
      type === 'SmsContactQuestionDefinition' ||
      type === 'EmailContactQuestionDefinition'
    ) {
      this.setState({ inputInitValue: message });
    } else {
      this.setState(values ? { inputInitValue: '', values } : { inputInitValue: '', values: [] });
    }
  };

  handleSetMessage = (message: string) => {
    this.setState({ message });
  };

  writeMessageToStore = (store: any, message: any, channelId: string) => {
    try {
      const queryMessage = clone(
        store.readQuery({
          query: messagesQuery,
          variables: { channelId },
        }),
      );
      if (!queryMessage.messages.find((msg: { id: string }) => msg.id === message.id)) {
        queryMessage.messages.push({ ...message, value: message.value || this.state.values });
        store.writeQuery({
          query: messagesQuery,
          data: queryMessage,
          variables: { channelId },
        });
      }
    } catch (e) {
      const dataQuery = {
        messages: [message],
      };
      store.writeQuery({
        query: messagesQuery,
        data: dataQuery,
        variables: { channelId },
      });
    }
  };

  addGraduallyMessages = (
    client: ApolloClient<any>,
    channelId: string,
    index: number,
    startingMessages: NonNullable<GetMessagesQuery['messages']>,
  ) => {
    this.writeMessageToStore(client, startingMessages[index], channelId);
    this.setState({ typing: false });
    if (index + 1 < startingMessages.length) {
      setTimeout(() => {
        this.setState({
          typing: true,
          typingStart: Date.now(),
        });
        setTimeout(() => {
          this.addGraduallyMessages(client, channelId, index + 1, startingMessages);
        }, CHATTING_ANIMATION_LENGTH);
      }, 100);
    } else {
      setTimeout(() => {
        this.setState({ hideControls: false });
      }, CHATTING_ANIMATION_LENGTH);
    }
  };

  handleSendMessage = async (message: string | null, index?: number, notSendValues?: number[], id?: string) => {
    const { channel, client } = this.props;
    const { values, notDeliveredMessages, lastMessage, selectedPersons } = this.state;
    this.setState({
      inputInitValue: '',
      typing: true,
      typingStart: Date.now(),
      hideControls: true,
    });
    clearTimeout(this.bounce);
    const optional = path(['definition', 'optional'], lastMessage);

    // send proper mutation based on previous message
    const isPersonMultiselect = this.hasTypename(definitionTypes[QuestionType.PersonMultiselect]);
    const isTextMessage = this.hasTypename(definitionTypes[QuestionType.Freetext]);
    const isSmsContactMessage = this.hasTypename(definitionTypes[QuestionType.SmsContact]);
    const isEmailContactMessage = this.hasTypename(definitionTypes[QuestionType.EmailContact]);

    if (!optional && isTextMessage && message === '' && this.state.message === '') {
      return false;
    }

    let newArr: any[] = [];
    if (index) {
      newArr = [
        ...notDeliveredMessages.slice(0, index - 1),
        ...notDeliveredMessages.slice(index, notDeliveredMessages.length),
      ];
      this.setState({ notDeliveredMessages: newArr });
    }

    const makeUpdate =
      (mutation: string) =>
      (store: any, { data }: { data: any }) => {
        const answer = data[mutation];
        this.writeMessageToStore(store, answer, channel.id);

        if (answer.response && answer.response.length) {
          if (Number.isInteger(answer.remainingQuestions)) {
            this.setState({ remainingQuestions: answer.remainingQuestions });
          }
          const diff = Date.now() - this.state.typingStart;

          setTimeout(
            () => {
              this.addGraduallyMessages(store, this.props.channel.id, 0, answer.response);
            },
            diff > CHATTING_ANIMATION_LENGTH ? 0 : CHATTING_ANIMATION_LENGTH - diff,
          );
        }
      };

    const variables = {
      channelId: channel.id,
      previousQuestionId: lastMessage?.id,
      ...(isTextMessage || isSmsContactMessage || isEmailContactMessage
        ? { text: message }
        : {
            values:
              notSendValues ||
              (isPersonMultiselect ? selectedPersons.map((i) => parseInt(i.id.toString(), 10)) : values),
            message: message || '',
          }),
    };

    const callSendMessage = () => {
      const mutation =
        isTextMessage || isSmsContactMessage || isEmailContactMessage ? 'sendTextAnswer' : 'sendSelectAnswer';
      const mutationFunction: any = this[mutation];
      return mutationFunction.call(this, message || this.state.message, {
        variables,
        update: makeUpdate(mutation),
      });
    };
    this.setState({
      message: '',
      values: [],
      index: 0,
      selectedPersons: [],
    });
    callSendMessage()
      .then(() => {
        this.setState({ notDeliveredMessages: [], err: null });
      })
      .catch((err: any) => {
        client.refetchQueries({
          include: [{ query: messagesQuery, variables: { channelId: channel.id } }],
        });
        this.setState({ err, typing: false });
        this.addNotDeriveMess(
          newArr,
          notDeliveredMessages,
          message || this.state.message,
          notSendValues || (isPersonMultiselect ? selectedPersons.map((i) => i.id) : values),
          index,
          id,
        );
      });
    return true;
  };

  addNotDeriveMess = (
    newArr: any[],
    notDeliveredMessages: any[],
    message: string,
    values?: (number | string)[],
    index?: number,
    id?: string,
  ) => {
    const {
      channel: { anonymityLevel },
    } = this.props;
    const newMessage = {
      id: id != null ? id : -notDeliveredMessages.length.toString(),
      text: message,
      values,
      createdAt: new Date().toISOString(),
      createdBy: 'RESPONDENT',
      __typename: 'Answer',
      anonymityLevel,
    };
    if (index === undefined) {
      this.setState({
        notDeliveredMessages: [...this.state.notDeliveredMessages, newMessage],
      });
    } else {
      this.setState({ notDeliveredMessages: [...newArr, newMessage] });
    }
  };

  sendTextAnswer = (message: string, options: any) => {
    const {
      sendTextAnswer,
      channel: { anonymityLevel },
    } = this.props;

    return sendTextAnswer({
      optimisticResponse: {
        sendTextAnswer: {
          id: '-1',
          text: this.hasTypename(definitionTypes[QuestionType.SmsContact])
            ? message
                .replace(/ /g, '')
                .split(/(?=(?:...)*$)/)
                .join(' ')
                .replace('+ ', '+')
            : message,
          createdAt: new Date().toISOString(),
          createdBy: 'RESPONDENT',
          __typename: 'FreeTextAnswer',
          anonymityLevel,
          response: [],
          isLast: false,
        },
      },
      ...options,
    });
  };

  sendSelectAnswer = (message: string, options: any) => {
    const {
      sendSelectAnswer,
      channel: { anonymityLevel },
    } = this.props;

    return sendSelectAnswer({
      optimisticResponse: {
        sendSelectAnswer: {
          id: '-1',
          text: message,
          createdAt: new Date().toISOString(),
          createdBy: 'RESPONDENT',
          __typename: 'SelectAnswer',
          anonymityLevel,
          response: [],
          isLast: false,
        },
      },
      ...options,
    });
  };

  handleChangeMessage = (message: string) => {
    this.setState({
      message,
    });
  };

  hasTypename = (type: string) => {
    const { lastMessage } = this.state;
    const typeName = path(['definition', '__typename'], lastMessage);
    return typeName && typeName === type;
  };

  handleAddChoice = (message: string, index: number) => {
    const { lastMessage } = this.state;
    const selectedRange = path<number[]>(['definition', 'selectedRange'], lastMessage);
    if (selectedRange && selectedRange[1] === 1) {
      this.setState({ values: [index] }, () => this.handleSendMessage(message, index));
    } else {
      this.setState((prevState) => ({
        message: prevState.message !== '' ? `${prevState.message}, ${message}` : message,
        values: [...prevState.values, index],
      }));
      this.prepareBounce();
    }
  };

  handleRemoveChoice = (index: number) => {
    this.setState((prevState) => ({
      values: remove(index, 1, prevState.values),
    }));
    this.prepareBounce();
  };

  setClearInputFunction = (clearInput: () => void) => {
    this.clearInputFunction = clearInput;
  };

  clearInput = () => {
    this.clearInputFunction?.();
  };

  setRemainingQuestions = (remainingQuestions: number) => {
    this.setState({ remainingQuestions });
  };

  render() {
    return (
      <InputContext.Provider
        value={{
          state: this.state,
          organizationId: this.props.channel?.respondent?.organizationId,
          changeMessage: this.handleChangeMessage,
          sendMessage: this.handleSendMessage,
          removeChoice: this.handleRemoveChoice,
          addChoice: this.handleAddChoice,
          rangeChoosed: this.handleRangeChoosed,
          changeLastMessage: this.handleChangeLastMessage,
          makeBounce: this.makeBounce,
          editInputValue: this.changeInputInitValue,
          handleSetMessage: this.handleSetMessage,
          handleSelectPerson: this.handleSelectPerson,
          handleRemoveRespondent: this.handleRemoveRespondent,
          setLastKey: this.setLastKey,
          setClearInputFunction: this.setClearInputFunction,
          clearInput: this.clearInput,
          setRemainingQuestions: this.setRemainingQuestions,
        }}
      >
        {this.props.children}
      </InputContext.Provider>
    );
  }
}

const WithMessagesOperations = compose(
  graphql<IProps>(sendTextAnswerMutation, {
    name: 'sendTextAnswer',
  }),
  graphql<IProps>(sendSelectAnswerMutation, {
    name: 'sendSelectAnswer',
  }),
);

export const InputProvider = WithMessagesOperations(InputProviderComponent);
export const InputConsumer = InputContext.Consumer;
