import React, { ReactElement, useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from '@emotion/styled/macro';
import { LanguageSelector, updateIntercomUser } from '@arnold/common';
import { Modals } from '../chat/ChatWindow';
import { Header, LoginModal } from '../components';
import ChangeLanguage from '../components/modals/ChangeLanguage';
import EmailSendModal from '../components/modals/EmailSendModal';
import ResetPasswordModal from '../components/modals/ResetPasswordModal';
import SetPassword from '../components/modals/SetPassword';
import auth, { SET_PASSWORD_ACCESS_TOKEN, USER_EMAIL_KEY } from '../lib/auth';
import { getUserHash, parseUrlParams } from '../lib/common';
import { IsAccessTokenValidQuery, ReportSubscriptionSubscription, UserOrgRole } from '../generated/hooks';
import { IChannel } from '../types';
import UserMenu from './UserMenu';

interface IProps extends RouteComponentProps<any> {
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  children: ReactElement;
  setLocalization: (lang: string) => any;
  history: any;
  location: any;
  defaultModal?: Modals;
  allowedLanguages?: string[];
  initLanugage?: string;
  navbarContent?: React.ReactNode;
}

const ControlsContainer = styled.div`
  display: flex;
  gap: 0.7rem;
`;

const PageLayout = (props: IProps) => {
  const [modal, setModal] = useState<Modals | undefined>(props.defaultModal);
  const [hasAnswered, toggleLanguageSelector] = useState<boolean>(false);
  const [selectedLanguage, handleChangeLanguage] = useState<string>(props.initLanugage || ``);
  const [channel, setChannel] = useState<IChannel | null>(null);
  const [reportRespondent, setReportRespondent] =
    useState<NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent']>(null);
  const [redirect, setRedirect] = useState<string | null>(null);

  const params = parseUrlParams(props.location.search);
  useEffect(() => {
    const accessToken = params.getParam('accessToken');
    const userEmail = params.getParam('userEmail');
    if (userEmail && accessToken) {
      localStorage.setItem(USER_EMAIL_KEY, userEmail);
      localStorage.setItem(SET_PASSWORD_ACCESS_TOKEN, accessToken);
      showModal(Modals.SET_PASSWORD, true)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeLanguage(selectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const { user } = props;
    if (user && user.respondents) {
      changeLanguage(
        (user.respondents[0] && user.respondents[0].language && user.respondents[0].language.code) ||
          (user.respondents[0] &&
            user.respondents[0].organization &&
            user.respondents[0].organization.primaryLanguageCode) ||
          (user.organization && user.organization!.primaryLanguageCode) ||
          'en',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = (modalName: Modals, show: boolean, redirectTo?: string) => () => {
    setModal(show ? modalName : undefined);
    if (!show && redirect) {
      props.history.push(redirect);
    }
    if (show && redirectTo) {
      setRedirect(redirectTo);
    } else {
      setRedirect(null);
    }
  };

  const changeLanguage = (languageCode: string) => {
    handleChangeLanguage(languageCode);
    if (languageCode) {
      props.setLocalization(languageCode);

      if (props.user?.respondents?.find((r) => r.organizationRole === UserOrgRole.OrgAdmin)) {
        updateIntercomUser({
          language: languageCode,
          language_override: languageCode,
          user_hash: getUserHash(),
        });
      }
    }
  };

  const handleLogoClick = (e: React.MouseEvent<HTMLElement> | null) => {
    if (auth.isLoggedIn()) {
      props.history.push('/overview');
    } else if (channel && channel.respondent && !channel.respondent.user) {
      showModal(Modals.RESET_PASSWORD, true)();
    } else {
      showModal(Modals.LOGIN, true)();
    }
  };

  return (
    <Div100vh
      style={{
        height: '100rvh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f9f9f9',
      }}
    >
      <ChatHeader>
        {channel !== null ? (
          <Header
            channel={channel}
            disabled={hasAnswered}
            isPublic={channel.public}
            showModal={showModal}
            onLogoClick={handleLogoClick}
            hideDropdown={params.getParam('hideDropdown') === 'true'}
          />
        ) : (
          <Row>
            <Brand>
              <img src="/arnold-logo.svg" width={192} height={48} alt="Arnold" onClick={handleLogoClick} />
            </Brand>
            {props.navbarContent}
            <ControlsContainer>
              {selectedLanguage && (
                <LanguageSelector
                  lang={selectedLanguage}
                  allowedLanguages={Object.freeze(props.allowedLanguages)}
                  onChange={changeLanguage}
                />
              )}
              <UserMenu reportRespondent={reportRespondent} channel={channel} showModal={showModal} user={props.user} />
            </ControlsContainer>
          </Row>
        )}
      </ChatHeader>
      {React.cloneElement(props.children, {
        showModal,
        modal,
        hasAnswered,
        toggleLanguageSelector,
        changeLanguage,
        setChannel,
        setReportRespondent,
        selectedLanguage,
      })}
      <LoginModal show={modal === Modals.LOGIN} showModal={showModal} />
      <ResetPasswordModal show={modal === Modals.RESET_PASSWORD} showModal={showModal} />
      <EmailSendModal show={modal === Modals.EMAIL_SEND} showModal={showModal} />
      <SetPassword show={modal === Modals.SET_PASSWORD} showModal={showModal} />
      <ChangeLanguage
        show={!!channel && modal === Modals.CHANGE_LANGUAGE}
        showModal={showModal}
        changeLanguage={changeLanguage}
        selectedLanguage={selectedLanguage}
        disabled={hasAnswered}
        id={channel?.id}
        finished={!!channel?.isFinished}
        allowedLanguages={channel?.allowedLanguages || []}
      />
    </Div100vh>
  );
};

const Brand = styled.div`
  width: 126px;
  cursor: pointer;

  @media (min-width: 992px) {
    width: 192px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;

    @media (min-width: 992px) {
      margin-top: -6px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ChatHeader = styled.div`
  height: 56px;
  background-color: #fff;
  z-index: 2147483641;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 20px;
  flex-shrink: 0;

  @media (min-width: 576px) {
    height: 64px;
    padding-left: 40px;
    padding-right: 20px;
  }
`;

export default withRouter<IProps, any>(PageLayout);
