import { ChildProps, graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Modals } from '../chat/ChatWindow';
import { GetChannelQuery, GetChannelQueryResult, GetChannelQueryVariables } from '../generated/hooks';
import { channelQuery } from '../graphql/queries';
import { USER_EMAIL_KEY } from '../lib/auth';
import { IUserChannel } from '../types';
import AnonymitySelector from './AnonymitySelector';
import UserMenu from './UserMenu';

const Brand = styled.div`
  width: 126px;
  cursor: pointer;

  @media (min-width: 992px) {
    width: 192px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;

    @media (min-width: 992px) {
      margin-top: -6px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
`;

const AnonymityLevelWrap = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 6px;

  @media (min-width: 576px) {
    margin-right: 22px;
  }

  @media (min-width: 992px) {
    margin-right: 65px;
  }
`;

const AnonymityLevelWrapLabel = styled.span`
  display: none;
  margin-right: 12px;
  font-weight: normal;

  @media (min-width: 992px) {
    display: flex;
    align-self: center;
  }
`;

interface IProps {
  channel: NonNullable<GetChannelQuery['channel']>;
  selectedLanguage: string;
  disabled: boolean;
  hideDropdown?: boolean;
  handleChangeLanguage: (languageCode: string) => any;
  t: any;
  isPublic: boolean;
  onLogoClick: (e: React.MouseEvent<HTMLImageElement> | null) => void;
  showModal: (modalName: Modals, show: boolean) => (e: React.MouseEvent<HTMLElement> | null) => void;
}

const handleCompleted = (data: { channel: NonNullable<GetChannelQuery['channel']> }) => {
  if (!localStorage.getItem(USER_EMAIL_KEY) && data && data.channel && data.channel.respondent) {
    localStorage.setItem(
      USER_EMAIL_KEY,
      data.channel.respondent.user
        ? data.channel.respondent.user.username
        : data.channel.respondent
            .contacts!.filter((c) => c.type === 'EMAIL' && c.source === 'PRIMARY')
            .map((c) => c.value)[0],
    );
  }
};

const Header = (props: ChildProps<IProps, { channel: NonNullable<GetChannelQuery['channel']> }, any>) => {
  const { t, channel, data, isPublic, hideDropdown, showModal, onLogoClick } = props;
  const channelData = (data && data.channel) || channel;
  const { allowedAnonymities, anonymityLevel, id, respondent, isFinished } = channelData;

  return (
    <RowWrapper>
      <Row>
        <Brand>
          <img src="/arnold-logo.svg" width={192} height={48} alt="Arnold" onClick={onLogoClick} />
        </Brand>
      </Row>
      <Row>
        {!isPublic && allowedAnonymities && (
          <AnonymityLevelWrap>
            <AnonymityLevelWrapLabel>{t('answering')}</AnonymityLevelWrapLabel>
            <AnonymitySelector
              allowedAnonymities={allowedAnonymities}
              id={id}
              respondentName={respondent ? `${respondent.firstname} ${respondent.surname}` : t('bubble:me')}
              selectedAnonymity={anonymityLevel}
              disabled={isFinished}
            />
          </AnonymityLevelWrap>
        )}
        {!hideDropdown && <UserMenu channel={channel} showModal={showModal} />}
      </Row>
    </RowWrapper>
  );
};

export default withTranslation('header')(
  compose(
    graphql<{ channel: IUserChannel }, GetChannelQueryResult, GetChannelQueryVariables>(channelQuery, {
      options: ({ channel }) => ({
        variables: { channelId: channel.id },
        notifyOnNetworkStatusChange: true,
        onCompleted: handleCompleted,
      }),
    }),
  )(Header),
) as any;
