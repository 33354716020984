import * as React from 'react';

const TeamIcon = ({ color, size }) => (
  <svg
    version="1.1"
    id="Vrstva_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 135.569 95.184"
    enableBackground="new 0 0 135.569 95.184"
    xmlSpace="preserve"
    fill={color}
  >
    <path
      d="M54.564,57.361l-0.115,0.222l-0.167,0.188c-1.047,1.174-2.169,2.15-3.293,2.975l0.045,0.245
        c0.028,0.163,0.086,0.298,0.16,0.412c2.362-0.889,3.892-1.387,4.972-1.387c0.175,0,0.334,0.012,0.478,0.033
        c3.544,0.534,6.888,1.152,10.076,1.847h11.228c3.142-0.729,6.431-1.35,9.869-1.848c0.132-0.019,0.278-0.03,0.439-0.03
        c0.941,0,2.239,0.411,4.219,1.147c-0.958-0.706-1.913-1.54-2.805-2.539l-0.167-0.188l-0.115-0.223
        c-0.489-0.94-1.187-1.968-2.075-3.053c-3.092-3.778-4.477-8.52-5.013-12.951c-1.336,0.432-2.563,0.733-3.524,0.733
        c-1.778,0-4.532-1.05-7.195-2.066c-1.33-0.508-3.136-1.197-3.739-1.283c-1.785,0.261-3.524,0.559-5.214,0.892
        c-0.776,4.603-2.409,9.341-5.571,13.206C55.992,54.995,55.153,56.228,54.564,57.361z"
    />
    <path
      d="M91.455,17.563c0.176-3.465-0.217-6.507-0.453-7.925C90.086,4.144,84.83,0,78.775,0S67.464,4.144,66.549,9.638
        c-0.501,3.007-1.739,13.301,3.345,19.515c0.877,1.072,1.594,2.115,2.133,3.106c2.487,2.678,5.722,3.645,6.749,3.896
        c0.581-0.141,1.855-0.521,3.297-1.287c0.099-1.913,0.257-3.467,0.547-5.205C83.481,24.485,86.857,20.108,91.455,17.563z"
    />
    <path
      d="M134.533,69.46c-6.126-2.393-13.18-4.176-21.535-5.44c-0.63,0.068-2.715,0.87-4.247,1.459
        c-2.988,1.148-6.078,2.337-8.08,2.337c-1.734,0-4.349-0.92-6.997-1.921c-0.352-0.133-0.704-0.268-1.056-0.401
        c-1.553-0.593-3.665-1.398-4.33-1.473c-2.912,0.424-5.713,0.937-8.399,1.531c0.322,0.112,0.641,0.228,0.96,0.343
        c0.78,0.281,1.558,0.563,2.315,0.859c2.813,1.149,4.391,4.008,3.865,6.962l-2.962,16.088h46.719c0.784,0,1.445-0.519,1.572-1.232
        l3.186-17.304C135.68,70.507,135.265,69.759,134.533,69.46z"
    />
    <path
      d="M86.004,36.583c-0.004,0.185-0.009,0.365-0.01,0.554c0.425-0.306,0.849-0.637,1.267-1.003
        C86.881,36.262,86.463,36.413,86.004,36.583z"
    />
    <path
      d="M114.777,30.32c-1.059-6.348-7.123-11.136-14.106-11.136c-1.916,0-3.757,0.371-5.45,1.025
        c-0.754,0.291-1.476,0.639-2.16,1.04c-0.874,0.512-1.688,1.104-2.424,1.774c-2.115,1.927-3.599,4.453-4.072,7.296
        c-0.017,0.103-0.036,0.235-0.055,0.353c-0.143,0.907-0.312,2.225-0.419,3.822c-0.044,0.655-0.073,1.356-0.087,2.089
        c0.459-0.171,0.877-0.321,1.257-0.45c-0.418,0.366-0.842,0.698-1.267,1.003c-0.003,0.513,0.006,1.04,0.024,1.578
        c0.022,0.681,0.071,1.381,0.136,2.089c0.374,4.066,1.501,8.46,4.254,11.825c0.992,1.212,1.8,2.39,2.403,3.506
        c2.948,3.193,6.724,4.268,7.854,4.528c0.964-0.228,3.875-1.063,6.525-3.267c0.458-0.381,0.908-0.797,1.339-1.263
        c0.361-0.668,0.805-1.359,1.308-2.065c0.337-0.474,0.696-0.952,1.095-1.438c0.646-0.789,1.189-1.643,1.669-2.528
        C116.445,42.998,115.285,33.373,114.777,30.32z"
    />
    <path
      d="M65.033,65.895h1.274c-0.287-0.063-0.585-0.122-0.875-0.185c-0.02,0.008-0.041,0.016-0.062,0.023
        C65.252,65.778,65.147,65.843,65.033,65.895z"
    />
    <path
      d="M81.68,70.469c-2.847-1.112-5.869-2.109-9.079-3.005c-1.172-0.327-2.376-0.639-3.602-0.938
        c-0.885-0.217-1.778-0.43-2.691-0.632h-1.274c0.114-0.052,0.219-0.117,0.338-0.161c0.021-0.008,0.042-0.016,0.062-0.023
        c-2.948-0.632-6.033-1.2-9.301-1.694c-0.738,0.052-3.258,1.02-5.122,1.736c-0.011,0.004-0.022,0.009-0.033,0.013
        C47.478,67.11,43.86,68.5,41.563,68.5c-2.254,0-5.876-1.382-9.38-2.719c-1.899-0.725-4.489-1.713-5.246-1.765
        c-9.556,1.389-18.133,3.562-25.496,6.462c-1.043,0.388-1.614,1.425-1.395,2.479L3.811,93.42c0.185,1.062,1.073,1.764,2.215,1.764
        H77.12c0.961,0,1.791-0.562,2.107-1.38c0.047-0.119,0.082-0.244,0.105-0.373l0.3-1.627l0.368-2l3.093-16.801
        C83.284,71.934,82.703,70.887,81.68,70.469z"
    />
    <path
      d="M58.575,24.533c-1.278-7.667-8.592-13.45-17.012-13.45c-8.421,0-15.733,5.782-17.011,13.45
        c-0.681,4.091-2.361,18.102,4.615,26.628c1.169,1.429,2.116,2.814,2.819,4.121c3.619,3.941,8.297,5.22,9.571,5.507
        c0.764-0.176,2.761-0.718,5.011-1.961c0.598-0.329,1.21-0.706,1.824-1.142c0.596-0.422,1.19-0.901,1.769-1.436
        c0.332-0.306,0.66-0.624,0.978-0.97c0.705-1.308,1.652-2.692,2.82-4.12c2.313-2.827,3.671-6.259,4.433-9.73
        c0.157-0.718,0.291-1.436,0.4-2.152c0.108-0.711,0.196-1.419,0.264-2.119L58.575,24.533z"
    />
  </svg>
);

export default TeamIcon;
