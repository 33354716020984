import {
  SelectWrapper,
  SelectMenuProps,
  selectMenuStyles,
  selectControlStyles,
  SelectPlaceholderProps,
} from '@arnold/common';
import styled from '@emotion/styled';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter, FILTER_OPERATOR } from '../../../../lib/reportHelper';
import { IReport } from '../../../../lib/reports/base';
import { CustomFilterPlaceholder } from '../CustomFilterPlaceholder';
import SourceMenu from './SourceMenu';

interface IProps {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
  report: IReport;
}

const StyledSelectWrapper = styled(SelectWrapper)`
  width: 100%;
`;

const GlobalSourceFilter: FC<IProps> = ({ filters, setFilters, report }) => {
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const scrollPositionRef = useRef<number>(0);

  const options = Array.from(new Set(report.source?.map(({ sourceId }) => sourceId)));

  const { t } = useTranslation('Report');

  return (
    <StyledSelectWrapper
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      isMulti={false}
      isSearchable={false}
      components={{
        Menu: (menuProps: SelectMenuProps) => (
          <SourceMenu
            {...menuProps}
            filteredOptions={filteredOptions}
            setFilteredOptions={setFilteredOptions}
            filters={filters}
            setFilters={setFilters}
            options={options}
            ref={scrollPositionRef}
          />
        ),
        Placeholder: (placeholderProps: SelectPlaceholderProps) => (
          <CustomFilterPlaceholder
            {...placeholderProps}
            placeholder={t('filterBySource')}
            operator={FILTER_OPERATOR.SOURCE}
            filters={filters}
          />
        ),
      }}
      options={options?.map((option) => ({ value: option, label: option }))}
      styles={{
        menu: (provided: any, state: any) => ({
          ...provided,
          ...selectMenuStyles,
        }),
        control: (provided: any, state: any) => ({
          ...selectControlStyles(provided, state),
          paddingLeft: '0',
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          display: 'flex',
        }),
      }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
    />
  );
};

export default GlobalSourceFilter;
