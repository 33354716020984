import { sum } from 'ramda';
import { GraphData } from '../../reports/components/Graph';
import {
  Filter,
  getGraphLabel,
  getRespondentsMap,
  getTeamLabel,
  getTeamName,
  isWidgetTraversable,
  ReportRespondentsMap,
} from '../reportHelper';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import { AggregatedData, Team } from './aggregateData';
import { IReport } from './base';
import { sortAndFilterRows } from './graphHelpers';
import { calculateCount, filterMessagesForTeamAndValue, getTeamsForReversedGraph } from './queryAggregatedData';
import { Answer, AnswerType } from './question';

const prepareRowInfo = (
  answers: Answer[],
  team: Team,
  reportRoots: string[],
  traversable: boolean,
  displayOnlyDirect: boolean,
  firstWidget: boolean,
  reportRespondents: ReportRespondentsMap,
  questionDefinitionIndex: string,
  filteredAnswers: string[],
  aggregatedData: AggregatedData,
  t: any,
  segmentsWithoutCounts = false,
) => {
  const onlyDirectMembers =
    displayOnlyDirect || (!firstWidget && reportRoots.length === 1 && team.id === reportRoots[0]);

  const totalRespondentsCount = calculateCount(
    aggregatedData.teams,
    aggregatedData.teams[team.id],
    questionDefinitionIndex,
    onlyDirectMembers,
    reportRespondents,
  );

  const rawValues = filterMessagesForTeamAndValue(
    aggregatedData.teams,
    team,
    questionDefinitionIndex,
    filteredAnswers,
    onlyDirectMembers,
  ).filter((msg) => reportRespondents[msg.respondentId]);

  const values = rawValues.map((msg) => msg.respondentName);
  const header = filteredAnswers.map((index) => answers[index].text).join(', ');
  const segments = [
    {
      id: team.id,
      header: segmentsWithoutCounts ? header : `${header}: (${values.length}/${totalRespondentsCount})`,
      traversable: traversable && !onlyDirectMembers,
      values,
    },
  ];

  return {
    description: `${getTeamLabel(team, t, onlyDirectMembers)}: ${getTeamName(team.teamName, t)}`,
    totalRespondentsCount,
    type: AnswerType.NEUTRAL,
    segments,
    get count() {
      return sum(this.segments.map((s) => s.values.length));
    },
  };
};

export const prepareReversedTeamGraph = (
  answers: Answer[],
  report: IReport,
  compareLevelFilter: Filter | undefined,
  firstWidget: boolean,
  widget: NonNullable<
    NonNullable<NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']>[0]['widgets']
  >[0],
  filteredAnswers: string[],
  aggregatedData: AggregatedData,
  t: any,
  segmentsWithoutCounts = false,
): GraphData | null => {
  if (widget.questionDefinitionIndex === null) {
    return null;
  }

  const teams = getTeamsForReversedGraph(aggregatedData, report, compareLevelFilter);

  const traversable = firstWidget || isWidgetTraversable(teams, widget);

  const graphData: GraphData = {
    ...getGraphLabel(report, widget, widget.questionDefinitionIndex || '', traversable, t),
    rows: [],
  };

  const reportRespondents = getRespondentsMap(report);

  const teamRows = teams
    .map((team) =>
      prepareRowInfo(
        answers,
        team.team,
        report.reportRoots,
        traversable,
        team.type === 'team' || team.team.teamLeader === null,
        firstWidget,
        reportRespondents,
        widget.questionDefinitionIndex || '',
        filteredAnswers,
        aggregatedData,
        t,
        segmentsWithoutCounts,
      ),
    )
    .filter((row) => row.totalRespondentsCount > 0);

  graphData.rows = sortAndFilterRows(teamRows);

  return graphData;
};
