import { FC, ReactNode, useCallback, useState } from 'react';
import { useComponentDidMount } from '@arnold/common';
import { SideMenuContext } from './SideMenuContext';
import { Sections, VisibleSections } from './types';

type SideMenuContextProviderProps = {
  children: ReactNode;
  sections: Sections;
};
export const SideMenuContextProvider: FC<SideMenuContextProviderProps> = ({ children, sections }) => {
  const [visibleSections, setVisibleSections] = useState<VisibleSections>([]);

  useComponentDidMount(() => {
    setVisibleSections(sections.map(({ id }) => ({ id, visible: false })));
  });

  const handleSectionVisibility = useCallback(
    (sectionId: string, isVisible: boolean) => {
      setVisibleSections((state) => {
        const newVisible = [...state];
        const visibleId = newVisible.findIndex(({ id }) => id === sectionId);

        if (visibleId > -1) {
          newVisible[visibleId].visible = isVisible;
          // all sections are hidden
          if (!newVisible.some(({ visible }) => visible === true)) {
            return state;
          }

          return newVisible;
        }
        return state;
      });
    },
    [setVisibleSections],
  );

  return (
    <SideMenuContext.Provider
      value={{
        visibleSectionId: visibleSections.find(({ visible }) => visible === true)?.id,
        handleSectionVisibility,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};
