import { Mutation } from '@apollo/client/react/components';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Modal } from '@arnold/common';
import { Modals } from '../../chat/ChatWindow';
import { resetPasswordMutation } from '../../graphql/mutations';
import { USER_EMAIL_KEY } from '../../lib/auth';
import { ResetPasswordMutation } from '../../generated/hooks';

interface IProps {
  show: boolean;
  showModal: (modal: Modals, show: boolean) => () => void;
}

interface IFormValues {
  email: string;
  responseError?: string | undefined;
}

const ResetPasswordModal = ({ show, showModal }: IProps) => {
  const { t } = useTranslation('resetPasswordScreen');
  const hide = showModal(Modals.RESET_PASSWORD, false);
  const userEmail = localStorage.getItem(USER_EMAIL_KEY);

  const handleLoginClick = () => {
    showModal(Modals.LOGIN, true)();
  };

  const handleMutationCompleted = (data: { resetPassword: boolean }) => {
    if (data.resetPassword) {
      showModal(Modals.EMAIL_SEND, true)();
    }
  };

  const ResetFormSchema = Yup.object().shape({
    email: Yup.string().email(t('invaildEmail')).required(t('fillEmail')),
  });

  const handleOnSubmit =
    (resetPasswordFunc: (arg: any) => void) =>
    (values: FormikValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
      setSubmitting(true);
      const username = values.email;
      resetPasswordFunc({ variables: { username, origin: 'TALK' } });
    };

  const initValues: IFormValues = {
    email: userEmail || '',
    responseError: undefined,
  };

  return (
    <Mutation<ResetPasswordMutation> mutation={resetPasswordMutation} onCompleted={handleMutationCompleted}>
      {(resetPasswordFunc: (arg: any) => void) => {
        return (
          <Formik
            initialValues={initValues}
            onSubmit={handleOnSubmit(resetPasswordFunc)}
            validationSchema={ResetFormSchema}
          >
            {(formikProps: FormikProps<IFormValues>) => (
              <Form>
                <Modal
                  onHide={hide}
                  show={show}
                  title={t('reset')}
                  text={t('recoverPasswordDescription')}
                  onSubmit={() => handleOnSubmit(resetPasswordFunc)(formikProps.values, { ...formikProps })}
                  buttons={{
                    cancel: { title: t('cancel') },
                    submit: { title: t('recoverPassword') },
                  }}
                  content={
                    <div className="form-group">
                      <label htmlFor="email" className="form-group__label">
                        {t('email')}
                      </label>
                      <Field id="email" name="email" type="email" className="form-control" />
                      {formikProps.errors.email && (
                        <small className="form-text text-danger">{formikProps.errors.email}</small>
                      )}
                    </div>
                  }
                  underline={
                    <p>
                      <span className="mr-3">{t('haveAccount')}</span>{' '}
                      <a href="#" className="font-weight-medium" onClick={handleLoginClick}>
                        {t('login')}
                      </a>
                    </p>
                  }
                />
              </Form>
            )}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default ResetPasswordModal;
