import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { InputConsumer } from '../chat/InputContext';
import { ChipsContainer, Item, Remove } from '../chat/InputForm';
import { organizationRespondent } from '../types';

const Wrap = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PersonItem = styled.a`
  font-size: 14px;
  padding: 4px 0;
  border-radius: 10px;
  cursor: pointer;
  outline: 0;

  &:hover {
    strong {
      text-decoration: underline;
    }
  }
  &:selected {
    strong {
      font-weight: 700;
    }
  }

  strong {
    font-weight: 500;
    color: #04b7ef;
  }

  i {
    font-style: normal;
    font-weight: 400;
    color: #a1a1a1;
  }
`;

const FloatWindow = styled.div`
  position: absolute;
  left: 12px;
  right: 45px;
  max-width: 500px;
  bottom: 8px;
  height: auto;
  max-height: 250px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 16px 24px;
  z-index: 100;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.01);
`;

interface IProps extends WithTranslation {
  items: organizationRespondent[];
  input: string;
  onSelect: (person: organizationRespondent) => void;
  selected: organizationRespondent[];
  handleRemoveRespondent: (respondent: organizationRespondent) => void;
  scrollToBottom: () => void;
  lastKey: string | undefined;
  setLastKey: (key: string | undefined) => void;
  disabled?: boolean;
}

interface IState {
  persons: organizationRespondent[];
  index: number;
}

class PersonAutocomplete extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      persons: [],
      index: 0,
    };
  }

  componentDidUpdate(oldProps: IProps) {
    if (oldProps.lastKey !== this.props.lastKey) {
      const matches = this.props.items.filter(this.respondentFilter);
      if (matches.length > 0) {
        if (oldProps.lastKey === 'ArrowUp') {
          this.setState((oldState) => ({
            ...oldState,
            index: (oldState.index - 1 + matches.length) % matches.length,
          }));
        }
        if (oldProps.lastKey === 'ArrowDown') {
          this.setState((oldState) => ({
            ...oldState,
            index: (oldState.index + 1) % matches.length,
          }));
        }
        if (oldProps.lastKey === 'Enter' || oldProps.lastKey === 'Tab') {
          if (matches.length > this.state.index) {
            this.onSelect(matches[this.state.index])();
          }
        }
      }
      this.props.setLastKey(undefined);
    }
  }

  onSelect = (respondent: organizationRespondent) => () => {
    this.props.onSelect(respondent);
    this.props.scrollToBottom();
    this.setState({ index: 0 });
  };

  handleRemoveRespondent = (respondent: organizationRespondent) => () => {
    this.props.handleRemoveRespondent(respondent);
  };

  renderPersons = () => {
    const { selected, disabled } = this.props;
    return (
      <ChipsContainer style={{ alignSelf: 'flex-start' }}>
        {selected.map((item) => (
          <Item
            key={item.id}
            onClick={this.handleRemoveRespondent(item)}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          >
            {item.firstname + ' ' + item.surname + ' '}(
            {item.contacts!.filter((contact) => contact.type === 'EMAIL').map((c) => c.value.toLowerCase())})
            <Remove>&times;</Remove>
          </Item>
        ))}
      </ChipsContainer>
    );
  };

  respondentFilter = (respondent: organizationRespondent) =>
    this.props.input &&
    this.props.input.length !== 0 &&
    ((respondent.firstname!.toLowerCase() + ' ' + respondent.surname!.toLowerCase()).indexOf(
      this.props.input.toLowerCase(),
    ) === 0 ||
      (respondent.surname!.toLowerCase() + ' ' + respondent.firstname!.toLowerCase()).indexOf(
        this.props.input.toLowerCase(),
      ) === 0 ||
      respondent
        .contacts!.filter((contact) => contact.type === 'EMAIL')
        .map((c) => c.value.toLowerCase())[0]
        .indexOf(this.props.input.toLowerCase()) === 0) &&
    this.props.selected.filter((selected) => selected.id === respondent.id).length === 0;

  render() {
    const matches = this.props.items.filter(this.respondentFilter);
    return (
      <Wrap>
        {this.props.selected && this.renderPersons()}
        {matches.length > 0 && (
          <FloatWindow data-cy="chat-input-options">
            {matches.map((respondent, index) => (
              <PersonItem
                key={respondent.id}
                tabIndex={index + 1}
                style={{
                  fontWeight: this.state.index === index ? 'bold' : 'normal',
                }}
                onClick={this.onSelect(respondent)}
              >
                <strong>
                  {respondent.firstname} {respondent.surname}
                </strong>{' '}
                <i>
                  (
                  {respondent.contacts!.filter((contact) => contact.type === 'EMAIL').map((c) => c.value.toLowerCase())}
                  )
                </i>
              </PersonItem>
            ))}
          </FloatWindow>
        )}
      </Wrap>
    );
  }
}

const GetPropsFromProvider = ({
  scrollToBottom,
  items,
  t,
  tReady,
  i18n,
  disabled,
}: Omit<IProps, 'input' | 'onSelect' | 'selected' | 'handleRemoveRespondent' | 'lastKey' | 'setLastKey'>) => (
  <InputConsumer>
    {(context) => (
      <PersonAutocomplete
        scrollToBottom={scrollToBottom}
        items={items}
        t={t}
        tReady={tReady}
        disabled={disabled}
        i18n={i18n}
        input={context.state.message}
        onSelect={context.handleSelectPerson}
        selected={context.state.selectedPersons}
        handleRemoveRespondent={context.handleRemoveRespondent}
        lastKey={context.state.lastKey}
        setLastKey={context.setLastKey}
      />
    )}
  </InputConsumer>
);

export default withTranslation('personMultiselect')(GetPropsFromProvider);
