import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loading } from '../components';
import { ReportOpenMetricsView, UserOrgRole } from '../generated/hooks';
import {
  ANONYMOUS_RESPONDENT_ID,
  getAlertsData,
  getPrintableReportDate,
  getRootTeams,
  getTopicGroupTranslation,
} from '../lib/reportHelper';
import { IReport } from '../lib/reports/base';
import AnonymousReport from './components/AnonymousReport';
import Conversation from './components/Conversation';
import { ReportOpenMetricsSender } from './components/ObserverEventSender';
import ReportContainer from './components/ReportContainer';
import { ReportMetrics } from './components/ReportMetrics';

interface IProps {
  report: IReport;
  publicAccess?: boolean;
  accessToken: string;
}

const Alerts = ({ report, publicAccess, accessToken }: IProps) => {
  const { t } = useTranslation('Report');
  const { token, respondentId } = useParams<{ token: string; respondentId: string }>();
  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;
  const reportContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const selectedRespondentId = publicAccess
    ? CryptoAES.decrypt(decodeURIComponent(respondentId), report.id.toString()).toString(CryptoENC)
    : respondentId;
  const alerts = getAlertsData(report, t, true).filter((alert) => alert.messages.length);
  const respondentDetail = alerts.find((alert) => alert.respondentId === selectedRespondentId);

  const accessReportSequencePath = publicAccess ? 'process-results' : 'report-sequence';
  const accessReportPath = publicAccess ? 'results' : 'report';
  const channelId = report.responses?.find((r) => r.respondentId === selectedRespondentId)?.id;

  if (!report.responses) {
    return <Loading />;
  }
  return (
    <ReportContainer
      ref={reportContainerRef}
      date={getPrintableReportDate(report, t)}
      rootTeams={getRootTeams(report)!}
      title={respondentDetail ? respondentDetail.name : t('unknownRespondent')}
      about={respondentId === ANONYMOUS_RESPONDENT_ID ? t('anonymousAnswersDescription') : null}
      breadcrumb={
        report.surveyGroup
          ? [
              {
                title: getTopicGroupTranslation(report.surveyGroup.topicGroup, report.metadata?.language).value,
                link: `/${accessReportSequencePath}/${token}${window.location.search}`,
              },
              {
                title: report.reportDefinition?.titleLocal || '',
                link: `/${accessReportPath}/${token}${window.location.search}`,
              },
              { title: t('header:alerts') },
            ]
          : !publicAccess ||
              report.metadata?.accepted ||
              !report.metadata?.respondent ||
              report.metadata?.respondent?.organizationRole === UserOrgRole.OrgAdmin
            ? [
                {
                  title: t('backToReport'),
                  link: `/${accessReportPath}/${token}${window.location.search}`,
                },
              ]
            : []
      }
      organizationName={report.metadata?.organization.name || ''}
    >
      {selectedRespondentId === ANONYMOUS_RESPONDENT_ID ? (
        <AnonymousReport report={report} accessToken={token} topicGroupId={undefined} publicAccess={publicAccess} />
      ) : (
        <section className={'section'}>
          {publicAccess && <ReportMetrics reportAccessKey={token} trackTime />}
          <div className={'container container--md'}>
            {respondentDetail && (
              <div
                ref={respondentId === selectedRespondentId ? scrollRef : undefined}
                id={respondentId}
                key={respondentId}
                className={'my-8'}
              >
                <Conversation
                  report={report}
                  messages={respondentDetail.messages}
                  team={respondentDetail.team}
                  name={report.reportDefinition?.titleLocal!}
                  endsAt={respondentDetail.date}
                />
                <ReportOpenMetricsSender
                  orgAdmin={!publicAccess}
                  reportAccessKey={accessToken}
                  channelIds={channelId ? [channelId] : []}
                  reportView={ReportOpenMetricsView.RespondentInPhase}
                />
              </div>
            )}
          </div>
        </section>
      )}
    </ReportContainer>
  );
};

export default Alerts;
