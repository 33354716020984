/* eslint-disable no-restricted-globals */
import { TFunction } from 'react-i18next';
import { toPng } from 'html-to-image';
import pptxgen from 'pptxgenjs';
import i18n from '../../translations/i18n';
import { wait } from '../../utils';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import { Filter, getTeamsLabel } from '../reportHelper';
import { IReport } from './base';

/*
  INFO FOR EVERYONE WHO WILL ADD NEW FEATURES OR SLIDES INTO THIS PRESENTATION:

  - you can easily open any example presentation from the ticket and click on any element with right mouse button and select size and position.
    This will open a window with exact position and size of the element. You can use this to position for the elements in this library.
  
  - keep in mind that this library works with inches, so you need to convert everything from cm to inches. We have a helper function for that called cmToInch.

  - any example presentation from ticket must have the wide layout (called "Widescreen" in PowerPoint). Otherwise, the elements will not be positioned correctly.
*/

type questionData = {
  section: string;
  questionText: string;
  questionDescription: string;
  imageData: { image: string; width: number; height: number };
}[];

/**
 * This function gathers all the data for the presentation and calls the generatePowerpoint function
 */
export const exportPowerpoint = async (
  globalFilters: Filter[],
  report: IReport,
  rootTeams: ReportSubscriptionSubscription['getReport']['teams'],
  sections: NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections'],
  addedOnFrom: string | undefined | null,
  addedOnTo: string | undefined | null,
  t: TFunction,
) => {
  await hideUnwantedElements();
  const companyName = report.metadata?.organization.name || '';
  const surveyName = report.reportDefinition?.titleLocal || '';
  const surveyRangeStartDate = new Date(report.metadata?.surveyStart || 0);
  const surveyRangeEndDate = report.metadata?.surveyEnd ? new Date(report.metadata.surveyEnd) : null;
  const surveyEnded = surveyRangeEndDate! < new Date();

  // get teams for the first slide
  const teams = getTeamsLabel(rootTeams!, t);

  // get filters for the first slide
  const filters =
    globalFilters.length === 0
      ? ''
      : globalFilters
          .map((filter) => getFilterText(filter, report, teams, t))
          .filter((s) => s)
          .join('; ');

  const questions = await getDataForMiddleSlides(sections, t);

  const summary = document.getElementById('report-conclusion');
  const summaryImageData = await htmlToBase64Image(summary as HTMLElement);

  await generatePowerpoint(
    surveyName,
    surveyEnded,
    surveyRangeStartDate,
    surveyRangeEndDate,
    questions,
    companyName,
    teams,
    filters,
    t,
    summaryImageData,
  );
};

const PRESENTATION_BLUE = '#0177CC';

const generatePowerpoint = async (
  surveyName: string,
  surveyEnded: boolean,
  surveyRangeStartDate: Date,
  surveyRangeEndDate: Date | null,
  questions: questionData,
  companyName: string,
  teams: string,
  filters: string,
  t: TFunction,
  summaryImageData: {
    image: string;
    width: number;
    height: number;
  },
) => {
  const surveyDateRange = `${
    surveyRangeEndDate ? '' : `${t('powerpointDateFrom')} `
  }${surveyRangeStartDate.toLocaleDateString(i18n.language)}${
    surveyRangeEndDate ? ` - ${surveyRangeEndDate.toLocaleDateString(i18n.language)}` : ''
  }`;

  const surveyDateRangeLong = `${
    surveyRangeEndDate ? '' : `${t('powerpointDateFrom')} `
  }${surveyRangeStartDate.toLocaleDateString(i18n.language, { day: 'numeric', month: 'long', year: 'numeric' })}${
    surveyRangeEndDate
      ? ` - ${surveyRangeEndDate.toLocaleDateString(i18n.language, { day: 'numeric', month: 'long', year: 'numeric' })}`
      : ''
  }`;
  const dateGenerationText = `${t('powerpointGeneratedOn')}: ${new Date().toLocaleString(i18n.language)}${
    !surveyEnded ? ` (${t('powerpointSurveyNotEnded')})` : ''
  }`;
  const surveyDetailsBottomText = `${surveyName}: ${surveyDateRange}`;

  const pres = new pptxgen();
  pres.layout = 'LAYOUT_WIDE';

  // First slide
  const firstSlide = pres.addSlide();
  firstSlide.background = { color: PRESENTATION_BLUE };
  addIntroOrOutroText(firstSlide, surveyName);
  addIntroMiddleText(firstSlide, companyName, surveyDateRangeLong, teams, filters, t);
  addLogo(firstSlide, true);
  addBottomRobot(firstSlide);
  addLeftBottomText(firstSlide, dateGenerationText, true);

  // Summary slide
  const summarySlide = pres.addSlide();
  addSummarySectionText(summarySlide, t('intro'));
  addScreenshot(summarySlide, summaryImageData);
  addLogo(summarySlide, false);
  addLeftBottomText(summarySlide, surveyDetailsBottomText, false);

  // AI summary slide
  const summaryDiv = document.querySelector('[data-complete-ai-report-summary="true"]');
  if (summaryDiv) {
    const summaryImageData = await htmlToBase64Image(summaryDiv as HTMLElement);
    const summaryAISlide = pres.addSlide();
    addQuestionSectionText(summaryAISlide, surveyName);
    addAiBadge(summaryAISlide);
    addQuestionText(summaryAISlide, t('powerpointAiSummary'), '', { x: cmToInch(3.53) });
    addScreenshot(summaryAISlide, summaryImageData, -0.5);
    addLogo(summaryAISlide, false);
    addLeftBottomText(summaryAISlide, surveyDetailsBottomText, false);
  }

  // Middle slides with questions
  questions.forEach((question) => {
    const slide = pres.addSlide();
    slide.background = { color: 'FFFFFF' };
    addQuestionSectionText(slide, question.section);
    addQuestionText(slide, question.questionText, question.questionDescription);
    addScreenshot(slide, question.imageData);
    addLogo(slide, false);
    addLeftBottomText(slide, surveyDetailsBottomText, false);
  });

  // Last slide
  const lastSlide = pres.addSlide();
  lastSlide.background = { color: PRESENTATION_BLUE };
  addIntroOrOutroText(lastSlide, t('powerpointLastSlideText'));
  addCenterRobot(lastSlide);

  // Save the Presentation
  pres.writeFile({ fileName: surveyName + '.pptx' });
};

const getDataForMiddleSlides = async (
  sections: NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections'],
  t: TFunction,
) => {
  const questions: questionData = [];
  for (const section of sections || []) {
    const sectionName = section.headingLocal || t('Report:results');
    for (const widget of section.widgets || []) {
      if (widget.type !== 'STACKED_CHART' && widget.type !== 'COMMENT_TABLE') {
        continue;
      }
      const questionIndex = widget.questionDefinitionIndex;
      if (questionIndex == null) {
        throw new Error(`Question index on widget is null`);
      }
      const headerElement = document.querySelector(`[data-text-question-index="${questionIndex}"]`);
      if (headerElement == null) {
        continue; // Some questions are not present in the report - it's ok, we do not want these questions in the presentation
      }
      const questionTextElement = headerElement.querySelector('[data-for-query-selector-question-text]');
      const questionDescriptionElement = headerElement.querySelector('[data-for-query-selector-question-description]');
      if (questionTextElement == null || questionDescriptionElement == null) {
        throw new Error(
          `Question text or description element of chart with question index ${questionIndex} was not found`,
        );
      }
      let elementToScreenshot: Element | null = null;
      let aiKeywords = false;
      let moreThan9Comments = false;
      if (widget.type === 'STACKED_CHART') {
        elementToScreenshot = document.querySelector(
          `[data-chart-question-index="${questionIndex}"] [data-for-query-selector-stacked-chart]`,
        );
      } else if (widget.type === 'COMMENT_TABLE') {
        elementToScreenshot = document.querySelector(
          `[data-comment-box-question-index="${questionIndex}"] [data-for-query-selector-ai-keywords]`,
        );
        if (elementToScreenshot) {
          aiKeywords = true;
        } else {
          // if there are no AI keywords, we screenshot the whole comment table
          elementToScreenshot = document.querySelector(
            `[data-comment-box-question-index="${questionIndex}"] [data-for-query-selector-comments]`,
          );
          moreThan9Comments = !!document.querySelector(
            `[data-comment-box-question-index="${questionIndex}"] [data-cy="report-comments-load-more"]`,
          );
        }
      }
      if (elementToScreenshot == null) {
        continue; // Some charts are not present in the report - it's ok, we do not want these charts in the presentation
      }
      const imageData = await htmlToBase64Image(elementToScreenshot as HTMLElement);
      questions.push({
        section: sectionName,
        questionText: questionTextElement.childNodes[0].textContent || '',
        questionDescription: `${questionDescriptionElement.childNodes[0].textContent || ''} ${
          aiKeywords ? t('powerpointAiKeywordsDisclosure') : ''
        }${moreThan9Comments ? t('powerpointMoreThan9CommentsDisclosure') : ''}`,
        imageData,
      });
      await wait(10); // wait to prevent blocking the main thread and freezing the UI
    }
  }
  return questions;
};

const getFilterText = (filter: Filter, report: IReport, teams: string, t: TFunction) => {
  switch (filter.operator) {
    case 'organization-level':
      return t('powerpointTeamComparisonLevel', { level: filter.values?.[0] || 1 });
    case 'unit-teams':
      if (teams.length > 300) {
        teams = teams.slice(0, 300) + '…';
      }
      return teams;
    case 'response':
      if (!filter.values) {
        return;
      }
      const question = report.questions?.find((q) => q.index === filter.questionDefinitionIndex);
      if (!question || !question.answersLocal) {
        return;
      }
      const questionText = question.text.replaceAll('\n', '').trim();
      const answers = filter.values
        .filter((v) => v != null)
        .map((a) => question.answersLocal![Number(a)])
        .join(', ');
      return `${questionText}: ${answers}`;
    default:
      return;
  }
};

// cm to inch
const cmToInch = (cm: number) => cm / 2.54;

const addLogo = (slide: pptxgen.Slide, white: boolean) => {
  slide.addImage({
    path: `${location.origin}/imagesForPresentations/arnoldLogo${white ? 'White' : 'Black'}.png`,
    x: cmToInch(25.46),
    y: cmToInch(16.94),
    w: cmToInch(6.65),
    h: cmToInch(1.67),
  });
};

const addBottomRobot = (slide: pptxgen.Slide) => {
  slide.addImage({
    path: `${location.origin}/imagesForPresentations/robotBottom.png`,
    x: cmToInch(12.69),
    y: cmToInch(15.15),
    w: cmToInch(8.47),
    h: cmToInch(3.91),
  });
};

const addCenterRobot = (slide: pptxgen.Slide) => {
  slide.addImage({
    path: `${location.origin}/imagesForPresentations/robotCenter.png`,
    x: cmToInch(9.06),
    y: cmToInch(5.64),
    w: cmToInch(15.74),
    h: cmToInch(12.54),
  });
};

const addAiBadge = (slide: pptxgen.Slide) => {
  slide.addImage({
    path: `${location.origin}/imagesForPresentations/aiBadge.png`,
    x: cmToInch(2.58),
    y: cmToInch(3.36),
    w: cmToInch(1),
    h: cmToInch(0.66),
  });
};

const addIntroOrOutroText = (slide: pptxgen.Slide, text: string) => {
  slide.addText(text, {
    x: cmToInch(4.23),
    y: cmToInch(3.12),
    w: cmToInch(25.4),
    h: cmToInch(3.16),
    color: 'FFFFFF',
    fontSize: 49,
    fontFace: 'Roboto Black',
    bold: true,
    align: 'center',
  });
};

const addIntroMiddleText = (
  slide: pptxgen.Slide,
  companyName: string,
  surveyDateRange: string,
  teams: string,
  filters: string,
  t: TFunction,
) => {
  if (teams.length > 300) {
    teams = teams.slice(0, 300) + '…';
  }
  if (filters.length > 600) {
    filters = filters.slice(0, 600) + '…';
  }
  const textNodes: PptxGenJS.default.TextProps[] = [
    {
      text: companyName + '\n' + surveyDateRange + '\n\n',
      options: {
        fontSize: 16,
        bold: true,
        lineSpacingMultiple: 1.5,
      },
    },
    {
      text: teams + '\n\n',
    },
  ];
  if (filters.length > 0) {
    textNodes.push(
      {
        text: t('powerpointUsedFilters') + ':\n',
        options: {
          bold: true,
        },
      },
      {
        text: filters,
      },
    );
  }

  slide.addText(textNodes, {
    x: cmToInch(4.23),
    y: cmToInch(7.06),
    w: cmToInch(25.4),
    h: cmToInch(6.36),
    color: 'FFFFFF',
    fontSize: 12,
    fontFace: 'Roboto',
    bold: false,
    align: 'center',
  });
};

const addLeftBottomText = (slide: pptxgen.Slide, text: string, white: boolean) => {
  slide.addText(text, {
    x: cmToInch(0.96),
    y: cmToInch(17.08),
    w: cmToInch(23.91),
    h: cmToInch(1.15),
    color: white ? 'FFFFFF' : PRESENTATION_BLUE,
    fontSize: 11,
    fontFace: 'Roboto',
    bold: true,
    align: 'left',
  });
};

const addSlideTitle = (slide: pptxgen.Slide, text: string, fontSize: number) => {
  slide.addText(text, {
    x: cmToInch(2.01),
    y: cmToInch(1.01),
    w: cmToInch(29.53),
    h: cmToInch(2.28),
    color: PRESENTATION_BLUE,
    fontSize,
    fontFace: 'Roboto',
    bold: true,
    align: 'left',
  });
};

const addSummarySectionText = (slide: pptxgen.Slide, text: string) => {
  addSlideTitle(slide, text, 32);
};

const addQuestionSectionText = (slide: pptxgen.Slide, text: string) => {
  addSlideTitle(slide, text, 20);
};

const addQuestionText = (
  slide: pptxgen.Slide,
  questionText: string,
  questionDescription: string,
  textOptions?: pptxgen.TextPropsOptions,
) => {
  const textNodes: PptxGenJS.default.TextProps[] = [
    {
      text: questionText.replaceAll('\n', '').trim() + '\n',
      options: {
        fontSize: 14,
        bold: true,
      },
    },
    {
      text: questionDescription.replaceAll('\n', '').trim(),
    },
  ];
  slide.addText(textNodes, {
    x: cmToInch(2.32),
    y: cmToInch(2.86),
    w: cmToInch(29.22),
    h: cmToInch(2.35),
    color: '000000',
    fontSize: 11,
    fontFace: 'Roboto',
    bold: false,
    align: 'left',
    lineSpacingMultiple: 1.5,
    ...textOptions,
  });
};

const addScreenshot = (
  slide: pptxgen.Slide,
  imageData: {
    image: string;
    width: number;
    height: number;
  },
  verticalOffset = 0,
) => {
  const MAX_HEIGHT = 12;
  let chartWidth = 27.38;
  const ratio = imageData.width / imageData.height;
  let height = chartWidth / ratio;
  if (height > MAX_HEIGHT) {
    // proportionally reduce the height and width to match the MAX_HEIGHT
    height = MAX_HEIGHT;
    chartWidth = height * ratio;
  }
  const centerX = (34 - chartWidth) / 2;
  slide.addImage({
    data: imageData.image,
    x: cmToInch(centerX),
    y: cmToInch(8 - height / 4 + verticalOffset),
    w: cmToInch(chartWidth),
    h: cmToInch(height),

    sizing: { type: 'contain', w: null!, h: null! },
  });
};

const htmlToBase64Image = async (element: HTMLElement) => {
  const image = await toPng(element, {
    backgroundColor: 'transparent',
    skipAutoScale: true,
    skipFonts: true,
    pixelRatio: 4,
    filter(domNode) {
      if (domNode.getAttribute?.('data-pptx-id') === 'report-intro-title' && domNode.style) {
        domNode.style.fontFamily = 'sans-serif';
        setTimeout(() => {
          domNode.style.removeProperty('font-family');
        }, 2000);
      }
      if (domNode.hasAttribute?.('data-pptx-hide') && domNode.style) {
        domNode.style.visibility = 'hidden';
        setTimeout(() => {
          domNode.style.visibility = 'initial';
        }, 2000);
      }
      return true;
    },
  });
  return { image, width: element.getBoundingClientRect().width, height: element.getBoundingClientRect().height };
};
const hideUnwantedElements = async () => {
  const elements = document.querySelectorAll<HTMLElement>('[data-pptx-click]');
  for (const element of elements) {
    if (element.getAttribute('data-pptx-click') === 'false') continue;
    element.click();
    await wait(100);
  }
  await wait(500);
};
