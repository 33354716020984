import { BarChart, Tabs, ToggleButton, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Text } from 'recharts';
import { AnonymityLevel, ReportSequenceSubscriptionSubscription } from '../../generated/hooks';
import SequenceComparsion from './SequenceComparsion/SequenceComparsion';

export enum PhaseTabs {
  Responses,
  Anonymous,
  Problems,
  Feelings,
}

export type ReportSequenceData = NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

interface IProps {
  reportSequence: ReportSequenceData;
  accessToken: string;
  teamsFilter: string[] | null;
  getTopicUrl: (topicId: string) => string;
}

export const PhasesSection = ({ reportSequence, getTopicUrl, accessToken, teamsFilter }: IProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [checked, setChecked] = useState(params.get('phasesChecked') === 'true');
  const { t, i18n } = useTranslation(['Report', 'ReportSequence']);
  const activeTabFromUrl = Number(params.get('phasesActiveTab'));
  const [activeTab, setActiveTab] = useState<PhaseTabs>(
    Number.isNaN(activeTabFromUrl) ? PhaseTabs.Responses : activeTabFromUrl,
  );
  const TabTitles = {
    [PhaseTabs.Responses]: t('RESPONSE_RATE'),
    [PhaseTabs.Anonymous]: t('ANONYMITY_RATE'),
    [PhaseTabs.Problems]: t('phasesNeedsAttention'),
    [PhaseTabs.Feelings]: t('phasesFeeling'),
  };
  const TabDescriptions = {
    [PhaseTabs.Responses]: t('phasesChartDescriptionFromAll'),
    [PhaseTabs.Anonymous]: t('phasesChartDescriptionFromAnswered'),
    [PhaseTabs.Problems]: t('phasesChartDescriptionFromAnswered'),
    [PhaseTabs.Feelings]: t('phasesChartDescriptionAverage'),
  };
  const hasAnonymousTopics = reportSequence.surveyGroup?.steps.some((step) =>
    step.processStep.stepTopicGroup.allowedAnonymities.includes(AnonymityLevel.Organization),
  );
  const anyMetricQuestions = reportSequence.surveyGroup?.steps.some((step) => step.hasMetricQuestion);

  useEffect(() => {
    params.set('phasesChecked', checked ? 'true' : 'false');
    params.set('phasesActiveTab', activeTab.toString());
    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, activeTab]);

  const tabs = [PhaseTabs.Responses];
  if (hasAnonymousTopics) tabs.push(PhaseTabs.Anonymous);
  tabs.push(PhaseTabs.Problems);
  if (anyMetricQuestions) tabs.push(PhaseTabs.Feelings);

  const charthWidth =
    reportSequence.surveyGroup!.steps.length > 8 ? reportSequence.surveyGroup!.steps.length * 100 : undefined;

  const barChartData = getBarChartData(reportSequence, i18n.language, activeTab);
  return (
    <>
      <TabsCard data-cy={'phases-tabs'}>
        <Tabs
          items={tabs.map((tab) => ({
            title: TabTitles[tab],
            active: activeTab === tab,
            onClick: () => setActiveTab(tab),
          }))}
        />
      </TabsCard>
      <ChartCard>
        <div className="d-flex justify-content-between">
          <div>
            <ChartTitle>{t(TabTitles[activeTab])}</ChartTitle>
            <ChartDesc>{t(TabDescriptions[activeTab])}</ChartDesc>
          </div>
          <div className="d-flex align-items-center">
            <span>{t('SequenceComparsion:label')}</span>
            <StyledToggle
              value={checked}
              onChange={() => setChecked((prev) => !prev)}
              dataIcom={'comparsion-toggle'}
              dataCy={'comparsion-toggle'}
            />
          </div>
        </div>

        <SequenceComparsion
          accessToken={accessToken}
          visible={checked}
          teamsFilter={teamsFilter}
          getTopicUrl={getTopicUrl}
          activeTab={activeTab}
          sequenceStart={new Date(reportSequence.surveyGroup!.from)}
          sequenceEnd={new Date(reportSequence.surveyGroup!.to)}
          surveyGroup={reportSequence.surveyGroup!}
        />

        {!checked && (
          <BarChartContainer bars={reportSequence.surveyGroup!.steps.length} tab={activeTab}>
            <BarChart
              height={250}
              width={charthWidth}
              xAxisHeight={50}
              barSize={40}
              noBackground
              topLabel
              showGrid={activeTab !== PhaseTabs.Feelings}
              valueFormatter={(value) => {
                if (value === t('SequenceComparsion:notAvailable')) {
                  return value;
                }
                if (activeTab === PhaseTabs.Feelings) {
                  return value.toFixed(1);
                }
                return value + '%';
              }}
              dataRange={activeTab === PhaseTabs.Feelings ? [0, 7] : [0, 100]}
              tooltipContent={(payload) =>
                payload.total === 0
                  ? t('SequenceComparsion:notAvailableDescription')
                  : `${t(TabTitles[activeTab])} ${payload.count.toFixed(activeTab === PhaseTabs.Feelings ? 1 : 0)} ${t(
                      'phasesOutOf',
                    )} ${payload.total}`
              }
              onClick={(data) => history.push(getTopicUrl(data.id))}
              data={barChartData}
              valueAccessor={(data: any) => {
                if (data.total === 0) return t('SequenceComparsion:notAvailable');
                return data.yOriginal;
              }}
              CustomXAxisTick={(props) => {
                const data = barChartData.find((d) => d.x === props.payload.value)!;
                let label = props.payload.value.replace(/(\S)\/(\S)/g, '$1 / $2'); // insert space around / (slash)
                if (label.length > 25) {
                  label = label.substring(0, 24) + '…';
                }
                return (
                  <BarLabel
                    onClick={() => history.push(getTopicUrl(data.id))}
                    {...props}
                    width={78}
                    fill={theme.colors.text.primary}
                    className="recharts-cartesian-axis-tick-value"
                  >
                    {label}
                  </BarLabel>
                );
              }}
            />
          </BarChartContainer>
        )}
      </ChartCard>
    </>
  );
};

const getBarChartData = (reportSequence: ReportSequenceData, userLanguage: string, activeTab: PhaseTabs) => {
  return reportSequence.surveyGroup!.steps.map((step, i) => {
    const stepTitle =
      step.processStep.stepTopicGroup.translations?.find((trans) => userLanguage === trans.language.code)?.value ||
      step.processStep.stepTopicGroup.translations![0].value;
    const stats = step.statistics!;
    let count = 0;
    let total = 0;
    switch (activeTab) {
      case PhaseTabs.Responses:
        total = stats.total;
        count = stats.answered;
        break;
      case PhaseTabs.Anonymous:
        total = stats.answered;
        count = stats.anonymous;
        break;
      case PhaseTabs.Problems:
        total = stats.answered;
        count = stats.withProblems;
        break;
      case PhaseTabs.Feelings:
        if (!stats.avgMetric) {
          total = 0;
          count = 0.1;
        } else {
          total = 7; // max value for metric question is 7
          count = stats.avgMetric;
        }
    }
    const value = activeTab === PhaseTabs.Feelings ? count : calculatePercentage(count, total);
    const barColor = getBarChartFillValue(value, activeTab);
    const darkerColor = darkenHexColor(barColor, 0.2);
    return {
      x: stepTitle,
      y: value || 1,
      yOriginal: value,
      count,
      total,
      fill: barColor,
      hoverFillColor: darkerColor,
      step: i,
      id: step.processStep.stepTopicGroup.id,
    };
  });
};

const getBarChartFillValue = (value: number, activeTab: PhaseTabs) => {
  switch (activeTab) {
    case PhaseTabs.Responses:
      return value < 40 ? theme.colors.emotionDanger.default : theme.colors.brand.primary;
    case PhaseTabs.Anonymous:
      return theme.colors.brand.primary;
    case PhaseTabs.Problems:
      return theme.colors.emotionDanger.default;
    case PhaseTabs.Feelings:
      if (value >= 5 || value === 0.1) {
        return theme.colors.emotionSuccess.backgroundActive;
      }
      if (value < 4) {
        return theme.colors.emotionDanger.backgroundActive;
      }
      return theme.colors.emotionWarning.backgroundActive;
  }
};

export const calculatePercentage = (numerator: number, denominator: number) =>
  denominator === 0 ? 0 : Math.round((numerator / denominator) * 100);

const TabsCard = styled(Card)({
  padding: 0,
});

const ChartCard = styled(Card)({
  marginTop: theme.spacing.g,
});

const ChartTitle = styled.p({
  ...theme.typography.heading.small.default,
  color: theme.colors.text.primary,
  marginTop: theme.spacing.e,
});

const ChartDesc = styled.p({
  ...theme.typography.body.small.regular,
  color: theme.colors.text.secondary,
});

const StyledToggle = styled(ToggleButton)`
  margin-bottom: 0;
  margin-left: ${theme.spacing.f};
`;

const BarChartContainer = styled.div<{ bars: number; tab: PhaseTabs }>`
  padding-top: ${theme.spacing.g};
  overflow-x: auto;
  & > div {
    min-width: ${({ bars }) => Math.min(bars, 8) * 100}px !important;
  }
  & .recharts-surface {
    overflow: visible !important;
  }
  & .recharts-bar-rectangle {
    cursor: pointer;
  }
  & .recharts-yAxis {
    display: ${({ tab }) => (tab === PhaseTabs.Feelings ? 'none' : 'initial')};
  }
`;

export const BarLabel = styled(Text)<{
  onClick?: unknown;
}>`
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
  & > tspan {
    text-anchor: start;
  }
  transform: translateX(-31px);
`;

export function darkenHexColor(hexColor: string, factor: number) {
  hexColor = hexColor.replace(/^#/, '');

  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const darkenedR = Math.round(r * (1 - factor));
  const darkenedG = Math.round(g * (1 - factor));
  const darkenedB = Math.round(b * (1 - factor));

  const darkenedHex = (
    (darkenedR < 16 ? '0' : '') +
    darkenedR.toString(16) +
    (darkenedG < 16 ? '0' : '') +
    darkenedG.toString(16) +
    (darkenedB < 16 ? '0' : '') +
    darkenedB.toString(16)
  ).toUpperCase();

  return `#${darkenedHex}`;
}
