import styled from '@emotion/styled/macro';
import { theme } from '@arnold/common';

interface IStyledProps {
  left?: boolean;
  margin?: boolean;
  isSending?: boolean;
  touchCursor?: boolean;
  runAnimation?: boolean;
}

const View = styled.div`
  position: relative;
  margin: 4px 8px 0 0;
  margin-right: ${(props: IStyledProps) => (props.left ? '8px' : '0')};
  margin-left: ${(props: IStyledProps) => (props.left ? '0' : '8px')};
  padding-top: ${(props: IStyledProps) => (props.margin ? '40px' : props.left ? '0' : '16px')};
  padding-bottom: ${(props: IStyledProps) => (props.left ? '0' : '34px')};
  cursor: ${(props: IStyledProps) => (props.touchCursor ? 'pointer' : 'auto')};
  display: flex;
  justify-content: ${(props: IStyledProps) => (props.left ? 'flex-start' : 'flex-end')};

  @media (min-width: 576px) {
    padding-top: ${(props: IStyledProps) => (props.margin ? '80px' : props.left ? '0' : '32px')};
    padding-bottom: ${(props: IStyledProps) => (props.left ? '0' : '50px')};
  }
`;

const AnonymityName = styled.span`
  min-width: 40px;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FullScreenWindow = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`;

const CenterView = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: 100;
`;

const ErrorMessage = styled.span`
  text-align: center;
  margin: 16px 16px 16px 16px;
  font-size: 18px;
  font-weight: 100;
`;

const ArchivedSection = styled.div`
  margin-top: ${theme.spacing.j};
  margin-bottom: ${theme.spacing.m};
`;

export { View, AnonymityName, FullScreenWindow, CenterView, ErrorMessage, ArchivedSection };
