import { EnvironmentRibbon, ToastProvider, getFrontendConfigValue } from '@arnold/common';
import { find } from 'ramda';
import * as React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import './App.css';
import ErrorBoundary from './ErrorBoundary';
import Admittance from './admittance/Admittance';
import ChatWindow from './chat/ChatWindow';
import { PrivateRouter } from './components';
import CookieConsent from './components/CookieConsent';
import PageLayout from './components/PageLayout';
import auth from './lib/auth';
import { getBrowserLanguages, useSaveSysAdminFromUrl } from './lib/common';
import Logout from './logout/Logout';
import Overview from './overview/Overview';
import Report from './reports/Report';
import ReportSequence from './reports/ReportSequence';
import i18n from './translations/i18n';
import { ApiVersionController } from './ApiVersionController';

const localizations = ['bg', 'cs', 'en', 'hu', 'pl', 'ro', 'sr', 'ru', 'sk', 'es', 'lt', 'fi', 'fr', 'it', 'ua'];

type IState = {
  currentLanguage: string;
  allowedLanguages: string[];
  navbarContent: React.ReactNode | null;
};

class App extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentLanguage: '',
      allowedLanguages: [],
      navbarContent: null,
    };
    this.setLanguage();
  }

  setLanguage() {
    const languages = getBrowserLanguages();

    const lang = find(
      (language: string) => !!(language && localizations.includes(language)),
      languages.map((langCode) => langCode.substring(0, 2)),
    );

    this.setLocalization(lang || 'en');
  }

  Chat = ({ match }: { match: any }) => (
    <PageLayout setLocalization={this.setLocalization}>
      <ChatWindow channelId={match.params.id} />
    </PageLayout>
  );

  SurveyChat = ({ match }: { match: any }) => (
    <PageLayout setLocalization={this.setLocalization}>
      {/* eslint-disable-next-line jsx-a11y/no-access-key */}
      <ChatWindow accessKey={match.params.accessKey} />
    </PageLayout>
  );

  Report = ({ match }: { match: any }) => {
    const location = useLocation();
    useSaveSysAdminFromUrl(match.params.token, location.search);
    return (
      <PageLayout
        setLocalization={this.setLocalization}
        allowedLanguages={this.state.allowedLanguages}
        initLanugage={this.state.currentLanguage}
        navbarContent={this.state.navbarContent}
      >
        <Report
          accessToken={match.params.token}
          selectedLanguage={this.state.currentLanguage}
          changeLanguage={this.setLocalization}
          setAllowedLanguages={this.setAllowedLanguages}
          allowedLanguages={this.state.allowedLanguages}
          setNavbarContent={this.setNavbarContent}
        />
      </PageLayout>
    );
  };

  ReportSequence = ({ match }: { match: any }) => {
    const location = useLocation();
    useSaveSysAdminFromUrl(match.params.token, location.search);
    return (
      <PageLayout
        setLocalization={this.setLocalization}
        allowedLanguages={this.state.allowedLanguages}
        initLanugage={this.state.currentLanguage}
        navbarContent={this.state.navbarContent}
      >
        <ReportSequence
          accessToken={match.params.token}
          selectedLanguage={this.state.currentLanguage}
          changeLanguage={this.setLocalization}
          setAllowedLanguages={this.setAllowedLanguages}
          allowedLanguages={this.state.allowedLanguages}
          setNavbarContent={this.setNavbarContent}
        />
      </PageLayout>
    );
  };

  setLocalization = (lang: string) => {
    this.setState({ currentLanguage: lang });
    i18n.changeLanguage(lang);
  };

  setAllowedLanguages = (languages: string[]) => {
    this.setState({ allowedLanguages: languages });
  };

  setNavbarContent = (content: React.ReactNode) => {
    this.setState({ navbarContent: content });
  };

  withPageLayout = (Component: React.ComponentType<any>) => () => (
    <PageLayout setLocalization={this.setLocalization}>
      <Component />
    </PageLayout>
  );

  render() {
    return (
      <Router>
        <ApiVersionController>
          <EnvironmentRibbon env={getFrontendConfigValue('ENVIRONMENT')} />
          <ToastProvider>
            <ErrorBoundary>
              <Switch>
                <PrivateRouter setLocalization={this.setLocalization} path="/overview" component={Overview} />
                <Route path="/admittance/:lang/:surveyId" component={this.withPageLayout(Admittance)} />
                <Route path="/logout" component={this.withPageLayout(Logout)} />
                <Route path="/report/:token" component={this.Report} />
                <Route path="/report-sequence/:token" component={this.ReportSequence} />
                <Route path="/results/:token" component={this.Report} />
                <Route path="/process-results/:token" component={this.ReportSequence} />
                <Route path="/surveys/:accessKey" component={this.SurveyChat} />
                <Route path="/:id" component={this.Chat} />
                {auth.isLoggedIn() && !window.location.search && <Redirect from={'/'} exact={true} to={'/overview'} />}
                <Route component={this.Chat} />
              </Switch>
            </ErrorBoundary>
          </ToastProvider>
          <CookieConsent />
        </ApiVersionController>
      </Router>
    );
  }
}

export default App;
