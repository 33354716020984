import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import { LinkButton, Modal, theme } from '@arnold/common';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { addMinutes } from 'date-fns';
import { FormControl, FormGroup } from 'react-bootstrap';
import styled from '@emotion/styled';
import { Modals } from '../../chat/ChatWindow';
import auth, { USER_EMAIL_KEY } from '../../lib/auth';
import { generateOtpMutation } from '../../graphql/mutations';

interface IProps extends RouteComponentProps<any> {
  show: boolean;
  showModal: (modal: Modals, show: boolean) => () => void;
}

interface IFormValues {
  email: string;
  password: string;
  otp: string;
  responseError?: string | undefined;
}

const LoginModal = ({ show, showModal, history }: IProps) => {
  const hide = showModal(Modals.LOGIN, false);
  const { t } = useTranslation('loginScreen');
  const [generateOtp] = useMutation(generateOtpMutation);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [otpValidTo, setOtpValidTo] = useState('');
  const [otpResended, setOtpResended] = useState(false);

  const userEmail = localStorage.getItem(USER_EMAIL_KEY);

  const handleResetPasswordClick = () => {
    showModal(Modals.RESET_PASSWORD, true)();
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t('fillEmail')),
    password: Yup.string().required(t('fillPassword')),
    otp: Yup.string(),
  });

  const handleSingIn = async (values: FormikValues, actions: FormikHelpers<IFormValues>) => {
    try {
      actions.setSubmitting(true);
      await auth.login(values.email, values.password, values.otp || undefined);
      localStorage.setItem(USER_EMAIL_KEY, values.email);
      hide();
      history.push('/overview');
    } catch (e: any) {
      actions.setSubmitting(false);
      if ((e as Response).status === 403) {
        let response;
        try {
          response = await (e as Response).json();
        } catch (err) {
          actions.setErrors({ responseError: t('somethingWentWrong') });
          return;
        }
        setShowOtpForm(true);
        setContactEmail(response?.email);
        setOtpValidTo(response.otpValidTo);
        return;
      }
      actions.setErrors({
        responseError:
          e.hasOwnProperty('status') && e.status === 401
            ? showOtpForm
              ? t('invalidCode')
              : t('badCredentials')
            : t('somethingWentWrong'),
      });
    }
    return true;
  };

  const initValues: IFormValues = {
    email: userEmail || '',
    password: '',
    otp: '',
    responseError: undefined,
  };

  const resendClicked = async () => {
    await generateOtp({
      variables: {
        username: contactEmail,
      },
    });
    setOtpResended(true);
    setOtpValidTo(addMinutes(new Date(), 15).toISOString());
    setTimeout(() => setOtpResended(false), 10000);
  };
  return (
    <Formik initialValues={initValues} onSubmit={handleSingIn} validationSchema={LoginSchema}>
      {(formikProps: FormikProps<IFormValues>) => (
        <Form>
          <Modal
            onHide={hide}
            show={show}
            title={showOtpForm ? t('verifyAccount') : t('login')}
            onSubmit={() => handleSingIn(formikProps.values, formikProps)}
            hideAfterSubmit={false}
            buttons={{
              submit: {
                title: showOtpForm ? t('verify') : t('login'),
                disabled: showOtpForm && formikProps.values.otp.length !== 6,
              },
              cancel: { title: t('cancel') },
            }}
            content={
              <>
                <div style={showOtpForm ? { display: 'none' } : {}}>
                  <div className="form-group">
                    <label htmlFor="email" className="form-group__label">
                      {t('email')}
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="form-control"
                      disabled={formikProps.isSubmitting}
                    />
                    {formikProps.errors.email && (
                      <small className="form-text text-danger">{formikProps.errors.email}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className="form-control"
                      disabled={formikProps.isSubmitting}
                      placeholder={t('password')}
                    />
                    {formikProps.errors.password && formikProps.touched.password && (
                      <small className="form-text text-danger">{formikProps.errors.password}</small>
                    )}
                    {formikProps.errors.responseError && (
                      <small className="form-text text-danger">{formikProps.errors.responseError}</small>
                    )}
                  </div>
                </div>
                <div style={showOtpForm ? {} : { display: 'none' }}>
                  <Trans
                    i18nKey="loginScreen:verifyAccountInfo"
                    values={{
                      email: contactEmail,
                      otpValidTo: new Date(otpValidTo).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                      }),
                    }}
                  />
                  <Line />
                  <FormGroup>
                    <StyledLabel>{t('verificationCode')}</StyledLabel>
                    <FormControl
                      style={{ width: '200px' }}
                      type="text"
                      name="otp"
                      value={formikProps.values.otp}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const regex = /^\d+$/;
                        if (!value || regex.test(value.toString())) {
                          formikProps.setFieldValue('otp', value.slice(0, 6));
                        }
                      }}
                      isInvalid={!!formikProps.errors.responseError}
                    />
                    {formikProps.errors.responseError && (
                      <small className="form-text text-danger">{formikProps.errors.responseError}</small>
                    )}
                  </FormGroup>
                </div>
              </>
            }
            underline={
              <>
                {showOtpForm ? (
                  otpResended ? (
                    <span>{t('resendOtpSuccessful')}</span>
                  ) : (
                    <span>
                      {t('resendOtp')} <LinkButton onClick={resendClicked}>{t('resendOtpButton')}</LinkButton>
                    </span>
                  )
                ) : (
                  <>
                    <p>
                      <span className="mr-3">{t('rememberPassword')}</span>
                    </p>
                    <p className="mb-0">
                      <LinkButton onClick={handleResetPasswordClick}>{t('resetPassword')}</LinkButton>
                    </p>
                  </>
                )}
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter<IProps, any>(LoginModal);

const Line = styled.hr`
  margin-top: ${theme.spacing.g};
  margin-bottom: ${theme.spacing.g};
  border-top: ${theme.spacing.a} solid ${theme.colors.borderSeparator.default};
`;

const StyledLabel = styled.label`
  font-size: ${theme.typography.body.small.regular.fontSize};
  color: ${theme.colors.text.secondary};
  margin-left: ${theme.spacing.f};
`;
