import styled from '@emotion/styled/macro';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ArnoldIcon } from '../../assets/images/ArnoldIconLight.svg';
import { vanillaCookieConsent } from '../../components/CookieConsent';

const FooterContainer = styled.div`
  font-size: 12px;
  line-height: 140%;
  background-color: #3f3f3f;
  color: #bdbdbd;
`;

const Footer = () => {
  const { t } = useTranslation('Report');
  const openCookieSettings = () => {
    // @ts-ignore
    vanillaCookieConsent?.showSettings(0);
    return false;
  };
  return (
    <FooterContainer className={'p-10 report-footer'}>
      <div className={'container container--md'} style={{ display: 'flex' }}>
        <ArnoldIcon className={'mr-9'} style={{ flex: '0 0 56px' }} />
        <div style={{ width: 'auto' }}>
          <Trans i18nKey="Report:footerInfo">
            Arnold pomáhá manažerům lépe porozumět lidem ve svém týmu. Dělá to průběžným sbíráním zpětné vazby na různá
            témata. <br />
            <br />
            &copy; LMC s.r.o., member of Alma Media Group
          </Trans>
        </div>
        <div style={{ flex: '0 0 240px' }} className={'ml-9'}>
          <Trans
            i18nKey="Report:cookiesAndTerms"
            components={[
              <a key="0" href="#" onClick={openCookieSettings}>
                cookies
              </a>,
              <a key="1" href={t('productTermsUrl')} target="_blank" rel="noreferrer">
                Podmínky používání služeb
              </a>,
              <a key="2" href={t('privacyPolicyUrl')} target="_blank" rel="noreferrer">
                Zásady ochrany soukromí
              </a>,
            ]}
          >
            Arnold používá{' '}
            <a key="0" href="#" onClick={openCookieSettings}>
              cookies
            </a>
            , viz
            <a href="https://www.arnold-robot.com/product-terms/" target="_blank" rel="noreferrer">
              Podmínky používání služeb
            </a>
            a také chrání vaše osobní údaje, viz
            <a href="https://www.arnold-robot.com/privacy-policy/" target="_blank" rel="noreferrer">
              Zásady ochrany soukromí
            </a>
          </Trans>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
