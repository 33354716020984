import { LinkCard } from '@arnold/common';
import { ReactComponent as Chat } from '@arnold/common/lib/assets/icons/Chat.svg';
import { ReactComponent as Report } from '@arnold/common/lib/assets/icons/Report.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { getLocale } from '@arnold/core';
import i18n from 'i18next';

export enum CardType {
  Channel = 'Channel',
  Report = 'Report',
  ReportSequence = 'ReportSequence',
}
export interface ICard {
  id: string;
  type: CardType;
  name: string;
  from: string;
  to: string;
}

interface IProps {
  card: ICard;
}

const getLink = (id: string, type: CardType) => {
  if (type === CardType.Channel) {
    return `/${id}`;
  }
  if (type === CardType.Report) {
    return `results/${id}`;
  }
  return `process-results/${id}`;
};

export const ChannelCard = ({ card }: IProps) => {
  const { t } = useTranslation('channelCard');
  const isChat = card.type === CardType.Channel;
  return (
    <Link to={getLink(card.id, card.type)}>
      <LinkCard
        headerIcon={isChat ? <Chat /> : <Report />}
        headerText={t(card.type)}
        headline={card.name}
        tagline={`${format(new Date(card.from), 'd. MMMM', { locale: getLocale(i18n.language) })} - ${format(
          new Date(card.to),
          'd. MMMM yyyy',
          { locale: getLocale(i18n.language) },
        )}`}
      />
    </Link>
  );
};
