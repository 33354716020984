import { pushToDataLayer, createEventData, SelectWrapper, selectMenuStyles } from '@arnold/common';
import { contains } from 'ramda';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Filter } from '../../lib/reportHelper';
import { DisabledPlaceholder, PlaceHolder } from '../../components/SelectFilterHelpers';
import auth from '../../lib/auth';
import { AggregatedData } from '../../lib/reports/aggregateData';

interface IProps {
  className?: string;
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
  aggregatedData: AggregatedData | null;
  organizationDepth: number;
}

const CustomSingleValue = styled.div`
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 10px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
`;

const GlobalCompareSelect = ({ className, filters, setFilters, aggregatedData, organizationDepth }: IProps) => {
  const { t } = useTranslation('Report');
  if (!aggregatedData) {
    return (
      <SelectWrapper
        blurInputOnSelect={false}
        menuShouldScrollIntoView={false}
        className={`react-select ${className || ''}`}
        classNamePrefix="react-select"
        isDisabled={true}
        components={{
          IndicatorSeparator: () => null,
          Placeholder: () => <DisabledPlaceholder>{t('compareAnswersInGraphs')}</DisabledPlaceholder>,
        }}
        styles={{
          container: (provided: any, state: any) => ({
            ...provided,
            width: '100% !important',
            margin: '0px',
          }),
        }}
        isSearchable={false}
        isMulti={false}
        closeMenuOnSelect={false}
      />
    );
  }

  const selectOrganizationLevel = (level: number) => {
    const user = auth.getUser();
    if (user) {
      pushToDataLayer({
        userId: user.id,
        orgId: user.orgId,
        event: 'ux.report-filter-compare',
        ...createEventData('report', 'filterCompare', 'report filter compare'),
      });
    }
    const existingFilterIndex = filters.findIndex((filter) => filter.operator === 'organization-level');

    if (existingFilterIndex !== -1) {
      const newFilters = [...filters];
      const values = newFilters[existingFilterIndex].values || [];
      if (contains(level.toString(), values)) {
        return;
      }

      if (!level) {
        newFilters.splice(existingFilterIndex, 1);
      } else {
        newFilters[existingFilterIndex] = {
          ...newFilters[existingFilterIndex],
          values: [level.toString()],
        };
      }

      setFilters(newFilters);
    } else {
      if (!level) {
        return;
      }

      setFilters([
        ...filters,
        {
          operator: 'organization-level',
          values: [level.toString()],
        },
      ]);
    }
  };

  const compareFilterOptions = [
    { value: 0, label: t('compareAnswersInGraphs') },
    ...Array.from(Array(organizationDepth).keys()).map((index) => ({
      value: index + 1,
      label: t('compareAnswersInLevel', {
        postProcess: 'interval',
        count: index + 1,
      }),
    })),
  ];

  const CustomSelectSelectedValue = (props: { getValue: () => { value: number } }) => {
    const selectedValue = props.getValue()[0];
    if (!selectedValue) {
      return null;
    }
    const foundOption = compareFilterOptions.find((option) => option.value === selectedValue.value);
    if (foundOption) {
      return <CustomSingleValue>{foundOption.label}</CustomSingleValue>;
    }
    return null;
  };

  return (
    <SelectWrapper
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      defaultValue={{ value: 0, label: t('compareAnswersInGraphs') }}
      className={`react-select ${className || ''}`}
      components={{
        IndicatorSeparator: () => null,
        SingleValue: CustomSelectSelectedValue,
        Placeholder: () => <PlaceHolder>{t('compareAnswersInGraphs')}</PlaceHolder>,
      }}
      styles={{
        menu: (provided: any, state: any) => ({
          ...provided,
          ...selectMenuStyles,
        }),
        container: (provided: any, state: any) => ({
          ...provided,
          width: '100% !important',
          margin: '0px',
        }),
      }}
      isSearchable={false}
      isMulti={false}
      /* tslint:disable-next-line:jsx-no-lambda */
      onChange={(opt: any) => selectOrganizationLevel(opt.value)}
      options={compareFilterOptions}
      menuPlacement="auto"
      value={compareFilterOptions.find(
        (option) =>
          option.value ===
          Number.parseInt(filters.find((filter) => filter.operator === 'organization-level')?.values?.[0] || '0', 10),
      )}
    />
  );
};

export default GlobalCompareSelect;
