import { cleanArray } from '@arnold/common';
import { toPng } from 'html-to-image';
import { contains, flatten } from 'ramda';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';
import {
  FeEventType,
  ReportSubscriptionSubscription,
  ReportWidgetType,
  useLogFeEventMutation,
} from '../../../generated/hooks';
import {
  Filter,
  WidgetType,
  getAnswersForQuestionWithValue,
  getQuestionDefinitionWithAnswers,
  getQuestionType,
  getRootTeams,
  getRootTeamsForLevel,
  getTeamsById,
  getTeamsOnLevel,
  getTeamsUntilLevel,
  getWidgetDescription,
} from '../../../lib/reportHelper';
import { IReport } from '../../../lib/reports/base';
import { LocalFilterContext } from '../../../lib/reports/localFilterContext';
import { ContentBox } from '../ContentBox';
import { ReportWidget, getWidgetTitle } from '../ReportWidget';
import WidgetFilter from '../WidgetFilter';
import { showSubTeamsCharts, showSubTeamsReversedCharts } from './utils';

type ReportDefinition = NonNullable<NonNullable<ReportSubscriptionSubscription['getReport']>['reportDefinition']>;

interface ReportWidgetGroupProps {
  widgets: NonNullable<NonNullable<ReportDefinition['sections']>[0]['widgets']>;
  section: NonNullable<NonNullable<ReportDefinition['sections']>[0]>;
  sectionReport: IReport;
  originalReport: IReport;
  globalFilters: Filter[] | null;
  addedWidgets: WidgetType[] | null;
  setWidgets: (widgets: WidgetType[]) => void;
  removeWidgets: (widgetIds: string[]) => () => void;
  widgetFilters: string[];
  setWidgetFilters: (newFilters: string[]) => void;
  baseIndex: number;
  maxIndex: number;
  loading?: boolean;
  accessToken: string;
}

export const ReportWidgetGroup: FC<ReportWidgetGroupProps> = ({
  widgets,
  section,
  sectionReport,
  originalReport,
  globalFilters,
  addedWidgets,
  setWidgets,
  removeWidgets,
  widgetFilters,
  setWidgetFilters,
  maxIndex,
  loading,
  baseIndex,
  accessToken,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [highlightedAnswerIndex, setHighlightedAnswerIndex] = useState<string | null>(null);
  const { t } = useTranslation('Report');
  const firstWidget = widgets[0];
  const { question, answers } = getQuestionDefinitionWithAnswers(sectionReport, firstWidget);
  const questionType = question && getQuestionType(question);
  const compareLevelFilter = (globalFilters || []).find((filter) => filter.operator === 'organization-level');

  const [shownTeamsLevel, setShownTeamsLevel] = useState<number>(
    (compareLevelFilter &&
      Number.parseInt((compareLevelFilter && compareLevelFilter.values && compareLevelFilter.values[0]) || '0', 10)) ||
      0,
  );

  const [logFeEvent] = useLogFeEventMutation();

  const handleDownload = async (filteredClassname?: string) => {
    // Timeout is needed to wait for the DOM to update before taking the screenshot
    setTimeout(async () => {
      if (ref?.current === null) {
        return;
      }
      const image = await toPng(ref.current, {
        backgroundColor: 'transparent',
        skipAutoScale: true,
        skipFonts: true,
        filter(domNode) {
          return !(
            domNode.classList?.contains('hide-from-export') &&
            filteredClassname &&
            !domNode.classList.contains(filteredClassname)
          );
        },
      });
      const link = document.createElement('a');
      const reportTitle = originalReport?.reportDefinition?.titleLocal || '';
      const title = `${reportTitle.length > 10 ? `${reportTitle.substring(0, 10)}…` : reportTitle}-${getWidgetTitle(
        sectionReport,
        firstWidget,
      )}`.replace(/[/\\?%*:|"<>\n]/g, '');
      link.download = `${title}.png`;
      link.href = image;
      link.click();
      try {
        logFeEvent({
          variables: {
            eventType: FeEventType.DownloadChart,
            payload: {
              reportAccess: accessToken,
              respondentId: originalReport.metadata?.respondent?.id,
              questionIndex: widgets[0].questionDefinitionIndex
                ? Number.parseInt(widgets[0].questionDefinitionIndex, 10)
                : undefined,
            },
          },
        });
      } catch (err) {
        // Do nothing
      }
    }, 1);
  };

  useEffect(() => {
    let defaultFilters = (question && question.answersLocal && question.answersLocal.map((_, i) => i.toString())) || [];
    if (questionType === 'MULTISELECT' && defaultFilters.length) {
      const filtersWithCounts = defaultFilters.map((index) => ({
        index,
        count: getAnswersForQuestionWithValue(
          sectionReport,
          firstWidget.questionDefinitionIndex || '',
          [index],
          questionType,
        ).length,
      }));
      filtersWithCounts.sort((a, b) => b.count - a.count);
      defaultFilters = [filtersWithCounts[0].index];
    }
    setWidgetFilters(defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionReport.responses]);

  useEffect(
    () =>
      setShownTeamsLevel(
        (compareLevelFilter &&
          Number.parseInt(
            (compareLevelFilter && compareLevelFilter.values && compareLevelFilter.values[0]) || '0',
            10,
          )) ||
          0,
      ),
    [compareLevelFilter],
  );

  if (widgets.length === 0) {
    return null;
  }

  const widgetFilter = (globalFilters || []).find(
    (filter) =>
      filter.operator === 'response' &&
      !!filter.editable &&
      filter.questionDefinitionIndex === firstWidget.questionDefinitionIndex,
  );

  const containsStackedChart = widgets.some((widget) => widget.type === ReportWidgetType.StackedChart);

  const nextLevel =
    sectionReport.reportRoots.length > 1 ? shownTeamsLevel : compareLevelFilter ? shownTeamsLevel : shownTeamsLevel + 1;

  const nextLevelTeams =
    containsStackedChart &&
    ((nextLevel &&
      flatten<string>(getRootTeams(sectionReport)?.map((rt) => getTeamsOnLevel(sectionReport, nextLevel, 0, rt))!)) ||
      sectionReport.reportRoots);

  const teamsUntilShownLevel =
    containsStackedChart &&
    !compareLevelFilter &&
    ((shownTeamsLevel &&
      flatten<string>(
        getRootTeams(sectionReport)?.map((rt) => getTeamsUntilLevel(sectionReport, shownTeamsLevel, 1, rt))!,
      )) ||
      sectionReport.reportRoots);

  const hideSubTeamsCharts = () => {
    removeWidgets(
      (addedWidgets || [])
        .filter((aw) => aw.subSectionId === section.id + widgets[0].questionDefinitionIndex)
        .map((aw) => aw.widget.id) || [],
    )();
    setShownTeamsLevel(
      (compareLevelFilter &&
        Number.parseInt(
          (compareLevelFilter && compareLevelFilter.values && compareLevelFilter.values[0]) || '0',
          10,
        )) ||
        0,
    );
  };

  const handleShowSubTeams = () => {
    const subSectionId = section.id + widgets[0].questionDefinitionIndex;
    const subSectionAddedWidgets = (addedWidgets || []).filter((aw) => aw.subSectionId === subSectionId);
    const subSectionAddedWidgetIds = subSectionAddedWidgets.map((saw) => saw.widget.id);

    const removedWidgets = subSectionAddedWidgets.filter(
      (saw) => !contains(saw.widget.id.substring(widgets[0].id.length), teamsUntilShownLevel || []),
    );

    const rootLevelTeams = getRootTeamsForLevel(sectionReport, nextLevel);

    if (compareLevelFilter) {
      showSubTeamsReversedCharts(
        widgets[0],
        subSectionId,
        setWidgets,
        (shownTeamsLevel + 1).toString() || '1',
        subSectionAddedWidgets.filter((saw) => !saw.widget.id.includes('level')),
      );
    } else {
      showSubTeamsCharts(
        widgets[0],
        subSectionId,
        setWidgets,
        getTeamsById(
          sectionReport.teams!,
          (nextLevelTeams || []).filter((nlt) => !contains(widgets[0].id + nlt + 'root', subSectionAddedWidgetIds)),
        ),
        getTeamsById(
          sectionReport.teams!,
          rootLevelTeams.filter((nlt) => !contains(widgets[0].id + nlt + 'team', subSectionAddedWidgetIds)),
        ),
        removedWidgets,
      );
    }
    setShownTeamsLevel(shownTeamsLevel + 1);
  };

  const displayShowTeamsButton =
    containsStackedChart &&
    ((nextLevelTeams && nextLevelTeams.length) ||
      (addedWidgets && addedWidgets.find((w) => w.subSectionId === section.id + widgets[0].questionDefinitionIndex)));
  const displayHideTeams =
    addedWidgets && addedWidgets.find((w) => w.subSectionId === section.id + widgets[0].questionDefinitionIndex);

  return (
    <div ref={ref}>
      <LocalFilterContext.Provider value={{ highlightedAnswerIndex, setHighlightedAnswerIndex }}>
        {containsStackedChart && compareLevelFilter && (
          <ContentBox
            header={getWidgetTitle(sectionReport, firstWidget)}
            subHeader={getWidgetDescription(sectionReport, sectionReport, firstWidget, section.displayFilters!, t)}
            index={maxIndex - baseIndex + 1}
            disabled={false}
            organizationName={originalReport.metadata?.organization.name}
            titleIcomTag={'report-question-text'}
            handleDownload={!!displayHideTeams ? undefined : handleDownload}
            questionDefinitionIndex={firstWidget.questionDefinitionIndex}
          >
            <WidgetFilter
              filters={widgetFilters}
              setFilters={setWidgetFilters}
              highlightedAnswerIndex={highlightedAnswerIndex}
              globalFilters={cleanArray(widgetFilter?.values)}
              answers={answers}
              hideDots={questionType === 'MULTISELECT'}
            />
          </ContentBox>
        )}
        {widgets &&
          widgets.map((widget, i) => (
            <ReportWidget
              key={widget.id}
              widget={widget}
              section={section}
              sectionReport={sectionReport}
              originalReport={originalReport}
              globalFilters={globalFilters}
              filters={widgetFilters}
              widgetFilter={widgetFilter}
              addedWidgets={addedWidgets}
              setWidgets={setWidgets}
              removeWidgets={removeWidgets}
              hideHeader={containsStackedChart && !!compareLevelFilter}
              i={maxIndex - baseIndex - i}
              firstWidget={i === 0}
              loading={loading}
              handleDownload={handleDownload}
              hideDownload={!!displayHideTeams}
              hasFilter={!!containsStackedChart && !!compareLevelFilter}
            />
          ))}
        {displayShowTeamsButton && (
          <div className={'mt-5 pl-8'}>
            <Button
              disabled={!nextLevelTeams || nextLevelTeams.length === 0 || loading}
              onClick={handleShowSubTeams}
              className={'btn-fill hide-from-export'}
              data-icom={'report-show-next-level-button'}
            >
              {t('showTeams', {
                postProcess: 'interval',
                count: shownTeamsLevel + 1,
              })}
            </Button>
            {displayHideTeams && (
              <Button onClick={hideSubTeamsCharts} className={'ml-6 btn-empty hide-from-export'}>
                {t('hideTeams')}
              </Button>
            )}
          </div>
        )}
      </LocalFilterContext.Provider>
    </div>
  );
};
