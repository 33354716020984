import { gql } from '@apollo/client';

export const QuestionDefinitionFragment = gql`
  fragment QuestionDefinition on QuestionDefinition {
    ... on SelectQuestionDefinition {
      choices
      selectedRange
    }
    ... on PersonMultiselectQuestionDefinition {
      organizationRespondents {
        id
        firstname
        surname
        contacts {
          type
          value
        }
      }
      selectedRange
    }

    ... on SmsContactQuestionDefinition {
      phoneNumber
    }

    ... on EmailContactQuestionDefinition {
      email
    }
  }
`;
