import { createContext } from 'react';

type SideMenuContextValue = {
  visibleSectionId: string | undefined;
  handleSectionVisibility: (id: string, isVisible: boolean) => void;
};
export const SideMenuContext = createContext<SideMenuContextValue>({
  visibleSectionId: undefined,
  handleSectionVisibility: () => {
    console.warn('handleSectionVisibility func has not been already initialized');
  },
});
