import {
  BodyMedium,
  BoxWithImage,
  HourGlass,
  NoMetricsQuestionIcon,
  NoSurveyIcon,
  NotDeliveredIcon,
  PlainButton,
  SendIcon,
  Tooltip,
  isNullish,
  theme,
  useToast,
} from '@arnold/common';
import { ReactComponent as AlertIcon } from '@arnold/common/lib/assets/icons/AlertIcon.svg';
import { ReactComponent as CloseIcon } from '@arnold/common/lib/assets/icons/Cancel.svg';
import { ReactComponent as No } from '@arnold/common/lib/assets/icons/No.svg';
import { ReactComponent as WarningIcon } from '@arnold/common/lib/assets/icons/WarningIcon.svg';
import { ReactComponent as EmptyTable } from '@arnold/common/lib/assets/images/EmptyTable.svg';
import { getEncodedCryptoAES, getLocalizedDateWithoutLeadingZeroes, getWorkingDay } from '@arnold/core';
import styled from '@emotion/styled/macro';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { addDays, addMinutes } from 'date-fns';
import { uniq } from 'ramda';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArnoldLoading } from '../../assets/images/ArnoldLoading.svg';
import { RemoveRespondentModal } from '../../components/modals/RemoveRespondentModal';
import { UpdateRespondent } from '../../components/modals/UpdateRespondent';
import {
  AnonymityLevel,
  ConversationSubjectType,
  Features,
  GetProcessReportRespondentsQuery,
  MessageEventType,
  MessageTemplateUid,
  ReportSequenceRespondentDetailQueryQuery,
  ReportSequenceSubscriptionSubscription,
  SurveyStatus,
  useCreateSurveyMutation,
  useLastMessagesForRespondentLazyQuery,
  useReopenSurveyMutation,
  useSendInvitationAgainMutation,
  useUpdateSurveyMutation,
} from '../../generated/hooks';
import { getTeamNameByRespondentId, getTopicGroupTranslation } from '../../lib/reportHelper';

type ReportSequence =
  | NonNullable<ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail']>
  | NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

type GroupedRespondents = GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0];

interface IProps {
  reportSequence: ReportSequence;
  groupedRespondents: GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'];
  accessToken: string;
  encryptedRespondentId?: string;
  urlParams: string;
  respondentLink?: boolean;
  publicAccess?: boolean;
  loading?: boolean;
  showDeleteButton?: boolean;
  onReload: (shoudReload?: boolean) => void;
  reloadRespondentsData?: () => void;
}

const Container = styled.div`
  padding: ${theme.spacing.g} ${theme.spacing.f};
  display: inline-flex;
  flex-direction: column;
`;

const Row = styled.div`
  color: ${theme.colors.text.primary};
  display: inline-flex;
  padding: 8px;
  font-size: 11px;
  transition: background 0.25s ease;
  border-bottom: 1px solid ${theme.colors.borderSeparator.default};

  &:hover {
    background: ${theme.colors.backgroundCover.hover};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const HeaderRow = styled.div`
  display: inline-flex;
  padding: 8px;
  font-size: 11px;
  color: ${theme.colors.text.secondary};
  background-color: ${theme.colors.backgroundBasic.default};
`;

const Cell = styled.div`
  height: 44px;
  padding: ${theme.spacing.d};

  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: ${theme.spacing.d};
  &:first-child {
    margin-left: 0;
  }
`;

const BaseDateCell = styled(Cell)`
  width: 80px;
  justify-content: flex-start;
`;

const DateCell = styled(BaseDateCell)`
  align-items: center;
`;

const NameSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
`;

const TeamSpan = styled(NameSpan)`
  position: relative;
  top: -4px;
  color: ${theme.colors.text.secondary};
`;

const RespondentCell = styled.div`
  width: 148px;
  height: 44px;
  padding: ${theme.spacing.d};
`;

const BaseAnswerCell = styled(Cell)`
  width: 64px;
  padding: 8px 0px;
  text-align: center;
`;

const AnswerCell = styled(BaseAnswerCell)`
  padding: ${theme.spacing.a};
  align-items: center;
  display: flex;
  flex-direction: column;
  color: ${theme.colors.text.secondary};
`;

const SplitCell = styled(AnswerCell)`
  padding: 0;
  flex-direction: row;
  border-radius: 6px;
  overflow: hidden;
`;
type HalfOfCellProps = { light?: boolean };
const HalfOfCell = styled.div`
  padding: 10px 0;
  display: block;
  background: ${(props: HalfOfCellProps) =>
    props.light ? theme.colors.backgroundCover.default : theme.colors.actionInvertedPrimary.default};
  border: 1px solid ${theme.colors.actionInvertedPrimary.default};
  align-items: center;
  justify-content: center;
  width: 50%;
  & svg {
    width: 18px;
    height: 18px;
  }
  & > div {
    margin-left: 0px;
  }
  &:hover {
    background: ${(props: HalfOfCellProps) =>
      props.light ? theme.colors.backgroundCover.hover : theme.colors.actionInvertedPrimary.hover};
  }
`;

const StyledAlertIcon = styled(AlertIcon)`
  &:hover {
    path {
      fill: ${theme.colors.chart.alert[0]};
    }
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  &:hover {
    path {
      fill: ${theme.colors.chart.warning[0]};
    }
  }
`;

const StyledNoIcon = styled(No)`
  &:hover {
    path {
      fill: ${theme.colors.iconText.disabled};
    }
  }
  flex-shrink: 0;
`;

const StylecCloseIcon = styled(PlainButton)`
  & {
    path {
      fill: ${theme.colors.brand.primary};
    }
  }
  &:disabled {
    path {
      fill: ${theme.colors.iconText.disabled} !important;
    }
  }
`;

interface IColoredAnswerCellProps {
  isPerfect?: boolean;
  isWarning?: boolean;
  isAlert?: boolean;
}

const BaseColorAnswerCell = styled(AnswerCell)`
  padding: ${theme.spacing.d};
  flex-direction: row;
  border-radius: 6px;
  cursor: pointer;
  color: ${theme.colors.text.primary};
  transition: background 0.25s ease;
  background: ${(props: IColoredAnswerCellProps) =>
    props.isAlert
      ? theme.colors.emotionDanger.background
      : props.isPerfect
        ? theme.colors.emotionSuccess.background
        : props.isWarning
          ? theme.colors.emotionWarning.background
          : theme.colors.actionInvertedPrimary.default};

  &:hover {
    background: ${(props: IColoredAnswerCellProps) =>
      props.isAlert
        ? theme.colors.emotionDanger.backgroundHover
        : props.isPerfect
          ? theme.colors.emotionSuccess.backgroundHover
          : props.isWarning
            ? theme.colors.emotionWarning.backgroundHover
            : theme.colors.actionInvertedPrimary.hover};
  }
  &:focus {
    background: ${(props: IColoredAnswerCellProps) =>
      props.isAlert
        ? theme.colors.emotionDanger.backgroundActive
        : props.isPerfect
          ? theme.colors.emotionSuccess.backgroundActive
          : props.isWarning
            ? theme.colors.emotionWarning.backgroundActive
            : theme.colors.actionInvertedPrimary.focused};
  }
  &:active {
    background: ${(props: IColoredAnswerCellProps) =>
      props.isAlert
        ? theme.colors.emotionDanger.backgroundActive
        : props.isPerfect
          ? theme.colors.emotionSuccess.backgroundActive
          : props.isWarning
            ? theme.colors.emotionWarning.backgroundActive
            : theme.colors.actionInvertedPrimary.active};
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: ${theme.spacing.d};
  display: inline;
`;

const tooltipProps: { animation: 'fade'; multiple: boolean } = {
  animation: 'fade',
  multiple: false,
};

type RespondentWithData = {
  respondent: GroupedRespondents['respondents'][0];
  group: GroupedRespondents;
};
type RespondentSurvey = {
  respondentId: string;
  surveyId: string;
};
const RespondentsFeelings = ({
  reportSequence,
  groupedRespondents,
  accessToken,
  encryptedRespondentId,
  urlParams,
  respondentLink,
  publicAccess,
  loading,
  showDeleteButton,
  onReload,
  reloadRespondentsData,
}: IProps) => {
  const [modal, setModal] = useState<boolean>(false);
  const [respondentToDelete, setRespondentToDelete] = useState<
    GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0]['respondents'][0] | null
  >(null);
  const [resendingInvitations, setResendingInvitations] = useState<RespondentSurvey[]>([]);
  const [deliveredInvitations, setDeliveredInvitations] = useState<RespondentSurvey[]>([]);
  const [getLastMessages] = useLastMessagesForRespondentLazyQuery();
  const [dateFrom, setDateFrom] = useState<Date>(new Date());

  const showModal = (
    respondent: GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0]['respondents'][0],
    group: RespondentWithData,
  ) => {
    setModal(true);
    setRespondentToDelete(respondent);
    const answeredSurveys = group.group.surveys.filter(
      (survey) =>
        survey.publicChannels.some((ch) => ch.respondentId === respondent.id && ch.withAnswer) &&
        [SurveyStatus.Completed, SurveyStatus.Ongoing].includes(survey.status),
    );

    const isPrepared = group.group.surveys.every(
      (survey) =>
        [SurveyStatus.Prepared, SurveyStatus.Notstarted].includes(survey.status) || answeredSurveys.length === 0,
    );

    const date = isPrepared
      ? new Date(group.group.addedOn)
      : answeredSurveys.length > 0
        ? addDays(
            new Date(
              answeredSurveys
                .map((survey) => survey.endsAt)
                .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0],
            ),
            1,
          )
        : new Date();
    setDateFrom(date);
  };

  const respondentId =
    publicAccess && encryptedRespondentId
      ? CryptoAES.decrypt(decodeURIComponent(encryptedRespondentId), reportSequence.id.toString()).toString(CryptoENC)
      : encryptedRespondentId;
  const { t, i18n } = useTranslation('Report');
  const history = useHistory();
  const respondents =
    groupedRespondents
      ?.reduce(
        (acc: RespondentWithData[], group: GroupedRespondents) => [
          ...acc,
          ...group.respondents.map((respondent) => ({ respondent, group })),
        ],
        [] as RespondentWithData[],
      )
      .filter((r) => !respondentId || r.respondent.id === respondentId) ?? [];

  const accessPath = publicAccess ? 'process-results' : 'report-sequence';
  const containsAnonymity = reportSequence.surveyGroup?.steps.some((step) =>
    step.processStep.stepTopicGroup.allowedAnonymities.includes(AnonymityLevel.Organization),
  );

  const { addToast } = useToast();
  const [callReopenMutation] = useReopenSurveyMutation();
  const [createSurvey] = useCreateSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();
  const [sendAgain] = useSendInvitationAgainMutation();
  const [clicked, setClicked] = useState(false);
  const [editRespondent, setEditRespondent] = useState<{
    respondent: GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0]['respondents'][0];
    contact: string;
  } | null>(null);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (resendingInvitations.length === 0) return;
      getLastMessages({
        variables: {
          surveyIds: resendingInvitations.map((x) => x.surveyId),
          respondentIds: resendingInvitations.map((x) => x.respondentId),
          accessToken,
        },
        onCompleted: (data) => {
          if (
            data.lastMessagesForRespondent.filter(
              (message) =>
                message.error ||
                [
                  MessageEventType.Bounced,
                  MessageEventType.Spam,
                  MessageEventType.Unsubscribed,
                  'undelivered',
                ].includes(message.messageStatus!),
            ).length > 2
          ) {
            addToast(t('contactSupport'));
          }
          setResendingInvitations((prev) =>
            prev.filter(
              (x) =>
                !data.lastMessagesForRespondent.some(
                  (y) => y.surveyId === x.surveyId && y.respondentId === x.respondentId && (y.error || y.messageStatus),
                ),
            ),
          );
          setDeliveredInvitations((prev) =>
            uniq([
              ...prev,
              ...data.lastMessagesForRespondent
                .filter((x) => x.messageStatus?.toUpperCase() === MessageEventType.Delivered)
                .map(({ surveyId, respondentId }) => ({ surveyId, respondentId })),
            ]),
          );
        },
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendingInvitations, accessToken]);

  return (
    <>
      {editRespondent && (
        <UpdateRespondent
          show
          respondent={editRespondent.respondent}
          contact={editRespondent.contact}
          organizationId={reportSequence.metadata.organization.id}
          cancel={() => {
            setEditRespondent(null);
          }}
          onComplete={() => {
            setEditRespondent(null);
            if (reloadRespondentsData) {
              reloadRespondentsData();
            } else {
              onReload(false);
            }
          }}
          accessToken={accessToken}
          defaultContryCallingCode={reportSequence.metadata.organization.config?.countryCallingCode}
        />
      )}
      <Container className="bg-white rounded-lg shadow-sm" data-cy="report-onb-respondents-table">
        <HeaderRow>
          <BaseDateCell>{t('addedOn')}</BaseDateCell>
          <RespondentCell>
            {t('respondent')} <br /> {t('team')}
          </RespondentCell>
          {reportSequence.surveyGroup?.steps.map((step) => {
            const topicGroupName = getTopicGroupTranslation(
              step!.processStep.stepTopicGroup,
              reportSequence.metadata.language,
              '',
            ).value;

            const shortTopicGroupName =
              topicGroupName.length > 18 ? `${topicGroupName.substring(0, 17).trim()}…` : topicGroupName;

            return (
              <StyledTooltip {...tooltipProps} title={topicGroupName}>
                <BaseAnswerCell>{shortTopicGroupName}</BaseAnswerCell>
              </StyledTooltip>
            );
          })}
        </HeaderRow>
        {loading && <BoxWithImage className="d-flex justify-content-center" image={<ArnoldLoading />}></BoxWithImage>}
        {!loading && respondents.length === 0 && (
          <BoxWithImage image={<EmptyTable />}>
            <BodyMedium>{t('table:emptyTableLabel')}</BodyMedium>
          </BoxWithImage>
        )}
        {respondents.map((respondent, i) => {
          const teamName = getTeamNameByRespondentId(
            reportSequence as NonNullable<ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail']>,
            respondent.respondent.id,
            t,
          );
          const respId = publicAccess
            ? encryptedRespondentId
              ? encryptedRespondentId
              : publicAccess && !encryptedRespondentId
                ? getEncodedCryptoAES(respondent.respondent.id, reportSequence.id)
                : respondent.respondent.id
            : respondent.respondent.id;

          const metricAnswers = respondent.group.metricQuestionsForRespondent?.find(
            (m) => m.respondentId === respondent.respondent.id,
          );

          const sortedSurveys = respondent.group.surveys
            .slice()
            .sort((a, b) => new Date(b.endsAt).getTime() - new Date(a.endsAt).getTime());
          const isDeleteDisabled =
            sortedSurveys.length === 0
              ? true
              : !!sortedSurveys[0].publicChannels.some(
                  (ch) => ch.respondentId === respondent.respondent.id && ch.withAnswer,
                );

          return (
            <Row data-cy="report-onb-respondents-row">
              <DateCell>
                {respondent.group.addedOn
                  ? getLocalizedDateWithoutLeadingZeroes(new Date(respondent.group.addedOn), i18n.language)
                  : t('deleted')}
              </DateCell>
              <RespondentCell data-icom={i === 0 ? 'process-report-feelings-respondent' : undefined}>
                {respondentLink ? (
                  <Link to={`/${accessPath}/${accessToken}/respondent/${respId}${urlParams}`}>
                    <NameSpan>
                      {respondent.respondent.surname} {respondent.respondent.firstname}
                    </NameSpan>
                  </Link>
                ) : (
                  <NameSpan>
                    {respondent.respondent.surname} {respondent.respondent.firstname}
                  </NameSpan>
                )}
                <br />
                <TeamSpan>
                  {t('team')} {teamName.teamName}
                </TeamSpan>
              </RespondentCell>
              {reportSequence.surveyGroup?.steps.map((step) => {
                const survey = respondent.group.surveys.find(
                  (s) => s.topicGroup?.id === step.processStep.stepTopicGroup.id,
                );
                const channel = survey?.publicChannels?.find((ch) => ch.respondentId === respondent.respondent.id);
                const undelivered = respondent.group.undeliveredInvitations
                  ?.find((messages) => messages?.respondentId === respondent.respondent.id)
                  ?.undeliveredSurveyInvitations?.find(
                    (inv) =>
                      inv.surveyId === survey?.id &&
                      !deliveredInvitations.some(
                        (dInv) => dInv.respondentId === respondent.respondent.id && survey?.id === dInv.surveyId,
                      ),
                  );
                const isResending = resendingInvitations.some(
                  (dInv) => dInv.respondentId === respondent.respondent.id && survey?.id === dInv.surveyId,
                );
                const disabled =
                  channel?.lastRemindedAt && !undelivered
                    ? addDays(new Date(channel?.lastRemindedAt), 1).getTime() >= new Date().getTime()
                    : false;
                const maxWeight = channel?.maxAnonymousHighlightedLevel ?? 0;

                const metricAnswer = metricAnswers?.answers.find(
                  (a) => a.topicGroupId === step.processStep.stepTopicGroup.id,
                );
                const detailUrl = `/${accessPath}/${accessToken}/respondent/${respId}?selected_tg=${step.processStep.stepTopicGroup.id}${urlParams}`;

                const sendAgainSurvey = async () => {
                  if (!survey) {
                    return;
                  }
                  setClicked(true);
                  const toastMessage = t('resendToast') + new Date(survey.endsAt).toLocaleDateString() + '.';
                  sendAgain({
                    refetchQueries: ['getProcessReportRespondents', 'ReportSequenceRespondentDetailQuery'],
                    awaitRefetchQueries: true,
                    variables: {
                      surveyId: survey.id,
                      accessToken: reportSequence.identifier,
                      respondentId: respondent.respondent.id,
                      templateUid: reportSequence.metadata.respondent ? MessageTemplateUid.ReminderFromManager : null,
                    },
                    onCompleted: () => {
                      setClicked(false);
                      addToast(toastMessage, undefined, undefined, undefined, true);
                      setResendingInvitations((prev) => [
                        ...prev,
                        { respondentId: respondent.respondent.id, surveyId: survey.id },
                      ]);
                    },
                    onError: (e) => {
                      setClicked(false);
                    },
                  });
                };

                const reopenSurvey = async () => {
                  if (!survey) {
                    return;
                  }
                  setClicked(true);
                  const endsAt = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
                  endsAt.setHours(new Date(survey?.endsAt).getHours());
                  endsAt.setMinutes(0);
                  endsAt.setSeconds(0);
                  endsAt.setMilliseconds(0);
                  const toastMessage = t('resendToast') + new Date(endsAt).toLocaleDateString() + '.';
                  callReopenMutation({
                    refetchQueries: ['getProcessReportRespondents', 'ReportSequenceRespondentDetailQuery'],
                    awaitRefetchQueries: true,
                    variables: {
                      input: {
                        surveyId: survey.id,
                        accessToken: reportSequence.identifier,
                        endsAt,
                        sendMessage: true,
                        respondentIds: [respondent.respondent.id],
                        messageTemplateUid: reportSequence.metadata.respondent
                          ? MessageTemplateUid.ReminderFromManager
                          : null,
                      },
                    },
                    onCompleted: () => {
                      setClicked(false);
                      addToast(toastMessage, undefined, undefined, undefined, true);
                      setResendingInvitations((prev) => [
                        ...prev,
                        { respondentId: respondent.respondent.id, surveyId: survey.id },
                      ]);
                    },
                    onError: (e) => {
                      setClicked(false);
                    },
                  });
                };
                const createMissingSurvey = async () => {
                  setClicked(true);
                  const processStep = step.processStep;
                  if (isNullish(processStep.startOffsetMins) || isNullish(processStep.endOffsetMins)) {
                    return null;
                  }
                  const addedOn = new Date(respondent.group.addedOn);
                  let sendAt = addMinutes(addedOn, processStep.startOffsetMins);
                  if (addedOn.getTimezoneOffset() !== sendAt.getTimezoneOffset()) {
                    sendAt = addMinutes(sendAt, sendAt.getTimezoneOffset() - addedOn.getTimezoneOffset());
                  }
                  // Set date of sendAt to today if it is in the past
                  const isInPast = sendAt < new Date();
                  if (isInPast) {
                    sendAt.setFullYear(new Date().getFullYear());
                    sendAt.setMonth(new Date().getMonth());
                    sendAt.setDate(new Date().getDate());
                  }
                  const reminders: { reminderAt: Date }[] = [];
                  let remindersShift = 0;

                  processStep.reminders.forEach((reminder) => {
                    const { workingDay: reminderDate, shiftInDays: reminderShift } = getWorkingDay(
                      addMinutes(addDays(sendAt, remindersShift), reminder.reminderOffsetMins ?? 0),
                    );
                    if (reminder.reminderOffsetMins) {
                      reminders.push({
                        reminderAt: reminderDate,
                      });
                      remindersShift = remindersShift + reminderShift;
                    }
                  });

                  const endsAt = getWorkingDay(
                    addMinutes(addDays(sendAt, remindersShift), processStep.endOffsetMins),
                  ).workingDay;

                  if (isInPast) {
                    sendAt = new Date();
                  }
                  const toastMessage = t('resendToast') + new Date(endsAt).toLocaleDateString() + '.';

                  createSurvey({
                    refetchQueries: ['getProcessReportRespondents', 'ReportSequenceRespondentDetailQuery'],
                    awaitRefetchQueries: true,
                    variables: {
                      input: {
                        sendAt,
                        endsAt,
                        reminders,
                        accessToken: reportSequence.identifier,
                        surveyGroupId: reportSequence.surveyGroup?.id!,
                        conversationSubjectId: processStep.stepTopicGroup.id,
                        conversationSubjectType: ConversationSubjectType.TopicGroup,
                        welcomeTemplateUid: reportSequence.metadata.respondent
                          ? MessageTemplateUid.ReminderFromManager
                          : null,
                        forceActivation: true,
                        organizationId: reportSequence.metadata.organization.id,
                        seqDate: addedOn,
                        respondentIds: [respondent.respondent.id],
                      },
                    },
                    onCompleted: () => {
                      setClicked(false);
                      addToast(toastMessage, undefined, undefined, undefined, true);
                      if (reloadRespondentsData) {
                        reloadRespondentsData();
                      }
                    },
                    onError: (e) => {
                      setClicked(false);
                    },
                  });
                };

                const updateNotStartedSurvey = async () => {
                  setClicked(true);
                  const processStep = step.processStep;
                  if (isNullish(processStep.startOffsetMins) || isNullish(processStep.endOffsetMins)) {
                    return null;
                  }
                  const addedOn = new Date(respondent.group.addedOn);
                  let sendAt = addMinutes(addedOn, processStep.startOffsetMins);
                  if (addedOn.getTimezoneOffset() !== sendAt.getTimezoneOffset()) {
                    sendAt = addMinutes(sendAt, sendAt.getTimezoneOffset() - addedOn.getTimezoneOffset());
                  }
                  // Set date of sendAt to today if it is in the past
                  const isInPast = sendAt < new Date();
                  if (isInPast) {
                    sendAt.setFullYear(new Date().getFullYear());
                    sendAt.setMonth(new Date().getMonth());
                    sendAt.setDate(new Date().getDate());
                  }

                  const reminders: { reminderAt: Date }[] = [];
                  let remindersShift = 0;

                  processStep.reminders.forEach((reminder) => {
                    const { workingDay: reminderDate, shiftInDays: reminderShift } = getWorkingDay(
                      addMinutes(addDays(sendAt, remindersShift), reminder.reminderOffsetMins ?? 0),
                    );
                    if (reminder.reminderOffsetMins) {
                      reminders.push({
                        reminderAt: reminderDate,
                      });
                      remindersShift = remindersShift + reminderShift;
                    }
                  });
                  const endsAt = getWorkingDay(
                    addMinutes(addDays(sendAt, remindersShift), processStep.endOffsetMins),
                  ).workingDay;

                  if (isInPast) {
                    sendAt = new Date();
                  }
                  const toastMessage = t('resendToast') + new Date(endsAt).toLocaleDateString() + '.';

                  updateSurvey({
                    refetchQueries: ['getProcessReportRespondents', 'ReportSequenceRespondentDetailQuery'],
                    awaitRefetchQueries: true,
                    variables: {
                      surveyId: survey?.id!,
                      input: {
                        sendAt,
                        endsAt,
                        reminders,
                        accessToken: reportSequence.identifier,
                        welcomeTemplateUid: reportSequence.metadata.respondent
                          ? MessageTemplateUid.ReminderFromManager
                          : null,
                        forceActivation: true,
                        respondentIds: [respondent.respondent.id],
                      },
                    },
                    onCompleted: () => {
                      setClicked(false);
                      addToast(toastMessage, undefined, undefined, undefined, true);
                      if (reloadRespondentsData) {
                        reloadRespondentsData();
                      }
                    },
                    onError: (e) => {
                      setClicked(false);
                    },
                  });
                };

                if (metricAnswer?.value !== null && metricAnswer?.value !== undefined) {
                  const result = metricAnswer.value;
                  return (
                    <StyledTooltip {...tooltipProps} title={t('feelingQuestion')}>
                      <BaseColorAnswerCell
                        data-cy="report-respondents-cell"
                        role="button"
                        tabIndex={0}
                        isPerfect={result > 5}
                        isWarning={result <= 5 && result >= 4}
                        isAlert={result < 4}
                        onClick={() => {
                          history.push(detailUrl);
                        }}
                      >
                        {result}
                        {maxWeight === 1 && (
                          <StyledTooltip {...tooltipProps} title={t('alertsTooltip')}>
                            <StyledWarningIcon data-cy="report-respondents-cell-warning" />
                          </StyledTooltip>
                        )}
                        {maxWeight > 1 && (
                          <StyledTooltip {...tooltipProps} title={t('alertsTooltip')}>
                            {' '}
                            <StyledAlertIcon data-cy="report-respondents-cell-alert" />
                          </StyledTooltip>
                        )}
                      </BaseColorAnswerCell>
                    </StyledTooltip>
                  );
                }

                if (undelivered) {
                  if (
                    !containsAnonymity &&
                    reportSequence.metadata.organization.featureNames?.includes(Features.SendInvitationFromReport)
                  ) {
                    return (
                      <SplitCell>
                        <HalfOfCell
                          data-cy="report-respondents-cell"
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            setEditRespondent({
                              respondent: respondent.respondent,
                              contact: undelivered.contact,
                            });
                          }}
                        >
                          {isResending ? (
                            <StyledTooltip {...tooltipProps} title={t('deliveryInProgressTooltip')}>
                              <HourGlass />
                            </StyledTooltip>
                          ) : (
                            <StyledTooltip {...tooltipProps} title={t('notDeliveredTooltip')}>
                              <NotDeliveredIcon />
                            </StyledTooltip>
                          )}
                        </HalfOfCell>
                        <HalfOfCell
                          light
                          role="button"
                          tabIndex={0}
                          aria-disabled={clicked}
                          onClick={() => {
                            if (survey?.status === SurveyStatus.Ongoing) {
                              sendAgainSurvey();
                            } else {
                              reopenSurvey();
                            }
                          }}
                        >
                          <StyledTooltip {...tooltipProps} title={t('sendAgainTooltip')}>
                            <SendIcon color={theme.colors.actionPrimary.default} size={18} />
                          </StyledTooltip>
                        </HalfOfCell>
                      </SplitCell>
                    );
                  } else {
                    return (
                      <StyledTooltip {...tooltipProps} title={t('notDeliveredTooltip')}>
                        <BaseColorAnswerCell
                          data-cy="report-respondents-cell"
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            setEditRespondent({
                              respondent: respondent.respondent,
                              contact: undelivered.contact,
                            });
                          }}
                        >
                          <NotDeliveredIcon />
                        </BaseColorAnswerCell>
                      </StyledTooltip>
                    );
                  }
                }
                if (
                  survey?.status === SurveyStatus.Ongoing &&
                  survey.respondentIds.includes(respondent.respondent.id) &&
                  !channel?.finishedAt
                ) {
                  return (
                    <StyledTooltip {...tooltipProps} title={t('ongoingTooltip')}>
                      <AnswerCell>
                        {t('ongoing')}
                        <div style={{ lineHeight: '10px' }}>{'-'}</div>
                        {getLocalizedDateWithoutLeadingZeroes(new Date(survey.endsAt), i18n.language, true)}
                      </AnswerCell>
                    </StyledTooltip>
                  );
                }

                if (
                  (survey &&
                    (([SurveyStatus.Prepared, SurveyStatus.Notstarted].includes(survey.status) &&
                      (respondent.respondent.deleted || respondent.group.deletedRespondents?.includes(respId))) ||
                      ([SurveyStatus.Completed, SurveyStatus.Archived, SurveyStatus.Ongoing].includes(survey.status) &&
                        !survey.respondentIds.includes(respondent.respondent.id)))) ||
                  !survey
                ) {
                  if (
                    containsAnonymity ||
                    !reportSequence.metadata.organization.featureNames?.includes(Features.SendInvitationFromReport) ||
                    (survey &&
                      (respondent.respondent.deleted || respondent.group.deletedRespondents?.includes(respId))) ||
                    !survey
                  ) {
                    return (
                      <StyledTooltip {...tooltipProps} title={t('notSentTooltip')}>
                        <BaseColorAnswerCell
                          data-cy="report-respondents-cell"
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            history.push(detailUrl);
                          }}
                        >
                          <NoSurveyIcon />
                        </BaseColorAnswerCell>
                      </StyledTooltip>
                    );
                  }
                  return (
                    <SplitCell>
                      <HalfOfCell
                        data-cy="report-respondents-cell"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          history.push(detailUrl);
                        }}
                      >
                        <StyledTooltip {...tooltipProps} title={t('notSentTooltip')}>
                          <NoSurveyIcon />
                        </StyledTooltip>
                      </HalfOfCell>
                      <HalfOfCell
                        light
                        role="button"
                        aria-disabled={disabled || clicked}
                        tabIndex={0}
                        onClick={() => {
                          if (survey?.status === SurveyStatus.Ongoing) {
                            sendAgainSurvey();
                          } else if (survey) {
                            reopenSurvey();
                          } else {
                            createMissingSurvey();
                          }
                        }}
                      >
                        <StyledTooltip {...tooltipProps} title={t(disabled ? 'todayWasAlreadySent' : 'sendTooltip')}>
                          <SendIcon color={theme.colors.actionPrimary.default} size={18} />
                        </StyledTooltip>
                      </HalfOfCell>
                    </SplitCell>
                  );
                }

                if (
                  channel &&
                  !channel?.withAnswer &&
                  !containsAnonymity &&
                  reportSequence.metadata.organization.featureNames?.includes(Features.SendInvitationFromReport) &&
                  !respondent.group.deletedRespondents?.includes(respId) &&
                  !respondent.respondent.deleted
                ) {
                  return (
                    <SplitCell>
                      <HalfOfCell
                        data-cy="report-respondents-cell"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          history.push(detailUrl);
                        }}
                      >
                        <StyledTooltip {...tooltipProps} title={t('noAnswer')}>
                          <StyledNoIcon />
                        </StyledTooltip>
                      </HalfOfCell>
                      <HalfOfCell
                        light
                        role="button"
                        tabIndex={0}
                        aria-disabled={disabled || clicked}
                        onClick={() => {
                          reopenSurvey();
                        }}
                      >
                        <StyledTooltip
                          {...tooltipProps}
                          title={t(disabled ? 'todayWasAlreadySent' : 'sendAgainTooltip')}
                        >
                          <SendIcon color={theme.colors.actionPrimary.default} size={18} />
                        </StyledTooltip>
                      </HalfOfCell>
                    </SplitCell>
                  );
                }

                if (survey && [SurveyStatus.Prepared].includes(survey.status)) {
                  return (
                    <StyledTooltip {...tooltipProps} title={t('preparedTooltip')}>
                      <AnswerCell>
                        {getLocalizedDateWithoutLeadingZeroes(new Date(survey.sendAt), i18n.language, true)}
                        <div style={{ lineHeight: '10px' }}>{'-'}</div>
                        {getLocalizedDateWithoutLeadingZeroes(new Date(survey.endsAt), i18n.language, true)}
                      </AnswerCell>
                    </StyledTooltip>
                  );
                }

                if (survey && !containsAnonymity && [SurveyStatus.Notstarted].includes(survey.status)) {
                  return (
                    <SplitCell>
                      <HalfOfCell
                        data-cy="report-respondents-cell"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          history.push(detailUrl);
                        }}
                      >
                        <StyledTooltip {...tooltipProps} title={t('notSentTooltip')}>
                          <NoSurveyIcon />
                        </StyledTooltip>
                      </HalfOfCell>
                      <HalfOfCell
                        light
                        role="button"
                        tabIndex={0}
                        aria-disabled={clicked}
                        onClick={() => {
                          updateNotStartedSurvey();
                        }}
                      >
                        <StyledTooltip {...tooltipProps} title={t('sendTooltip')}>
                          <SendIcon color={theme.colors.actionPrimary.default} size={18} />
                        </StyledTooltip>
                      </HalfOfCell>
                    </SplitCell>
                  );
                }
                if (!step.hasMetricQuestion && channel?.withAnswer) {
                  return (
                    <StyledTooltip {...tooltipProps} title={t('noMetricQuestionTooltip')}>
                      <BaseColorAnswerCell
                        data-cy="report-respondents-cell"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          history.push(detailUrl);
                        }}
                      >
                        <NoMetricsQuestionIcon />
                        {maxWeight === 1 && (
                          <StyledTooltip {...tooltipProps} title={t('alertsTooltip')}>
                            <StyledWarningIcon data-cy="report-respondents-cell-warning" />
                          </StyledTooltip>
                        )}
                        {maxWeight > 1 && (
                          <StyledTooltip {...tooltipProps} title={t('alertsTooltip')}>
                            {' '}
                            <StyledAlertIcon />
                          </StyledTooltip>
                        )}
                      </BaseColorAnswerCell>
                    </StyledTooltip>
                  );
                }
                return (
                  <StyledTooltip {...tooltipProps} title={t('noAnswer')}>
                    <BaseColorAnswerCell
                      data-cy="report-respondents-cell"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        history.push(detailUrl);
                      }}
                    >
                      <StyledNoIcon />
                      {maxWeight === 1 && (
                        <StyledTooltip {...tooltipProps} title={t('alertsTooltip')}>
                          <StyledWarningIcon data-cy="report-respondents-cell-warning" />
                        </StyledTooltip>
                      )}
                      {maxWeight > 1 && (
                        <StyledTooltip {...tooltipProps} title={t('alertsTooltip')}>
                          {' '}
                          <StyledAlertIcon />
                        </StyledTooltip>
                      )}
                    </BaseColorAnswerCell>
                  </StyledTooltip>
                );
              })}
              {showDeleteButton &&
                !(respondent.respondent.deleted || respondent.group.deletedRespondents?.includes(respId)) && (
                  <Tooltip title={t(isDeleteDisabled ? 'removeRespondentDisabledTooltip' : 'removeRespondentTooltip')}>
                    <StylecCloseIcon
                      key="close"
                      aria-label="Close"
                      onClick={() => showModal(respondent.respondent, respondent)}
                      disabled={isDeleteDisabled}
                    >
                      <CloseIcon />
                    </StylecCloseIcon>
                  </Tooltip>
                )}
            </Row>
          );
        })}
      </Container>
      <RemoveRespondentModal
        show={modal}
        showModal={setModal}
        respondent={respondentToDelete}
        dateFrom={dateFrom}
        surveyGroupId={reportSequence.surveyGroup!.id}
        accessToken={accessToken}
        onReload={onReload}
        surveysWithAnswers={groupedRespondents
          .find((respondent) => respondent.respondents.find((resp) => resp.id === respondentToDelete?.id))
          ?.surveys?.filter((survey) =>
            survey.publicChannels.find(
              (channel) => channel.withAnswer && channel.respondentId === respondentToDelete?.id,
            ),
          )}
      />
    </>
  );
};

export default RespondentsFeelings;
