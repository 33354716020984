import { theme, useIsVisible } from '@arnold/common';
import { ReactComponent as LeftChevron } from '@arnold/common/lib/assets/icons/Left-Chevron.svg';
import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { contains, memoizeWith, sortBy, uniq, uniqBy, clone } from 'ramda';
import { MutableRefObject, useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Loading } from '../../components';
import LoadingComponent from '../../components/LoadingComponent';
import { ArchivedSection } from '../../components/StyledComponents';
import {
  FeEventType,
  Features,
  QuestionWithAnswer,
  ReportSubscriptionSubscription,
  ReportWidgetType,
  SurveyStatus,
  useLogFeEventMutation,
} from '../../generated/hooks';
import { composeSearchParamsString } from '../../lib/common';
import {
  Filter,
  IFilterDescription,
  WidgetType,
  addWidgets,
  filterReport,
  getAlertsData,
  getAnswersForQuestionWithValue,
  getComments,
  getFilterDescriptions,
  getIntroWidgetData,
  getPrintableReportDate,
  getPrintableSequenceDate,
  getQuestionDefinitionByIndex,
  getQuestionType,
  getQuestionsForGlobalFilter,
  getSectionInfo,
  getTeamsById,
  getTeamsToShow,
  getTopicGroupTranslation,
  groupWidgetsIntoGroups,
} from '../../lib/reportHelper';
import { AggregatedData } from '../../lib/reports/aggregateData';
import { IReport, WidgetFilters, getMessagesWithQuestionIndex } from '../../lib/reports/base';
import { exportPowerpoint } from '../../lib/reports/exportPowerpoint';
import { wait } from '../../utils';
import { FilterModal } from '../components/StyledComponents';
import { useClickOutside } from '../../lib/reports/hooks';
import { AiSurveySummary } from './AiSurveySummary';
import Conclusion, { IConclusionBox } from './Conclusion';
import Contacts from './Contacts';
import { Error } from './Error';
import Footer from './Footer';
import { GlobalFilter } from './GlobalFilter';
import ReportContainer from './ReportContainer';
import { ReportMetrics } from './ReportMetrics';
import ReportSection from './ReportSection';
import { ReportWidget } from './ReportWidget';
import { ReportWidgetGroup } from './ReportWidgetGroup';
import RespondentsAnswers from './RespondentsAnswers';
import { SideMenu, SideMenuContextProvider } from './SideMenu';
import TeamsOverview from './TeamsOverview';
import { useMetadataSetter } from './hooks';
import { NavbarFilter } from './NavbarFilter';

const StyledLink = styled(Link)`
  display: block;
  margin-top: ${theme.spacing.f};
  margin-bottom: ${theme.spacing.g};

  font-weight: 400;

  svg {
    display: inline-block;
    margin-right: ${theme.spacing.d};
    position: relative;
    top: -1.5px;

    path {
      fill: ${theme.colors.iconAction.primary};
    }
  }

  &:hover {
    svg path {
      fill: ${theme.colors.actionPrimary.hover};
    }
  }

  &:active {
    svg path {
      fill: ${theme.colors.actionPrimary.active};
    }
  }
`;

interface IReportProps {
  accessToken: string;
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void;
}

interface IReportPageProps
  extends Pick<IReportProps, 'accessToken' | 'selectedLanguage' | 'changeLanguage' | 'setReportRespondent'> {
  topicGroupId?: string;
  addedOnFrom?: string | null;
  addedOnTo?: string | null;
  unitFilters?: string;
  report: IReport;
  aggregatedData: AggregatedData | null;
  publicAccess?: boolean; // publicAccess is false when org admins or sys admins are viewing reports
  setNavbarContent?: (content: React.ReactNode) => void;
  setAddedOnFrom: (from: string | null) => void;
  setAddedOnTo: (to: string | null) => void;
  usedFilters: Filter[];
  setUsedFilters: (filters: Filter[]) => void;
}

const ReportPage = (props: IReportPageProps) => {
  const { usedFilters, setUsedFilters } = props;
  const { t, i18n } = useTranslation(['Report', 'header']);
  const [filters, setFilters] = useState<Filter[]>(usedFilters);
  const [widgetFilters, setWidgetFilters] = useState<WidgetFilters>({});
  const [widgets, setWidgets] = useState<WidgetType[]>([]);
  const [powerpointLoading, setPowerpointLoading] = useState(false);
  const [logFeEvent] = useLogFeEventMutation();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [from, setFrom] = useState<string | null>(props.addedOnFrom || null);
  const [to, setTo] = useState<string | null>(props.addedOnTo || null);

  useClickOutside([modalRef, buttonRef], setShowFilters);

  const handleVisibility = useCallback(
    (isVisible: boolean) => {
      const filteredFilters = uniqBy(
        (filter) => filter.operator,
        usedFilters.filter((f) => f.values?.length),
      );

      if (!isVisible && !!report) {
        props.setNavbarContent &&
          props.setNavbarContent(
            <NavbarFilter
              title={report.reportDefinition?.titleLocal!}
              addedOnFrom={props.addedOnFrom}
              addedOnTo={props.addedOnTo}
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              pillNumber={props.addedOnFrom || props.addedOnTo ? filteredFilters.length + 1 : filteredFilters.length}
              ref={buttonRef}
            />,
          );
      } else {
        props.setNavbarContent && props.setNavbarContent(null);
        setShowFilters(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.addedOnFrom, props.addedOnTo, JSON.stringify(usedFilters), showFilters],
  );

  useIsVisible(ref, handleVisibility);

  const handleRefreshButton = () => {
    // we need deep copy of filters to avoid reference issues
    setUsedFilters(clone(filters));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 10);
    clearWidgets();

    props.setAddedOnFrom(from);
    props.setAddedOnTo(to);
  };

  useMetadataSetter(
    props.changeLanguage,
    props.selectedLanguage,
    props.report.metadata?.language && props.report.metadata.language.code,
    props.report.metadata?.respondent,
    props.setReportRespondent,
  );

  const loadingRef = useRef() as MutableRefObject<any>;

  const setLoading = (value: boolean = true) => {
    if (!loadingRef || !loadingRef.current) {
      return;
    }
    loadingRef.current.setLoading(value);
  };

  const isWithoutResponses = !props.report.responses;
  const addedOnFrom = props.addedOnFrom ? new Date(props.addedOnFrom) : null;
  const addedOnTo = props.addedOnTo ? new Date(props.addedOnTo) : null;

  useEffect(() => {
    if (!isWithoutResponses && props.unitFilters) {
      setFilters([{ operator: 'unit-teams', values: props.unitFilters.split(',') }]);
      setUsedFilters([{ operator: 'unit-teams', values: props.unitFilters.split(',') }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWithoutResponses]);

  const defaultFilters: Filter[] = [{ operator: 'empty-widgets', values: [] }];
  const report = isWithoutResponses
    ? props.report
    : filterReport(addWidgets(props.report, widgets), [...defaultFilters, ...usedFilters]);

  const handleAddWidgets = (newWidgets: WidgetType[]) => {
    setLoading();
    const notInWidgets = (specifiedWidgets: WidgetType[]) => (widget: WidgetType) =>
      !contains(
        widget.widget.id,
        specifiedWidgets.map((w) => w.widget.id),
      );

    const removedWidgets = widgets.filter(notInWidgets(newWidgets));
    const addedWidgets = newWidgets.filter(notInWidgets(widgets));

    setTimeout(() => {
      setWidgets([...removedWidgets, ...addedWidgets]);
      setLoading(false);
    }, 60);
  };

  const removeWidgets = (widgetIds: string[]) => () => {
    setLoading();
    const removedWidgets = widgets.filter((w) => contains(w.widget.id, widgetIds));
    setTimeout(() => {
      setWidgets(
        widgets
          .filter((widget) => !contains(widget.widget.id, widgetIds))
          .map((widget) =>
            contains(widget.afterWidgetId, widgetIds)
              ? {
                  ...widget,
                  afterWidgetId: removedWidgets ? removedWidgets[0].afterWidgetId : widget.afterWidgetId,
                }
              : widget,
          ),
      );
      setLoading(false);
    }, 60);
  };

  const clearWidgets = () => () => {
    setLoading();
    setTimeout(() => setWidgets([]), 60);
  };

  const filteredReportSections = (report.reportDefinition?.sections || []).filter(
    (section) => section.widgets && section.widgets.length > 0,
  );

  const menuSections = [
    { id: 'intro', headingLocal: t('intro') },
    ...filteredReportSections.map((s) => getSectionInfo(s, t)),
    { id: 'about', headingLocal: t('about') },
  ];

  const filterOptions = getQuestionsForGlobalFilter(props.report, filters);

  const reportDate =
    report.sequence && props.topicGroupId
      ? getPrintableSequenceDate(report, props.topicGroupId, t)
      : getPrintableReportDate(report, t);

  const rootTeams = getTeamsById(report.teams!, report.reportRoots);
  const accessPath = props.publicAccess ? 'process-results' : 'report-sequence';
  const exportExcel = async () => {
    if (props.aggregatedData) {
      const { exportExcel } = await import('../../lib/reports/exportExcel');
      exportExcel(
        props.aggregatedData,
        usedFilters,
        report,
        reportDate,
        rootTeams,
        filteredReportSections,
        t,
        widgets,
        widgetFilters,
      );
      try {
        logFeEvent({
          variables: {
            eventType: FeEventType.ReportExport,
            payload: {
              reportAccess: props.accessToken,
              respondentId: props.report.metadata?.respondent?.id,
              detail: 'excel',
            },
          },
        });
      } catch (err) {
        // Do nothing
      }
    }
  };
  const exportPowerpointHandler = async () => {
    if (props.aggregatedData) {
      setPowerpointLoading(true);
      await wait(100); // We need to wait for the loading spinner to appear because exportPowerpoint blocks the UI
      await exportPowerpoint(
        usedFilters,
        report,
        rootTeams,
        filteredReportSections,
        props.addedOnFrom,
        props.addedOnTo,
        t,
      );
      setPowerpointLoading(false);
      try {
        logFeEvent({
          variables: {
            eventType: FeEventType.ReportExport,
            payload: {
              reportAccess: props.accessToken,
              respondentId: props.report.metadata?.respondent?.id,
              detail: 'powerpoint',
            },
          },
        });
      } catch (err) {
        // Do nothing
      }
    }
  };

  if (!report.reportDefinition) {
    return <div>{t('noDataInfo')}</div>;
  }

  if (props.publicAccess && !props.report.metadata?.accepted) {
    return <Error>{t('errorLoading')}</Error>;
  }

  const questionWithAnswers: QuestionWithAnswer[] = filteredReportSections.reduce((acc, section) => {
    const groupedWidgets = section.widgets !== null ? groupWidgetsIntoGroups(section.widgets!, widgets) : [];
    groupedWidgets.forEach((widgetGroup) => {
      const widget = widgetGroup.widgets[0];
      const question = getQuestionDefinitionByIndex(report, widget.questionDefinitionIndex!);
      if (!question) {
        return;
      }

      const type = getQuestionType(question);
      if (widget.type === ReportWidgetType.CommentTable) {
        const comments = getComments(report, widget.questionDefinitionIndex!, t).filter((c) => c.text.length > 5);
        if (comments.length > 0) {
          acc.push({
            type,
            id: widget.questionDefinitionIndex!,
            question: question?.text!,
            answers: comments.map((c) => c.text),
          });
        }
      } else if (widget.type === ReportWidgetType.StackedChart) {
        const totalCount = getMessagesWithQuestionIndex(report, widget.questionDefinitionIndex || '').length;
        if (totalCount > 0) {
          acc.push({
            type,
            id: widget.questionDefinitionIndex!,
            question: question?.text!,
            choices:
              question?.answersLocal?.map((choice, index) => {
                const answers = getAnswersForQuestionWithValue(
                  report,
                  widget.questionDefinitionIndex || '',
                  [index.toString()],
                  type,
                );
                return {
                  choice,
                  count: answers.length,
                };
              }) ?? [],
          });
        }
      }
    });

    return acc;
  }, [] as QuestionWithAnswer[]);

  return (
    <>
      {powerpointLoading && <Loading />}
      <SideMenuContextProvider sections={menuSections}>
        <ReportContainer
          date={reportDate}
          rootTeams={
            props.report.responses
              ? getTeamsToShow(props.report).filter((team) => {
                  const onlyDirectMembers = report.reportRoots.length === 1 && team.id === report.reportRoots[0];

                  const filteredReport = onlyDirectMembers
                    ? filterReport(report, [{ operator: 'team', values: [team.id] }])
                    : filterReport(report, [{ operator: 'unit', values: [team.id] }]);
                  return filteredReport.respondents.length || props.report.responses?.length === 0;
                })
              : []
          }
          title={report.reportDefinition.titleLocal!}
          about={report.reportDefinition.aboutLocal!}
          topicGroupId={report.metadata?.topicGroupId ?? undefined}
          breadcrumb={
            report.surveyGroup
              ? [
                  {
                    title: getTopicGroupTranslation(report.surveyGroup.topicGroup, report.metadata?.language).value,
                    link: `/${accessPath}/${props.accessToken}${composeSearchParamsString({
                      added_on_from: props.addedOnFrom,
                      added_on_to: props.addedOnTo,
                      'unit-teams': usedFilters.find((f) => f.operator === 'unit-teams')?.values?.join(','),
                    })}`,
                  },
                  { title: report.reportDefinition.titleLocal || '' },
                ]
              : []
          }
          organizationName={report.metadata?.organization.name || ''}
          exportExcel={exportExcel}
          exportPowerpoint={exportPowerpointHandler}
          isOrganizationLevelFilterOn={!!usedFilters.find((f) => f.operator === 'organization-level')}
        >
          <>
            <FilterModal showFilters={showFilters}>
              <GlobalFilter
                filters={filters}
                rootTeams={report.reportRoots}
                setFilters={setFilters}
                options={filterOptions}
                clearWidgets={clearWidgets()}
                organizationName={report.metadata?.organization.name!}
                organizationDepth={report.metadata?.organizationDepth!}
                respondentId={report.metadata?.respondent?.id}
                report={report}
                ref={modalRef}
                onConfirm={handleRefreshButton}
                unfilteredReport={props.report}
                addedOnFrom={from}
                addedOnTo={to}
                setAddedOnFrom={setFrom}
                setAddedOnTo={setTo}
              />
            </FilterModal>
            {report.metadata?.status === SurveyStatus.Archived && (
              <ArchivedSection className={'container container--md'} data-cy="report-archived-label">
                {t('reportArchived')}
              </ArchivedSection>
            )}
            {report.metadata?.status === SurveyStatus.Anonymized && (
              <ArchivedSection className={'container container--md'} data-cy="report-anonymized-label">
                {t('reportAnonymized')}
              </ArchivedSection>
            )}
            {![SurveyStatus.Archived, SurveyStatus.Anonymized].includes(report.metadata?.status!) && (
              <section className={'section'}>
                {props.publicAccess && (
                  <ReportMetrics
                    topSeen
                    reportAccessKey={props.accessToken}
                    trackTime
                    topicGroupId={props.topicGroupId}
                  />
                )}
                <div className={'container container--md position-relative'}>
                  <SideMenu sections={menuSections} />
                  {(addedOnFrom || addedOnTo) && (
                    <StyledLink
                      to={`/${accessPath}/${props.accessToken}${composeSearchParamsString({
                        added_on_from: props.addedOnFrom,
                        added_on_to: props.addedOnTo,
                        phasesChecked: params.get('phasesChecked'),
                        phasesActiveTab: params.get('phasesActiveTab'),
                        timeRangePeriod1: params.get('timeRangePeriod1'),
                        timeRangePeriod2: params.get('timeRangePeriod2'),
                        timeRangePeriod3: params.get('timeRangePeriod3'),
                        'unit-teams': usedFilters.find((f) => f.operator === 'unit-teams')?.values?.join(','),
                      })}`}
                    >
                      <LeftChevron />
                      {t('addedOnFilter')}
                      {addedOnFrom &&
                        t('addedOnFilterFrom', {
                          from: getLocalizedDateWithoutLeadingZeroes(addedOnFrom, i18n.language),
                        })}
                      {addedOnTo &&
                        t('addedOnFilterTo', {
                          to: getLocalizedDateWithoutLeadingZeroes(addedOnTo, i18n.language),
                        })}
                    </StyledLink>
                  )}
                  {filterOptions.length > 0 && (
                    <ReportSection id={'report-filter'}>
                      <GlobalFilter
                        filters={filters}
                        rootTeams={report.reportRoots}
                        setFilters={setFilters}
                        options={filterOptions}
                        clearWidgets={clearWidgets()}
                        organizationName={report.metadata?.organization.name!}
                        organizationDepth={report.metadata?.organizationDepth!}
                        respondentId={report.metadata?.respondent?.id}
                        report={report}
                        unfilteredReport={props.report}
                        ref={ref}
                        className={'mb-9'}
                        onConfirm={handleRefreshButton}
                        addedOnFrom={from}
                        addedOnTo={to}
                        setAddedOnFrom={setFrom}
                        setAddedOnTo={setTo}
                      />
                    </ReportSection>
                  )}
                  {props.report.reportDefinition?.intro && (
                    <ReportSection id={'intro'} header={t('intro')} icomTag={'report-intro-title'}>
                      <Conclusion
                        conclusions={
                          props.report.reportDefinition.intro
                            .map((introWidget) => getIntroWidgetData(report, introWidget, t))
                            .filter((w) => w != null) as IConclusionBox[]
                        }
                      />
                      {report?.metadata?.organization.featureNames?.includes(Features.AiSummaryOfSurveyAndPhase) && (
                        <AiSurveySummary
                          isProcessSurvey={!!report.surveyGroup}
                          languageCode={props.selectedLanguage || i18n.language}
                          accessToken={props.accessToken}
                          answers={questionWithAnswers}
                          getReportWidget={memoizeWith(
                            (questionIndex: string) => {
                              return (
                                report.identifier +
                                (report.metadata?.language?.code || '') +
                                JSON.stringify(
                                  sortBy((f) => f.operator)(
                                    uniq([...JSON.parse(report.filters || '[]'), ...(usedFilters || [])]),
                                  ),
                                ) +
                                report.widgets +
                                questionIndex
                              );
                            },
                            (idx: number) => {
                              const section = report.reportDefinition?.sections?.find(
                                (s) => s.widgets?.some((w) => w.questionDefinitionIndex === idx.toString()),
                              );
                              const widget = section?.widgets?.find(
                                (w) => w.questionDefinitionIndex === idx.toString(),
                              );
                              if (!section || !widget) {
                                return null;
                              }
                              return (
                                <ReportWidget
                                  key={widget.id}
                                  widget={widget}
                                  section={section}
                                  originalReport={report}
                                  sectionReport={
                                    isWithoutResponses ? report : filterReport(report, section.displayFilters)
                                  }
                                  i={0}
                                  globalFilters={usedFilters}
                                  filters={widgetFilters[idx.toString()]}
                                  widgetFilter={undefined}
                                  addedWidgets={widgets}
                                  setWidgets={setWidgets}
                                  removeWidgets={removeWidgets}
                                  hideHeader={false}
                                  hideDownload
                                  hasFilter={false}
                                  firstWidget={false}
                                  disableGraphClick
                                />
                              );
                            },
                          )}
                        />
                      )}
                      {report?.metadata?.organization.featureNames?.includes(
                        Features.DisplayTableWithRespondentAnswers,
                      ) &&
                        !isWithoutResponses &&
                        !report.anonymityForced && (
                          <RespondentsAnswers
                            publicAccess={!!props.publicAccess}
                            reportId={report.id}
                            data={getAlertsData(report, t, true)}
                            anonymityEnabled={report.anonymityEnabled}
                            accessToken={props.accessToken}
                            search={composeSearchParamsString({
                              added_on_from: props.addedOnFrom,
                              added_on_to: props.addedOnTo,
                              topic_group_id: props.topicGroupId,
                              'unit-teams': usedFilters.find((f) => f.operator === 'unit-teams')?.values?.join(','),
                            })}
                          />
                        )}
                    </ReportSection>
                  )}
                  {filteredReportSections.map(
                    (
                      section: NonNullable<
                        NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']
                      >[0],
                      i,
                    ) => {
                      const sectionReport = isWithoutResponses ? report : filterReport(report, section.displayFilters);
                      const groupedWidgets =
                        section.widgets !== null ? groupWidgetsIntoGroups(section.widgets!, widgets) : [];
                      const sectionInfo = getSectionInfo(section, t);
                      return (
                        <ReportSection
                          id={section.id}
                          key={`section_${section.id}`}
                          header={sectionInfo.headingLocal}
                          subHeader={sectionInfo.descriptionLocal}
                          loading={isWithoutResponses}
                          filterDescriptions={
                            getFilterDescriptions(section.titleFilters || [], sectionReport) as IFilterDescription[]
                          }
                          icomTag={'report-section-title'}
                          sectionHeading={section.heading}
                        >
                          {groupedWidgets.map((widgetGroup, i) => {
                            const firstWidget = widgetGroup.widgets[0];
                            const index = firstWidget.questionDefinitionIndex || '';
                            return (
                              <>
                                <ReportWidgetGroup
                                  key={`subsection_${firstWidget.id}`}
                                  widgets={widgetGroup.widgets}
                                  section={section}
                                  loading={isWithoutResponses}
                                  sectionReport={sectionReport}
                                  originalReport={report}
                                  widgetFilters={widgetFilters[index]}
                                  setWidgetFilters={(newFilters: string[]) => {
                                    setWidgetFilters((oldFilters) => ({
                                      ...oldFilters,
                                      [index]: newFilters,
                                    }));
                                  }}
                                  globalFilters={usedFilters}
                                  addedWidgets={widgets}
                                  setWidgets={handleAddWidgets}
                                  removeWidgets={removeWidgets}
                                  baseIndex={widgetGroup.baseIndex}
                                  maxIndex={20 * ((section.widgets || []).length + widgets.length)}
                                  accessToken={props.accessToken}
                                />
                                {props.publicAccess && (
                                  <ReportMetrics
                                    questionsSeen={i + 1}
                                    questionsTotal={groupedWidgets.length}
                                    reportAccessKey={props.accessToken}
                                    topicGroupId={props.topicGroupId}
                                  />
                                )}
                              </>
                            );
                          })}
                        </ReportSection>
                      );
                    },
                  )}
                  <ReportSection
                    id={'about'}
                    key={`section_about`}
                    header={t('about')}
                    icomTag={'report-summary-title'}
                  >
                    <TeamsOverview report={report} loading={isWithoutResponses} />
                  </ReportSection>
                  <hr className={'mb-8'} />
                  <Contacts adminEmails={props.report.metadata?.adminEmails!} id={'contacts'} />
                </div>
                {props.publicAccess && (
                  <ReportMetrics bottomSeen reportAccessKey={props.accessToken} topicGroupId={props.topicGroupId} />
                )}
              </section>
            )}
            <Footer />
            <LoadingComponent ref={loadingRef} />
          </>
        </ReportContainer>
      </SideMenuContextProvider>
    </>
  );
};

export default ReportPage;
