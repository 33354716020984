import { useEffect } from 'react';
import { getFrontendConfigValue, createEventData } from '@arnold/common';
import { ENVIRONMENT, ProductCode } from '@arnold/core';
import { ReportSequenceSubscriptionSubscription, ReportSubscriptionSubscription } from '../../generated/hooks';
import { setupIntercom } from '../../lib/reportHelper';
import i18n from '../../translations/i18n';

export const useMetadataSetter = (
  changeLanguage: (lang: string) => void,
  selectedLanguage: string,
  reportLanguage?: string | null,
  reportRespondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void,
) => {
  useEffect(() => {
    if (setReportRespondent) {
      setReportRespondent(reportRespondent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportLanguage && reportLanguage !== selectedLanguage && changeLanguage) {
      changeLanguage(reportLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, reportLanguage]);
};

export const useReportSatismeter = (
  metadata:
    | NonNullable<ReportSubscriptionSubscription['getReport']>['metadata']
    | NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>['metadata'],
  publicAccess: boolean,
) => {
  useEffect(() => {
    const satismeter = (window as any).satismeter;
    if (
      [ENVIRONMENT.PROD, ENVIRONMENT.DEV].includes(getFrontendConfigValue('ENVIRONMENT')) &&
      satismeter &&
      publicAccess &&
      metadata?.productVersion?.validFrom &&
      metadata?.productVersion?.validTo &&
      metadata?.respondent?.user
    ) {
      const validFrom = new Date(metadata.productVersion.validFrom);
      const validTo = new Date(metadata.productVersion.validTo);
      const midDate = new Date((validFrom.getTime() + validTo.getTime()) / 2);
      const productVersion = metadata.productVersion;
      const user = metadata.respondent.user;
      const organization = metadata.organization;

      satismeter({
        writeKey: getFrontendConfigValue('SATISMETER_REPORT_WRITE_KEY'),
        userId: user.id,
        traits: {
          name: `${user.firstname} ${user.surname}`,
          contractHalf: midDate.toISOString(),
          contractValidFrom: validFrom.toISOString(),
          contractValidTo: validTo.toISOString(),
          createdAt: metadata.respondent.createdAt,
          plan: productVersion.product,
          isPaidPlan: productVersion.product !== ProductCode.Free,
          role: metadata.respondent.organizationRole,
          organizationName: organization.name,
          organizationId: organization.id,
          email: user.username,
        },
        language: i18n.language,
      });
    }
  }, [metadata, publicAccess]);
};

export const useSendOpenReportToIntercom = (
  publicAccess: boolean,
  respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
) => {
  useEffect(() => {
    if (respondent && publicAccess) {
      setupIntercom(respondent, i18n.language, {
        event: 'ux.results-open',
        ...createEventData('results', 'open', 'results open'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(respondent), publicAccess]);
};
