import { Filter, FILTER_OPERATOR } from '../../../../lib/reportHelper';

export const toggleFilter = (
  option: string,
  filteredOptions: string[],
  setFilteredOptions: (filteredOptions: string[]) => void,
  filters: Filter[],
  setFilters: (filters: Filter[]) => void,
) => {
  const updatedFilteredOptions = getFilteredOptions(option, setFilteredOptions, filteredOptions);
  handleGlobalFilterSettings(updatedFilteredOptions, filters, setFilters);
};

export const getFilteredOptions = (
  option: string,
  setFilteredOptions: (filteredOptions: string[]) => void,
  filteredOptions: string[],
) => {
  const filteredOptionsCloned = [...filteredOptions];
  const filteredOptionsIndex = filteredOptionsCloned.indexOf(option);
  if (filteredOptionsIndex === -1) {
    filteredOptionsCloned.push(option);
  } else {
    filteredOptionsCloned.splice(filteredOptionsIndex, 1);
  }
  setFilteredOptions(filteredOptionsCloned);
  return filteredOptionsCloned;
};

export const handleGlobalFilterSettings = (
  filteredOptions: string[],
  filters: Filter[],
  setFilters: (filters: Filter[]) => void,
) => {
  if (filteredOptions.length > 0) {
    const existingFilterIndex = filters.findIndex((filter) => filter.operator === FILTER_OPERATOR.SOURCE);
    if (existingFilterIndex === -1) {
      setFilters([...filters, { operator: FILTER_OPERATOR.SOURCE, values: filteredOptions }]);
    } else {
      const newFilters = [...filters];
      newFilters[existingFilterIndex] = {
        ...newFilters[existingFilterIndex],
        values: filteredOptions,
      };
      setFilters(newFilters);
    }
  } else {
    setFilters(filters.filter(({ operator }: Filter) => operator !== FILTER_OPERATOR.SOURCE));
  }
};
