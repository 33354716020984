import BrowserInteractionTime from 'browser-interaction-time';
import { getFrontendConfigValue, useIsVisible } from '@arnold/common';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateReportAccessMetricsMutation } from '../../generated/hooks';

const timeMetricsURL = getFrontendConfigValue('API_URL') + '/reportMetricsTime';

const MiniDiv = styled.div`
  width: 0px;
  height: 0px;
`;
export const ReportMetrics = (props: {
  reportAccessKey: string;
  topicGroupId?: string;
  bottomSeen?: boolean;
  topSeen?: boolean;
  questionsSeen?: number;
  questionsTotal?: number;
  trackTime?: boolean;
}) => {
  const tgId = props.topicGroupId ? parseInt(props.topicGroupId, 10) : undefined;
  const ref = useRef(null);
  const [reported, setReported] = useState(false);
  const [callMetricsMutation] = useUpdateReportAccessMetricsMutation();
  const handleVisibility = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        setReported(true);
        if (props.bottomSeen || props.topSeen || props.questionsSeen || props.questionsTotal) {
          // we want to only call the mutation if this components should report one of the things mentions above
          callMetricsMutation({ variables: { ...props, topicGroupId: tgId } });
        }
      }
    },
    [callMetricsMutation, props, tgId],
  );

  useIsVisible(ref, handleVisibility);

  const timer = useMemo(() => {
    if (!props.trackTime) return;
    const timer = new BrowserInteractionTime({
      idleTimeoutMs: 15 * 1000,
    });
    timer.startTimer();
    return timer;
  }, [props.trackTime]);

  useEffect(() => {
    const sendTimeMetrics = () => {
      if (!timer) return; // this should never happen, but I'd like to leave it here just in case
      navigator.sendBeacon(
        timeMetricsURL,
        createPayloadBlob(props.reportAccessKey, tgId, Math.round(timer.getTimeInMilliseconds() / 1000)),
      );
      timer.reset();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        sendTimeMetrics();
      }
    };
    if (props.trackTime) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (props.trackTime) {
        sendTimeMetrics();
      }
    };
  }, [props.trackTime, props.reportAccessKey, timer, tgId]);

  if (reported) return <></>;

  return <MiniDiv ref={ref} />;
};

const createPayloadBlob = (reportAccessKey: string, topicGroupId: number | undefined, timeSpentInSec: number) => {
  const payload = {
    timeSpentInSec,
    reportAccessKey,
    topicGroupId,
  };
  return new Blob([JSON.stringify(payload)], { type: 'application/json' });
};
