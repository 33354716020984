import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { contains } from 'ramda';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation, useParams, useRouteMatch, withRouter } from 'react-router-dom';
import { Loading } from '../components';
import client from '../createApollo';
import {
  AnonymityLevel,
  Features,
  ReportOpenMetricsView,
  ReportSequenceRespondentDetailQueryQuery,
  ReportSubscriptionSubscription,
  SurveyStatus,
  useReportSequenceRespondentDetailQueryLazyQuery,
} from '../generated/hooks';
import { composeSearchParamsString } from '../lib/common';
import {
  getAlertsData,
  getPrintableReportMonth,
  getTeamNameByRespondentId,
  getTopicGroupTranslation,
} from '../lib/reportHelper';
import Conversation from './components/Conversation';
import { Error } from './components/Error';
import ReportContainer from './components/ReportContainer';
import { ReportMetrics } from './components/ReportMetrics';
import ReportSection from './components/ReportSection';
import RespondentsFeelings from './components/RespondentsFeelings';
import { useMetadataSetter, useReportSatismeter, useSendOpenReportToIntercom } from './components/hooks';
import RespondentSummary from './sequence/RespondentSummary';
import { ReportOpenMetricsSender } from './components/ObserverEventSender';

interface IProps {
  report: ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail'];
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void;
  publicAccess?: boolean;
  onReload: () => void;
}

const RespondentDetail = ({
  report,
  setReportRespondent,
  changeLanguage,
  selectedLanguage,
  publicAccess,
  onReload,
}: IProps) => {
  const { t } = useTranslation('Report');
  const { token, respondentId } = useParams<{ token: string; respondentId: string }>();
  const selectedRespondentId = publicAccess
    ? CryptoAES.decrypt(decodeURIComponent(respondentId), report.id.toString()).toString(CryptoENC)
    : respondentId;
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search.substring(1) || '');
  const selectedTopicGroupId = searchParams.get('selected_tg');
  const addedOnFrom = searchParams.get('added_on_from') || undefined;
  const addedOnTo = searchParams.get('added_on_to') || undefined;
  const unitFilters = searchParams.get('unit-teams') || undefined;

  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;

  const respondent = report.respondents.find((respondent) => respondent.id === selectedRespondentId);

  useEffect(() => {
    setTimeout(() => {
      if (selectedTopicGroupId && scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 0);
  }, [selectedTopicGroupId]);

  useMetadataSetter(
    changeLanguage,
    selectedLanguage,
    report.metadata.language && report.metadata.language.code,
    report.metadata.respondent as NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
    setReportRespondent,
  );

  useSendOpenReportToIntercom(!!publicAccess, report.metadata.respondent);

  if (!respondent) {
    return <Error>{t('respondentNotFound')}</Error>;
  }

  const team = report.teams.find((team) => contains(selectedRespondentId, team.directMembers || []));
  const teamName = getTeamNameByRespondentId(report, selectedRespondentId, t);

  if (!report.surveyGroup) {
    return null;
  }

  const accessReportSequencePath = publicAccess ? 'process-results' : 'report-sequence';
  const urlParams = composeSearchParamsString({
    added_on_from: addedOnFrom,
    added_on_to: addedOnTo,
    'unit-teams': unitFilters,
  });
  return (
    <ReportContainer
      date={`${getPrintableReportMonth(report, report.metadata.language && report.metadata.language.code)}`}
      rootTeams={team ? [team] : []}
      title={`${respondent.firstname} ${respondent.surname}`}
      about={null}
      breadcrumb={[
        {
          title: getTopicGroupTranslation(report.surveyGroup.topicGroup, report.metadata.language).value,
          link: `/${accessReportSequencePath}/${token}${urlParams}`,
        },
      ]}
      organizationName={report.metadata.organization.name}
    >
      <section className={'section'}>
        {publicAccess && <ReportMetrics reportAccessKey={token} trackTime />}
        <div className={'container container--md'}>
          <RespondentSummary report={report} />
          {!report.surveyGroup.topicGroup.hideOnboardingParts && (
            <ReportSection id={'respondentsTable'} header={t('respondentsTable')}>
              <RespondentsFeelings
                groupedRespondents={report.groupedRespondents}
                reportSequence={report}
                accessToken={token}
                urlParams={urlParams}
                encryptedRespondentId={respondentId}
                publicAccess={publicAccess}
                showDeleteButton={
                  report.surveyGroup.steps.every(
                    (step) =>
                      !step.processStep.stepTopicGroup.allowedAnonymities?.includes(AnonymityLevel.Organization),
                  ) &&
                  (report.metadata.organization.featureNames!.includes(
                    Features.ReportRemoveRespondentFromProcessSurvey,
                  ) ||
                    !report.metadata.respondent)
                }
                onReload={onReload}
              />
            </ReportSection>
          )}
          <h2 className={'mb-0'}>{t('chats')}</h2>
          {report.surveyGroup.steps.map((step) => {
            const chat = getAlertsData(report, t, false, true).filter(
              (chat) =>
                chat.messages.length &&
                chat.respondentId === selectedRespondentId &&
                chat.topicGroupId === step.processStep.stepTopicGroup.id,
            )[0];

            const survey = step.surveys.find((r) => (chat ? r.id === chat.surveyId : r.seqDate === respondent.addedOn));
            const surveyIsNotFinished =
              survey && [SurveyStatus.Ongoing, SurveyStatus.Prepared, SurveyStatus.Notstarted].includes(survey.status);

            const channelId = report.groupedRespondents
              .find((gr) => gr.respondents.some((r) => r.id === selectedRespondentId))
              ?.surveys.find((s) => s.id === survey?.id)
              ?.publicChannels.find((ch) => ch.respondentId === selectedRespondentId && ch.withAnswer)?.id;

            return (
              <div
                ref={step.processStep.stepTopicGroup.id === selectedTopicGroupId ? scrollRef : undefined}
                id={step.processStep.stepTopicGroup.id}
                className={'my-8'}
              >
                <Conversation
                  report={report}
                  allowedAnonymities={step.processStep.stepTopicGroup.allowedAnonymities}
                  messages={chat ? chat.messages : []}
                  team={`${teamName.teamLabel}: ${teamName.teamName}`}
                  notFinished={surveyIsNotFinished}
                  name={getTopicGroupTranslation(step.processStep.stepTopicGroup, report.metadata.language, '').value}
                  endsAt={chat ? chat.date : survey ? new Date(survey.endsAt) : null}
                  startsAt={survey?.sendAt}
                />
                <ReportOpenMetricsSender
                  reportAccessKey={token}
                  orgAdmin={!publicAccess}
                  channelIds={channelId ? [channelId] : []}
                  reportView={ReportOpenMetricsView.RespondentDetail}
                />
              </div>
            );
          })}
        </div>
      </section>
    </ReportContainer>
  );
};

interface IReportSequenceLoaderProps extends RouteComponentProps<any> {
  accessToken: string;
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'] | null,
  ) => void;
  setAllowedLanguages: (languages: string[]) => void;
  allowedLanguages?: string[];
}

const RespondentDetailLoader = (props: IReportSequenceLoaderProps) => {
  const { t } = useTranslation('Report');
  const { path } = useRouteMatch();
  const { respondentId } = useParams<{ token: string; respondentId: string }>();

  const [getReportSequenceRespondentDetailQuery, { data, loading }] = useReportSequenceRespondentDetailQueryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      props.changeLanguage(data.getProcessReportRespondentDetail.metadata.language!.code);
    },
  });
  const publicAccess = path === '/process-results/:token/respondent/:respondentId';

  useEffect(() => {
    if (props.selectedLanguage !== data?.getProcessReportRespondentDetail.metadata.language!.code) {
      getReportSequenceRespondentDetailQuery({
        variables: {
          accessToken: props.accessToken,
          languageCode: props.selectedLanguage,
          publicAccess,
          respondentId: publicAccess ? decodeURIComponent(respondentId) : respondentId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLanguage]);

  useReportSatismeter(data?.getProcessReportRespondentDetail.metadata, publicAccess);

  useEffect(() => {
    if (data || loading) {
      getReportSequenceRespondentDetailQuery({
        variables: {
          accessToken: props.accessToken,
          languageCode: props.selectedLanguage,
          publicAccess,
          respondentId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respondentId]);

  if (loading) {
    return <Loading />;
  }

  if (!data || (!data.getProcessReportRespondentDetail.metadata.accepted && publicAccess)) {
    return <Error>{t('errorLoading')}</Error>;
  }

  return (
    <RespondentDetail
      report={data.getProcessReportRespondentDetail}
      selectedLanguage={props.selectedLanguage}
      changeLanguage={props.changeLanguage}
      setReportRespondent={props.setReportRespondent}
      publicAccess={publicAccess}
      onReload={() => client.refetchQueries({ include: ['ReportSequenceRespondentDetailQuery'] })}
    />
  );
};

export default withRouter(RespondentDetailLoader);
