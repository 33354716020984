import { gql } from '@apollo/client';

export const reportSubscription = gql`
  subscription ReportSubscription(
    $languageCode: String
    $accessToken: String!
    $reportDefinitionId: ID
    $topicGroupId: ID
    $addedOnFrom: Date
    $addedOnTo: Date
    $publicAccess: Boolean
  ) {
    getReport(
      languageCode: $languageCode
      accessToken: $accessToken
      reportDefinitionId: $reportDefinitionId
      topicGroupId: $topicGroupId
      addedOnFrom: $addedOnFrom
      addedOnTo: $addedOnTo
      publicAccess: $publicAccess
    ) {
      id
      identifier
      surveyKeywordsGenerated
      anonymityEnabled
      anonymityForced
      responses {
        id
        surveyId
        respondentId
        topicGroupId
        anonymous
        originalAnonymous
        finished
        additionallyAnonymized
        messages {
          id
          questionDefinitionIndex
          text
          value
          anonymous
          originalAnonymous
          date
        }
      }
      questions {
        id
        index
        text
        type
        selectedRange
        answersLocal
        reportIndex
        answersToHighlight
      }
      respondents {
        id
        firstname
        surname
        addedOn
      }
      reportRoots
      metadata {
        surveyEnd
        surveyStart
        status
        accepted
        topicGroupId
        organization {
          id
          name
          featureNames
        }
        language {
          id
          code
        }
        respondent {
          id
          firstname
          surname
          intercomHash
          createdAt
          contacts {
            type
            value
            source
          }
          organizationRole
          organization {
            id
            name
            companyId
            featureNames
          }
          user {
            id
            username
            firstname
            surname
            intercomHash
            createdAt
            systemRole
            respondents {
              id
              firstname
              surname
              organizationRole
            }
          }
        }
        adminEmails
        organizationDepth
        productVersion {
          validFrom
          validTo
          product
        }
      }
      teams {
        id
        teamName
        directMembers
        memberTeams
        teamLeader
      }
      surveyGroup {
        from
        to
        topicGroup {
          id
          typeCode
          translations {
            id
            language {
              code
              id
            }
            value
            description
          }
        }
        steps {
          daysFromStart
          processStep {
            stepTopicGroup {
              id
              allowedAnonymities
              translations {
                id
                language {
                  code
                  id
                }
                value
                description
              }
            }
          }
        }
      }
      questionLabels {
        questionDefinitionIndex
        questionLabelComments {
          label
          commentIds
        }
      }
      source {
        respondentId
        sourceId
      }
      reportDefinition {
        id
        titleLocal
        aboutLocal
        sections {
          id
          heading
          headingLocal
          descriptionLocal
          type
          displayFilters {
            operator
            questionDefinitionIndex
            values
          }
          titleFilters {
            operator
            questionDefinitionIndex
            values
          }
          widgets {
            id
            questionDefinitionIndex
            headingLocal
            type
            descriptionLocal
            titleFilters {
              operator
              questionDefinitionIndex
              values
            }
            displayFilters {
              operator
              questionDefinitionIndex
              values
            }
            answersToHighlight
            values
          }
        }
        intro {
          questionDefinitionIndex
          headingLocal
          type
          descriptionLocal
          displayFilters {
            operator
            questionDefinitionIndex
            values
          }
          answersToHighlight
          values
          numerator
          denominator
          additionallyAnonymized
        }
      }
      additionalRespondentData {
        channelId
        position
        recruiter
        managers
        addedOn
      }
      sequenceFrom
      sequenceTo
    }
  }
`;

export const reportSequenceSubscription = gql`
  subscription ReportSequenceSubscription(
    $languageCode: String
    $accessToken: String!
    $addedOnFrom: Date
    $addedOnTo: Date
    $publicAccess: Boolean
    $teamsFilter: [String!]
    $withoutDeleted: Boolean
  ) {
    getProcessReport(
      languageCode: $languageCode
      accessToken: $accessToken
      addedOnFrom: $addedOnFrom
      addedOnTo: $addedOnTo
      publicAccess: $publicAccess
      teamFilters: $teamsFilter
      withoutDeleted: $withoutDeleted
    ) {
      id
      identifier
      totalRespondents
      filteredRespondents
      addedOnFrom
      addedOnTo
      reportRoots
      groupedRespondents {
        data {
          metricQuestionsForRespondent {
            respondentId
            answers {
              topicGroupId
              value
            }
          }
          respondents {
            id
            preferredContactType
            firstname
            surname
            deleted
            contacts {
              type
              source
              value
              phoneNumber {
                countryCallingCode
                nationalNumber
              }
            }
          }
          addedOn
          surveys {
            id
            status
            sendAt
            endsAt
            topicGroup {
              id
            }
            respondentIds
            publicChannels {
              id
              respondentId
              lastRemindedAt
              maxAnonymousHighlightedLevel
              withAnswer
              finishedAt
            }
          }
          deletedRespondents
          undeliveredInvitations {
            respondentId
            undeliveredSurveyInvitations {
              surveyId
              contact
              status
            }
          }
        }
        totalCount
      }
      metadata {
        surveyEnd
        surveyStart
        status
        accepted
        organization {
          id
          name
          featureNames
          config {
            countryCallingCode
          }
        }
        language {
          id
          code
        }
        respondent {
          id
          firstname
          surname
          organizationRole
          createdAt
          intercomHash
          contacts {
            type
            value
            source
          }
          organization {
            id
            name
            companyId
            featureNames
          }
          user {
            id
            username
            firstname
            surname
            intercomHash
            createdAt
            systemRole
            respondents {
              id
              firstname
              surname
              organizationRole
            }
          }
        }
        adminEmails
        productVersion {
          validFrom
          validTo
          product
        }
      }
      widgets {
        withoutProblems {
          numerator
          denominator
        }
        responseRate {
          numerator
          denominator
        }
        anonymityRate {
          numerator
          denominator
        }
      }
      rootTeamsStatistics {
        teamId
        statistics {
          finishedRate {
            numerator
            denominator
          }
          responseRate {
            numerator
            denominator
          }
          anonymityRate {
            numerator
            denominator
          }
        }
      }
      teams {
        id
        teamName
        directMembers
        memberTeams
        teamLeader
      }

      surveyGroup {
        from
        to
        id
        statistics {
          total
          answered
          withProblems
        }
        topicGroup {
          id
          hideOnboardingParts
          typeCode
          translations {
            id
            language {
              code
              id
            }
            value
            description
          }
        }
        steps {
          daysFromStart
          hasMetricQuestion
          status
          hasNotStarted
          notstartedReason
          statistics {
            answered
            total
            withProblems
            anonymous
            avgMetric
          }
          surveys {
            id
            sendAt
            endsAt
            seqDate
            status
          }
          processStep {
            id
            startOffsetMins
            reminders {
              reminderOffsetMins
            }
            endOffsetMins
            stepTopicGroup {
              allowedAnonymities
              id
              translations {
                id
                language {
                  code
                  id
                }
                value
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const aiSurveySummarySubscription = gql`
  subscription aiSurveySummarySubscription(
    $languageCode: String!
    $accessToken: String!
    $gpt4: Boolean
    $systemMessage: String
    $answers: [QuestionWithAnswer!]
  ) {
    aiSurveySummary(
      languageCode: $languageCode
      accessToken: $accessToken
      gpt4: $gpt4
      systemMessage: $systemMessage
      answers: $answers
    ) {
      text
      done
    }
  }
`;
