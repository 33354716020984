import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import styled from '@emotion/styled/macro';
import { clone, differenceWith } from 'ramda';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InputConsumer } from '../chat/InputContext';
import { GetMessagesQuery } from '../generated/hooks';
import { channelQuery, messagesQuery } from '../graphql/queries';
import { IChannel } from '../types';

const EditMessage = styled.button`
  margin-left: 18px;
  font-size: 12px;
  font-weight: 500;
  color: #04b7ef;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  padding: 0;
  background: none;
  outline-color: transparent;
  outline: none;

  :hover {
    text-decoration: underline;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  }

  :disabled {
    color: #a1a1a1;
    cursor: auto;
    text-decoration: none;
  }
`;

interface IProps extends WithTranslation {
  message: NonNullable<GetMessagesQuery['messages']>[0];
  disabled?: boolean;
  mutate?: (conf: any) => Promise<any>;
  channel: IChannel;
  editInputText: (type: string, text: string, values?: number[]) => void;
  t: any;
  clearInput: () => void;
  setRemainingQuestions: (remainingQuestions: number) => void;
}

type MessageTypeSelectAnswer = Extract<IProps['message'], { __typename?: 'SelectAnswer' }>;

class EditBubble extends React.Component<IProps> {
  handleClick = () => {
    this.props.clearInput();
    const { mutate, message, channel, editInputText } = this.props;
    if (message && mutate) {
      mutate({
        variables: {
          channelId: channel.id,
          fromMessageId: message.id,
        },
        update: (proxy: any, { data: { deleteChannelMessages } }: any) => {
          const deletedMessages = deleteChannelMessages.deletedMessages;
          this.props.setRemainingQuestions(deleteChannelMessages.remainingQuestions);
          const data = clone(
            proxy.readQuery({
              query: messagesQuery,
              variables: { channelId: channel.id },
            }),
          );
          const newMessages = differenceWith(
            (x: NonNullable<GetMessagesQuery['messages']>[0], y: NonNullable<GetMessagesQuery['messages']>[0]) =>
              x.id === y.id,
            data.messages,
            deletedMessages,
          );
          data.messages = newMessages;
          const channelData = clone(
            proxy.readQuery({
              query: channelQuery,
              variables: { channelId: channel.id },
            }),
          );
          channelData.channel.isFinished = false;
          proxy.writeQuery({
            query: channelQuery,
            data: channelData,
            variables: { channelId: channel.id },
          });
          proxy.writeQuery({
            query: messagesQuery,
            data,
            variables: { channelId: channel.id },
          });
        },
      }).then(() => {
        const value =
          message.hasOwnProperty('question') &&
          (message as MessageTypeSelectAnswer).question.definition.selectedRange &&
          (message as MessageTypeSelectAnswer).question.definition.selectedRange[1] > 1
            ? (message as MessageTypeSelectAnswer).value
            : undefined;
        editInputText(message.__typename!, message.text || '', value);
      });
    }
  };

  render() {
    const { disabled } = this.props;
    return (
      <EditMessage onClick={!disabled ? this.handleClick : undefined} data-cy="chat-edit-button" disabled={disabled}>
        {this.props.t('changeAnswer')}
      </EditMessage>
    );
  }
}

const deleteChannelMessagesMutation = gql`
  mutation deleteChannelMessages($channelId: ID!, $fromMessageId: ID!) {
    deleteChannelMessages(channelId: $channelId, fromMessageId: $fromMessageId) {
      deletedMessages {
        id
        text
        createdBy
      }
      remainingQuestions
    }
  }
`;

const WithdeleteOperation = graphql<Omit<IProps, 'mutate'>>(deleteChannelMessagesMutation, {
  name: 'mutate',
});

const EditBubbleWithOperations = withTranslation('editBubble')(WithdeleteOperation(EditBubble));

const GetPropsFromProvider = ({
  channel,
  message,
  disabled,
}: {
  disabled?: boolean;
  channel: IChannel;
  message: NonNullable<GetMessagesQuery['messages']>[0];
}) => (
  <InputConsumer>
    {(context) => (
      <EditBubbleWithOperations
        channel={channel}
        editInputText={context.editInputValue}
        message={message}
        disabled={disabled}
        clearInput={context.clearInput}
        setRemainingQuestions={context.setRemainingQuestions}
      />
    )}
  </InputConsumer>
);

export default GetPropsFromProvider;
