import { SelectChoices } from '../components';
import PersonAutocomplete from '../components/PersonAutocomplete';
import { GetMessagesQuery } from '../generated/hooks';

interface IProps {
  data?: { messages?: GetMessagesQuery['messages'] };
  values: number[];
  scrollToBottom: () => void;
  disabled?: boolean;
}

const Specials = ({ data, values, scrollToBottom, disabled }: IProps) => {
  const messages = data && data.messages;
  const message = messages && messages[messages.length - 1];

  if (message && 'definition' in message) {
    const definition = message.definition;

    switch (definition.__typename) {
      case 'PersonMultiselectQuestionDefinition':
        return (
          <PersonAutocomplete
            items={definition.organizationRespondents}
            scrollToBottom={scrollToBottom}
            disabled={disabled}
          />
        );
      case 'SelectQuestionDefinition': {
        if (values.length < definition.selectedRange[1]) {
          return (
            <SelectChoices choices={definition.choices} selectedRange={definition.selectedRange} disabled={disabled} />
          );
        }
        return null;
      }
      default:
        return null;
    }
  }
  return null;
};

export default Specials;
