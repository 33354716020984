import { keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const skeletonLoading = keyframes`
0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const LoadingLine = styled.span`
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  height: 16px;
  display: inline-block;
  border-radius: 4px;
  width: 128px;

  animation: ${skeletonLoading} ${(props: { animationLength?: number }) => props.animationLength || 1.3}s linear
    infinite;
`;

export default LoadingLine;
