import { Checkbox, selectControlStyles, selectMenuStyles, SelectWrapper, ThemeType } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Answer } from '../../lib/reports/question';

const WidgetRow = styled.div`
  display: flex;
  scrollbar-width: none;
  font-size: 12px;
  line-height: 140%;
  padding: 0 max(32px, 10%);
  white-space: nowrap;
  flex-wrap: wrap;
  margin: -0.5rem 0;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterLabel = styled.span`
  display: inline-block;
  font-size: 15px;
  line-height: 145%;
  margin-top: 8px;
`;

const SelectRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 12px;
  font-size: 15px;
  font-weight: normal;
  font-size: 15px;
  line-height: 145%;
  padding: 0.5rem 1.5rem;
  color: ${({ isDisabled, theme }: { isDisabled: boolean; theme?: ThemeType }) =>
    isDisabled ? theme && theme.colors.text.disabled : theme && theme.colors.text.primary};
  transition: background-color 0.25s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${({ isDisabled, theme }: { isDisabled: boolean; theme?: ThemeType }) =>
      isDisabled ? theme && theme.colors.backgroundCover.default : theme && theme.colors.backgroundBasic.default};
  }
  }
`;

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 8,
    content: '" "',
    display: 'block',
    transition: 'background-color .25s ease',
    marginRight: 8,
    height: 8,
    width: 8,
  },
});

type SelectOption = {
  value: string;
  color: string;
  label: string;
};

type SelectValue = SelectOption | SelectOption[] | null;

const transformValueToArray = (value: SelectValue) => {
  if (value === null) {
    return [];
  }

  if (Array.isArray(value)) {
    return value.map((item) => item.value);
  }

  return [value.value];
};
interface IWidgetFilterProps {
  filters: string[];
  globalFilters: string[];
  setFilters: (newFilters: string[]) => void;
  answers: Answer[];
  hideDots?: boolean;
  theme?: ThemeType;
  highlightedAnswerIndex: string | null;
}

interface ICustomOptionProps {
  data: SelectOption;
  label: string;
  getValue: () => SelectValue;
  isSelected: boolean;
  setValue: (data: SelectOption[], op: 'set-value') => void;
}
type Styles = any;
type ItemData = {
  data: {
    value: string;
    hoverColor: string;
    color: string;
  };
};
const WidgetFilter = ({
  filters,
  setFilters,
  highlightedAnswerIndex,
  globalFilters,
  answers,
  theme,
  hideDots,
}: IWidgetFilterProps) => {
  const options = answers.map((answer) => ({
    value: answer.index,
    color: answer.color,
    hoverColor: answer.hoverColor,
    label: answer.text,
  }));

  const colourStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      ...selectMenuStyles,
      backgroundColor: theme?.colors.backgroundCover.default,
    }),
    container: (styles: Styles) => ({
      ...styles,
      maxWidth: 520,
      minWidth: 300,
      marginLeft: 8,
      '@media (max-width: 768px)': {
        marginLeft: 0,
      },
    }),
    control: (provided: any, state: any) => ({
      ...selectControlStyles(provided, state),
      paddingLeft: '0',
    }),
    valueContainer: (styles: Styles) => ({
      ...styles,
      padding: '2px !important',
    }),
    placeholder: (styles: Styles) => ({
      ...styles,
      fontSize: 15,
      marginLeft: 6,
    }),
    input: (styles: Styles) => ({
      ...styles,
      fontSize: 15,
      color: theme?.colors.text.primary,
      marginLeft: 6,
    }),
    multiValueLabel: (styles: Styles, { data }: ItemData) => {
      const isHighlighted = data.value === highlightedAnswerIndex;
      const isDisabled = globalFilters.length && !globalFilters.includes(data.value);
      return {
        ...styles,
        fontSize: 15,
        lineHeight: '145%',
        padding: 0,
        transition: 'color .25s ease',
        color: isDisabled
          ? theme?.colors.text.disabled
          : isHighlighted
            ? theme?.colors.textInverted.primary
            : theme?.colors.text.primary,
        paddingLeft: 0,
      };
    },
    multiValue: (styles: Styles, { data }: ItemData) => {
      const isHighlighted = data.value === highlightedAnswerIndex;
      const isDisabled = globalFilters.length && !globalFilters.includes(data.value);
      const dotStyles = hideDots
        ? {}
        : dot(
            isDisabled ? theme?.colors.text.disabled : isHighlighted ? theme?.colors.textInverted.primary : data.color,
          );
      return {
        ...styles,
        transition: 'background-color .25s ease',
        backgroundColor: isHighlighted ? data.hoverColor : theme?.colors.backgroundBasic.active,
        borderRadius: 4,
        padding: '4px 8px',
        ...dotStyles,
      };
    },
  };

  const { t } = useTranslation('Report');

  const CustomOption = ({ data, label, getValue, isSelected, setValue }: ICustomOptionProps) => {
    const isDisabled = globalFilters.length ? !globalFilters.includes(data.value) : false;

    const selectOption = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      const currentValue = transformValueToArray(getValue());
      const newValue = isSelected ? currentValue.filter((item) => item !== data.value) : [...currentValue, data.value];

      setValue(
        options.filter((option) => newValue.includes(option.value)),
        'set-value',
      );
    };

    return (
      <SelectRow isDisabled={isDisabled} onClick={selectOption}>
        <Checkbox disabled={isDisabled} selected={isSelected} onToggle={selectOption} text={label} />
      </SelectRow>
    );
  };
  const saveOption = (value: SelectValue) => {
    setFilters(transformValueToArray(value));
  };
  return (
    <WidgetRow>
      <FilterLabel>{t('compareTeams')}</FilterLabel>
      <SelectWrapper
        blurInputOnSelect={false}
        className="react-select figure"
        defaultValue={options.filter((option) => filters.includes(option.value))}
        isMulti={true}
        components={{
          Option: CustomOption,
          MultiValueRemove: () => null,
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder={t('localFilterPlaceholder')}
        options={options}
        menuPlacement="auto"
        styles={colourStyles}
        onChange={saveOption}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
    </WidgetRow>
  );
};

export default withTheme(WidgetFilter);
