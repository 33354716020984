import { Breadcrumb, BreadcrumbItem, CleanButton, LinkButton, theme, Tooltip } from '@arnold/common';
import { ReactComponent as Download } from '@arnold/common/lib/assets/icons/Download.svg';
import styled from '@emotion/styled/macro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingLine from '../../components/LoadingLine';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import { getTeamsLabel } from '../../lib/reportHelper';

interface IProps {
  header: string | null;
  subHeader: string | null;
  date: string;
  teams: NonNullable<ReportSubscriptionSubscription['getReport']['teams']>;
  breadcrumb?: BreadcrumbItem[];
  organizationName: string;
  exportExcel?: () => void;
  exportPowerpoint?: () => void;
  isOrganizationLevelFilterOn?: boolean;
}

const CustomLoadingLine = styled(LoadingLine)`
  position: relative;
  top: 2px;
  width: 228px;
`;

const ShowMoreButton = styled(CleanButton)`
  color: ${theme.colors.actionPrimary.default};
  margin-left: ${theme.spacing.d};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.actionPrimary.hover};
  }
  &:focus {
    color: ${theme.colors.actionPrimary.focused};
  }
`;

const ButtonText = styled.span`
  margin-left: ${theme.spacing.c};
`;

const StyledLinkButton = styled(LinkButton)`
  margin-left: ${theme.spacing.d};
`;

const DISPLAYED_TEAMS = 5;

const TopBar = ({
  breadcrumb,
  header,
  subHeader,
  date,
  teams,
  organizationName,
  exportExcel,
  exportPowerpoint,
  isOrganizationLevelFilterOn,
}: IProps) => {
  const { t } = useTranslation('Report');
  const [areTeamsExpanded, setAreTeamsExpanded] = useState(false);

  const teamsToShow = areTeamsExpanded ? teams : teams.slice(0, DISPLAYED_TEAMS);
  const teamsLabel = getTeamsLabel(teamsToShow, t);

  return (
    <div className={'container container--md'}>
      {breadcrumb && <Breadcrumb classList={'mt-n8'} items={breadcrumb} />}
      <h1 className={'display-4 mb-0'} data-icom={'report-title'} data-cy={'report-title'}>
        {header}
      </h1>
      <p className={'mt-6 mb-0'}>{subHeader}</p>
      <p className={'mt-6 mb-0'}>
        <div className={'text-muted'} data-icom={'organization-name'}>{`${t(
          'organization',
        )}: ${organizationName}`}</div>
        <div className={'text-muted'} data-icom={'report-teams'}>
          {teamsLabel || <CustomLoadingLine />}
          {teams.length > DISPLAYED_TEAMS && !areTeamsExpanded && (
            <>
              , &hellip;
              <ShowMoreButton onClick={() => setAreTeamsExpanded(true)}>{t('displayAllTeams')}</ShowMoreButton>
            </>
          )}
          {teams.length > DISPLAYED_TEAMS && areTeamsExpanded && (
            <ShowMoreButton onClick={() => setAreTeamsExpanded(false)}>{t('hideAllTeams')}</ShowMoreButton>
          )}
        </div>
        <div className={'text-muted'} data-icom={'report-date'}>{`${t('period')}: ${date}`}</div>
        {(exportExcel || exportPowerpoint) && (
          <div className="text-muted">
            {`${t('exportLabel')}:`}
            <span>
              {exportExcel && (
                <Tooltip title={t('excelExportTooltip')}>
                  <StyledLinkButton
                    onClick={exportExcel}
                    data-icom={'report-export-excel-button'}
                    variant="outline-primary"
                  >
                    <Download height={22} width={22} />
                    <ButtonText>{t('excelExportLabel')}</ButtonText>
                  </StyledLinkButton>
                </Tooltip>
              )}
              {exportPowerpoint && (
                <Tooltip title={t('powerpointExportTooltip')}>
                  <StyledLinkButton
                    onClick={exportPowerpoint}
                    data-icom={'report-export-powerpoint-button'}
                    variant="outline-primary"
                    disabled={isOrganizationLevelFilterOn}
                  >
                    <Download height={22} width={22} />
                    <ButtonText>{t('powerpointExportLabel')}</ButtonText>
                  </StyledLinkButton>
                </Tooltip>
              )}
            </span>
          </div>
        )}
      </p>
    </div>
  );
};

export default TopBar;
