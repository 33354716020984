import { BodyMedium, theme, LinkButton, DownChevron, UpChevron } from '@arnold/common';
import styled from '@emotion/styled';
import { forwardRef } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Filter = styled.div`
  display: flex;
  gap: ${theme.spacing.d};
`;

const StyledLinkButton = styled(LinkButton)`
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.c};
  margin-left: ${theme.spacing.d};
`;

const Pill = styled.span`
  width: ${theme.spacing.f};
  height: ${theme.spacing.f};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.typography.body.small.regular.fontSize};
  background-color: ${theme.colors.actionPrimary.default};
  color: ${theme.colors.textInverted.primary};
  line-height: ${theme.spacing.f};
  letter-spacing: normal;
`;

const getDateFilterTitle = (t: any, from?: string | null, to?: string | null) => {
  if (from && to) {
    return `${new Date(from).toLocaleDateString(i18n.language)} - ${new Date(to).toLocaleDateString(i18n.language)}`;
  }

  if (from) {
    return `${t('from')} ${new Date(from).toLocaleDateString(i18n.language)}`;
  }

  if (to) {
    return `${t('to')} ${new Date(to).toLocaleDateString(i18n.language)}`;
  }

  return null;
};

type Props = {
  title: string;
  addedOnFrom?: string | null;
  addedOnTo?: string | null;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  pillNumber: number;
  showFilters: boolean;
};

export const NavbarFilter = forwardRef<HTMLDivElement, Props>(
  ({ title, addedOnFrom, addedOnTo, setShowFilters, pillNumber, showFilters }, ref) => {
    const { t } = useTranslation(['Report']);

    return (
      <Filter>
        <BodyMedium color={theme.colors.text.disabled}>{title}</BodyMedium>
        {(addedOnFrom || addedOnTo) && (
          <>
            <BodyMedium color={theme.colors.text.disabled}>{'|'}</BodyMedium>
            <BodyMedium bold>{getDateFilterTitle(t, addedOnFrom, addedOnTo)}</BodyMedium>
          </>
        )}
        <div ref={ref}>
          <StyledLinkButton
            key={showFilters.toString()}
            onClick={() => {
              setShowFilters((prevValue) => !prevValue);
            }}
          >
            <Pill>{pillNumber}</Pill>
            {t('filters')}
            {showFilters ? <UpChevron /> : <DownChevron />}
          </StyledLinkButton>
        </div>
      </Filter>
    );
  },
);
