import { useTranslation } from 'react-i18next';
import { formatText, replaceQuestionPlaceholders, theme } from '@arnold/common';
import { Fragment, FC, useRef } from 'react';
import { IFilterDescription } from '../../lib/reportHelper';
import FilterDescription, { FilterTitleType } from './FilterDescription';
import { DownloadButton } from './DownloadButton';

type ContentBoxProps = {
  header?: string;
  subHeader?: string;
  organizationName?: string;
  filterDescriptions?: IFilterDescription[];
  children: any;
  index?: number;
  disabled: boolean;
  loading?: boolean;
  boxIcomTag?: string;
  titleIcomTag?: string;
  handleDownload?: (classname?: string) => void;
  classnames?: string;
  hasFilter?: boolean;
  questionDefinitionIndex?: string | null;
};

export const ContentBox: FC<ContentBoxProps> = ({
  header,
  subHeader,
  filterDescriptions,
  children,
  index,
  disabled,
  loading,
  organizationName,
  boxIcomTag,
  titleIcomTag,
  handleDownload,
  classnames,
  hasFilter,
  questionDefinitionIndex,
}) => {
  const { t } = useTranslation('Report');
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div
        className={!header ? `mt-1 ${classnames}` : 'mt-7 '}
        style={{
          marginBottom: `${theme.spacing.c}`,
        }}
        data-icom={boxIcomTag}
        ref={contentRef}
      >
        {header && (
          <div
            className={`bg-white rounded-lg shadow-sm mb-3 px-8 py-6 d-flex justify-content-between align-items-center ${
              disabled ? 'no-data-header' : ''
            }`}
          >
            <div data-text-question-index={questionDefinitionIndex}>
              <h3 className={'mb-0'} data-icom={titleIcomTag} data-for-query-selector-question-text>
                {formatText(
                  replaceQuestionPlaceholders(
                    header,
                    organizationName || `<${t('orgNameReplacement')}>`,
                    `<${t('firstDateReplacement')}>`,
                    `<${t('teamLeaderReplacement')}>`,
                  ),
                )}
              </h3>
              <p className={'mb-0 font-size-xs'} data-for-query-selector-question-description>
                {subHeader}
                {filterDescriptions && !!filterDescriptions.length ? ` ${t('WIDGET_FILTER_RESPONDED')}` : ''}
                {filterDescriptions && !!filterDescriptions.length ? (
                  filterDescriptions.map((fd, idx) => (
                    <Fragment key={fd.answers[0].answer}>
                      <FilterDescription
                        answers={fd.answers}
                        question={replaceQuestionPlaceholders(
                          fd.question,
                          organizationName || `<${t('orgNameReplacement')}>`,
                          `<${t('firstDateReplacement')}>`,
                          `<${t('teamLeaderReplacement')}>`,
                        )}
                        filterTitleType={FilterTitleType.REPORT_WIDGET}
                        hideNumbers={loading}
                      />
                      {idx + 1 < filterDescriptions.length ? t('FILTER_SEPARATOR') : ''}
                    </Fragment>
                  ))
                ) : (
                  <></>
                )}
              </p>
            </div>
            <DownloadButton handleDownload={handleDownload} index={((index || 0) - (hasFilter ? 1 : 0))?.toString()} />
          </div>
        )}
      </div>
      <div
        className={`bg-white rounded-lg shadow-sm mb-3 p-8 ${classnames}`}
        data-chart-question-index={questionDefinitionIndex}
      >
        {children}
      </div>
    </>
  );
};
