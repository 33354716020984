import {
  pushToDataLayer,
  createEventData,
  SelectWrapper,
  selectControlStyles,
  Checkbox,
  ItemWithCheckboxContainer,
} from '@arnold/common';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { Filter, FilterOption } from '../../lib/reportHelper';
import { SelectFilterSelected } from '../../components/SelectFilterHelpers';
import auth from '../../lib/auth';
import { FilterBox } from './StyledComponents';

interface IProps {
  filters: Filter[];
  className?: string;
  options: { value: string; count: number }[];
  setFilters: (filters: Filter[]) => void;
  type: 'position' | 'recruiter' | 'manager';
}

const GlobalGenericFilter = ({ options, className, filters, setFilters, type }: IProps) => {
  const { t } = useTranslation('Report');
  const scrollPositionRef = React.useRef<number>(0);

  const selectValue = (option: string) => (e: React.MouseEvent<any, MouseEvent>) => {
    const user = auth.getUser();
    if (user) {
      pushToDataLayer({
        userId: user.id,
        orgId: user.orgId,
        event: `ux.report-filter-${type}`,
        ...createEventData('report', `filter${type}`, `report filter ${type}`),
      });
    }

    const filtersCopy = [...filters];

    let filter = filtersCopy.find((filter) => filter.editable && filter.operator === type);
    if (!filter) {
      filter = {
        operator: type,
        editable: true,
        values: [],
      };
      filtersCopy.push(filter);
    }
    if (filter.values == null) filter.values = [];

    if (filter.values.includes(option)) {
      filter.values = filter.values.filter((value) => value !== option);
    } else {
      filter.values.push(option);
    }
    setFilters(filtersCopy);

    e.preventDefault();
  };

  const selectedOptions = (filters.find((filter) => filter.operator === type)?.values?.filter(Boolean) ||
    []) as string[];

  const CustomMenu = (props: { opt: FilterOption }) => {
    const menuRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      const handle = () => {
        if (menuRef.current) {
          scrollPositionRef.current = menuRef.current.scrollTop;
        }
      };
      if (menuRef.current) {
        menuRef.current.scrollTop = scrollPositionRef.current;
        menuRef.current.addEventListener('scroll', handle);
      }
      return () => {
        if (menuRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          menuRef.current.removeEventListener('scroll', handle);
        }
      };
    }, []);

    return (
      <components.Menu {...props}>
        <FilterBox
          ref={menuRef}
          className={'bg-white rounded-lg shadow-sm position-absolute'}
          data-cy={`report-${type}-filter-menu`}
        >
          {options.map((option) => (
            <ItemWithCheckboxContainer key={option.value}>
              <Checkbox
                selected={selectedOptions.includes(option.value)}
                onToggle={selectValue(option.value)}
                text={
                  <>
                    <span>{option.value}</span>
                    <span className={'text-muted'}>&nbsp;({option.count})</span>
                  </>
                }
                labelStyles="margin: 8px 0;display: flex;align-items: center;justify-content: space-between;"
              />
            </ItemWithCheckboxContainer>
          ))}
        </FilterBox>
      </components.Menu>
    );
  };

  const CustomPlaceholder = () => {
    return (
      <SelectFilterSelected
        emptyPlaceholder={t(`filterBy${type}`)}
        selectedAnswers={selectedOptions}
        disabled={options.length === 0}
      />
    );
  };

  return (
    <SelectWrapper
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      className={`react-select ${className || ''}`}
      components={{
        IndicatorSeparator: () => null,
        Menu: CustomMenu,
        Placeholder: CustomPlaceholder,
      }}
      styles={{
        container: (provided: any, state: any) => ({
          ...provided,
          width: '100% !important',
          margin: '0px',
        }),
        control: (provided: any, state: any) => ({
          ...selectControlStyles(provided, state),
          paddingLeft: '0',
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          display: 'flex',
        }),
      }}
      isDisabled={options.length === 0}
      isSearchable={false}
      isMulti={false}
      closeMenuOnSelect={false}
    />
  );
};

export default GlobalGenericFilter;
