import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { SendReportOpenMetricsMutationVariables, useSendReportOpenMetricsMutation } from '../../generated/hooks';
import { useSaveSysAdminFromUrl } from '../../lib/common';

declare global {
  interface Window {
    Intercom: any;
  }
}

export enum IntercomEvent {
  KeywordsSeen = 'keywords-seen',
  KeywordsFilter = 'keywords-filter',
  RespondentSummarySeen = 'respondent-summary-seen',
  RespondentSummaryOpened = 'respondent-summary-opened',
  SurveySummarySeen = 'survey-summary-seen',
  SurveySummaryOpened = 'survey-summary-opened',
  SurveySummaryFullyLoadedSeen = 'survey-summary-fully-loaded-seen',
  SurveySummaryCompleteToastClicked = 'survey-summary-complete-toast-clicked',
  PhaseSummarySeen = 'phase-summary-seen',
  PhaseSummaryOpened = 'phase-summary-opened',
  PhaseSummaryFullyLoadedSeen = 'phase-summary-fully-loaded-seen',
  PhaseSummaryCompleteToastClicked = 'phase-summary-complete-toast-clicked',
}

interface IntercomEventSenderProps {
  eventName: IntercomEvent;
  data?: unknown;
}

/**
 * This component sends an event to Intercom when it becomes visible on the screen.
 **/
export const IntercomEventSender: React.FC<IntercomEventSenderProps> = ({ eventName, data }) => {
  const callback = useCallback(() => {
    sendEventToIntercom(eventName, data);
  }, [eventName, data]);
  return <ObserverWithCallback callback={callback} />;
};

export const ReportOpenMetricsSender = (metrics: Omit<SendReportOpenMetricsMutationVariables, 'sysAdmin'>) => {
  const [sendMetrics] = useSendReportOpenMetricsMutation();
  const sysAdmin = useSaveSysAdminFromUrl(metrics.reportAccessKey);
  const callback = useCallback(
    () => sendMetrics({ variables: { ...metrics, sysAdmin } }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendMetrics, ...Object.values(metrics), sysAdmin],
  );
  if (metrics.channelIds.length === 0) return null;
  return <ObserverWithCallback callback={callback} />;
};

/**
 * Component that observes when it becomes visible on the screen and calls the callback.
 * Works only for the first time it becomes visible.
 */
export const ObserverWithCallback = ({ callback }: { callback: () => void }) => {
  const [alreadyCalled, setAlreadyCalled] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (alreadyCalled || !ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        // check if the element is visible on the screen
        if (entries[0].isIntersecting) {
          callback();
          setAlreadyCalled(true);
        }
      },
      { threshold: 0.5 },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [alreadyCalled, callback]);

  return alreadyCalled ? null : <OnePixDiv ref={ref} />;
};

const OnePixDiv = styled.div`
  width: 1px;
  height: 1px;
  visibility: hidden;
`;

export const sendEventToIntercom = (eventName: IntercomEvent, data?: unknown) => {
  if (window.Intercom) {
    window.Intercom('trackEvent', eventName, data);
  } else {
    // for testing environments without Intercom
    console.log(`Intercom event: ${eventName}`, data);
  }
};
