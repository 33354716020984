import { formatText } from '@arnold/common';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { getLocale } from '@arnold/core';
import { getQuestionDefinitionByIndex } from '../../lib/reportHelper';
import { IReport } from '../../lib/reports/base';
import { AnonymityLevel, ReportSequenceRespondentDetailQueryQuery } from '../../generated/hooks';
import { HighlightedAnswersIcon } from './HighlightedAnswerIcon';
import { Answer, IMessage } from './RespondentsAnswers';

interface IProps {
  messages: IMessage[];
  report: IReport | ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail'];
  name: string;
  team: string;
  startsAt?: Date | null;
  endsAt: Date | null;
  notFinished?: boolean;
  allowedAnonymities?: AnonymityLevel[];
}

const Conversation = ({ messages, report, team, name, startsAt, endsAt, notFinished, allowedAnonymities }: IProps) => {
  const { t } = useTranslation('Report');
  return (
    <>
      <div className={'bg-white rounded-lg shadow-sm px-8 py-6 mb-1'} data-cy="report-answers-header">
        <h4>{name}</h4>
        <p className={'font-size-xs mb-0'}>
          {startsAt && <span>{format(new Date(startsAt), 'PPPp', { locale: getLocale(i18n.language) })}</span>}
          {endsAt && (
            <span>
              {' '}
              {startsAt ? ' - ' : ''}
              {format(endsAt, 'PPPp', { locale: getLocale(i18n.language) })}
            </span>
          )}

          <span className={startsAt || endsAt ? 'ml-6' : ''}>{team}</span>
        </p>
      </div>
      <div className={'bg-white rounded-lg shadow-sm px-8 py-8'} data-cy="report-answers-body">
        {messages &&
          messages.map((message) => {
            const qd = getQuestionDefinitionByIndex(report, message.questionDefinitionIndex);
            return (
              <div key={message.id} className={`mb-7`} data-cy="report-answers-item" id={message.id}>
                <div className={`mb-4 ml-6 text-muted font-size-xs`} title={message.question}>
                  {formatText(message.question)}
                </div>
                <Answer
                  className={`mb-0 rounded-lg`}
                  style={{
                    minWidth: '361px',
                    maxWidth: '576px',
                    width: 'fit-content',
                  }}
                >
                  {qd && qd.type === 'SELECT' ? (
                    <ul className={'red-list-ul'}>
                      {message.answers.map((ans, index) => (
                        <li key={ans.text} className={'d-flex'}>
                          <HighlightedAnswersIcon type={ans.type} />
                          <div>{ans.text.trim()}</div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span className={'pl-6'}>{message.answers.map((answer) => answer.text).join('')}</span>
                  )}
                </Answer>
              </div>
            );
          })}
        {messages.length === 0 && (
          <span className={'no-data'}>
            {t(
              notFinished
                ? 'withoutResponseForNow'
                : allowedAnonymities?.includes(AnonymityLevel.Organization)
                  ? 'anonymousResponse'
                  : 'noResponse',
            )}
          </span>
        )}
      </div>
    </>
  );
};

export default Conversation;
