import styled from '@emotion/styled/macro';
import { forwardRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { DateInput } from '@arnold/common';
import { TopicGroupTypeCode } from '../../../generated/hooks';
import { Filter, FilterOption, filterReport } from '../../../lib/reportHelper';
import { AggregatedReportDataContext } from '../../../lib/reports/aggregatedReportDataContext';
import { IReport } from '../../../lib/reports/base';
import GlobalAnswerFilter from '../GlobalAnswerFilter';
import GlobalCompareSelect from '../GlobalCompareSelect';
import GlobalGenericFilter from '../GlobalGenericFilter';
import GlobalTeamFilter from '../GlobalTeamFilter';
import { FiltersContainer } from '../StyledComponents';
import { GlobalSourceFilter } from './GlobalSourceFilter';

interface IProps {
  filters: Filter[];
  options: FilterOption[];
  setFilters: (filters: Filter[]) => void;
  clearWidgets: any;
  rootTeams: string[];
  organizationName: string;
  organizationDepth: number;
  respondentId?: string | null;
  report: IReport;
  unfilteredReport: IReport;
  className?: string;
  onConfirm: () => void;
  addedOnFrom?: string | null;
  addedOnTo?: string | null;
  setAddedOnTo: (value: string | null) => void;
  setAddedOnFrom: (value: string | null) => void;
}

const FilterWrap = styled.div`
  min-width: 200px;
  max-width: calc((100% - 2rem) / 3);
  width: 300px;
  flex-grow: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const StyledDateInput = styled(DateInput)`
  min-width: 200px;
  width: 100%;
  max-width: 300px;
  flex-grow: 1;
  margin-right: 1rem;
  flex: 1;
`;

export const GlobalFilter = forwardRef<HTMLDivElement, IProps>(
  (
    {
      options,
      filters,
      setFilters,
      rootTeams,
      organizationName,
      organizationDepth,
      respondentId,
      report,
      unfilteredReport,
      onConfirm,
      className,
      addedOnFrom,
      addedOnTo,
      setAddedOnTo,
      setAddedOnFrom,
    },
    ref,
  ) => {
    const { t } = useTranslation('ReportSequence');

    const aggregatedData = useContext(AggregatedReportDataContext);

    const isHiring = report.surveyGroup?.topicGroup.typeCode === TopicGroupTypeCode.Hiring;
    const [positionsFilterOptions, recruiterFilterOptions, managersFilterOptions] = useMemo(() => {
      if (!isHiring) return [[], [], []];

      const positionsFilterOptions: { value: string; count: number }[] = [];
      const recruiterFilterOptions: { value: string; count: number }[] = [];
      const managersFilterOptions: { value: string; count: number }[] = [];

      if (report.additionalRespondentData && report.additionalRespondentData.length > 0) {
        // we need to filter the additional respondent data for each filter type - but without the filter that we are currently building
        // the reason is to display in each filter only the options that are available in combination with already applied filters
        const filteredReportForPosition = filterReport(
          unfilteredReport,
          filters.filter((f) => f.operator !== 'position'),
        );
        const channelIdsForPosition = filteredReportForPosition.responses?.map((ch) => ch.id) || [];
        const filteredRespondentDataForPosition = report.additionalRespondentData.filter((respondentData) =>
          channelIdsForPosition.includes(respondentData.channelId),
        );

        const filteredReportForRecruiter = filterReport(
          unfilteredReport,
          filters.filter((f) => f.operator !== 'recruiter'),
        );
        const channelIdsForRecruiter = filteredReportForRecruiter.responses?.map((ch) => ch.id) || [];
        const filteredRespondentDataForRecruiter = report.additionalRespondentData.filter((respondentData) =>
          channelIdsForRecruiter.includes(respondentData.channelId),
        );

        const filteredReportForManager = filterReport(
          unfilteredReport,
          filters.filter((f) => f.operator !== 'manager'),
        );
        const channelIdsForManager = filteredReportForManager.responses?.map((ch) => ch.id) || [];
        const filteredRespondentDataForManager = report.additionalRespondentData.filter((respondentData) =>
          channelIdsForManager.includes(respondentData.channelId),
        );

        filteredRespondentDataForPosition.forEach((respondent) => {
          if (respondent.position) {
            const position = positionsFilterOptions.find((option) => option.value === respondent.position);
            if (position) {
              position.count++;
            } else {
              positionsFilterOptions.push({ value: respondent.position, count: 1 });
            }
          }
        });
        filteredRespondentDataForRecruiter.forEach((respondent) => {
          if (respondent.recruiter) {
            const recruiter = recruiterFilterOptions.find((option) => option.value === respondent.recruiter);
            if (recruiter) {
              recruiter.count++;
            } else {
              recruiterFilterOptions.push({ value: respondent.recruiter, count: 1 });
            }
          }
        });
        filteredRespondentDataForManager.forEach((respondent) => {
          respondent.managers?.forEach((manager) => {
            const managerOption = managersFilterOptions.find((option) => option.value === manager);
            if (managerOption) {
              managerOption.count++;
            } else {
              managersFilterOptions.push({ value: manager, count: 1 });
            }
          });
        });
      }

      positionsFilterOptions.sort((a, b) => a.value.localeCompare(b.value));
      recruiterFilterOptions.sort((a, b) => a.value.localeCompare(b.value));
      managersFilterOptions.sort((a, b) => a.value.localeCompare(b.value));

      return [positionsFilterOptions, recruiterFilterOptions, managersFilterOptions];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(report.additionalRespondentData), JSON.stringify(filters), !!report.responses]);
    return (
      <div className={`bg-white rounded-lg shadow-sm px-8 py-6 ${className}`} ref={ref}>
        <FiltersContainer>
          {report.surveyGroup && (
            <FilterWrap data-icom={'report-date-filter-from'}>
              <StyledDateInput
                fromYear={new Date(report.sequenceFrom)}
                toYear={new Date(report.sequenceTo)}
                value={addedOnFrom}
                onDateChange={setAddedOnFrom}
                dataCy="report-date-filter"
                baseTranslationPath="Report"
                placeholder={t('Report:dateFilterPlaceholder')}
                label={t(
                  report.surveyGroup?.topicGroup.typeCode === TopicGroupTypeCode.Onboarding
                    ? 'Report:labelAddedOnFromOnb'
                    : 'Report:labelAddedOnFrom',
                )}
              />
            </FilterWrap>
          )}
          {report.surveyGroup && (
            <FilterWrap data-icom={'report-date-filter-to'}>
              <StyledDateInput
                fromYear={new Date(report.sequenceFrom)}
                toYear={new Date(report.sequenceTo)}
                value={addedOnTo}
                onDateChange={setAddedOnTo}
                dataCy="report-date-filter"
                baseTranslationPath="Report"
                placeholder={t('Report:dateFilterPlaceholder')}
                label={t(
                  report.surveyGroup?.topicGroup.typeCode === TopicGroupTypeCode.Onboarding
                    ? 'Report:labelAddedOnToOnb'
                    : 'Report:labelAddedOnTo',
                )}
              />
            </FilterWrap>
          )}
          <FilterWrap data-icom={'report-answer-filter'} data-cy={'report-section-filter'}>
            <GlobalAnswerFilter
              options={options}
              setFilters={setFilters}
              filters={filters}
              className="same-size figure"
              organizationName={organizationName}
            />
          </FilterWrap>
          <FilterWrap data-icom={'report-teams-filter'} data-cy={'report-section-filter'}>
            <GlobalTeamFilter
              rootTeamsIds={rootTeams}
              setFilters={setFilters}
              respondentId={respondentId}
              filters={filters}
              aggregatedData={aggregatedData}
              className="same-size figure"
            />
          </FilterWrap>
          <FilterWrap data-icom={'report-organizaton-level-filter'} data-cy={'report-section-filter'}>
            <GlobalCompareSelect
              setFilters={setFilters}
              filters={filters}
              organizationDepth={organizationDepth}
              aggregatedData={aggregatedData}
              className="same-size figure no-margin"
            />
          </FilterWrap>
          {report?.source && report.source.length > 0 && (
            <FilterWrap data-icom={'report-source-filter'}>
              <GlobalSourceFilter setFilters={setFilters} filters={filters} report={report} />
            </FilterWrap>
          )}
          {isHiring &&
            (positionsFilterOptions.length > 0 ||
              recruiterFilterOptions.length > 0 ||
              managersFilterOptions.length > 0) && (
              <>
                <FilterWrap data-icom={'report-position-filter'}>
                  <GlobalGenericFilter
                    options={positionsFilterOptions}
                    setFilters={setFilters}
                    filters={filters}
                    type="position"
                    className="same-size figure"
                  />
                </FilterWrap>
                <FilterWrap data-icom={'report-recruiter-filter'}>
                  <GlobalGenericFilter
                    options={recruiterFilterOptions}
                    setFilters={setFilters}
                    filters={filters}
                    type="recruiter"
                    className="same-size figure"
                  />
                </FilterWrap>
                <FilterWrap data-icom={'report-manager-filter'}>
                  <GlobalGenericFilter
                    options={managersFilterOptions}
                    setFilters={setFilters}
                    filters={filters}
                    type="manager"
                    className="same-size figure"
                  />
                </FilterWrap>
              </>
            )}
        </FiltersContainer>

        <ButtonsContainer>
          <Button
            variant="outline-primary"
            data-cy={`report-filter-refresh`}
            data-icom={'report-filter-refresh'}
            onClick={() => onConfirm()}
          >
            {t('refresh')}
          </Button>
        </ButtonsContainer>
      </div>
    );
  },
);
