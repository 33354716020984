import React from 'react';

interface ILocalFilterContext {
  highlightedAnswerIndex: null | string;
  setHighlightedAnswerIndex: (a: null | string) => void;
}

export const LocalFilterContext = React.createContext<ILocalFilterContext>({
  highlightedAnswerIndex: null,
  setHighlightedAnswerIndex: () => undefined,
});
