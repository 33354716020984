import { gql } from '@apollo/client';
import { QuestionDefinitionFragment } from './fragments';

const messagesQuery = gql`
  query getMessages($channelId: ID!) {
    messages(channelId: $channelId) {
      id
      text
      createdAt
      isLast
      createdBy
      ... on SelectAnswer {
        value
        question {
          definition {
            selectedRange
          }
        }
      }
      anonymityLevel
      ... on Question {
        definition {
          ...QuestionDefinition
          optional
        }
      }
    }
  }
  ${QuestionDefinitionFragment}
`;

const channelQuery = gql`
  query getChannel($channelId: ID!) {
    channel(channelId: $channelId) {
      id
      anonymityLevel
      anonymityLocked
      isFinished
      isOpen
      createdAt
      endsAt
      topicName
      allowedLanguages {
        id
        code
      }
      allowedAnonymities
      respondent {
        firstname
        surname
        user {
          username
        }
        contacts {
          value
          type
          source
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

const channelSlugForIdNumberQuery = gql`
  query getChannelSlugForIdNumber($surveyId: ID!, $respondentIdNo: String!) {
    getChannelSlugForIdNumber(surveyId: $surveyId, respondentIdNo: $respondentIdNo)
  }
`;

const isAccessTokenValidQuery = gql`
  query isAccessTokenValid($accessToken: String!) {
    isAccessTokenValid(accessToken: $accessToken) {
      expireAt
      status
      consent
      user {
        id
        username
        firstname
        systemRole
        surname
        organization {
          id
          name
          systemName
          primaryLanguageCode
        }
        respondents {
          id
          firstname
          surname
          organizationRole
          language {
            id
            code
          }
          contacts {
            value
            type
            source
          }
          channels {
            id
            isOpen
            isFinished
            createdAt
            sendAt
            endsAt
            topicName
            surveyFinished
            disabled
          }
          reports {
            accessKey
            survey {
              sendAt
              endsAt
            }
            topicName
            surveyGroup {
              from
              to
            }
          }
          organization {
            id
            name
            systemName
            productVersionHistory {
              product
              licenceCount
              validFrom
              validTo
            }
            companyId
            primaryLanguageCode
            languages {
              code
            }
          }
        }
      }
    }
  }
`;

const reportSequenceRespondentDetailQuery = gql`
  query ReportSequenceRespondentDetailQuery(
    $languageCode: String
    $accessToken: String!
    $publicAccess: Boolean
    $respondentId: ID!
  ) {
    getProcessReportRespondentDetail(
      languageCode: $languageCode
      accessToken: $accessToken
      publicAccess: $publicAccess
      respondentId: $respondentId
    ) {
      id
      identifier
      groupedRespondents {
        respondents {
          id
          preferredContactType
          firstname
          surname
          deleted
          contacts {
            type
            source
            value
            phoneNumber {
              countryCallingCode
              nationalNumber
            }
          }
        }
        addedOn
        surveys {
          id
          status
          sendAt
          endsAt
          topicGroup {
            id
          }
          publicChannels {
            id
            respondentId
            lastRemindedAt
            maxAnonymousHighlightedLevel
            withAnswer
            finishedAt
          }
          respondentIds
        }
        undeliveredInvitations {
          respondentId
          undeliveredSurveyInvitations {
            surveyId
            contact
            status
          }
        }
        metricQuestionsForRespondent {
          respondentId
          answers {
            topicGroupId
            value
          }
        }
        deletedRespondents
      }
      questions {
        id
        index
        text
        type
        selectedRange
        answersLocal
        reportIndex
        answersToHighlight
        metrics
        topicGroupId
      }
      responses {
        id
        surveyId
        respondentId
        topicGroupId
        anonymous
        originalAnonymous
        finished
        additionallyAnonymized
        messages {
          id
          questionDefinitionIndex
          text
          value
          anonymous
          originalAnonymous
          date
          hasAlert
        }
      }
      respondents {
        id
        firstname
        surname
        addedOn
      }
      reportRoots
      keyPoints {
        answerIds
        point
        sentiment
      }
      surveyGroup {
        id
        from
        to
        topicGroup {
          id
          hideOnboardingParts
          translations {
            id
            language {
              code
              id
            }
            value
            description
          }
        }
        steps {
          daysFromStart
          hasMetricQuestion
          status
          hasNotStarted
          notstartedReason
          surveys {
            id
            sendAt
            endsAt
            seqDate
            status
          }
          processStep {
            id
            startOffsetMins
            reminders {
              reminderOffsetMins
            }
            endOffsetMins
            stepTopicGroup {
              allowedAnonymities
              id
              translations {
                id
                language {
                  code
                  id
                }
                value
                description
              }
            }
          }
        }
      }
      metadata {
        surveyEnd
        surveyStart
        status
        accepted
        organization {
          id
          name
          featureNames
          config {
            countryCallingCode
          }
        }
        language {
          id
          code
        }
        respondent {
          id
          firstname
          surname
          createdAt
          contacts {
            type
            value
            source
          }
          intercomHash
          organizationRole
          organization {
            id
            name
            companyId
            featureNames
          }
          user {
            id
            username
            firstname
            surname
            intercomHash
            createdAt
            systemRole
            respondents {
              id
              firstname
              surname
              organizationRole
            }
          }
        }
        adminEmails
        productVersion {
          validFrom
          validTo
          product
        }
      }
      teams {
        id
        teamName
        directMembers
        memberTeams
        teamLeader
      }
    }
  }
`;

const surveyPublicInfoQuery = gql`
  query surveyPublicInfo($surveyId: ID!, $langCode: String) {
    surveyPublicInfo(surveyId: $surveyId, langCode: $langCode) {
      id
      topicGroupName
      organizationName
      endsAt
      status
    }
  }
`;

const getComparsionDataForProcessReport = gql`
  query getComparsionDataForProcessReport(
    $accessToken: String!
    $periods: [DateRangeInput!]!
    $teamsFilter: [String!]
  ) {
    getComparsionDataForProcessReport(accessToken: $accessToken, periods: $periods, teamFilters: $teamsFilter) {
      id
      stepStatistics {
        periodIndex
        stepId
        avgMetric
        statistics {
          withoutProblems {
            denominator
            numerator
          }
          responseRate {
            numerator
            denominator
          }
          anonymityRate {
            numerator
            denominator
          }
          finishedRate {
            numerator
            denominator
          }
        }
      }
    }
  }
`;

const surveyGroupRespondents = gql`
  query getProcessReportRespondents(
    $accessToken: String!
    $addedOnFrom: Date
    $addedOnTo: Date
    $teamsFilter: [String!]
    $pagination: Pagination
    $searchQuery: String
    $withoutDeleted: Boolean
  ) {
    getProcessReportRespondents(
      accessToken: $accessToken
      addedOnFrom: $addedOnFrom
      addedOnTo: $addedOnTo
      teamFilters: $teamsFilter
      pagination: $pagination
      searchQuery: $searchQuery
      withoutDeleted: $withoutDeleted
    ) {
      data {
        metricQuestionsForRespondent {
          respondentId
          answers {
            topicGroupId
            value
          }
        }
        respondents {
          id
          preferredContactType
          firstname
          surname
          deleted
          contacts {
            type
            source
            value
            phoneNumber {
              countryCallingCode
              nationalNumber
            }
          }
        }
        addedOn
        surveys {
          id
          status
          sendAt
          endsAt
          topicGroup {
            id
          }
          respondentIds
          publicChannels {
            id
            respondentId
            lastRemindedAt
            maxAnonymousHighlightedLevel
            withAnswer
            finishedAt
          }
        }
        deletedRespondents
        undeliveredInvitations {
          respondentId
          undeliveredSurveyInvitations {
            surveyId
            contact
            status
          }
        }
      }
      totalCount
    }
  }
`;

const lastMessagesForRespondent = gql`
  query lastMessagesForRespondent($surveyIds: [ID!]!, $respondentIds: [ID!]!, $accessToken: String) {
    lastMessagesForRespondent(surveyIds: $surveyIds, respondentIds: $respondentIds, accessToken: $accessToken) {
      id
      messageStatus
      processed
      createdAt
      error
      surveyId
      respondentId
    }
  }
`;

export {
  channelQuery,
  channelSlugForIdNumberQuery,
  getComparsionDataForProcessReport,
  lastMessagesForRespondent,
  isAccessTokenValidQuery,
  messagesQuery,
  reportSequenceRespondentDetailQuery,
  surveyGroupRespondents,
  surveyPublicInfoQuery,
};
