import { BodyMedium, getFrontendConfigValue } from '@arnold/common';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  type: string;
}

declare global {
  interface Window {
    Intercom: any;
  }
}
const RateAi: React.FC<Props> = ({ type }) => {
  const { t, i18n } = useTranslation('aiRating');
  const [alreadyRated, setAlreadyRated] = React.useState(false);

  const surveyId = getFrontendConfigValue(`${type}_${i18n.language.toUpperCase()}`);
  if (!surveyId || alreadyRated) {
    return null;
  }
  return (
    <div className="d-flex justify-content-end align-items-center mt-8 mb-8">
      <BodyMedium className="mr-4">{t('rateText')}</BodyMedium>{' '}
      <Button
        onClick={() => {
          setAlreadyRated(true);
          window.Intercom?.('startSurvey', parseInt(surveyId, 10));
        }}
        variant="outline-primary"
      >
        {t('rateButton')}
      </Button>
    </div>
  );
};

export default RateAi;
