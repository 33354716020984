import { contains } from 'ramda';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../components/LoadingComponent';
import { ReportSubscriptionSubscription, SurveyStatus } from '../../generated/hooks';
import {
  Filter,
  IFilterDescription,
  WidgetType,
  addWidgets,
  filterReport,
  getFilterDescriptions,
  getSectionInfo,
  groupWidgetsIntoGroups,
} from '../../lib/reportHelper';
import { IReport, WidgetFilters } from '../../lib/reports/base';
import { Error } from './Error';
import Footer from './Footer';
import { ReportMetrics } from './ReportMetrics';
import ReportSection from './ReportSection';
import { ReportWidgetGroup } from './ReportWidgetGroup';

interface IReportPageProps {
  accessToken: string;
  topicGroupId?: string;
  unitFilters?: string;
  report: IReport;
  publicAccess?: boolean; // publicAccess is false when org admins or sys admins are viewing reports
}

const AnonymousReport = (props: IReportPageProps) => {
  const { t } = useTranslation(['Report', 'header']);
  const filters: Filter[] = [
    { operator: 'empty-widgets', values: [] },
    {
      operator: 'anonymity-filter',
      values: [],
    },
  ];
  const [widgets, setWidgets] = useState<WidgetType[]>([]);
  const [widgetFilters, setWidgetFilters] = useState<WidgetFilters>({});

  const loadingRef = useRef() as MutableRefObject<any>;

  const setLoading = (value: boolean = true) => {
    if (!loadingRef || !loadingRef.current) {
      return;
    }
    loadingRef.current.setLoading(value);
  };

  useEffect(() => {
    setLoading(false);
  });
  const isWithoutResponses = !props.report.responses;

  const report = isWithoutResponses ? props.report : filterReport(addWidgets(props.report, widgets), [...filters]);

  const handleAddWidgets = (newWidgets: WidgetType[]) => {
    setLoading();
    const notInWidgets = (specifiedWidgets: WidgetType[]) => (widget: WidgetType) =>
      !contains(
        widget.widget.id,
        specifiedWidgets.map((w) => w.widget.id),
      );

    const removedWidgets = widgets.filter(notInWidgets(newWidgets));
    const addedWidgets = newWidgets.filter(notInWidgets(widgets));

    setTimeout(() => setWidgets([...removedWidgets, ...addedWidgets]), 60);
  };

  const removeWidgets = (widgetIds: string[]) => () => {
    setLoading();
    const removedWidgets = widgets.filter((w) => contains(w.widget.id, widgetIds));
    setTimeout(
      () =>
        setWidgets(
          widgets
            .filter((widget) => !contains(widget.widget.id, widgetIds))
            .map((widget) =>
              contains(widget.afterWidgetId, widgetIds)
                ? {
                    ...widget,
                    afterWidgetId: removedWidgets ? removedWidgets[0].afterWidgetId : widget.afterWidgetId,
                  }
                : widget,
            ),
        ),
      60,
    );
  };

  const filteredReportSections = (report.reportDefinition?.sections || []).filter(
    (section) => section.widgets && section.widgets.length > 0,
  );

  if (!report.reportDefinition) {
    return <div>{t('noDataInfo')}</div>;
  }

  if (props.publicAccess && !props.report.metadata?.accepted) {
    return <Error>{t('errorLoading')}</Error>;
  }
  return (
    <>
      {![SurveyStatus.Archived, SurveyStatus.Anonymized].includes(report.metadata?.status!) && (
        <section className={'section'}>
          <div className={'container container--md'}>
            {filteredReportSections.map(
              (
                section: NonNullable<
                  NonNullable<ReportSubscriptionSubscription['getReport']['reportDefinition']>['sections']
                >[0],
                i,
              ) => {
                const sectionReport = isWithoutResponses ? report : filterReport(report, section.displayFilters);
                const groupedWidgets =
                  section.widgets !== null ? groupWidgetsIntoGroups(section.widgets!, widgets) : [];
                const sectionInfo = getSectionInfo(section, t);
                return (
                  <ReportSection
                    id={section.id}
                    key={`section_${section.id}`}
                    header={sectionInfo.headingLocal}
                    subHeader={sectionInfo.descriptionLocal}
                    loading={isWithoutResponses}
                    filterDescriptions={
                      getFilterDescriptions(section.titleFilters || [], sectionReport) as IFilterDescription[]
                    }
                    icomTag={'report-section-title'}
                    sectionHeading={section.heading}
                  >
                    {groupedWidgets.map((widgetGroup, i) => {
                      const firstWidget = widgetGroup.widgets[0];
                      const index = firstWidget.questionDefinitionIndex || '';
                      return (
                        <>
                          <ReportWidgetGroup
                            key={`subsection_${firstWidget.id}`}
                            widgets={widgetGroup.widgets}
                            section={section}
                            loading={isWithoutResponses}
                            sectionReport={sectionReport}
                            originalReport={report}
                            widgetFilters={widgetFilters[index]}
                            setWidgetFilters={(newFilters: string[]) => {
                              setWidgetFilters((oldFilters) => ({
                                ...oldFilters,
                                [index]: newFilters,
                              }));
                            }}
                            globalFilters={filters}
                            addedWidgets={widgets}
                            setWidgets={handleAddWidgets}
                            removeWidgets={removeWidgets}
                            baseIndex={widgetGroup.baseIndex}
                            maxIndex={20 * ((section.widgets || []).length + widgets.length)}
                            accessToken={props.accessToken}
                          />
                          {props.publicAccess && (
                            <ReportMetrics
                              questionsSeen={i + 1}
                              questionsTotal={groupedWidgets.length}
                              reportAccessKey={props.accessToken}
                              topicGroupId={props.topicGroupId}
                            />
                          )}
                        </>
                      );
                    })}
                  </ReportSection>
                );
              },
            )}
          </div>
        </section>
      )}
      <Footer />
      <LoadingComponent ref={loadingRef} />
    </>
  );
};

export default AnonymousReport;
