import {
  BodyMedium,
  BodySmall,
  Check,
  No,
  Pagination,
  SearchInput,
  Table,
  ToggleButton,
  Tooltip,
  theme,
} from '@arnold/common';
import { getEncodedCryptoAES, removeAccents } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { URLs } from '../../lib/common';
import { ANONYMOUS_RESPONDENT_ID } from '../../lib/reportHelper';
import { AnswerType } from '../../lib/reports/question';
import HighlightedAnswersIcon from './HighlightedAnswerIcon';

interface IProps {
  data: IAlertBlock[];
  search: string;
  accessToken: string;
  anonymityEnabled: boolean;
  publicAccess: boolean;
  reportId: string;
}

export interface IAnswer {
  text: string;
  type: AnswerType;
}

export interface IMessage {
  id: string;
  question: string;
  answers: IAnswer[];
  date: Date;
  questionDefinitionIndex: string;
}

export interface IAlertBlock {
  respondentId: string;
  topicGroupId: string;
  surveyId: string;
  name: string;
  date: Date;
  team: string;
  messages: IMessage[];
}

export const TYPE_CLASS_MAPPING = {
  [AnswerType.NEUTRAL]: 'blue-list',
  [AnswerType.WARNING]: 'orange-list',
  [AnswerType.ALERT]: 'red-list',
};

const LS_KEY_WITHOUT_ANSWER = 'hideRespondentsWithoutAnswer';
const LS_KEY_WITH_WARNING = 'hideRespondentsWithWarning';
const RESPONDENTS_ON_PAGE = 10;
const StyledSearchInput = styled(SearchInput)`
  width: 270px;
`;

type TableProps = {
  containsAnonymity: boolean;
};
const StyledTable = styled(Table)`
  tr:first-child {
    background-color: ${theme.colors.backgroundBasic.default};
  }
  tr:nth-child(3) {
    border-top: 1px solid
      ${({ containsAnonymity }: TableProps) =>
        containsAnonymity ? theme.colors.text.disabled : theme.colors.borderSeparator.default};
  }
`;

type IconContainerProps = {
  bg: string;
  fill?: string;
};
const CheckContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${(p: IconContainerProps) => p.bg};

  & path {
    fill: ${(p: IconContainerProps) => p.fill};
  }
`;

const PaginationContainer = styled.div`
  padding: 0 ${theme.spacing.e} ${theme.spacing.e} ${theme.spacing.e};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RespondentsAnswers = ({ data, accessToken, search, anonymityEnabled, publicAccess, reportId }: IProps) => {
  const { t } = useTranslation('Report');
  const { url } = useRouteMatch();
  const history = useHistory();

  const savedValueWithoutAnswer = localStorage.getItem(`${LS_KEY_WITHOUT_ANSWER}:${accessToken}`);
  const savedValueWithWarning = localStorage.getItem(`${LS_KEY_WITH_WARNING}:${accessToken}`);
  const [filterWithoutAnswer, setFilterWithoutAnswer] = useState(
    savedValueWithoutAnswer && !anonymityEnabled ? JSON.parse(savedValueWithoutAnswer) : false,
  );
  const [filterWithWarning, setFilterWithWarning] = useState(
    savedValueWithWarning ? JSON.parse(savedValueWithWarning) : false,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangeSearchQuery = (value: string) => {
    if (currentPage !== 1 && value !== searchQuery) {
      setCurrentPage(1);
    }
    setSearchQuery(value);
  };

  const handleToggleWithoutAnswer = (value: boolean) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setFilterWithoutAnswer(value);
    localStorage.setItem(`${LS_KEY_WITHOUT_ANSWER}:${accessToken}`, value.toString());
    if (filterWithWarning && value) {
      setFilterWithWarning(false);
      localStorage.setItem(`${LS_KEY_WITH_WARNING}:${accessToken}`, 'false');
    }
  };
  const handleToggleWithWarning = (value: boolean) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setFilterWithWarning(value);
    localStorage.setItem(`${LS_KEY_WITH_WARNING}:${accessToken}`, value.toString());
    if (filterWithoutAnswer && value) {
      setFilterWithoutAnswer(false);
      localStorage.setItem(`${LS_KEY_WITHOUT_ANSWER}:${accessToken}`, 'false');
    }
  };

  const baseUrlForAlerts = `${url}${url.endsWith('/') ? '' : '/'}${URLs.REPORT_ALERT_DETAIL}`;

  if (data.length === 0) {
    return <></>;
  }

  const filteredRows = data.filter((row) => {
    if (filterWithWarning && !row.messages.some((msg) => msg.answers.some((ans) => ans.type !== AnswerType.NEUTRAL))) {
      return false;
    }
    if (filterWithoutAnswer && row.messages.length > 0) {
      return false;
    }
    if (anonymityEnabled && row.messages.length === 0) {
      return false;
    }
    if (searchQuery) {
      const searchWords = searchQuery.split(' ');
      return searchWords.every((word) => {
        const sanitizedWord = removeAccents(word).toLowerCase();
        return (
          removeAccents(row.team).toLowerCase().includes(sanitizedWord) ||
          removeAccents(row.name).toLowerCase().includes(sanitizedWord)
        );
      });
    }
    return true;
  });

  const rows = filteredRows
    .slice((currentPage - 1) * RESPONDENTS_ON_PAGE, (currentPage - 1) * RESPONDENTS_ON_PAGE + RESPONDENTS_ON_PAGE)
    .map((alert) => {
      const noWarning = alert.messages.filter((msg) =>
        msg.answers.some((ans) => ans.type === AnswerType.WARNING),
      ).length;
      const noAlerts = alert.messages.filter((msg) => msg.answers.some((ans) => ans.type === AnswerType.ALERT)).length;
      return {
        id: alert.respondentId,
        name: (
          <BodySmall color={theme.colors.actionPrimary.default} bold>
            <Highlighter
              highlightStyle={{
                fontWeight: '900',
                backgroundColor: 'transparent',
                color: theme.colors.actionPrimary.active,
                textDecoration: 'underline',
                padding: 0,
              }}
              textToHighlight={alert.name}
              searchWords={searchQuery.split(' ')}
              sanitize={removeAccents}
            />
          </BodySmall>
        ),
        team: (
          <BodySmall color={theme.colors.text.secondary}>
            <Highlighter
              highlightStyle={{
                fontWeight: '900',
                backgroundColor: 'transparent',
                color: theme.colors.actionPrimary.active,
                textDecoration: 'underline',
                padding: 0,
              }}
              textToHighlight={alert.team}
              searchWords={searchQuery.split(' ')}
              sanitize={removeAccents}
            />
          </BodySmall>
        ),
        status:
          alert.messages.length > 0 ? (
            <Tooltip title={t('withAnswer')}>
              <CheckContainer bg={theme.colors.emotionSuccess.background} fill={theme.colors.emotionSuccess.default}>
                <Check />
              </CheckContainer>
            </Tooltip>
          ) : (
            <Tooltip title={t('withoutAnswer')}>
              <CheckContainer bg={theme.colors.backgroundBasic.default}>
                <No />
              </CheckContainer>
            </Tooltip>
          ),
        answersWithWarnings: (
          <div className="d-flex align-items-center">
            {noWarning > 0 && (
              <>
                <HighlightedAnswersIcon type={AnswerType.WARNING} />{' '}
                <BodySmall color={theme.colors.text.secondary} margin={`0 ${theme.spacing.f} 0 ${theme.spacing.c}`}>
                  ({noWarning})
                </BodySmall>
              </>
            )}
            {noAlerts > 0 && (
              <>
                <HighlightedAnswersIcon type={AnswerType.ALERT} />{' '}
                <BodySmall color={theme.colors.text.secondary} margin={`0 0 0 ${theme.spacing.c}`}>
                  ({noAlerts})
                </BodySmall>
              </>
            )}
          </div>
        ),
      };
    });

  const containsAnonymity = rows[0]?.id === ANONYMOUS_RESPONDENT_ID;

  return (
    <div
      className={'mt-9'}
      style={{ position: 'relative' }}
      data-icom={'report-alerts'}
      data-cy={'report-alert-widget'}
    >
      <div className={`bg-white rounded-lg shadow-sm mb-1 px-8 py-6`}>
        <h3 className={'mb-0'} data-icom={'report-alerts-title'}>
          {t('answersByRespondents')}
        </h3>
        {containsAnonymity && (
          <p className={'mb-0 font-size-xs'}>
            <Trans
              i18nKey="Report:anonymityAnswersInfo"
              components={[
                <strong />,
                <a
                  href="https://intercom.help/arnold-robot/cs/articles/6421241-prace-s-arnoldovymi-reporty#h_4df0a074d7"
                  target="__blank"
                />,
              ]}
            />
          </p>
        )}
      </div>
      <div className={`bg-white rounded-lg shadow-sm mb-1 px-8 py-6`}>
        <div className={`d-flex justify-content-between align-items-center mb-6`}>
          <StyledSearchInput
            onChange={handleChangeSearchQuery}
            value={searchQuery}
            delay={500}
            name="respondents-search"
          />
          <div className="d-flex ml-10 mt-4">
            {!anonymityEnabled && (
              <>
                <BodyMedium margin={`0 ${theme.spacing.d} 0 0`}>{t('filterWithoutAnswer')}</BodyMedium>
                <ToggleButton
                  value={filterWithoutAnswer}
                  className="mb-0"
                  onChange={handleToggleWithoutAnswer}
                  dataIcom={'hide-respondents-without-answer-toggle'}
                  dataCy={'hide-respondents-without-answer-toggle'}
                />
              </>
            )}
            <BodyMedium margin={`0 ${theme.spacing.d} 0 ${theme.spacing.h}`}>{t('filterWithWarnings')}</BodyMedium>
            <ToggleButton
              value={filterWithWarning}
              className="mb-0"
              onChange={handleToggleWithWarning}
              dataIcom={'hide-respondents-with-warning-toggle'}
              dataCy={'hide-respondents-with-warning-toggle'}
            />
          </div>
        </div>
        <StyledTable
          containsAnonymity={containsAnonymity}
          labels={{ emptyTableLabel: t('table:emptyTableLabel') }}
          columns={[
            {
              key: 'name',
              title: t('respondent'),
              dataIndex: 'name',
            },
            {
              key: 'team',
              title: t('team'),
              dataIndex: 'team',
            },
            {
              key: 'status',
              title: t('status'),
              dataIndex: 'status',
            },
            {
              key: 'answersWithWarnings',
              title: t('answersWithWarnings'),
              dataIndex: 'answersWithWarnings',
            },
          ]}
          dataSources={rows}
          onClick={(r) => {
            history.push(`${baseUrlForAlerts}/${publicAccess ? getEncodedCryptoAES(r.id, reportId) : r.id}${search}`);
          }}
        />
        <PaginationContainer>
          <span style={{ marginTop: 20 }} data-cy="report-alerts-pagination">
            {t('paginationInfoText', {
              total: filteredRows.length,
              from: (currentPage - 1) * RESPONDENTS_ON_PAGE + 1,
              to: (currentPage - 1) * RESPONDENTS_ON_PAGE + rows.length,
            })}
          </span>
          <Pagination
            totalPages={Math.floor((filteredRows.length - 1) / RESPONDENTS_ON_PAGE) + 1}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </PaginationContainer>
      </div>
    </div>
  );
};

export const Answer = styled.div`
  min-height: 26px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
`;

export default RespondentsAnswers;
