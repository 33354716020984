import {
  Alert,
  BodyLarge,
  CallingCodeInput,
  FormErrorFeedback,
  FormGroupLabel,
  FormRadioInput,
  Modal,
  REGEXP_PHONE_NUMBER,
  Severity,
  createEventData,
  createPhoneNumberWithCallingCode,
  isEmpty,
  pushToDataLayer,
  theme,
  useToast,
} from '@arnold/common';
import styled from '@emotion/styled';
import { Form, Formik, FormikValues } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { equals } from 'ramda';
import { useState } from 'react';
import { Button, Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  ContactInput,
  ContactSource,
  ContactType,
  GetProcessReportRespondentsQuery,
  UpdateRespondentInput,
  UpdateRespondentMutation,
  useUpdateRespondentMutation,
  useVerifyPhoneNumbersMutation,
} from '../../generated/hooks';
import auth from '../../lib/auth';

type Member = GetProcessReportRespondentsQuery['getProcessReportRespondents']['data'][0]['respondents'][0];
type Props = {
  cancel: () => void;
  onComplete: () => void;
  respondent: Member;
  contact?: string;
  organizationId: string;
  accessToken: string;
  defaultContryCallingCode?: string;
  show: boolean;
};
const RoundedContent = styled.div`
  border: 1px solid ${theme.colors.borderMain.default};
  padding: ${theme.spacing.h};
  padding-bottom: 0px;
  border-radius: 8px;
  margin-top: 40px;
`;

export const handlePhoneChange = (handler: any) => (e: any) => {
  const { target } = e;
  const { value } = target;

  handler({ target });

  if (value) {
    const parsedPhoneNumber = parsePhoneNumber(value);
    if (parsedPhoneNumber) {
      handler({
        target: { name: 'callingCode', value: parsedPhoneNumber.countryCallingCode },
      });
      handler({
        target: { name: target.name, value: parsedPhoneNumber.nationalNumber },
      });
    }
  }

  if (!value) {
    handler({
      target: { name: 'preferredContactType', value: ContactType.Email },
    });
  }
};

export const handlePhoneBlur = async (cc: string, phoneNumber: string, verifyNumber: (phoneNumber: string) => void) => {
  const internationalPhoneNumber = `+${cc}${phoneNumber}`;
  if (
    /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/.test(
      internationalPhoneNumber,
    )
  ) {
    verifyNumber(internationalPhoneNumber);
  }
};

export function UpdateRespondent({
  cancel,
  onComplete,
  respondent,
  organizationId,
  contact,
  show,
  accessToken,
  defaultContryCallingCode,
}: Props) {
  const { t } = useTranslation('updateRespondentModal');
  const { addToast } = useToast();
  const [contactInfo, setContactInfo] = useState(true);

  const handleMutationCompleted = (data: UpdateRespondentMutation) => {
    addToast(
      t('updateSuccess', {
        respondentName: `${data.updateRespondentFromReport.firstname} ${data.updateRespondentFromReport.surname}`,
      }),
    );

    const user = auth.getUser();
    pushToDataLayer({
      userId: user!.id,
      orgId: organizationId,
      event: 'ux.respondent-update-success',
      ...createEventData('organization', 'updateRespondentSuccess', 'organization update respondent success'),
    });
  };

  const handleMutationError = () => {
    const user = auth.getUser();
    pushToDataLayer({
      userId: user!.id,
      orgId: organizationId,
      event: 'ux.respondent-update-error',
      ...createEventData('organization', 'updateRespondentError', 'organization update respondent error'),
    });
  };
  const [updateRespondentMutation] = useUpdateRespondentMutation({
    onCompleted: handleMutationCompleted,
    onError: handleMutationError,
  });

  const [unverifiedPhoneNumber, setUnverifiedPhoneNumber] = useState(false);
  const [verifyPhoneNumbers] = useVerifyPhoneNumbersMutation();
  const workEmail =
    respondent.contacts?.find((c) => c.source === ContactSource.Primary && c.type === ContactType.Email)?.value || '';
  const personalEmail =
    respondent.contacts?.find((c) => c.source === ContactSource.Respondent && c.type === ContactType.Email)?.value ||
    '';
  const phone = respondent.contacts?.find((c) => c.type === ContactType.Sms)?.phoneNumber?.nationalNumber || '';
  const countryCallingCode =
    respondent.contacts?.find((c) => c.type === ContactType.Sms)?.phoneNumber?.countryCallingCode ?? '';
  const RespondentFormValidationSchema = Yup.object().shape({
    primaryEmail: Yup.lazy((value: string = '') => {
      return value.startsWith('#')
        ? Yup.string().required(t('enterValidWorkEmail'))
        : Yup.string().required(t('enterValidWorkEmail')).email(t('enterValidWorkEmail'));
    }),
    secondaryEmail: Yup.string().email(t('enterValidEmail')),
    phone: Yup.string().matches(REGEXP_PHONE_NUMBER, t('enterValidPhone')),
    callingCode: Yup.string()
      .matches(/^(\+?\d{1,3})$/, t('enterValidCallingCode'))
      .when('phone', (phone, schema) => {
        return phone ? schema.required(t('enterCallingCode')) : schema;
      }),
  });

  const getContacts = (values: FormikValues) => {
    const contacts: ContactInput[] = [];
    if (workEmail !== values.primaryEmail) {
      contacts.push({
        source: ContactSource.Primary,
        contactType: ContactType.Email,
        value: values.primaryEmail,
      });
    }
    if ((personalEmail || values.secondaryEmail) && personalEmail !== values.secondaryEmail) {
      contacts.push({
        source: ContactSource.Respondent,
        contactType: ContactType.Email,
        value: values.secondaryEmail || null,
      });
    }
    if ((phone || values.phone) && (phone !== values.phone || countryCallingCode !== values.callingCode)) {
      contacts.push({
        source: ContactSource.Respondent,
        contactType: ContactType.Sms,
        value: values.phone ? createPhoneNumberWithCallingCode(values.phone, values.callingCode) : null,
      });
    }

    return contacts;
  };
  const createUpdateInput = (values: FormikValues) => {
    const updateInput: UpdateRespondentInput = {};

    Object.keys(values).forEach((key) => {
      if (
        (respondent[key as keyof Member] || respondent[key as keyof Member] === '' || key === 'disabled') &&
        respondent[key as keyof Member] !== values[key]
      ) {
        updateInput[key as keyof UpdateRespondentInput] = values[key];
      }
    });

    const contacts = getContacts(values);

    if (contacts.length > 0) {
      updateInput.contacts = contacts;
    }

    return updateInput;
  };

  const initialValues = {
    primaryEmail: workEmail,
    secondaryEmail: personalEmail,
    phone,
    callingCode: countryCallingCode || defaultContryCallingCode || '',
    preferredContactType: (respondent && respondent.preferredContactType) || ContactType.Email,
  };
  return (
    <Modal
      onHide={cancel}
      show={show}
      title={t('updateRespondentModal:title')}
      content={
        <div>
          {contactInfo && (
            <Alert severity={Severity.DANGER} onClose={() => setContactInfo(false)}>
              <Trans
                i18nKey={'updateRespondentModal:contactInfo'}
                values={{
                  contact,
                }}
                components={[<strong />]}
              />
            </Alert>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={RespondentFormValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);
                const input = createUpdateInput(values);
                if (Object.keys(input).length !== 0) {
                  await updateRespondentMutation({
                    variables: {
                      id: respondent.id,
                      input,
                      accessToken,
                    },
                  });
                }
              } catch (e) {
                setSubmitting(false);
              }
              setSubmitting(false);
              onComplete();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
              setValues,
            }) => (
              <Form onSubmit={handleSubmit} data-icom={'form-respondent'}>
                <RoundedContent>
                  <BodyLarge bold margin={`0px 0px ${theme.spacing.g}`}>
                    {respondent.firstname} {respondent.surname}
                  </BodyLarge>
                  <Row>
                    <FormGroup as={Col}>
                      <FormGroupLabel>{t('updateRespondentModal:workEmail')} (Arnold ID)</FormGroupLabel>
                      <FormControl
                        type="text"
                        name="primaryEmail"
                        value={values.primaryEmail || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.primaryEmail && !!errors.primaryEmail}
                      />
                      <FormErrorFeedback error={errors.primaryEmail} />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup as={Col}>
                      <FormGroupLabel>{t('updateRespondentModal:personalEmail')}</FormGroupLabel>
                      <FormControl
                        type="email"
                        name="secondaryEmail"
                        value={values.secondaryEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.secondaryEmail}
                      />
                      <FormErrorFeedback error={errors.secondaryEmail} />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup as={Col} sm={6}>
                      <FormGroupLabel>{t('updateRespondentModal:callingCode')}</FormGroupLabel>
                      <CallingCodeInput
                        callingCode={values.callingCode || ''}
                        onChange={(callingCode: string) => {
                          setValues(() => ({ ...values, callingCode }));
                        }}
                        isInvalid={!!errors.callingCode}
                      />
                      <FormErrorFeedback error={errors.callingCode} />
                    </FormGroup>
                    <FormGroup as={Col} sm={6}>
                      <FormGroupLabel>{t('updateRespondentModal:phone')}</FormGroupLabel>
                      <FormControl
                        type="tel"
                        name="phone"
                        value={values.phone}
                        onChange={handlePhoneChange(handleChange)}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePhoneBlur(values.callingCode, e.target.value, (internationalPhoneNumber: string) =>
                            verifyPhoneNumbers({
                              variables: { phoneNumbers: [internationalPhoneNumber] },
                              onCompleted: (data) => {
                                setUnverifiedPhoneNumber(
                                  !data.verifyPhoneNumbers.find((pn) => pn.phoneNumber === internationalPhoneNumber),
                                );
                              },
                              onError: () => {
                                setUnverifiedPhoneNumber(false);
                              },
                            }),
                          )
                        }
                        isInvalid={!!errors.phone || unverifiedPhoneNumber}
                      />
                      <FormErrorFeedback error={errors.phone || (unverifiedPhoneNumber && t('enterValidPhone'))} />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup as={Col}>
                      <FormGroupLabel>{t('sendSurveyTo')}</FormGroupLabel>
                      <FormGroup className="form-check-group">
                        <FormRadioInput
                          name="preferredContactType"
                          value={ContactType.Email}
                          clicked={() => {
                            setFieldValue('preferredContactType', ContactType.Email);
                          }}
                          changed={handleChange}
                          onBlur={handleBlur}
                          defaultChecked={values.preferredContactType === ContactType.Email}
                          label={t('loginScreen:email')}
                        />
                        <FormRadioInput
                          name="preferredContactType"
                          value={ContactType.Sms}
                          clicked={() => {
                            setFieldValue('preferredContactType', ContactType.Sms);
                          }}
                          changed={handleChange}
                          onBlur={handleBlur}
                          defaultChecked={values.preferredContactType === ContactType.Sms}
                          label={t('updateRespondentModal:phone')}
                          disabled={!values.phone || errors.phone || unverifiedPhoneNumber}
                        />
                      </FormGroup>
                    </FormGroup>
                  </Row>
                </RoundedContent>
                <div className="d-flex flex-wrap align-items-start mt-7">
                  <Button
                    variant="primary"
                    className={'mr-5 mb-5'}
                    disabled={
                      (!isEmpty(errors) && Object.keys(errors).length !== 0) ||
                      isSubmitting ||
                      unverifiedPhoneNumber ||
                      equals(values, initialValues)
                    }
                    type="submit"
                  >
                    {t('SequenceComparsion:save')}
                  </Button>
                  <Button
                    color="primary"
                    variant="outline-primary"
                    className={'mb-5'}
                    onClick={() => {
                      cancel();
                    }}
                    disabled={isSubmitting}
                    type="button"
                  >
                    {t('emailSend:close')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  );
}
