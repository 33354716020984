import { CleanButton, DownloadIcon, Tooltip } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  handleDownload?: (classname?: string) => void;
  index?: string;
};

export const DownloadButton: FC<IProps> = ({ handleDownload, index }) => {
  const { t } = useTranslation('Report');

  if (handleDownload) {
    const onDownload = () => {
      handleDownload(`hide-from-export-${index}`);
    };
    return (
      <Tooltip title={t('downloadAsImageTooltip')}>
        <CleanButton onClick={onDownload} className="hide-from-export" data-icom="report-download-graph-as-image">
          <DownloadIcon />
        </CleanButton>
      </Tooltip>
    );
  }

  return null;
};
