import * as React from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react/macro';

const goUp = keyframes`
    0% {
      transform: translate(0, 100%)
    }
    100% {
      transform: translate(0, 0)
    }
`;

const goDown = keyframes`
    0% {
      transform: translate(0, -100%)
    }
    100% {
      transform: translate(0, 0)
    }
`;

const SnackBarView = styled.div<{ bottom?: boolean }>`
  position: absolute;
  top: ${(p) => (p.bottom ? undefined : 0)};
  left: 0;
  right: 0;
  bottom: ${(p) => (p.bottom ? 0 : undefined)};
  height: ${(p) => (p.bottom ? undefined : '50px')};
  background: rgb(49, 49, 49);
  color: white;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  text-align: center;
  padding: 6px 24px;
  animation: ${(p) => (p.bottom ? `${goUp} 0.6s` : `${goDown} 0.6s`)};
`;

const Row = styled.div`
  justify-content: space-between;
  display: flex;
`;

const RefreshButton = styled.button`
  outline: none;
  color: #5fa5e0;
  background: transparent;
  border: none;
  font-size: 13px;
  cursor: pointer;
`;

const Message = styled.span`
  text-align: left;
  padding: 8px 0;
  font-size: 14;
`;

interface IProps {
  message: string;
  bottom?: boolean;
}

interface IState {
  isVisible: boolean;
}

class SnackBar extends React.Component<IProps, IState> {
  state = {
    isVisible: false,
  };

  setVisibleSnackBar = () => {
    this.setState({
      isVisible: true,
    });
  };

  handleClick = () => {
    window.location.reload();
  };

  render() {
    const { bottom, message } = this.props;
    if (this.state.isVisible || !bottom) {
      return (
        <SnackBarView bottom={bottom}>
          <Row>
            <Message>{message}</Message>
            {bottom && <RefreshButton onClick={this.handleClick}>OBNOVIT</RefreshButton>}
          </Row>
        </SnackBarView>
      );
    }
    return false;
  }
}

export default SnackBar;
