import { ThemeType } from '@arnold/common';
import { getLocale } from '@arnold/core';
import { format } from 'date-fns';
import styled from '@emotion/styled/macro';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ReactComponent as ClosedChat } from '../assets/images/ClosedChat.svg';
import { Banner, Loading, FullScreenWindow, CenterView, ErrorMessage } from '../components';
import {
  GetChannelSlugForIdNumberQuery,
  SurveyStatus,
  useGetChannelSlugForIdNumberLazyQuery,
  useSurveyPublicInfoQuery,
} from '../generated/hooks';

interface IAdmittanceProps extends RouteComponentProps<any> {
  surveyId: number;
}

const AdmittanceCard = styled.div`
  margin: auto;
  max-width: 544px;
  min-height: 374px;
  background-color: ${({ theme }: { theme?: ThemeType }) => theme && theme.colors.backgroundCover.default};
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  padding: 48px;
`;

const Header = styled.div`
  color: ${({ theme }: { theme?: ThemeType }) => theme && theme.colors.text.primary};
  font-size: 22px;
`;

const Text = styled.div`
  margin-top: 16px;
  color: ${({ theme }: { theme?: ThemeType }) => theme && theme.colors.text.primary};
  font-size: 15px;
`;

const Admittance = ({ history, match }: IAdmittanceProps) => {
  const { t } = useTranslation(['Admittance', 'channelBanner']);
  const {
    loading: surveyInfoLoading,
    data: surveyInfoData,
    error: surveyInfoError,
  } = useSurveyPublicInfoQuery({
    variables: {
      surveyId: match.params.surveyId,
      langCode: match.params.lang,
    },
  });

  useEffect(() => {
    i18n.changeLanguage(match.params.lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forwardToChannel = (data?: GetChannelSlugForIdNumberQuery) => {
    if (data) {
      history.push(`/${data.getChannelSlugForIdNumber}`);
    }
  };

  const [respondentIdNo, setRespondentIdNo] = useState<string>('');
  const [getSlug, { error: slugError }] = useGetChannelSlugForIdNumberLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: forwardToChannel,
  });

  if (surveyInfoLoading) {
    return <Loading />;
  }
  if (surveyInfoError || !surveyInfoData || !surveyInfoData.surveyPublicInfo) {
    return (
      <FullScreenWindow>
        <CenterView>
          <ErrorMessage>{t('NoChannelExists')}</ErrorMessage>
        </CenterView>
      </FullScreenWindow>
    );
  }

  if (surveyInfoData.surveyPublicInfo.status !== SurveyStatus.Ongoing) {
    return (
      <Banner
        image={<ClosedChat width={'176px'} />}
        text={
          <>
            <h2>{t('channelBanner:closed_header')}</h2>
            <p>
              {t('channelBanner:closed_text_1')}
              <strong>{surveyInfoData.surveyPublicInfo.topicGroupName}</strong>
              {t('channelBanner:closed_text_2')}
              <strong>{t('channelBanner:closed_text_3')}</strong>
              {t('channelBanner:closed_text_4')}
              <strong>
                {format(new Date(surveyInfoData.surveyPublicInfo.endsAt).getTime(), 'HH:mm, eeee, d. MMMM.', {
                  locale: getLocale(i18n.language),
                })}
              </strong>
            </p>
          </>
        }
      />
    );
  }

  return (
    <AdmittanceCard>
      <Header>{t('headings')}</Header>
      <Text>
        {t('content1')} <span className={'font-weight-bold'}>{surveyInfoData.surveyPublicInfo.organizationName}</span>{' '}
        {t('content2')}{' '}
        <span className={'font-weight-bold'}>{surveyInfoData.surveyPublicInfo.topicGroupName || ''}</span>.{' '}
        {t('content3')}
      </Text>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          getSlug({
            variables: {
              surveyId: match.params.surveyId,
              respondentIdNo,
            },
          });
        }}
      >
        <div className="mt-6">
          <label className="form-group__label">{t('personalNumber')}</label>
          <input
            type="text"
            className="form-control"
            value={respondentIdNo || ''}
            onChange={(e) => setRespondentIdNo(e.target.value)}
          />
          {slugError && <small className="form-text text-danger">{t(slugError.graphQLErrors[0].name)}</small>}
        </div>
        <div className="mt-8">
          <button type="submit" className="btn btn-primary" disabled={!respondentIdNo}>
            {t('action')}
          </button>
        </div>
      </Form>
    </AdmittanceCard>
  );
};

export default withRouter(Admittance);
