import { getFrontendConfigValue } from '@arnold/common';
import { useEffect, useLayoutEffect, ReactNode, FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import packageJson from '../package.json';

type Props = {
  children: ReactNode;
};

export const ApiVersionController: FC<Props> = ({ children }) => {
  const [needsUpdate, setNeedsUpdate] = useState(false);

  // check api version
  const checkApiVersion = async () => {
    await fetch(`${getFrontendConfigValue('API_URL')}/check-api-version`)
      .then(async (r) => {
        const version = await r.text();
        if (version !== packageJson.version) {
          setNeedsUpdate(true);
        }
        return r.text();
      })
      .catch((e) => {
        console.error('Error checking API version', e);
      });
  };

  useEffect(() => {
    setInterval(checkApiVersion, 5 * 60 * 1000);
  }, []);

  // hook to check the app version on route change
  function useVersionCheck() {
    // check if a version refresh needed on route change
    const location = useLocation();
    useLayoutEffect(() => {
      // if there is an update available
      if (needsUpdate) {
        setNeedsUpdate(false);
        window.location.reload(); // refresh the browser
      }
    }, [location]);
  }

  useVersionCheck();

  return <>{children}</>;
};
