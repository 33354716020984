import styled from '@emotion/styled/macro';

export const Button = styled.button`
  background-color: #04b7ef;
  padding: 8px 32px;
  font-weight: 500;
  color: #fff;
  border-radius: 23px;
  border: none;
  cursor: pointer;
  outline: 0;

  :hover,
  :focus {
    background-color: #04a5d8;
    border-color: #04a5d8;
    outline: 0;
  }
  :active {
    background-color: #03c3ff;
    border-color: #03c3ff;
  }
`;
