import styled from '@emotion/styled/macro';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingLine from '../../../components/LoadingLine';

const COMMENT_LENGTH = 150;

export type CommentContentProps = {
  id?: string;
  author?: string;
  teamId?: string;
  teamName?: string;
  text: string;
  loading?: boolean;
};

const Comment = styled.p`
  position: relative;
  word-break: break-word;
  padding-left: 20px;
  padding-left: 1.25rem;

  &:before {
    position: absolute;
    left: 0;
    top: -0.5em;
    content: '„';
    font-weight: 300;
    font-size: 34px;
    line-height: 120%;
    color: #bdbdbd;
  }
`;

export const CommentContent: FC<CommentContentProps> = ({ author, text, teamName, loading }) => {
  const { t } = useTranslation('Report');
  const [opened, setOpened] = useState<boolean>(false);

  const handleOpen = (open: boolean) => (e: any) => {
    e.preventDefault();
    setOpened(open);
  };
  const displayedText = text.substring(0, opened ? text.length : COMMENT_LENGTH);

  return (
    <div className={'col-sm-4 px-2 d-flex'} data-cy="report-comment-content">
      <div className={'flex-grow-1 bg-white rounded-lg shadow-sm p-8 mb-3'}>
        {loading ? (
          <LoadingLine />
        ) : (
          <p>
            {author || <span className={'text-muted'}>{t('ANONYMOUS')}</span>},{' '}
            {teamName && <span className={'text-muted'}>{`${t('team')}: ${teamName}`}</span>}
          </p>
        )}
        <Comment className={'mb-0'}>
          {loading ? (
            <>
              <LoadingLine />
              <LoadingLine />
            </>
          ) : (
            <span>
              {displayedText.split(/(?:\r\n|\r|\n)/).map((line) => (
                <>
                  {line}
                  <br />
                </>
              ))}
            </span>
          )}
          {text.length > displayedText.length && (
            <>
              <span>... </span>
              <a href={''} onClick={handleOpen(true)} data-pptx-hide>
                {t('COMMENT_ALL')}
              </a>
            </>
          )}
        </Comment>
        {opened && (
          <p className="mt-4 mb-0 text-right">
            <a href="packages/arnold-ui/src/components/reports" onClick={handleOpen(false)} data-pptx-click>
              {t('COMMENT_HIDE')}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
