import {
  createEventData,
  getFrontendConfigValue,
  logInIntercomUser,
  logOutIntercomUser,
  pushToDataLayer,
} from '@arnold/common';
import fetch from 'unfetch';
import client from '../createApollo';

export const USER_KEY = '@Arnold/Talk';
export const USER_EMAIL_KEY = '@Arnold/UserEmail';
export const ACCESS_TOKEN_PARAM = 'accessToken';
export const SET_PASSWORD_ACCESS_TOKEN = 'setPasswordAccessToken';
export const DEVICE_TOKEN_KEY = '@Arnold/DeviceToken';

type User = {
  id?: number;
  orgId?: number;
  accessToken: string;
};

export const getUserEmail = (): string | null => localStorage.getItem(USER_EMAIL_KEY);

class Auth {
  getDeviceToken() {
    const deviceToken = localStorage.getItem(DEVICE_TOKEN_KEY);
    if (deviceToken) {
      return deviceToken;
    }
    // Generate random token of length 20
    const newToken = Array.from({ length: 20 }, () => Math.floor(Math.random() * 36).toString(36)).join('');
    localStorage.setItem(DEVICE_TOKEN_KEY, newToken);
    return newToken;
  }

  private static parseAccessToken(search: string) {
    const accessTokenPosition = search.indexOf(ACCESS_TOKEN_PARAM);
    const equalPosition = search.indexOf('=', accessTokenPosition);
    const ampersandPosition = search.indexOf('&', accessTokenPosition);
    return search.substring(equalPosition + 1, ampersandPosition > 0 ? ampersandPosition : undefined);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  user: User | null = null;

  async login(username: string, password: string, otp?: string) {
    const response = await fetch(getFrontendConfigValue('API_URL') + '/auth/session', {
      body: JSON.stringify({ username, password, deviceToken: this.getDeviceToken(), otp, origin: 'TALK' }),
      // fetch has incorrectly defined types
      // @ts-ignore
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!response.ok) {
      const error: any = new Error(`fetch error, status ${response.status}`);
      error.status = response.status;
      error.json = response.json;
      throw error;
    }
    const { id, orgId, accessToken, intercomHash, orgAdmin, createdAt } = await response.json();
    client.resetStore();

    if (orgAdmin) {
      pushToDataLayer({
        userId: id,
        orgId,
        event: 'ux.user-login',
        ...createEventData('user', 'login', 'user login'),
      });
      logInIntercomUser(id, intercomHash, new Date(createdAt));
    }

    return this.saveUser(accessToken, id, orgId, intercomHash);
  }

  saveUser(accessToken: string, id?: number, orgId?: number, intercomHash?: string) {
    if (accessToken == null) {
      throw new Error('Empty accessToken!');
    }
    localStorage.setItem(USER_KEY, JSON.stringify({ id, orgId, accessToken, intercomHash }));
    this.user = { id, orgId, accessToken };
    return this.user;
  }

  getUser(search?: string): User | null {
    let user = this.user;
    if (!user) {
      const storageUser = localStorage.getItem(USER_KEY);
      if (storageUser) {
        user = JSON.parse(storageUser);
      }
      if (search && search.includes(ACCESS_TOKEN_PARAM)) {
        const accessToken = Auth.parseAccessToken(search);
        this.saveUser(accessToken);
        user = this.user;
      }
      this.user = user;
    }
    return user;
  }

  isLoggedIn(search?: string) {
    const user = this.getUser(search);
    return user != null;
  }

  async logout() {
    const user = this.getUser();
    if (user == null) {
      return false;
    }
    localStorage.removeItem(USER_KEY);
    this.user = null;

    await fetch(getFrontendConfigValue('API_URL') + '/auth/session', {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        'Content-Type': 'application/json',
      },
      // unfetch has incorrectly defined types
      // @ts-ignore
      mode: 'cors',
      method: 'DELETE',
    }).catch((err) => {
      // We don't care about the result. If this fails, server will have stale session but
      // it's servers bussiness only and client doesn't care
    });

    client.resetStore();

    logOutIntercomUser();
    return true;
  }
}

const auth = new Auth();
export default auth;
