import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as VisibilityOff } from '../assets/images/VisibilityOff.svg';
import { ReactComponent as VisibilityOn } from '../assets/images/VisibilityOn.svg';
import { AnonymityLevel, QuestionType } from '../generated/hooks';
import { USER_KEY } from './auth';

export const URLs = {
  REPORT_ALERT_DETAIL: 'alert',
  REPORT_RESPONDENT_DETAIL: 'respondent',
};

export const definitionTypes = {
  [QuestionType.Select]: 'SelectQuestionDefinition',
  [QuestionType.Freetext]: 'FreeTextQuestionDefinition',
  [QuestionType.Ending]: 'EndingQuestionDefinition',
  [QuestionType.Tell]: 'TellQuestionDefinition',
  [QuestionType.SmsContact]: 'SmsContactQuestionDefinition',
  [QuestionType.PersonMultiselect]: 'PersonMultiselectQuestionDefinition',
  [QuestionType.EmailContact]: 'EmailContactQuestionDefinition',
};

export const getAnonymityIcon = (anonymity: AnonymityLevel, size: number) =>
  anonymity === AnonymityLevel.None ? (
    <VisibilityOn width={size} height={size} />
  ) : (
    <VisibilityOff width={size} height={size} />
  );

export const getAnonymityName = (anonymity: string, respondentName: string, t: any) => {
  switch (anonymity) {
    case AnonymityLevel.None:
      return truncateString(respondentName, 14);
    case AnonymityLevel.Organization:
      return t('anonymities_' + anonymity);
    default:
      return anonymity.toLowerCase();
  }
};

const truncateString = (input: string, length: number) => {
  if (input.length <= length) {
    return input;
  }

  return input.slice(0, length) + '...';
};

export const getBrowserLanguages = () => {
  let languages: readonly string[];

  if (navigator.languages) {
    languages = navigator.languages;
    // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
  } else if (navigator['userLanguage']) {
    // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
    languages = navigator['userLanguage'];
  } else {
    languages = [navigator.language];
  }

  return languages;
};

export const parseUrlParams = (ulrString: string) => {
  // tslint:disable-next-line no-console no-useless-escape
  const tokens = ulrString.replace(/\?|#/g, '').split('&');
  const getParam = (name: string) => {
    const find = tokens.find((param: string) => param.match(new RegExp(name, 'g')));
    return find ? find.split('=')[1] : undefined;
  };
  return { getParam };
};

export const getUserHash = () => {
  const user = localStorage.getItem(USER_KEY);
  const userHash = user != null ? JSON.parse(user).intercomHash : undefined;
  return userHash;
};

export const composeSearchParamsString = (params: { [key: string]: string | undefined | null }) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      searchParams.append(key, params[key] === null ? 'null' : params[key]!);
    }
  });

  return Array.from(searchParams.values()).length ? `?${searchParams.toString()}` : '';
};

const SYS_ADMIN_KEY = 'sysAdminInReports';
/**
 * Save sysAdmin status to local storage if it is in the URL and return it
 * sysAdmin is saved only for specific accessToken
 */
export const useSaveSysAdminFromUrl = (accessToken: string, url = '') => {
  const history = useHistory();
  useEffect(() => {
    const storageValue = localStorage.getItem(SYS_ADMIN_KEY);
    if (url.includes('sysAdmin') && !storageValue?.includes(accessToken)) {
      localStorage.setItem(SYS_ADMIN_KEY, `${storageValue || ''},${accessToken}`);
    }
    removeSysAdminFromUrl(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, url]);

  const isSysAdmin = useMemo(() => {
    const storageValue = localStorage.getItem(SYS_ADMIN_KEY);
    return storageValue?.includes(accessToken) || url.includes('sysAdmin');
  }, [accessToken, url]);
  return isSysAdmin;
};

const removeSysAdminFromUrl = (history: ReturnType<typeof useHistory>) => {
  const params = new URLSearchParams(history.location.search);
  if (params.has('sysAdmin')) {
    params.delete('sysAdmin');
    history.replace({ search: params.toString() });
  }
};
