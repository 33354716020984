import { ReportSubscriptionSubscription } from '../../../generated/hooks';
import { WidgetType } from '../../../lib/reportHelper';

type Report = ReportSubscriptionSubscription['getReport'];

export const showSubTeamsCharts = (
  widget: NonNullable<NonNullable<NonNullable<Report['reportDefinition']>['sections']>[0]['widgets']>[0],
  subSectionId: string,
  setWidgets: (widgets: WidgetType[]) => void,
  rootTeams: Report['teams'],
  oldRootTeams: Report['teams'],
  removedWidgets: WidgetType[],
) => {
  const createWidget = (team: NonNullable<Report['teams']>[0], filterOperator: string): WidgetType => ({
    afterWidgetId: widget.id,
    subSectionId,
    widget: {
      ...widget,
      __typename: 'PubWidget',
      id: widget.id + team.id + filterOperator,
      displayFilters: [
        ...(widget.displayFilters || []).filter((f) => f.operator !== 'organization-level'),
        {
          __typename: 'PubDisplayFilter',
          operator: filterOperator,
          questionDefinitionIndex: '',
          values: [team.id],
        },
      ],
    },
  });
  const newRootWidgets = (rootTeams || []).map((mt) => createWidget(mt, 'root'));
  const newTeamWidgets = oldRootTeams
    ?.filter((team) => team.directMembers?.length)
    .map((mt) => createWidget(mt, 'team'));
  const widgets = [
    ...newRootWidgets,
    ...(newTeamWidgets || []),
    ...removedWidgets.filter((widgetForRemoval) => {
      // Filter out the widgets, that corresponds with the new ones
      if (!widgetForRemoval || !widgetForRemoval.widget.displayFilters) {
        return true;
      }
      const filter = widgetForRemoval.widget.displayFilters.find(
        (f) => f.values && (f.operator === 'root' || f.operator === 'team'),
      );
      if (!filter || !filter.values) {
        return false;
      }
      const filterValue = filter.values[0];
      if (filter.operator === 'team') {
        if (oldRootTeams?.some((team) => team?.id === filterValue)) {
          return true;
        }
      }
      if (filter.operator === 'root') {
        if (rootTeams?.some((team) => team.id === filterValue)) {
          return true;
        }
      }
      return false;
    }),
  ];
  return setWidgets(widgets);
};

export const showSubTeamsReversedCharts = (
  widget: NonNullable<NonNullable<NonNullable<Report['reportDefinition']>['sections']>[0]['widgets']>[0],
  subSectionId: string,
  setWidgets: (widgets: WidgetType[]) => void,
  compareLevel: string,
  removedWidgets: WidgetType[],
) =>
  setWidgets([
    {
      afterWidgetId: widget.id,
      subSectionId,
      widget: {
        ...widget,
        __typename: 'PubWidget',
        id: `${widget.id}-level-${compareLevel}`,
        displayFilters: [
          ...(widget.displayFilters || []).filter((f) => f.operator !== 'organization-level'),
          {
            __typename: 'PubDisplayFilter',
            operator: 'organization-level',
            questionDefinitionIndex: '',
            values: [compareLevel],
          },
        ],
      },
    },
    ...removedWidgets,
  ]);
