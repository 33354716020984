import { AIIcon, Box, formatText, replaceQuestionPlaceholders, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FeEventType, ReportSubscriptionSubscription, useLogFeEventMutation } from '../../../generated/hooks';
import { IFilterDescription } from '../../../lib/reportHelper';
import FilterDescription, { FilterTitleType } from '../FilterDescription';
import { IntercomEvent, IntercomEventSender, sendEventToIntercom } from '../ObserverEventSender';
import RateAi from '../RateAi';
import { CommentContent, CommentContentProps } from './CommentContent';
import { ExpandLink } from './ExpandLink';
import { KeyWord } from './KeyWord';

const COMMENT_LIMIT = 9;

type CommentBoxProps = {
  header: string;
  subHeader?: string | undefined;
  organizationName?: string;
  filterDescriptions?: IFilterDescription[];
  comments: CommentContentProps[];
  questionLabels?: NonNullable<ReportSubscriptionSubscription['getReport']['questionLabels']>[0];
  emptyState: string;
  loading?: boolean;
  keywordsEnabled: boolean;
  questionIndex: number;
  reportAccess: string;
  reportId: string;
  respondentId?: string;
};

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.c};
`;

const KeyWordsBox = styled(Box)`
  padding-top: ${theme.spacing.f};
  padding-bottom: ${theme.spacing.f};
  margin-top: ${theme.spacing.c};
  margin-bottom: ${theme.spacing.c};
  border: ${theme.spacing.a} solid ${theme.colors.ai.default};
`;

const CommentsWrapper = styled.div`
  margin-top: ${theme.spacing.c};
`;

const KeyWordsHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SeparatorLine = styled.div`
  border-top: 1px solid ${theme.colors.borderSeparator.default};
  margin: ${theme.spacing.f} 0;
`;

const KeyWordsHeader = styled.span`
  font-size: ${theme.typography.body.small?.regular!.fontSize};
  font-weight: ${theme.typography.body.small?.regular!.fontWeight};
  line-height: ${theme.typography.body.small?.regular!.lineHeight};
  color: ${theme.colors.text.secondary};
  margin-left: 36px;
  margin-right: 180px;
`;

const KeyWordsHeaderContainer = styled.div`
  margin-bottom: ${theme.spacing.f};
  white-space: nowrap;
`;

const KeyWordDescription = styled.div`
  font-size: ${theme.typography.body.small?.regular!.fontSize};
  font-weight: ${theme.typography.body.small?.regular!.fontWeight};
  line-height: ${theme.typography.body.small?.regular!.lineHeight};
  color: ${theme.colors.text.primary};
  margin-top: -${theme.spacing.f};
`;

const KeyWordsHeaderWithLogo = styled.div`
  margin-bottom: ${theme.spacing.f};
  display: flex;
  align-items: center;
`;

const KeyWordsTitle = styled.div`
  margin-left: ${theme.spacing.f};
  width: 100%;
`;

export const CommentBox: FC<CommentBoxProps> = ({
  header,
  subHeader,
  organizationName,
  filterDescriptions,
  comments,
  questionLabels,
  emptyState,
  loading,
  keywordsEnabled,
  questionIndex,
  reportAccess,
  reportId,
  respondentId,
}) => {
  const { t } = useTranslation('Report');
  const [displayedComments, setdisplayedComments] = useState<number>(COMMENT_LIMIT);

  const [labels, setLabels] = useState<{ label: string; commentIds: string[] }[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[] | null>(null);
  const [showKeyWords, setShowKeyWords] = useState<boolean>(true);
  const [logFeEvent] = useLogFeEventMutation();

  const executeLogEvent = (eventType: FeEventType, detail?: string) => {
    try {
      logFeEvent({
        variables: {
          eventType,
          payload: {
            reportAccess,
            reportAccessId: reportId,
            questionIndex,
            respondentId: respondentId?.toString(),
            detail,
          },
        },
      });
    } catch (err) {
      // Do nothing
    }
  };

  const commentsWithoutLabel = comments.filter((comment) => !labels.some((l) => l.commentIds.includes(comment.id!)));

  const containsLabel = (commentId: string, texts: string[]) =>
    labels.some((c) => texts.includes(c.label) && c.commentIds?.includes(commentId));
  const filteredComments = selectedLabels?.length
    ? [
        comments.filter((comment) => containsLabel(comment.id!, selectedLabels)),
        selectedLabels.includes(t('answersWithoutLabels')) ? commentsWithoutLabel : [],
      ].flat()
    : comments;

  const nextCommentsCount = Math.min(COMMENT_LIMIT, filteredComments.length - displayedComments);

  const loadMoreComments = () => {
    setdisplayedComments(displayedComments + nextCommentsCount);
  };

  const hideComments = () => {
    setdisplayedComments(COMMENT_LIMIT);
  };

  const handleToggleKeyWords = () => {
    executeLogEvent(showKeyWords ? FeEventType.HideKeyWords : FeEventType.ShowKeyWords);
    setShowKeyWords(!showKeyWords);
  };

  const handleSelectedLabelChange = (selectedLabel: string) => {
    const selectedLabelsCloned = selectedLabels?.slice() || [];
    const selectedLabelIndex = selectedLabelsCloned.indexOf(selectedLabel);

    if (selectedLabelIndex === -1) {
      selectedLabelsCloned.push(selectedLabel);
    } else {
      selectedLabelsCloned.splice(selectedLabelIndex, 1);
    }
    setSelectedLabels(selectedLabelsCloned);
    sendEventToIntercom(IntercomEvent.KeywordsFilter, { reportId });
  };

  useEffect(() => {
    if (keywordsEnabled && comments.length > 5) {
      let filteredLabels = (questionLabels?.questionLabelComments || [])
        .map((label) => ({
          ...label,
          // label should only contain comments that are in the comments list
          commentIds: label.commentIds?.filter((c) => comments.some((comment) => c === comment.id)) || [],
        }))
        .filter((label) => label.label.length && label.commentIds && label.commentIds.length > 1)
        .sort((labelA, labelB) => labelB.commentIds!.length - labelA.commentIds!.length)
        .slice(0, 10);
      if (filteredLabels.length === 1) {
        // if there is only one label, we want to hide the entire keywords section instead of showing only one label
        filteredLabels = [];
      }
      setLabels(filteredLabels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionLabels, keywordsEnabled]);

  // number of comments that the biggest keyword has
  const biggestKeyWordCount = Math.max(...labels.map((l) => l.commentIds.length), commentsWithoutLabel.length);

  return (
    <div className={'mt-7'} data-cy="report-comment-box" data-comment-box-question-index={questionIndex}>
      <HeaderBox>
        <div data-text-question-index={questionIndex}>
          <h3 className={'mb-0'} data-icom={'report-question-text'} data-for-query-selector-question-text>
            {formatText(
              replaceQuestionPlaceholders(
                header,
                organizationName || `<${t('orgNameReplacement')}>`,
                `<${t('firstDateReplacement')}>`,
                `<${t('teamLeaderReplacement')}>`,
              ),
            )}
          </h3>
          <p className={'mb-0 font-size-xs'} data-for-query-selector-question-description>
            {subHeader}
            {filterDescriptions && !!filterDescriptions.length ? ` ${t('WIDGET_FILTER_RESPONDED')}` : ''}
            {filterDescriptions && !!filterDescriptions.length ? (
              filterDescriptions.map((fd, idx) => (
                <Fragment key={fd.answers && fd.answers[0] && fd.answers[0].answer}>
                  <FilterDescription
                    answers={fd.answers}
                    question={replaceQuestionPlaceholders(
                      fd.question,
                      organizationName || `<${t('orgNameReplacement')}>`,
                      `<${t('firstDateReplacement')}>`,
                      `<${t('teamLeaderReplacement')}>`,
                    )}
                    hideNumbers={loading}
                    filterTitleType={FilterTitleType.REPORT_WIDGET}
                  />
                  {idx + 1 < filterDescriptions.length ? t('FILTER_SEPARATOR') : ''}
                </Fragment>
              ))
            ) : (
              <></>
            )}
          </p>
        </div>
      </HeaderBox>

      {keywordsEnabled && !!labels.length && (
        <>
          <KeyWordsBox>
            <KeyWordsHeaderWithLogo>
              <AIIcon />
              <KeyWordsTitle>
                <KeyWordsHeaderWrap>
                  <h3>{t('keyWords')}</h3>
                  <ExpandLink
                    expanded={showKeyWords}
                    text={showKeyWords ? t('hideKeyWords') : t('revealKeyWords')}
                    dataIcom={showKeyWords ? t('btn-hide-keywords') : t('btn-reveal-keywords')}
                    click={handleToggleKeyWords}
                  />
                </KeyWordsHeaderWrap>
                <KeyWordDescription>
                  {`${t('keyWordsDescription')} `}
                  <b data-icom="report-powered-by-ai">{t('ai')}</b>
                </KeyWordDescription>
              </KeyWordsTitle>
            </KeyWordsHeaderWithLogo>
            {showKeyWords && (
              <div data-for-query-selector-ai-keywords data-icom="report-key-words-table">
                <KeyWordsHeaderContainer>
                  <KeyWordsHeader>{t('theme')}</KeyWordsHeader>
                  <KeyWordsHeader>{t('numberOfAnswers')}</KeyWordsHeader>
                </KeyWordsHeaderContainer>
                {labels.map((l) => (
                  <KeyWord
                    key={l.label}
                    keyWord={l.label}
                    count={l.commentIds.length}
                    maxCount={comments.length}
                    biggestKeyWordCount={biggestKeyWordCount}
                    selected={!!selectedLabels?.includes(l.label)}
                    onSelect={() => handleSelectedLabelChange(l.label)}
                    t={t}
                  />
                ))}
                {commentsWithoutLabel.length > 0 && (
                  <>
                    <SeparatorLine />
                    <KeyWord
                      key={'answersWithoutLabels'}
                      keyWord={t('answersWithoutLabels')}
                      count={commentsWithoutLabel.length}
                      maxCount={comments.length}
                      biggestKeyWordCount={biggestKeyWordCount}
                      selected={!!selectedLabels?.includes(t('answersWithoutLabels'))}
                      onSelect={() => handleSelectedLabelChange(t('answersWithoutLabels'))}
                      t={t}
                    />
                  </>
                )}
              </div>
            )}
          </KeyWordsBox>

          <RateAi type="KEYWORDS" />
          <IntercomEventSender eventName={IntercomEvent.KeywordsSeen} data={{ reportId }} />
        </>
      )}
      {filteredComments.length > 0 ? (
        <>
          <CommentsWrapper className={'row mx-n2'} data-for-query-selector-comments>
            {filteredComments
              .filter((_, index) => index < displayedComments)
              .map(({ author, teamName, text }, index) => (
                <CommentContent key={index} author={author} teamName={teamName} text={text} />
              ))}
          </CommentsWrapper>
          {filteredComments.length >= COMMENT_LIMIT && (
            <div className={'mt-5 pl-8'}>
              <Button
                onClick={loadMoreComments}
                disabled={filteredComments.length <= displayedComments}
                data-cy="report-comments-load-more"
                className={'btn-fill'}
              >
                {t('MORE_COMMENTS', {
                  count: filteredComments.length - displayedComments,
                })}
              </Button>
              {displayedComments > COMMENT_LIMIT && (
                <Button
                  onClick={hideComments}
                  data-cy="report-comments-hide"
                  className={'ml-6 btn-empty'}
                  data-pptx-click
                >
                  {t('HIDE_COMMENTS')}
                </Button>
              )}
            </div>
          )}
        </>
      ) : loading ? (
        <div className={'row mx-n2'}>
          <CommentContent key={0} text="" loading={true} />
          <CommentContent key={1} text="" loading={true} />
          <CommentContent key={2} text="" loading={true} />
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm p-8 no-data">
          <span>{emptyState}</span>
        </div>
      )}
    </div>
  );
};
