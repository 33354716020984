import { MutationFunction } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { theme } from '@arnold/common';
import { css, keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { path } from 'ramda';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SendIcon } from '../components/icons';
import {
  GetMessagesQuery,
  QuestionType,
  VerifyPhoneNumbersMutation,
  VerifyPhoneNumbersMutationVariables,
} from '../generated/hooks';
import { verifyPhoneNumberMutation } from '../graphql/mutations';
import { definitionTypes } from '../lib/common';
import { organizationRespondent } from '../types';
import { InputConsumer } from './InputContext';

interface IStyledProps {
  isBouncing?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  smallerPadding?: boolean;
  unactive?: boolean;
  isDisabled?: boolean;
  error?: boolean;
}

const shake = keyframes`
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  25%,
  75% {
    transform: translate3d(5px, 0, 0);
  }

  50% {
    transform: translate3d(-5px, 0, 0);
  }
`;

export const ProgressBar = styled.div`
  width: ${(props: { width: number }) => props.width}%;
  height: 2px;
  background-color: ${theme.colors.actionPrimary.default};
  transition: width 250ms ease-out;
  position: relative;
  top: 2px;
  z-index: 1;
`;

const ChatInput = styled.div`
  background-color: #fff;
`;

const Input = styled.div`
  width: 100%;
  min-height: 38px !important;
  max-height: 180px;
  padding: ${(props: IStyledProps) => (props.smallerPadding ? '0' : '8px 16px')};
  padding-right: 45px;
  outline: none;
  color: #3f3f3f;
  background-color: ${(props: IStyledProps) =>
    props.disabled ? '#f9f9f9' : '#f9f9f9'}; // TODO: problem u multiselectem
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  min-height: 20px;
  overflow-style: none;
  border: 1px solid
    ${(props: IStyledProps) => (props.disabled ? '#f9f9f9' : props.error ? theme.colors.form.error : '#f9f9f9')};
  border-radius: 23px;
  transition: border 0.15s;
  ::-webkit-scrollbar {
    display: none;
  }
  :focus {
    border-color: ${(props: IStyledProps) => (props.error ? '#c00' : '#c5e4f5')};
  }
  :empty::before {
    content: ${(props: IStyledProps) => (!props.isDisabled ? 'attr(data-placeholder)' : "''")};
    color: #a1a1a1;
    font-size: 15px;
    cursor: text;
  }
`;

const SendView = styled.div`
  flex: none;
  ${(props: IStyledProps) => (props.isBouncing ? MakeBounce : 'undefined')};
`;

const SendButton = styled.button`
  min-width: 40px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border: none;
  outline: none;
  border-radius: 23px;
  z-index: 11;
  font-weight: 500;
  color: #fff;
  background-color: #04b7ef;
  cursor: pointer;

  :hover,
  :focus {
    background-color: #04a5d8;
    border-color: #04a5d8;
  }
  :active {
    background-color: #03c3ff;
    border-color: #03c3ff;
  }
  :disabled {
    color: #bdbdbd;
    background-color: #e7e7e7;
    cursor: default !important;

    svg {
      fill: currentColor;
    }
  }

  @media (min-width: 576px) {
    margin-left: 32px;
    padding: 8px 32px;
  }

  span {
    display: none;

    @media (min-width: 576px) {
      display: inline-block;
      margin-left: 0.5em;
    }
  }
`;

export const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  bottom: 0;
`;

const MakeBounce = css`
  animation: 0.3s ease-in-out 0.15s 1 normal forwards ${shake};
`;

const Form = styled.form<{ hide?: boolean; transparent?: boolean }>`
  position: relative;
  max-width: 960px;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  padding: 16px 12px;
  background-color: ${(p) => (p.transparent ? `transparent` : `#ffffff`)};
  display: ${(p) => (p.hide ? `none` : `flex`)};
`;

export const Item = styled.span`
  color: #04b7ef;
  background-color: #fff;
  border: 1px solid #04b7ef;
  border-radius: 15px;
  padding: 3px 46px 3px 32px;
  margin: 3px;
  cursor: pointer;
  position: relative;

  :hover {
    color: #04a5d8;
    border-color: #04a5d8;
  }

  :disabled {
    background-color: #a1a1a1;
    border-color: #a1a1a1;
    cursor: auto;
  }
`;

export const Remove = styled.div`
  position: absolute;
  right: 7px;
  top: 50%;
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-color: #04b7ef;
  border-radius: 50%;
  transform: translate(0, -50%);

  ${Item}:hover & {
    background-color: #04a5d8;
  }
`;

interface IProps extends WithTranslation {
  lastMessage: NonNullable<GetMessagesQuery['messages']>[0] | null;
  disabled?: boolean;
  progressWidth: number;
  organizationId?: string;
  sendMessage: (message: string | null, index?: number) => void;
  values: number[];
  removeChoice: (index: number) => void;
  changeMessage: (message: string) => void;
  index: number;
  bounce: boolean;
  makeBounce: (makeBounce?: boolean) => void;
  initInputValue: string;
  handleSetMessage: (message: string) => void;
  respondents: organizationRespondent[];
  hideControls: boolean;
  setLastKey: (key: string | undefined) => void;
  changeInputInitValue: (type: string, message: string, values?: number[]) => void;
  verifyPhoneNumber: MutationFunction<VerifyPhoneNumbersMutation, VerifyPhoneNumbersMutationVariables>;
  setClearInputFunction: (clearInput: () => void) => void;
}

interface IState {
  error: boolean;
}

class InputForm extends React.Component<IProps, IState> {
  state: IState = {
    error: false,
  };

  constructor(props: IProps) {
    super(props);

    this.submitMultiselectOptions();
    this.props.setClearInputFunction(this.clearInput);
  }

  inputValueRef = React.createRef<HTMLDivElement>();

  handleSetError = () => this.setState((prevState) => ({ error: !prevState.error }));

  validateEmail = (email: string) => {
    const re =
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = async (event?: React.FormEvent<HTMLFormElement> | React.KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    const { sendMessage, verifyPhoneNumber } = this.props;
    clearTimeout(this.bounce);
    this.props.makeBounce(false);
    const inputValue: HTMLDivElement | null | undefined = path(['current'], this.inputValueRef);
    const input = inputValue != null ? inputValue.innerText.replace(/×/g, '').trim() : null;
    const isSmsType = this.hasTypename(definitionTypes[QuestionType.SmsContact]);
    const iEmailType = this.hasTypename(definitionTypes[QuestionType.EmailContact]);
    let inputValid = false;
    if ((iEmailType || isSmsType) && input !== null) {
      if (isSmsType) {
        const response = await verifyPhoneNumber({
          variables: { phoneNumbers: [input], organizationId: this.props.organizationId },
        });
        const phonenNumber = response.data!.verifyPhoneNumbers[0];
        inputValid =
          phonenNumber.specifiedPhoneNumber.replace(/\s/g, '') === phonenNumber.phoneNumber?.replace(/\s/g, '');
      } else {
        inputValid = this.validateEmail(input);
      }
      if (!inputValid) {
        this.handleSetError();
      }
    }

    if ((!iEmailType && !isSmsType) || ((iEmailType || isSmsType) && inputValid)) {
      sendMessage(input);
      this.clearInput();
    }
  };

  focus = (nextProps: IProps, force: boolean = false) => {
    const isFocusable =
      path(['definition', '__typename'], nextProps.lastMessage) === definitionTypes[QuestionType.Freetext] ||
      path(['definition', '__typename'], nextProps.lastMessage) === definitionTypes[QuestionType.PersonMultiselect];
    const isFocusableWhenReturn =
      nextProps.initInputValue.length > 0 &&
      (path(['definition', '__typename'], nextProps.lastMessage) === definitionTypes[QuestionType.SmsContact] ||
        path(['definition', '__typename'], nextProps.lastMessage) === definitionTypes[QuestionType.EmailContact]);

    if (isFocusable || isFocusableWhenReturn) {
      setTimeout(() => {
        const inputValue = this.inputValueRef.current;
        if (inputValue) {
          if (nextProps.initInputValue.length > 0 && document.activeElement !== this.inputValueRef.current) {
            const rng = document.createRange(); // focus to input end
            const sel = window.getSelection();
            if (inputValue.childNodes[0]) {
              rng.setStart(
                inputValue.childNodes[0],
                Math.min(inputValue.innerText.length, nextProps.initInputValue.length),
              );
            }
            rng.collapse(true);
            if (sel) {
              sel.removeAllRanges();
              sel.addRange(rng);
            }
          }

          inputValue.focus();
        }
      }, 0);
    } else if (this.inputValueRef.current) {
      this.inputValueRef.current.blur();
    }
  };

  clearInput = () => {
    const inputValue: HTMLDivElement | null | undefined = path(['current'], this.inputValueRef);
    if (inputValue && !this.hasTypename(definitionTypes[QuestionType.Select])) {
      inputValue.innerText = '';
    }
  };

  componentDidUpdate(oldProps: IProps) {
    if (this.props.respondents.length !== oldProps.respondents.length) {
      this.clearInput();
      this.props.handleSetMessage('');
    }
    if (oldProps.lastMessage !== this.props.lastMessage || this.props.initInputValue !== oldProps.initInputValue) {
      this.focus(this.props);
    }

    this.submitMultiselectOptions();
  }

  submitMultiselectOptions = () => {
    const selectedRange = path<number[]>(['definition', 'selectedRange'], this.props.lastMessage);
    const isPersonMultiselect = this.hasTypename(definitionTypes[QuestionType.PersonMultiselect]);
    if (isPersonMultiselect && selectedRange && selectedRange[1] === this.props.respondents.length) {
      this.handleSubmit(undefined as any);
    }
  };

  handleRemoveChoice = (event: React.ChangeEvent<EventTarget>, index: number) => {
    this.props.removeChoice(index);
  };

  renderAddedChoices = () => {
    const { lastMessage, values } = this.props;
    const selectedRange = path<number[]>(['definition', 'selectedRange'], lastMessage);
    if (selectedRange && selectedRange[1] === 1) {
      return false;
    }
    const message = path<string[]>(['definition', 'choices'], lastMessage);
    return (
      <ChipsContainer style={{ backgroundColor: '#f9f9f9' }}>
        {values.map((item, index) => (
          <Item key={item} onClick={(e) => this.handleRemoveChoice(e, index)}>
            {message && message[item]}
            <Remove>&times;</Remove>
          </Item>
        ))}
      </ChipsContainer>
    );
  };

  hasTypename = (...types: string[]) => {
    const { lastMessage } = this.props;
    return types.map((type) => path(['definition', '__typename'], lastMessage) === type).some((val) => val === true);
  };

  isAnswerable = () =>
    this.hasTypename(
      definitionTypes[QuestionType.Select],
      definitionTypes[QuestionType.Freetext],
      definitionTypes[QuestionType.PersonMultiselect],
      definitionTypes[QuestionType.SmsContact],
      definitionTypes[QuestionType.EmailContact],
    );

  onKeyDown = (event: React.KeyboardEvent) => {
    if (this.hasTypename(definitionTypes[QuestionType.PersonMultiselect])) {
      if (event.key === 'Escape') {
        this.clearInput();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key === 'Enter' || event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        this.props.setLastKey(event.key);
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }
    if (event.key === 'Enter' && !event.shiftKey && this.isAnswerable()) {
      const { lastMessage, initInputValue } = this.props;

      const optional = path(['definition', 'optional'], lastMessage);
      const inputValue: HTMLDivElement | null | undefined = path(['current'], this.inputValueRef);
      if (
        !optional &&
        this.hasTypename(definitionTypes[QuestionType.Freetext]) &&
        !!inputValue &&
        (inputValue.innerText || initInputValue).trim().length === 0
      ) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      this.handleSubmit(event);
      return false;
    }
    if (this.hasTypename(definitionTypes[QuestionType.SmsContact])) {
      if (isNaN(parseInt(event.key, 10)) && event.key !== '+' && (event.key.length === 1 || event.key === 'Enter')) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
    this.props.setLastKey(undefined);
  };

  handleKeyUp = () => {
    const inputValue: HTMLDivElement | null | undefined = path(['current'], this.inputValueRef);
    if (this.hasTypename(definitionTypes[QuestionType.PersonMultiselect])) {
      this.props.handleSetMessage(inputValue != null ? inputValue.innerText.replace(/×/g, '').replace(/\n/gi, '') : '');
    }
    if (this.hasTypename(definitionTypes[QuestionType.Freetext])) {
      this.props.handleSetMessage(inputValue != null ? inputValue.innerText : '');
    }
  };

  bounce: any = null;

  prepareBounce = () => {
    clearTimeout(this.bounce);
    this.bounce = setTimeout(this.props.makeBounce, 3000);
    if (this.state.error) {
      this.handleSetError();
    }
  };

  handleFocus = () => {
    const inputValue: HTMLDivElement | null | undefined = path(['current'], this.inputValueRef);
    const lastMessage = this.props.lastMessage;
    if (inputValue && inputValue.innerText.length === 0) {
      const messageQuestionDefinition = path<string>(['definition', '__typename'], lastMessage);
      const initValueForMessage =
        path<string>(['definition', 'phoneNumber'], this.props.lastMessage) ||
        path<string>(['definition', 'email'], this.props.lastMessage);
      this.props.changeInputInitValue(messageQuestionDefinition || '', initValueForMessage || '');
      inputValue.innerText = initValueForMessage || '';
    }
    this.focus(this.props, true);
  };

  render() {
    const { lastMessage, disabled, bounce, values, initInputValue, hideControls, respondents, t } = this.props;

    const selectedRange = path<number[]>(['definition', 'selectedRange'], lastMessage);

    const isInputDisabled =
      disabled ||
      this.hasTypename(definitionTypes[QuestionType.Select]) ||
      (lastMessage ? lastMessage.__typename === 'EndingMessage' : true);

    const isAnswerable = this.isAnswerable();
    const optional = path(['definition', 'optional'], lastMessage);

    const isPersonMultiselect = this.hasTypename(definitionTypes[QuestionType.PersonMultiselect]);
    const isChoiceQuestion = this.hasTypename(definitionTypes[QuestionType.Select]);
    const isTextMessage = this.hasTypename(definitionTypes[QuestionType.Freetext]);

    const hideInput =
      hideControls ||
      !isAnswerable ||
      (isPersonMultiselect && selectedRange && respondents.length >= selectedRange[1]) ||
      (isChoiceQuestion && selectedRange && selectedRange[1] === 1);

    const inputValue: HTMLDivElement | null | undefined = path(['current'], this.inputValueRef);

    const buttonDisabled =
      disabled ||
      (!optional && isTextMessage && !!inputValue && (inputValue.innerText || initInputValue).trim().length === 0) ||
      (selectedRange && isChoiceQuestion && values.length < selectedRange[0]) ||
      (selectedRange && isPersonMultiselect && respondents.length < selectedRange[0]) ||
      (lastMessage ? lastMessage.__typename === 'EndingMessage' : true) ||
      (isPersonMultiselect && !!inputValue && inputValue.innerText.trim() !== '');

    return (
      <ChatInput>
        {[
          definitionTypes[QuestionType.EmailContact],
          definitionTypes[QuestionType.SmsContact],
          definitionTypes[QuestionType.Freetext],
        ].includes(path(['definition', '__typename'], this.props.lastMessage)!) && (
          <ProgressBar width={this.props.progressWidth} />
        )}
        <Form onSubmit={this.handleSubmit} hide={hideInput} transparent={false}>
          <Input
            data-cy="chat-input"
            tabIndex={0}
            key={initInputValue}
            isDisabled={isInputDisabled}
            data-placeholder={optional ? this.props.t('writeOrContinue') : this.props.t('write')}
            contentEditable={!isInputDisabled}
            onFocus={!isInputDisabled ? this.handleFocus : undefined}
            smallerPadding={isChoiceQuestion && values.length !== 0}
            ref={this.inputValueRef}
            disabled={isInputDisabled}
            suppressContentEditableWarning
            onInput={this.prepareBounce}
            isBouncing={bounce}
            defaultValue={initInputValue}
            onKeyDown={this.onKeyDown}
            onKeyUp={this.handleKeyUp}
            error={this.state.error}
          >
            {initInputValue}
            {isChoiceQuestion && this.renderAddedChoices()}
          </Input>
          <SendView isBouncing={bounce && !buttonDisabled}>
            <SendButton data-cy="chat-input-send" type="submit" disabled={!isAnswerable || buttonDisabled}>
              <SendIcon size="18px" color={!isAnswerable || buttonDisabled ? 'grey' : '#fff'} />
              <span>{t('send')}</span>
            </SendButton>
          </SendView>
        </Form>
      </ChatInput>
    );
  }
}

interface IGetPropsFromProvider extends WithTranslation {
  disabled: boolean;
  progressWidth: number;
  verifyPhoneNumber?: any;
}

const GetPropsFromProvider = (props: IGetPropsFromProvider) => (
  <InputConsumer>
    {(context) => (
      <InputForm
        disabled={props.disabled}
        progressWidth={props.progressWidth}
        changeMessage={context.changeMessage}
        removeChoice={context.removeChoice}
        organizationId={context.organizationId}
        lastMessage={context.state.lastMessage}
        values={context.state.values}
        hideControls={context.state.hideControls}
        sendMessage={context.sendMessage}
        index={context.state.index}
        bounce={context.state.bounce}
        makeBounce={context.makeBounce}
        initInputValue={context.state.inputInitValue}
        handleSetMessage={context.handleSetMessage}
        t={props.t}
        i18n={props.i18n}
        tReady={props.tReady}
        respondents={context.state.selectedPersons}
        setLastKey={context.setLastKey}
        changeInputInitValue={context.editInputValue}
        verifyPhoneNumber={props.verifyPhoneNumber}
        setClearInputFunction={context.setClearInputFunction}
      />
    )}
  </InputConsumer>
);

const WithVerifyPhoneNumber = graphql<IGetPropsFromProvider>(verifyPhoneNumberMutation, {
  name: 'verifyPhoneNumber',
});

export default withTranslation('inputForm')(WithVerifyPhoneNumber(GetPropsFromProvider));
