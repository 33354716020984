import { useApolloClient } from '@apollo/client';
import { equals } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useRouteMatch, withRouter } from 'react-router-dom';
import { Loading } from '../components';
import { ReportSequenceSubscriptionSubscription, ReportSubscriptionSubscription } from '../generated/hooks';
import { reportSequenceSubscription } from '../graphql/subscription';
import { Error } from './components/Error';
import ReportSequence from './sequence/ReportSequencePage';
import { useReportSatismeter } from './components/hooks';

type ReportSequenceData = NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

interface IReportSequenceLoaderProps extends RouteComponentProps<any> {
  accessToken: string;
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void;
  setAllowedLanguages: (languages: string[]) => void;
  allowedLanguages?: string[];
  setNavbarContent?: (content: React.ReactNode) => void;
}

const ReportSequenceLoader = (props: IReportSequenceLoaderProps) => {
  const { t } = useTranslation('Report');
  const { path } = useRouteMatch();
  const [data, setData] = useState<ReportSequenceData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reloading, setReloading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const lastSubscription = useRef<any>(null);
  const params = new URLSearchParams(props.location.search);
  const client = useApolloClient();
  const [addedOnFrom, setAddedOnFrom] = useState(
    params.get('added_on_from') === 'null' ? null : params.get('added_on_from') ?? undefined,
  );
  const [addedOnTo, setAddedOnTo] = useState(
    params.get('added_on_to') === 'null' ? null : params.get('added_on_to') ?? undefined,
  );
  const [teamsFilter, setTeamsFilter] = useState(params.get('unit-teams')?.split(',') ?? []);
  const usedTeamsFilter = useRef<string[] | null>(null);
  const [usedAddedOnFrom, setUsedAddedOnFrom] = useState(addedOnFrom);
  const [usedAddedOnTo, setUsedAddedOnTo] = useState(addedOnTo);

  const handleAddedFromChange = (value: string | null) => {
    setAddedOnFrom(value);
    if (value) {
      params.set('added_on_from', value);
    } else {
      params.set('added_on_from', 'null');
    }
    props.history.push({
      pathname: props.location.pathname,
      search: params.toString(),
    });
  };

  const handleAddedToChange = (value: string | null) => {
    setAddedOnTo(value);
    if (value) {
      params.set('added_on_to', value);
    } else {
      params.set('added_on_to', 'null');
    }
    props.history.push({
      pathname: props.location.pathname,
      search: params.toString(),
    });
  };

  const publicAccess = path === '/process-results/:token';

  const loadData = (from: string | null | undefined, to: string | null | undefined, shoudReload?: boolean) => {
    if (
      props.selectedLanguage === data?.metadata?.language?.code &&
      from === data?.addedOnFrom &&
      to === data?.addedOnTo &&
      equals(teamsFilter, usedTeamsFilter.current) &&
      !shoudReload
    ) {
      setUsedAddedOnFrom(from);
      setUsedAddedOnTo(to);
      return;
    }
    if (data) {
      setReloading(true);
    } else {
      setLoading(true);
    }
    setError(null);
    usedTeamsFilter.current = teamsFilter;
    setUsedAddedOnFrom(from);
    setUsedAddedOnTo(to);
    if (lastSubscription.current) {
      lastSubscription.current.unsubscribe();
    }
    const savedCheckedValue = localStorage.getItem(`hideRespondents:${props.accessToken}`);
    lastSubscription.current = client
      .subscribe({
        query: reportSequenceSubscription,
        variables: {
          accessToken: props.accessToken,
          addedOnFrom: from,
          addedOnTo: to,
          languageCode: props.selectedLanguage,
          teamsFilter,
          publicAccess,
          withoutDeleted: savedCheckedValue ? JSON.parse(savedCheckedValue) : false,
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: (result) => {
          setData(result.data.getProcessReport);
          setLoading(false);
          setReloading(false);

          props.changeLanguage(result.data.getProcessReport.metadata.language!.code);
          if (!params.get('added_on_from')) {
            handleAddedFromChange(result.data.getProcessReport.addedOnFrom?.toString());
          }
          if (!params.get('added_on_to')) {
            handleAddedToChange(result.data.getProcessReport.addedOnTo?.toString());
          }
          // Everything is loaded
          if (result.data.getProcessReport.rootTeamsStatistics && lastSubscription.current) {
            lastSubscription.current.unsubscribe();
          }
        },
        error: (err) => {
          setLoading(false);
          setReloading(false);
          setError(err);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadData(addedOnFrom, addedOnTo), [props.selectedLanguage]);

  useReportSatismeter(data?.metadata, publicAccess);

  const setAddedOnAndLoad = (from: string | null, to: string | null) => {
    handleAddedFromChange(from);
    handleAddedToChange(to);
    loadData(from, to);
  };

  if (!loading && error) {
    return <Error>{t('errorLoading')}</Error>;
  }
  if (loading || !data) {
    return <Loading />;
  }

  return (
    <ReportSequence
      {...props}
      reportSequence={data}
      reloading={reloading}
      publicAccess={publicAccess}
      setTeamsFilter={setTeamsFilter}
      teamsFilter={teamsFilter}
      usedTeamsFilter={usedTeamsFilter.current}
      setAddedOnFrom={handleAddedFromChange}
      setAddedOnTo={handleAddedToChange}
      setAddedOnAndLoad={setAddedOnAndLoad}
      addedOnFrom={addedOnFrom}
      addedOnTo={addedOnTo}
      usedAddedOnFrom={usedAddedOnFrom}
      usedAddedOnTo={usedAddedOnTo}
      onConfirm={(shoudReload?: boolean) => loadData(addedOnFrom, addedOnTo, shoudReload)}
    />
  );
};

export default withRouter(ReportSequenceLoader);
