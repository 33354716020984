import { theme } from '@arnold/common';
import { ReportSubscriptionSubscription } from '../../generated/hooks';

type BaseAnswer = {
  index: string;
  count?: number;
};
type AnswerWithHeight = {
  index: string;
  weight: number;
};

export type Answer = {
  index: string;
  color: string;
  hoverColor: string;
  text: string;
  count?: number;
};

type Questions = NonNullable<ReportSubscriptionSubscription['getReport']['questions']>[0];

export const getHighlightedAnswers = (question: Questions) => {
  const highlightedAnswers: {
    [index: string]: number;
  } = question.answersToHighlight
    ? question.answersToHighlight.reduce(
        (acc, currentItem) => ({
          ...acc,
          [currentItem]: (acc[currentItem] || 0) + 1,
        }),
        {},
      )
    : {};
  return Object.keys(highlightedAnswers)
    .map((answerIndex) => ({
      index: answerIndex,
      count: highlightedAnswers[answerIndex],
    }))
    .sort((firstEl, secondEl) => secondEl.count - firstEl.count);
};

export const addWeights = (answers: BaseAnswer[], reverse: boolean) => {
  const { length } = answers;
  return answers.map((answer, index) => ({
    ...answer,
    weight: reverse ? index + 1 : length - index,
  }));
};

export enum AnswerType {
  NEUTRAL,
  WARNING,
  ALERT,
}

const COLORS = {
  [AnswerType.NEUTRAL]: theme.colors.chart.neutral,
  [AnswerType.WARNING]: theme.colors.chart.warning,
  [AnswerType.ALERT]: theme.colors.chart.alert,
};

const HOVER_COLORS = {
  [AnswerType.NEUTRAL]: theme.colors.emotionInformative.hover,
  [AnswerType.WARNING]: theme.colors.emotionWarning.hover,
  [AnswerType.ALERT]: theme.colors.emotionDanger.hover,
};

const getColorForTypeAndWeight = (type: AnswerType, weight: number) => {
  const colorsForType = COLORS[type];
  const index = weight - 1;
  if (index < 0) {
    return colorsForType[0];
  }
  if (index >= colorsForType.length) {
    return colorsForType[colorsForType.length - 1];
  }
  return colorsForType[index];
};

export const addColors = (answers: AnswerWithHeight[], type: AnswerType) =>
  answers.map((answer) => ({
    ...answer,
    color: getColorForTypeAndWeight(type, answer.weight),
    hoverColor: HOVER_COLORS[type],
  }));

export const prepareAnswersForQuestion = (question: Questions | null): Answer[] => {
  if (!question || !question.answersLocal) {
    return [];
  }
  const highlightedAnswers = getHighlightedAnswers(question);
  const highlightedAnswerIds = highlightedAnswers.map((answer) => answer.index);
  const alerts = addColors(
    addWeights(
      highlightedAnswers.filter((answer) => answer.count > 1),
      false,
    ),
    AnswerType.ALERT,
  );
  const warnings = addColors(
    addWeights(
      highlightedAnswers.filter((answer) => answer.count === 1),
      false,
    ),
    AnswerType.WARNING,
  );
  const neutrals = addColors(
    addWeights(
      Object.keys(question.answersLocal)
        .filter((index) => !highlightedAnswerIds.includes(index))
        .map((index) => ({ index })),
      true,
    ),
    AnswerType.NEUTRAL,
  );
  const answersWithColors = [...alerts, ...warnings, ...neutrals]
    .map((answer) => ({
      ...answer,
      text: question && question.answersLocal && question.answersLocal[answer.index],
    }))
    .sort((a, b) => parseInt(a.index, 10) - parseInt(b.index, 10));
  return answersWithColors;
};
