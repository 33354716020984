import { CancelIcon, CleanButton, theme } from '@arnold/common';
import { flatten, prop, uniqBy } from 'ramda';
import * as React from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  aggregateGraphData,
  getGraphRowsFromAggregatedData,
  getTotalRespondentCount,
} from '../../lib/reports/graphHelpers';
import { AnswerType } from '../../lib/reports/question';
import { DownloadButton } from './DownloadButton';

export type GraphRow = {
  description: string;
  type: AnswerType;
  count?: number;
  totalRespondentsCount?: any;
  segments: Array<{
    id: string;
    header?: string;
    color?: string;
    segmentSeverity?: number;
    percentage?: number;
    hoverColor?: string;
    values: string[];
    traversable: boolean;
    directMembers?: boolean;
    traversableInfo?: {
      teamId: string;
      teamName: string;
    };
  }>;
};

export type GraphData = {
  label?: string;
  description?: string;
  rows: GraphRow[];
};

interface IProps {
  data: GraphData | null;
  removable?: boolean;
  disableSegmentSorting?: boolean;
  addWidget?: (a: { id: string; name: string }, displayDirectPeople?: boolean) => void;
  setHighlightedAnswer?: (index: string | null) => void;
  removeWidgets?: () => void;
  handleDownload?: (classname?: string) => void;
  index?: string;
  disableGraphClick?: boolean;
}

const BASE_CELL_CLASS = 'barCellClass';
const TYPE_CLASS_MAPPING = {
  [AnswerType.NEUTRAL]: `${BASE_CELL_CLASS}--neutral`,
  [AnswerType.WARNING]: `${BASE_CELL_CLASS}--warning`,
  [AnswerType.ALERT]: `${BASE_CELL_CLASS}--alert`,
};

const BASE_TOOLTIP_STYLE: React.CSSProperties = {
  backgroundColor: theme.colors.backgroundDark.default,
  color: theme.colors.textInverted.primary,
  zIndex: 2147483643,
  padding: '10px',
  margin: '10px 0px 0px -10px',
  borderRadius: '4px',
  fontWeight: 'normal',
};

const TOOLTIP_DATA_COUNT = 29;

const Graph = ({
  addWidget,
  removeWidgets,
  data,
  disableSegmentSorting,
  removable,
  setHighlightedAnswer,
  handleDownload,
  index,
  disableGraphClick,
}: IProps) => {
  const { t, i18n } = useTranslation('Report');
  const [graphLabelTooltip, setGraphLabelTooltip] = React.useState<string | null>(null);
  const [tooltipData, setTooltipData] = React.useState<{
    entry: string;
    key: string;
  }>();

  if (data === null) {
    return <></>;
  }

  const aggregatedData = aggregateGraphData(data.rows);

  const totalCount = getTotalRespondentCount(data.rows);

  const graphData = getGraphRowsFromAggregatedData(aggregatedData, totalCount, !disableSegmentSorting);

  const renderTooltip = () => {
    if (
      tooltipData &&
      tooltipData.key &&
      aggregatedData[tooltipData.entry] &&
      aggregatedData[tooltipData.entry].values[tooltipData.key]
    ) {
      const values = aggregatedData[tooltipData.entry].values[tooltipData.key].values;
      return (
        <div className={'font-size-xs'} style={BASE_TOOLTIP_STYLE}>
          {aggregatedData[tooltipData.entry].values[tooltipData.key].name && (
            <div style={{ color: theme.colors.text.disabled, width: '100%' }}>
              {aggregatedData[tooltipData.entry].values[tooltipData.key].name}
            </div>
          )}
          <div
            style={{
              columns: `110px ${Math.ceil(Math.min(3, Math.max(3, values.length / TOOLTIP_DATA_COUNT)))}`,
            }}
          >
            {values
              .filter((_: any, index: number) => index < TOOLTIP_DATA_COUNT)
              .map((value: string, index: number) => (
                <span key={index}>
                  {value.length > 15 ? value.substring(0, 15) + '...' : value}
                  <br />
                </span>
              ))}
            {
              <div style={{ color: theme.colors.text.disabled, width: '100%' }}>
                {values.length > TOOLTIP_DATA_COUNT && t('moreItems', { count: values.length - TOOLTIP_DATA_COUNT })}
              </div>
            }
          </div>
        </div>
      );
    }
    return undefined;
  };

  const handleHover = (entry: string, key: string) => () => {
    setTooltipData({
      entry,
      key,
    });
    if (setHighlightedAnswer) {
      setHighlightedAnswer(key.replace('answer#', ''));
    }
  };

  const handleHoverOff = () => {
    setTooltipData({ entry: '0', key: '0' });
    if (setHighlightedAnswer) {
      setHighlightedAnswer(null);
    }
  };
  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props;

    const val = value[0] || 0;
    return (
      <g>
        {val === 0 && <rect x={x} y={y} width="4" height="22" fill={theme.colors.chart.neutral[0]} />}
        <text
          x={x + width + (val === 0 ? 12 : 8)}
          y={y + height / 2 + 1}
          fill={val === 0 ? theme.colors.text.disabled : '#3F3F3F'}
          textAnchor="right"
          dominantBaseline="middle"
        >
          {val + '% '}
          <tspan fill={theme.colors.text.disabled} dominantBaseline="middle">
            {'(' + value[1].toLocaleString(i18n.language) + ')'}
          </tspan>
        </text>
      </g>
    );
  };

  const renderCustomizedLegend = () => {
    return (
      <div
        style={{
          position: 'relative',
          left: 120,
          right: 0,
          top: -10,
          width: 'calc(100% - 18px)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <span style={{ color: '#3F3F3F' }}>{data.label}</span>
          <span style={{ color: theme.colors.text.disabled }}>{data.description}</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: `${theme.spacing.e}` }}>
          <DownloadButton handleDownload={handleDownload} index={index} />
          {removable && (
            <CleanButton onClick={removeWidgets} className="hide-from-export">
              <CancelIcon />
            </CleanButton>
          )}
        </div>
      </div>
    );
  };

  const getValue = (b: any) => (a: any) => {
    const found = a.values[b];
    return found ? found.percent : 0;
  };

  const getId = (b: any) => (a: any) => {
    const found = a.values[b];
    return found ? found.id : null;
  };

  const renderAxisLabel = (label: string) => {
    return label.length > 35 ? label.substring(0, 35) + '...' : label;
  };

  const traverseTeam =
    (segmentData: {
      id: string;
      name: string;
      traversable: boolean;
      directMembers?: boolean;
      traversableInfo?: {
        teamId: string;
        teamName: string;
      };
    }) =>
    () => {
      if (addWidget && segmentData.traversable) {
        addWidget(
          {
            id: segmentData.traversableInfo ? segmentData.traversableInfo.teamId : segmentData.id,
            name: segmentData.traversableInfo ? segmentData.traversableInfo.teamName : segmentData.name,
          },
          segmentData.directMembers,
        );
      }
      return segmentData.id;
    };

  const uniqueSegments = uniqBy<any, { id: string }>(prop('id'), flatten(graphData.map((d) => d.values)));

  return (
    <div data-cy="report-stacked-chart" data-for-query-selector-stacked-chart>
      {!removable && (
        <div style={{ position: 'absolute', right: '60px', zIndex: '100' }}>
          <DownloadButton handleDownload={handleDownload} index={index} />
        </div>
      )}
      <MouseTooltip
        visible={graphLabelTooltip}
        offsetX={15}
        offsetY={10}
        style={{
          ...BASE_TOOLTIP_STYLE,
          fontSize: '12px',
        }}
      >
        {graphLabelTooltip}
      </MouseTooltip>
      <ResponsiveContainer width={'100%'} height={data.rows.length * 30 + (data.label ? 20 : 0)} minWidth={340}>
        <BarChart
          layout="vertical"
          data={graphData}
          margin={{
            top: 0,
            right: 120,
            left: 0,
            bottom: 0,
          }}
          className="barChartClass"
        >
          <CartesianGrid horizontal={false} strokeWidth={1} stroke="#EDEDED" />
          <XAxis type="number" hide={true} ticks={[0, 25, 50, 75, 100]} domain={[0, 100]} />
          <YAxis
            type="category"
            dataKey="name"
            width={256}
            axisLine={false}
            tickLine={false}
            onMouseEnter={(tick) => {
              if (tick.value.length > 35) {
                setGraphLabelTooltip(tick.value);
              }
            }}
            onMouseLeave={() => {
              setGraphLabelTooltip(null);
            }}
            tickFormatter={renderAxisLabel}
            tick={{ fill: '#3F3F3F', style: { fontSize: '12px' } }}
          />
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            cursor={{ fill: 'transparent' }}
            content={renderTooltip}
            wrapperStyle={{ zIndex: 2147483643 }}
          />
          {uniqueSegments.map((_, i) => {
            const segment = i;
            return (
              <Bar key={i} dataKey={getValue(segment)} stackId="uniqueId" barSize={22} isAnimationActive={false}>
                {graphData.map((entry) => {
                  const isHovered = tooltipData && getId(segment)(entry) === tooltipData.key;
                  const segmentData = aggregatedData[entry.id].values[getId(segment)(entry)];

                  return (
                    <Cell
                      key={`cell-${entry.id}`}
                      cursor="pointer"
                      style={{
                        border: 'solid red 2px',
                        fill: segmentData ? (isHovered ? segmentData.hoverColor : segmentData.color) : undefined,
                        cursor: disableGraphClick ? 'default' : 'pointer',
                      }}
                      className={`${BASE_CELL_CLASS} ${TYPE_CLASS_MAPPING[entry.type]} ${isHovered ? 'hovered' : ''}`}
                      onClick={disableGraphClick ? undefined : traverseTeam(segmentData)}
                      onMouseEnter={handleHover(entry.id, getId(segment)(entry))}
                      onMouseLeave={handleHoverOff}
                    />
                  );
                })}
                {i + 1 === uniqueSegments.length && (
                  <LabelList position="right" dataKey="label" content={renderCustomizedLabel} />
                )}
              </Bar>
            );
          })}
          {data.label && <Legend verticalAlign="top" content={renderCustomizedLegend} />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default React.memo(Graph);
