import styled from '@emotion/styled/macro';
import { Checkbox, theme, Tooltip } from '@arnold/common';
import { TFunction } from 'react-i18next';
import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { ReactComponent as Line } from '@arnold/common/lib/assets/icons/Line.svg';

const KeyWordRow = styled.div`
  display: flex;
  margin-top: ${theme.spacing.d};
  align-items: center;
  .inline-checkbox {
    top: -11px;
  }
`;

interface IKeyWordBarProps {
  percents?: number;
  selected?: boolean;
}

const KeyWordRowButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: ${({ percents }: IKeyWordBarProps) => `calc(328px + ((100% - 361px) * ${(percents || 0) / 100}))`};

  :hover span {
    color: ${theme.colors.actionPrimary.hover} !important;
  }

  :hover .bar {
    background-color: ${theme.colors.actionPrimary.hover} !important;
  }
`;

const KeyWordBar = styled.div`
  background-color: ${({ selected }: IKeyWordBarProps) =>
    `${selected ? theme.colors.actionPrimary.default : theme.colors.actionInvertedPrimary.default}`};
  width: 100%;
  height: ${theme.spacing.g};
  border-radius: 6px;
`;

const KeyWordSpan = styled.span`
  font-size: ${theme.typography.body.small?.regular!.fontSize};
  font-weight: ${theme.typography.body.small?.regular!.fontWeight};
  line-height: ${theme.typography.body.small?.regular!.lineHeight};
  color: ${theme.colors.text.primary};
  margin-right: ${theme.spacing.g};
  display: flex;
  align-items: center;
  width: 220px;
  min-height: ${theme.spacing.h};
`;

const KeyWordCountSpan = styled.span`
  font-size: ${theme.typography.body.medium?.regular!.fontSize};
  font-weight: ${theme.typography.body.small?.regular!.fontWeight};
  line-height: ${theme.typography.body.small?.regular!.lineHeight};
  color: ${theme.colors.text.secondary};
  min-width: ${theme.spacing.m};
  margin-right: ${theme.spacing.c};
  text-align: start;
`;

interface IKeyWordProps {
  keyWord: string;
  selected: boolean;
  count: number;
  maxCount: number;
  biggestKeyWordCount: number;
  onSelect: any;
  t: TFunction;
}

export const KeyWord = ({ keyWord, selected, count, maxCount, biggestKeyWordCount, onSelect, t }: IKeyWordProps) => (
  <KeyWordRow>
    <div data-pptx-hide>
      <Checkbox
        onToggle={onSelect}
        selected={selected}
        partlySelected={false}
        text=""
        checkIcon={<Check />}
        lineIcon={<Line />}
      />
    </div>
    <KeyWordRowButton
      percents={Math.ceil((count / biggestKeyWordCount) * 100)}
      onClick={onSelect}
      data-icom={'btn-keyword'}
    >
      <Tooltip title={keyWord} disabled={keyWord.length <= 80}>
        <KeyWordSpan>{keyWord.length > 80 ? keyWord.slice(0, 78) + '…' : keyWord}</KeyWordSpan>
      </Tooltip>
      <KeyWordCountSpan>
        {count} ({Math.ceil((count / maxCount) * 100)}%)
      </KeyWordCountSpan>
      <KeyWordBar selected={selected} className="bar" />
    </KeyWordRowButton>
  </KeyWordRow>
);
