import {
  pushToDataLayer,
  createEventData,
  SelectWrapper,
  selectControlStyles,
  Checkbox,
  cleanArray,
  ItemWithCheckboxContainer,
} from '@arnold/common';
import styled from '@emotion/styled/macro';
// eslint-disable-next-line id-blacklist
import { any, contains } from 'ramda';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { ReactComponent as Line } from '@arnold/common/lib/assets/icons/Line.svg';
import { Filter, FilterOption } from '../../lib/reportHelper';
import { AggregatedData, AggregatedTeams, Team } from '../../lib/reports/aggregateData';
import { AggregatedReportDataContext } from '../../lib/reports/aggregatedReportDataContext';
import { DisabledPlaceholder, SelectFilterSelected } from '../../components/SelectFilterHelpers';
import auth from '../../lib/auth';
import { hasTeamDirectResponses, hasTeamResponses, isTeamNonempty } from '../../lib/reports/queryAggregatedData';
import { FilterBox } from './StyledComponents';

const FilterGroupTeamHeader = styled(ItemWithCheckboxContainer)`
  padding: 9px;
  padding-left: ${({ level }: { level: number }) => 18 + level * 16}px;
`;

const OptionName = styled.span`
  white-space: nowrap;
`;

interface IMenuItemProps {
  filter?: Filter;
  teamId: string;
  level: number;
  displayDirectMembers?: boolean;
  hideSubTeams?: boolean;
  isSequenceFilter?: boolean;
  preselected?: boolean;
  selectFilteredAnswer: (teamId: string, filterType: string) => (e: React.MouseEvent<any, MouseEvent>) => void;
}

const sortTeamsByName = (teamIds: string[], teams: AggregatedTeams) => {
  return teamIds.sort((a, b) => teams[a].team.teamName.localeCompare(teams[b].team.teamName));
};

const getFirstSelectedTeam = (
  selectedTeamIds: string[],
  teams: AggregatedTeams,
  memberTeams: string[],
  disableTopLevelSort?: boolean,
): string | undefined => {
  const sortedTeams = disableTopLevelSort ? memberTeams : sortTeamsByName(memberTeams, teams);
  for (const currentTeamId of sortedTeams) {
    const foundTeamId = selectedTeamIds.includes(currentTeamId)
      ? currentTeamId
      : getFirstSelectedTeam(selectedTeamIds, teams, teams[currentTeamId].team.memberTeams || []);
    if (foundTeamId) {
      return foundTeamId;
    }
  }

  return undefined;
};

const MenuItem = ({
  filter,
  teamId,
  preselected,
  hideSubTeams,
  displayDirectMembers,
  level,
  selectFilteredAnswer,
  isSequenceFilter,
}: IMenuItemProps) => {
  const { t } = useTranslation('Report');
  const aggregatedData = React.useContext(AggregatedReportDataContext);
  if (!aggregatedData) {
    return null;
  }
  const team: Team = aggregatedData.teams[teamId].team;
  const selectedTeams =
    (filter &&
      filter.values &&
      filter.values.map((value) => value?.replace(displayDirectMembers ? 'team_' : 'unit_', ''))) ||
    [];
  const allContained =
    (!displayDirectMembers &&
      filter &&
      filter.values &&
      filter.values.map((value) => value?.replace('unit_', '').replace('team_', ''))) ||
    [];

  const toggleFunction = selectFilteredAnswer(team.id, displayDirectMembers ? 'team' : 'unit');
  const isSelected = preselected || (selectedTeams.includes(team.id) && (!hideSubTeams || selectedTeams.length === 1));
  return (
    <div key={team.id}>
      <FilterGroupTeamHeader key={team.id} onClick={toggleFunction} level={level}>
        <Checkbox
          onToggle={toggleFunction}
          selected={preselected || isSelected}
          partlySelected={
            !hideSubTeams &&
            (allContained.includes(team.id) ||
              (!!team.memberTeams && any((subTeamId) => allContained.includes(subTeamId), team.memberTeams)))
          }
          text={
            <OptionName>
              {displayDirectMembers
                ? t('directMembers')
                : team.teamName.length
                  ? team.teamName
                  : t('withoutSupervisor')}
            </OptionName>
          }
          checkIcon={<Check />}
          lineIcon={<Line />}
        />
      </FilterGroupTeamHeader>
      {!hideSubTeams && (
        <div>
          {!displayDirectMembers &&
            team.directMembers &&
            !!team.directMembers.length &&
            (isSequenceFilter || hasTeamDirectResponses(aggregatedData.teams, team)) &&
            team.memberTeams &&
            !!team.memberTeams.filter(
              (aTeam) =>
                isTeamNonempty(aggregatedData.teams, aggregatedData.teams[aTeam].team) &&
                (isSequenceFilter || hasTeamResponses(aggregatedData.teams, aggregatedData.teams[aTeam].team)),
            ).length && (
              <MenuItem
                level={level + 1}
                key={`team_${team.id}`}
                filter={filter}
                teamId={team.id}
                isSequenceFilter={isSequenceFilter}
                displayDirectMembers={true}
                preselected={isSelected}
                selectFilteredAnswer={selectFilteredAnswer}
              />
            )}
          {!displayDirectMembers &&
            team.memberTeams &&
            sortTeamsByName(
              team.memberTeams.filter(
                (aTeam) =>
                  isTeamNonempty(aggregatedData.teams, aggregatedData.teams[aTeam].team) &&
                  (isSequenceFilter || hasTeamResponses(aggregatedData.teams, aggregatedData.teams[aTeam].team)),
              ),
              aggregatedData.teams,
            ).map((value) => (
              <MenuItem
                level={level + 1}
                key={value}
                filter={filter}
                isSequenceFilter={isSequenceFilter}
                teamId={value}
                preselected={isSelected}
                selectFilteredAnswer={selectFilteredAnswer}
              />
            ))}
        </div>
      )}
    </div>
  );
};

interface IProps {
  filters: Filter[];
  className?: string;
  rootTeamsIds: string[];
  setFilters: (filters: Filter[]) => void;
  respondentId?: string | null;
  isSequenceFilter?: boolean;
  aggregatedData: AggregatedData | null;
}

const isContainedInSomeUnitFilter = (
  selectedValues: string[],
  aggregatedData: AggregatedData,
  teamId: string,
  type: string,
) => {
  const units = selectedValues.filter((val) => val.startsWith('unit_')).map((val) => val.replace('unit_', ''));
  return (
    units.find((unitId: string) => aggregatedData.teams[unitId].team.subTeams.includes(teamId)) ||
    (type === 'team' && units.includes(teamId) && teamId)
  );
};

const GlobalTeamFilter = ({
  className,
  rootTeamsIds,
  respondentId,
  filters,
  aggregatedData,
  setFilters,
  isSequenceFilter,
}: IProps) => {
  const { t } = useTranslation('Report');
  const scrollPositionRef = React.useRef<number>(0);

  if (!aggregatedData) {
    return (
      <SelectWrapper
        blurInputOnSelect={false}
        menuShouldScrollIntoView={false}
        className={`react-select ${className || ''}`}
        isDisabled={true}
        components={{
          IndicatorSeparator: () => null,
          Placeholder: () => <DisabledPlaceholder>{t('filterByTeams')}</DisabledPlaceholder>,
        }}
        styles={{
          container: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            width: '100% !important',
          }),
        }}
        isSearchable={false}
        isMulti={false}
        closeMenuOnSelect={false}
      />
    );
  }
  const rootTeams = rootTeamsIds.map((id) => aggregatedData.teams[id].team);
  const respondentTeamId = Object.keys(aggregatedData.teams).find(
    (teamId) => aggregatedData.teams[teamId].team.teamLeader === respondentId,
  );
  const respondentTeam = respondentTeamId && aggregatedData.teams[respondentTeamId].team;
  const sortedRootTeams = rootTeams
    .filter(
      (rootTeam) =>
        isTeamNonempty(aggregatedData.teams, rootTeam) &&
        (isSequenceFilter || hasTeamResponses(aggregatedData.teams, rootTeam)),
    )
    .sort((aTeam, bTeam) => {
      return bTeam.id === respondentTeamId
        ? 10000000
        : aTeam.id === respondentTeamId
          ? -10000000
          : aTeam.teamName.localeCompare(bTeam.teamName);
    });

  const toggleFilteredAnswer = (teamId: string, filterType: string) => (e: React.MouseEvent<any, MouseEvent>) => {
    const user = auth.getUser();
    if (user) {
      pushToDataLayer({
        userId: user.id,
        orgId: user.orgId,
        event: 'ux.report-filter-team',
        ...createEventData('report', 'filterTeam', 'report filter team'),
      });
    }
    const existingFilterIndex = filters.findIndex((filter) => filter.operator === 'unit-teams');
    const newFilterValue = `${filterType}_${teamId}`;

    if (existingFilterIndex !== -1) {
      const newFilters = [...filters];
      const values = cleanArray(newFilters[existingFilterIndex].values);
      let newValues: string[] = [];
      const isContained = isContainedInSomeUnitFilter(values, aggregatedData, teamId, filterType);
      if (isContained) {
        newValues = values.filter((v) => v !== `unit_${isContained}`);
        const addExplicitlySubTeams = (
          parentTeamId: string,
          excludedTeamId: string,
          filterTypeOfExcludedTeam: string,
        ) => {
          const team = aggregatedData.teams[parentTeamId].team;
          if (
            team.subTeams.includes(excludedTeamId) ||
            (filterTypeOfExcludedTeam === 'team' && team.id === excludedTeamId)
          ) {
            if (team.memberTeams) {
              team.memberTeams
                .filter((tId: string) => filterTypeOfExcludedTeam === 'team' || tId !== excludedTeamId)
                .forEach((tId: string) => addExplicitlySubTeams(tId, excludedTeamId, filterTypeOfExcludedTeam));
            }
          } else {
            const type = 'unit';
            newValues.push(`${type}_${parentTeamId}`);
          }
        };
        addExplicitlySubTeams(isContained, teamId, filterType);
        if (isContained !== teamId) {
          newValues.push(`team_${isContained}`);
        }
      } else if (contains(newFilterValue, values)) {
        newValues = values.filter((v) => v !== newFilterValue);
      } else {
        newValues = [...values, newFilterValue];
      }

      const addMissingUnits = (parentTeamId: string) => {
        if (newValues.includes(`unit_${parentTeamId}`)) {
          return true;
        }
        const team = aggregatedData.teams[parentTeamId].team;
        const containsDirectMembers = !(
          team.directMembers &&
          team.directMembers.length &&
          !newValues.includes(`team_${parentTeamId}`)
        );
        if (team.memberTeams && team.memberTeams.length) {
          const allMemberTeamsResult = team.memberTeams.map(addMissingUnits).filter((val) => val !== null);
          const containsAllMemberTeams = !allMemberTeamsResult.includes(false);
          if (containsAllMemberTeams && containsDirectMembers) {
            if (allMemberTeamsResult.length > 0) {
              newValues.push(`unit_${parentTeamId}`);
            }
            return true;
          }
          return false;
        }
        if (!team.directMembers || !team.directMembers.length) {
          return null;
        }
        return containsDirectMembers;
      };
      rootTeams.forEach(({ id }) => {
        addMissingUnits(id);
      });
      newValues = newValues.filter(
        (val) =>
          !isContainedInSomeUnitFilter(
            newValues,
            aggregatedData,
            val.replace('unit_', '').replace('team_', ''),
            val.substring(0, 4),
          ),
      );

      if (newValues.length === 0) {
        newFilters.splice(existingFilterIndex, 1);
      } else {
        newFilters[existingFilterIndex] = {
          ...newFilters[existingFilterIndex],
          values: newValues,
        };
      }
      setFilters(newFilters);
    } else {
      setFilters([
        ...filters,
        {
          operator: 'unit-teams',
          values: [newFilterValue],
        },
      ]);
    }
    e.preventDefault();
  };

  const selectFilteredAnswer = (teamId: string, filterType: string) => (e: React.MouseEvent<any, MouseEvent>) => {
    const existingFilterIndex = filters.findIndex((filter) => filter.operator === 'unit-teams');
    const newFilterValue = `${filterType}_${teamId}`;

    if (existingFilterIndex !== -1) {
      const newFilters = [...filters];
      const values = newFilters[existingFilterIndex].values || [];
      const newValues: string[] = contains(newFilterValue, values) && values.length === 1 ? [] : [newFilterValue];

      if (newValues.length === 0) {
        newFilters.splice(existingFilterIndex, 1);
      } else {
        newFilters[existingFilterIndex] = {
          ...newFilters[existingFilterIndex],
          values: newValues,
        };
      }
      setFilters(newFilters);
    } else {
      setFilters([
        ...filters,
        {
          operator: 'unit-teams',
          values: [newFilterValue],
        },
      ]);
    }
    e.preventDefault();
  };

  const CustomMenu = (props: { opt: FilterOption }) => {
    const menuRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      const handle = () => {
        if (menuRef.current) {
          scrollPositionRef.current = menuRef.current.scrollTop;
        }
      };
      if (menuRef.current) {
        menuRef.current.scrollTop = scrollPositionRef.current;
        menuRef.current.addEventListener('scroll', handle);
      }
      return () => {
        if (menuRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          menuRef.current.removeEventListener('scroll', handle);
        }
      };
    }, []);
    const unitTeamFilter = filters.filter((filter) => filter.operator === 'unit-teams')[0];
    return (
      <components.Menu {...props}>
        <FilterBox ref={menuRef} className={'bg-white rounded-lg shadow-sm position-absolute'}>
          {respondentTeamId &&
            respondentTeam &&
            respondentTeam.memberTeams &&
            !!respondentTeam.memberTeams.filter(
              (aTeam) =>
                isTeamNonempty(aggregatedData.teams, aggregatedData.teams[aTeam].team) &&
                (isSequenceFilter || hasTeamResponses(aggregatedData.teams, aggregatedData.teams[aTeam].team)),
            ).length && (
              <>
                <MenuItem
                  level={0}
                  key={`t_${respondentTeamId}`}
                  filter={unitTeamFilter}
                  teamId={respondentTeamId}
                  isSequenceFilter={isSequenceFilter}
                  hideSubTeams={true}
                  selectFilteredAnswer={selectFilteredAnswer}
                />
                {respondentTeam.directMembers &&
                  !!respondentTeam.directMembers.length &&
                  (isSequenceFilter || hasTeamDirectResponses(aggregatedData.teams, respondentTeam)) && (
                    <MenuItem
                      level={0}
                      key={`td_${respondentTeamId}`}
                      filter={unitTeamFilter}
                      teamId={respondentTeamId}
                      hideSubTeams={true}
                      isSequenceFilter={isSequenceFilter}
                      displayDirectMembers={true}
                      selectFilteredAnswer={selectFilteredAnswer}
                    />
                  )}
                <hr />
              </>
            )}
          {sortedRootTeams &&
            sortedRootTeams.map((rootTeam) => (
              <MenuItem
                level={0}
                key={rootTeam.id}
                filter={unitTeamFilter}
                teamId={rootTeam.id}
                isSequenceFilter={isSequenceFilter}
                selectFilteredAnswer={toggleFilteredAnswer}
              />
            ))}
        </FilterBox>
      </components.Menu>
    );
  };

  const CustomPlaceholder = () => {
    const selectedFilter = filters.find((filter) => filter.operator === 'unit-teams');
    const firstTeamId =
      selectedFilter &&
      selectedFilter.values &&
      getFirstSelectedTeam(
        selectedFilter.values.map((v) => v?.replace('unit_', '').replace('team_', '')!),
        aggregatedData.teams,
        sortedRootTeams.map((team) => team.id),
        true,
      );
    const firstTeam = firstTeamId && aggregatedData.teams[firstTeamId].team;
    const otherTeams =
      selectedFilter && selectedFilter.values ? Array.from(Array(selectedFilter.values.length - 1).map(() => '')) : [];
    return (
      <SelectFilterSelected
        emptyPlaceholder={t('filterByTeams')}
        selectedAnswers={
          firstTeam
            ? firstTeam.teamName.length
              ? [firstTeam.teamName, ...otherTeams]
              : [t('withoutSupervisor'), ...otherTeams]
            : []
        }
      />
    );
  };

  return (
    <SelectWrapper
      blurInputOnSelect={false}
      defaultMenuIsOpen={false}
      menuShouldScrollIntoView={false}
      className={`react-select ${className || ''}`}
      components={{
        IndicatorSeparator: () => null,
        Menu: CustomMenu,
        Placeholder: CustomPlaceholder,
      }}
      styles={{
        control: (provided: any, state: any) => ({
          ...selectControlStyles(provided, state),
          paddingLeft: '0',
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          display: 'flex',
        }),
        container: (provided: any, state: any) => ({
          ...provided,
          width: '100% !important',
          margin: '0px',
        }),
      }}
      isSearchable={false}
      isMulti={false}
      closeMenuOnSelect={false}
    />
  );
};

export default GlobalTeamFilter;
