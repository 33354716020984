import { startOfMonth } from 'date-fns';

export function getMonthsInRange(startDate: Date, endDate: Date, selectedDate: Date): Date[] {
  const monthsInRange: Date[] = [];

  // Ensure selectedDate is within the allowed time range
  if (startOfMonth(selectedDate) < startOfMonth(startDate) || selectedDate > endDate) {
    throw new Error('Selected date is outside the allowed time range.');
  }

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const selectedYear = selectedDate.getFullYear();

  // Loop through each year in the range
  for (let year = startYear; year <= endYear; year++) {
    // Check if the current year is the year of the selected date
    if (year === selectedYear) {
      // Loop through each month of the selected year
      for (let month = 0; month < 12; month++) {
        const currentDate = new Date(year, month, 1);

        // Check if the current date is within the allowed time range
        if (startOfMonth(currentDate) >= startOfMonth(startDate) && currentDate <= endDate) {
          monthsInRange.push(currentDate);
        }
      }
    }
  }
  return monthsInRange;
}

export function getYearsInRange(startDate: Date, endDate: Date, selectedDate: Date): Date[] {
  const yearsInRange: Date[] = [];

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const month = selectedDate.getMonth();

  for (let year = startYear; year <= endYear; year++) {
    const currentDate = new Date(year, month, 1);

    if (currentDate > endDate) {
      yearsInRange.push(endDate);
    } else if (currentDate < startDate) {
      yearsInRange.push(startDate);
    } else {
      yearsInRange.push(currentDate);
    }
  }

  return yearsInRange;
}
