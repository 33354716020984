import { PlainButton, SelectWrapper, ThemeType, selectMenuStyles, theme, useClickAway } from '@arnold/common';
import { ReactComponent as LeftChevron } from '@arnold/common/lib/assets/icons/Left-Chevron.svg';
import { ReactComponent as Close } from '@arnold/common/src/assets/icons/Close.svg';
import { getLocale, getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { addMonths, format, startOfMonth } from 'date-fns';
import * as locale from 'date-fns/locale';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { DateRange, DayPicker, Styles } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { DateRangeInput } from '../../../generated/hooks';
import { Label, SequenceFilterContainer } from '../StyledComponents';
import { getMonthsInRange, getYearsInRange } from './utils';

type IProps = {
  sequenceStart: Date;
  sequenceEnd: Date;
  onDateChange: (value: DateRange | null) => void;
  otherPeriods: DateRangeInput[];
  value: DateRange | null;
  label: string;
};

type IDateInputProps = {
  theme?: ThemeType;
};

type Option = {
  value: string;
  label: string;
};

const MonthLabel = styled.p`
  margin-left: ${theme.spacing.h};
  font-size: ${theme.typography.body.medium.regular.fontSize};
  font-weight: ${theme.typography.body.medium.regular.fontWeight};

  &::first-letter {
    text-transform: capitalize;
  }
`;

const Heading = styled.h3`
  font-size: ${theme.typography.heading.medium.default.fontSize};
  font-weight: ${theme.typography.heading.medium.default.fontWeight};
`;

const RightArrow = styled(PlainButton)`
  transform: scaleX(-1);
`;

const CalendarContainer = styled.div`
  border: ${theme.spacing.a} solid ${theme.colors.borderMain.default};
  color: ${theme.colors.text.primary};
  border-radius: 6px;
  position: absolute;
  background-color: ${theme.colors.backgroundCover.default};
  margin: 0;
  boxshadow: ${theme.shadows.elevationContentDialog};
  padding: ${theme.spacing.f}};
`;

const DateInput = styled.input`
  border: ${({ theme }: IDateInputProps) => `${theme?.spacing.a} solid ${theme?.colors.borderMain.default}`};
  color: ${theme.colors.text.primary};
  height: 39px;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  padding: ${theme.spacing.d} 40px ${theme.spacing.d} ${theme.spacing.f};
  font-size: 14px;
  outline: none;
`;

export const TimeRangeInput = React.forwardRef<HTMLInputElement, IProps>(
  ({ sequenceStart, sequenceEnd, onDateChange, value, label, otherPeriods }, ref) => {
    const { t, i18n } = useTranslation('Report');
    const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<DateRange | null>(value);
    const [month, setMonth] = useState<Date>(
      startOfMonth(
        value?.from ||
          addMonths(
            sequenceEnd,
            startOfMonth(sequenceEnd).toISOString() === startOfMonth(sequenceStart).toISOString() ? 0 : -1,
          ),
      ),
    );

    const close = () => {
      setNewValue(value);
      setIsDayPickerOpen(false);
    };

    const selectDay = (selectedDay: Date) => {
      const missingTo = newValue?.from && !newValue.to;
      const setNewFrom = missingTo && selectedDay < newValue.from!;
      const newDataRange: DateRange = {
        from: !newValue || (newValue.from && newValue.to) || setNewFrom ? selectedDay : newValue.from,
        to: setNewFrom ? newValue.from : missingTo ? selectedDay : undefined,
      };
      setNewValue(newDataRange);
    };

    const innerRef = useClickAway(close);

    const dayPickerSharedProps = {
      disabled: { before: sequenceStart, after: sequenceEnd },
      modifiers: {
        otherSelectedPeriods: otherPeriods,
        otherSelectedPeriodsStarts: otherPeriods.map((p) => p.from),
        otherSelectedPeriodsEnds: otherPeriods.map((p) => p.to),
      },
      modifiersClassNames: {
        otherSelectedPeriods: 'otherSelectedPeriods',
        otherSelectedPeriodsStarts: 'otherSelectedPeriodsPoint',
        otherSelectedPeriodsEnds: 'otherSelectedPeriodsPoint',
      },
      locale: locale[i18n.language],
      components: {
        Caption: () => null,
        DayContent: ({ date }: { date: Date }) => {
          return <span>{date.getDate()}</span>;
        },
      },
      styles: {
        months: { margin: theme.spacing.f },
        caption_label: { ...theme.typography.heading.medium!.default },
        head_cell: {
          ...theme.typography.body.medium!.regular,
          color: theme.colors.text.secondary,
          textTransform: 'none',
        },
        day: { width: theme.spacing.h, height: theme.spacing.h },
      } as Styles,
    };

    const selectSharedProps = {
      blurInputOnSelect: false,
      menuShouldScrollIntoView: false,
      components: {
        IndicatorSeparator: () => null,
        Placeholder: () => null,
      },
      styles: {
        menu: (provided: any) => ({
          ...provided,
          ...selectMenuStyles,
        }),
      },
      isSearchable: false,
      isMulti: false,
      onChange: (opt: Option) => setMonth(new Date(opt.value)),
      isOptionSelected: (option: Option) => option.value === month.toISOString(),
    };

    const previousMonth = addMonths(startOfMonth(month), -1);
    const nextMonth = addMonths(startOfMonth(month), 1);

    const months = getMonthsInRange(sequenceStart, sequenceEnd, month).map((m) => ({
      value: m.toISOString(),
      label: format(m, 'LLLL', { locale: getLocale(i18n.language) }),
    }));

    const years = getYearsInRange(sequenceStart, sequenceEnd, month).map((m) => ({
      value: m.toISOString(),
      label: format(m, 'Y', { locale: getLocale(i18n.language) }),
    }));

    return (
      <SequenceFilterContainer data-cy="report-time-range-filter">
        <>
          <Label>{label}</Label>
          <DateInput
            onFocus={(e) => {
              setNewValue(value);
              setIsDayPickerOpen(true);
              e.target.blur();
            }}
            value={`${
              value?.from ? getLocalizedDateWithoutLeadingZeroes(value.from, i18n.language).replaceAll(' ', '') : ''
            } - ${value?.to ? getLocalizedDateWithoutLeadingZeroes(value.to, i18n.language).replaceAll(' ', '') : ''}`}
            placeholder={t('dateFilterPlaceholder')}
            data-cy={'report-date-filter-input'}
            ref={ref}
          />

          {isDayPickerOpen && (
            <CalendarContainer ref={innerRef}>
              <div className="d-flex justify-content-end">
                <PlainButton onClick={close}>
                  <Close />
                </PlainButton>
              </div>
              <Heading className=" ml-6 ">{label}</Heading>
              <div className="d-flex justify-content-between ml-4 mr-6 mb-8">
                <PlainButton onClick={() => setMonth(previousMonth)} disabled={previousMonth < sequenceStart}>
                  <LeftChevron />
                </PlainButton>
                <div className="d-flex">
                  <SelectWrapper
                    {...selectSharedProps}
                    value={{
                      value: month.toISOString(),
                      label: format(month, 'LLLL', { locale: getLocale(i18n.language) }),
                    }}
                    className={`react-select mr-8`}
                    options={months}
                  />
                  <SelectWrapper
                    {...selectSharedProps}
                    value={{
                      value: month.toISOString(),
                      label: format(month, 'Y', { locale: getLocale(i18n.language) }),
                    }}
                    className={`react-select ml-8`}
                    options={years}
                  />
                </div>
                <RightArrow onClick={() => setMonth(nextMonth)} disabled={nextMonth > sequenceEnd}>
                  <LeftChevron />
                </RightArrow>
              </div>
              <div className="d-flex timeRangePicker">
                <div>
                  <MonthLabel>{format(month, 'LLLL Y', { locale: getLocale(i18n.language) })}</MonthLabel>

                  <DayPicker
                    {...dayPickerSharedProps}
                    month={month}
                    mode={'range'}
                    selected={newValue ?? undefined}
                    onDayClick={selectDay}
                  />
                </div>{' '}
                <div>
                  <MonthLabel>{format(nextMonth, 'LLLL Y', { locale: getLocale(i18n.language) })}</MonthLabel>

                  <DayPicker
                    {...dayPickerSharedProps}
                    mode={'range'}
                    month={nextMonth}
                    selected={newValue ?? undefined}
                    onDayClick={selectDay}
                  />
                </div>
              </div>
              <div className="d-flex ml-8 mt-4 mb-8">
                <Button
                  variant="primary"
                  data-cy={`btn-modal-submit`}
                  onClick={() => {
                    onDateChange(newValue);
                    close();
                  }}
                  disabled={!newValue?.from || !newValue.to}
                >
                  {t('SequenceComparsion:save')}
                </Button>
                <Button variant="outline-primary" className="ml-4" data-cy={`btn-modal-submit`} onClick={close}>
                  {t('SequenceComparsion:cancel')}
                </Button>
              </div>
            </CalendarContainer>
          )}
        </>
      </SequenceFilterContainer>
    );
  },
);

export default TimeRangeInput;
