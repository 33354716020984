import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIcon.svg';
import ReportSection from './ReportSection';

interface IProps {
  adminEmails: string[] | null;
  id: string;
}
const Contacts = ({ adminEmails, id }: IProps) => {
  const { t } = useTranslation('Report');
  const adminEmailsLinks =
    adminEmails &&
    adminEmails.map((e, index) => {
      return (
        <React.Fragment key={`admin-email-${index}`}>
          {index > 0 ? ', ' : ''}
          <a href={`mailto:${e}`} target="_blank" rel="noreferrer">
            {e}
          </a>
        </React.Fragment>
      );
    });
  return (
    <ReportSection id={id}>
      <div className={'bg-white rounded-lg shadow-sm p-7'}>
        <div className={'container--md'} style={{ display: 'flex' }}>
          <InfoIcon style={{ alignSelf: 'center' }} />
          <div className={'ml-7'} style={{ width: 'auto' }}>
            <h3 className={'mb-0'}>{t('contactsTitle')}</h3>
            <p className={'mb-0 font-size-xs'}>
              <Trans
                i18nKey={'Report:contactsContent'}
                components={[
                  <span key="0">{adminEmailsLinks || t('noAdminContact')}</span>,
                  <a key="1" href={`mailto:${t('arnoldHelpMeEmail')}`} target="_blank" rel="noreferrer">
                    {t('arnoldHelpMeEmail')}
                  </a>,
                ]}
              >
                Stačí napsat na
                <span>{adminEmailsLinks || t('noAdminContact')}</span>
                nebo přímo Arnoldovi na
                <a href={`mailto:${t('arnoldHelpMeEmail')}`} target="_blank" rel="noreferrer">
                  {t('arnoldHelpMeEmail')}
                </a>
                .
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </ReportSection>
  );
};

export default Contacts;
