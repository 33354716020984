import { theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { ReactComponent as AlertIcon } from '@arnold/common/lib/assets/icons/AlertIcon.svg';
import { ReactComponent as WarningIcon } from '@arnold/common/lib/assets/icons/WarningIcon.svg';
import { AnswerType } from '../../lib/reports/question';

type IProps = {
  type: AnswerType;
};

const HightligthedIcon = styled.span`
  padding-right: ${theme.spacing.c};
  font-size: 14px;
`;

export const HighlightedAnswersIcon = ({ type }: IProps) => {
  const icon = type === AnswerType.ALERT ? <AlertIcon /> : type === AnswerType.WARNING ? <WarningIcon /> : undefined;

  if (icon) {
    return <HightligthedIcon>{icon}</HightligthedIcon>;
  }

  return <></>;
};

export default HighlightedAnswersIcon;
