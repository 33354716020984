import * as React from 'react';

const AnonymIcon = ({ color, size }) => (
  <svg
    version="1.1"
    id="Vrstva_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 93.886 94.935"
    enableBackground="new 0 0 93.886 94.935"
    fill={color}
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M90.986,64.152c-9.778-3.819-20.012-5.881-28.108-7.102c-2.062-0.306-12.074,4.82-15.935,4.82
            c-3.792,0-13.927-5.12-15.99-4.82c-8.094,1.172-18.334,3.282-28.033,7.102c-2.061,0.765-3.283,2.905-2.823,5.116l4.046,22
            c0.384,2.215,2.293,3.666,4.506,3.666h76.609c2.216,0,4.125-1.529,4.506-3.666l4.05-22C94.193,67.132,93.049,64.995,90.986,64.152z
            "
      />
      <path
        d="M58.291,36.048c-0.374-0.144-0.759-0.267-1.146-0.368c-2.68-0.702-5.521-0.154-7.794,1.504c-1.441,1.05-3.377,1.05-4.818,0
            c-0.002-0.002-0.005-0.003-0.007-0.005c-2.211-1.611-4.966-2.177-7.56-1.556c-0.465,0.112-0.928,0.254-1.373,0.425
            c-1.854,0.708-3.829,0.878-5.715,0.494c-1.127-0.229-2.168-0.564-3.103-0.978c0.945,4.133,2.574,8.005,5.142,11.143
            c1.338,1.636,2.278,3.072,2.928,4.322c5.108,5.726,12.098,6.887,12.098,6.887s6.988-1.161,12.097-6.887
            c0.65-1.25,1.591-2.686,2.929-4.322c2.567-3.138,4.196-7.01,5.142-11.144c-0.935,0.415-1.978,0.749-3.104,0.979
            C62.121,36.926,60.145,36.755,58.291,36.048z"
      />
      <path
        d="M30.669,18.126c0.931-0.146,1.895-0.22,2.864-0.22c0.554,0,1.123,0.058,1.692,0.172c1.387,0.278,2.746,0.915,3.929,1.843
            l7.188,5.639c0.352,0.276,0.848,0.276,1.199,0l7.188-5.639c1.183-0.928,2.541-1.565,3.93-1.843
            c0.568-0.114,1.138-0.172,1.691-0.172c0.97,0,1.934,0.074,2.864,0.22c1.499,0.234,2.998,0.211,4.456-0.011
            c-0.082-0.616-0.173-1.225-0.272-1.821C65.77,6.519,56.537,0,46.943,0S28.115,6.519,26.487,16.294
            c-0.1,0.597-0.19,1.205-0.272,1.822C27.673,18.338,29.171,18.36,30.669,18.126z"
      />
      <path
        d="M58.432,30.595c1.979,0,3.729-0.878,4.776-1.548c0.549-0.352,0.549-1.151,0-1.502c-1.048-0.671-2.797-1.548-4.776-1.548
            c-1.98,0-3.729,0.877-4.776,1.548c-0.55,0.351-0.55,1.15,0,1.502C54.703,29.717,56.451,30.595,58.432,30.595z"
      />
      <path
        d="M35.454,30.595c1.979,0,3.729-0.878,4.776-1.548c0.549-0.352,0.549-1.151,0-1.502c-1.048-0.671-2.797-1.548-4.776-1.548
            c-1.98,0-3.729,0.877-4.777,1.548c-0.549,0.351-0.549,1.15,0,1.502C31.726,29.717,33.474,30.595,35.454,30.595z"
      />
    </g>
  </svg>
);

export default AnonymIcon;
