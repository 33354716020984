import { BodyMedium, Pagination, SearchInput, ToggleButton, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  AnonymityLevel,
  Features,
  ReportOpenMetricsView,
  ReportSequenceSubscriptionSubscription,
  useGetProcessReportRespondentsLazyQuery,
} from '../../generated/hooks';
import { ReportOpenMetricsSender } from '../components/ObserverEventSender';
import ReportSection from '../components/ReportSection';
import RespondentsFeelings from '../components/RespondentsFeelings';

export const RESPONDENTS_ON_PAGE = 15;

export type ReportSequenceData = NonNullable<NonNullable<ReportSequenceSubscriptionSubscription>['getProcessReport']>;

interface IParticipantTableProps {
  reportSequence: ReportSequenceData;
  publicAccess?: boolean;
  urlParams: string;
  accessToken: string;
  teamsFilter: string[] | null;
  onReload: (shoudReload?: boolean) => void;
}

const StyledCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px ${theme.spacing.f};
  margin-bottom: ${theme.spacing.c};
  max-width: 58rem;
`;

const RespondentsFeelingContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 270px;
`;
enum INITAL_LOADING_STATE {
  BEFORE_FIRST_RENDER,
  AFTER_FIRST_RENDER_BEFORE_ANOTHER_LOADING,
  OWN_LOADING_STARTED,
}
const ParticipantTable = ({
  accessToken,
  reportSequence,
  publicAccess,
  urlParams,
  teamsFilter,
  onReload,
}: IParticipantTableProps) => {
  const { t } = useTranslation(['Report', 'header']);
  const savedCheckedValue = localStorage.getItem(`hideRespondents:${accessToken}`);
  const [checked, setChecked] = useState(savedCheckedValue ? JSON.parse(savedCheckedValue) : false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const container = useRef<HTMLDivElement>(null);
  const initialLoading = useRef<INITAL_LOADING_STATE>(INITAL_LOADING_STATE.BEFORE_FIRST_RENDER);

  const [loadData, { loading, error, data, refetch }] = useGetProcessReportRespondentsLazyQuery();

  const useDate =
    initialLoading.current !== INITAL_LOADING_STATE.OWN_LOADING_STARTED
      ? reportSequence.groupedRespondents
      : data?.getProcessReportRespondents;

  const reloadRespondentsData = () => {
    // skip first render
    if (initialLoading.current === INITAL_LOADING_STATE.BEFORE_FIRST_RENDER) {
      initialLoading.current = INITAL_LOADING_STATE.AFTER_FIRST_RENDER_BEFORE_ANOTHER_LOADING;
      return;
    }
    if (initialLoading.current === INITAL_LOADING_STATE.OWN_LOADING_STARTED) {
      refetch({
        accessToken,
        addedOnFrom: reportSequence.addedOnFrom ?? null,
        addedOnTo: reportSequence.addedOnTo ?? null,
        teamsFilter,
        pagination: {
          offset: (currentPage - 1) * RESPONDENTS_ON_PAGE,
          limit: RESPONDENTS_ON_PAGE,
        },
        searchQuery,
        withoutDeleted: checked,
      });
    } else {
      initialLoading.current = INITAL_LOADING_STATE.OWN_LOADING_STARTED;
      loadData({
        variables: {
          accessToken,
          addedOnFrom: reportSequence.addedOnFrom ?? null,
          addedOnTo: reportSequence.addedOnTo ?? null,
          teamsFilter,
          pagination: {
            offset: (currentPage - 1) * RESPONDENTS_ON_PAGE,
            limit: RESPONDENTS_ON_PAGE,
          },
          searchQuery,
          withoutDeleted: checked,
        },
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reloadRespondentsData, [
    reportSequence,
    teamsFilter,
    searchQuery,
    checked,
    currentPage,
    loadData,
    accessToken,
  ]);

  if (!reportSequence.surveyGroup) {
    return null;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const handleChangeSearchQuery = (value: string) => {
    if (currentPage !== 1 && value !== searchQuery) {
      setCurrentPage(1);
    }
    setSearchQuery(value);
  };

  const handleToggleChange = (value: boolean) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setChecked(value);
    localStorage.setItem(`hideRespondents:${accessToken}`, value.toString());
  };

  return (
    <ReportSection
      passedRef={container}
      id={'respondentsTable'}
      header={t('respondentsTable')}
      icomTag={'process-report-feelings-table-title'}
    >
      <RespondentsFeelingContainer>
        <StyledCard>
          <StyledSearchInput
            onChange={handleChangeSearchQuery}
            value={searchQuery}
            delay={500}
            name="respondents-search"
          />
          <div className="d-flex ml-10 mt-4" data-icom="hide-deleted-participants">
            <BodyMedium margin={`0 ${theme.spacing.d} 0 0`}>{t('hideDeletedParticipantsTitle')}</BodyMedium>
            <ToggleButton
              value={checked}
              className="mb-0"
              onChange={handleToggleChange}
              dataIcom={'hide-respondents-toggle'}
              dataCy={'hide-respondents-toggle'}
            />
          </div>
        </StyledCard>
        <RespondentsFeelings
          reportSequence={reportSequence}
          accessToken={accessToken}
          respondentLink={true}
          groupedRespondents={useDate?.data ?? []}
          urlParams={urlParams}
          reloadRespondentsData={reloadRespondentsData}
          publicAccess={!!publicAccess}
          loading={(initialLoading.current === INITAL_LOADING_STATE.OWN_LOADING_STARTED && loading) || !useDate}
          showDeleteButton={
            reportSequence.surveyGroup.steps.every(
              (step) => !step.processStep.stepTopicGroup.allowedAnonymities?.includes(AnonymityLevel.Organization),
            ) &&
            (reportSequence.metadata.organization.featureNames!.includes(
              Features.ReportRemoveRespondentFromProcessSurvey,
            ) ||
              !reportSequence.metadata.respondent)
          }
          onReload={onReload}
        />
        {!loading && !!useDate?.data.length && (
          <div className="d-flex justify-content-between align-items-center">
            <ReportOpenMetricsSender
              reportAccessKey={accessToken}
              channelIds={
                useDate?.data.flatMap((gr) =>
                  gr.surveys.flatMap((s) => s.publicChannels.filter((ch) => ch.withAnswer).map((ch) => ch.id)),
                ) || []
              }
              reportView={ReportOpenMetricsView.ProcessTable}
              orgAdmin={!publicAccess}
            />

            <span style={{ marginTop: 20 }}>
              {t('paginationInfoText', {
                total: useDate.totalCount,
                from: (currentPage - 1) * RESPONDENTS_ON_PAGE + 1,
                to:
                  (currentPage - 1) * RESPONDENTS_ON_PAGE +
                  useDate.data.reduce((acc: number, group) => acc + group.respondents.length, 0),
              })}
            </span>
            <Pagination
              totalPages={Math.floor((useDate.totalCount - 1) / RESPONDENTS_ON_PAGE) + 1}
              currentPage={currentPage}
              onPageChange={(page) => {
                setCurrentPage(page);
                const reportPage = document.querySelector('.report');
                if (!reportPage || !container.current) {
                  return;
                }
                const rect = container.current.getBoundingClientRect();
                const top = rect.top + reportPage.scrollTop;
                reportPage.scroll({
                  top: top - 260,
                  behavior: 'smooth',
                });
              }}
            />
          </div>
        )}
      </RespondentsFeelingContainer>
    </ReportSection>
  );
};

export default ParticipantTable;
