import {
  pushToDataLayer,
  createEventData,
  formatText,
  SelectWrapper,
  selectControlStyles,
  replaceQuestionPlaceholders,
  Checkbox,
  ItemWithCheckboxContainer,
  theme,
} from '@arnold/common';
import { contains } from 'ramda';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import styled from '@emotion/styled';
import { Filter, FilterOption } from '../../lib/reportHelper';
import { SelectFilterSelected } from '../../components/SelectFilterHelpers';
import auth from '../../lib/auth';
import { FilterBox } from './StyledComponents';

interface IProps {
  filters: Filter[];
  className?: string;
  options: FilterOption[];
  setFilters: (filters: Filter[]) => void;
  organizationName: string;
}

const FilterGroupHeader = styled(ItemWithCheckboxContainer)`
  padding: 9px ${theme.spacing.f};
`;

const GlobalAnswerFilter = ({ options, className, filters, setFilters, organizationName }: IProps) => {
  const { t } = useTranslation('Report');
  const scrollPositionRef = React.useRef<number>(0);

  const selectFilteredAnswer =
    (sectionId: string, optionId: number, disabled: boolean) => (e: React.MouseEvent<any, MouseEvent>) => {
      if (disabled) {
        return;
      }

      const user = auth.getUser();
      if (user) {
        pushToDataLayer({
          userId: user.id,
          orgId: user.orgId,
          event: 'ux.report-filter-answer',
          ...createEventData('report', 'filterAnswer', 'report filter answer'),
        });
      }

      const existingFilterIndex = filters.findIndex(
        (filter) => filter.editable && filter.operator === 'response' && filter.questionDefinitionIndex === sectionId,
      );
      if (existingFilterIndex !== -1) {
        const newFilters = [...filters];
        const values = newFilters[existingFilterIndex].values || [];
        const newValues = contains(optionId.toString(), values)
          ? values.filter((v) => v !== optionId.toString())
          : [...values, optionId.toString()];
        if (newValues.length === 0) {
          newFilters.splice(existingFilterIndex, 1);
        } else {
          newFilters[existingFilterIndex] = {
            ...newFilters[existingFilterIndex],
            values: newValues,
          };
        }
        setFilters(newFilters);
      } else {
        setFilters([
          ...filters,
          {
            operator: 'response',
            questionDefinitionIndex: sectionId,
            values: [optionId.toString()],
            editable: true,
          },
        ]);
      }
      e.preventDefault();
    };
  const CustomMenu = (props: { opt: FilterOption }) => {
    const menuRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      const handle = () => {
        if (menuRef.current) {
          scrollPositionRef.current = menuRef.current.scrollTop;
        }
      };
      if (menuRef.current) {
        menuRef.current.scrollTop = scrollPositionRef.current;
        menuRef.current.addEventListener('scroll', handle);
      }
      return () => {
        if (menuRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          menuRef.current.removeEventListener('scroll', handle);
        }
      };
    }, []);

    return (
      <components.Menu {...props}>
        <FilterBox
          ref={menuRef}
          className={'bg-white rounded-lg shadow-sm position-absolute'}
          data-cy="report-answer-filter-menu"
        >
          {options &&
            options.map((option, index) => (
              <div key={option.index}>
                <div className={'mt-6 font-size-xs pl-6 pr-6'}>
                  <span>
                    {formatText(
                      replaceQuestionPlaceholders(
                        option.section,
                        organizationName || `<${t('orgNameReplacement')}>`,
                        `<${t('firstDateReplacement')}>`,
                        `<${t('teamLeaderReplacement')}>`,
                      ),
                    )}
                  </span>
                </div>
                <br />
                {option.values.map((value, i) => (
                  <FilterGroupHeader key={option.index}>
                    <Checkbox
                      disabled={value.count === 0 && value.selected !== true}
                      selected={value.selected}
                      onToggle={selectFilteredAnswer(option.index, i, value.count === 0 && value.selected !== true)}
                      text={
                        <>
                          <span>{value.name}</span>
                          <span className={'text-muted'}>&nbsp;({value.count})</span>
                        </>
                      }
                      labelStyles="display: flex;align-items: center;justify-content: space-between;"
                    />
                  </FilterGroupHeader>
                ))}
                {index + 1 !== options.length && <hr />}
              </div>
            ))}
        </FilterBox>
      </components.Menu>
    );
  };

  const CustomPlaceholder = () => {
    const selectedAnswers = options.reduce((arr, option) => {
      const selectedAnswerForOption = option.values.filter((value) => value.selected).map((value) => value.name);
      arr.push(...selectedAnswerForOption);
      return arr;
    }, [] as string[]);
    return <SelectFilterSelected emptyPlaceholder={t('filterByAnswers')} selectedAnswers={selectedAnswers} />;
  };

  return (
    <SelectWrapper
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      className={`react-select ${className || ''}`}
      components={{
        IndicatorSeparator: () => null,
        Menu: CustomMenu,
        Placeholder: CustomPlaceholder,
      }}
      styles={{
        container: (provided: any, state: any) => ({
          ...provided,
          width: '100% !important',
          margin: '0px',
        }),
        control: (provided: any, state: any) => ({
          ...selectControlStyles(provided, state),
          paddingLeft: '0',
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          display: 'flex',
        }),
      }}
      isSearchable={false}
      isMulti={false}
      closeMenuOnSelect={false}
    />
  );
};

export default GlobalAnswerFilter;
