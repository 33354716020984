import { LinkButton, TellIcon, formatText, theme } from '@arnold/common';
import { ReactComponent as DownChevron } from '@arnold/common/lib/assets/icons/Down-Chevron.svg';
import { ReactComponent as UpChevron } from '@arnold/common/lib/assets/icons/Up-Chevron.svg';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportSequenceRespondentDetailQueryQuery, Sentiment } from '../../generated/hooks';
import { Answer } from '../components/RespondentsAnswers';
import { IntercomEvent, IntercomEventSender } from '../components/ObserverEventSender';

interface RespondentSummaryProps {
  report: ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail'];
  keyPoint: NonNullable<ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail']['keyPoints']>[0];
}

const GrayText = styled.p`
  color: ${theme.colors.text.secondary};
`;
export const RedTellIcon = styled(TellIcon)`
  & path {
    fill: ${theme.colors.emotionDanger.default};
  }
`;
export const GreenTellIcon = styled(TellIcon)`
  & path {
    fill: ${theme.colors.emotionSuccess.default};
  }
`;

type Step = NonNullable<
  ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail']['surveyGroup']
>['steps'][0];

const RespondentSummary: React.FC<RespondentSummaryProps> = ({ keyPoint, report }) => {
  const { t, i18n } = useTranslation('RespondentSummary');
  const [expanded, setExpanded] = React.useState(false);
  const answersWithStep = keyPoint.answerIds.map((answerId) => {
    let step: Step | null = null;
    let question: string | null = null;
    let answer: string | null = null;
    for (const response of report.responses) {
      const msg = response.messages?.find((m) => m.id === answerId);
      if (msg) {
        step =
          report.surveyGroup?.steps.find((step) => step.processStep.stepTopicGroup.id === response.topicGroupId) ??
          null;
        question = report.questions.find((q) => q.index === msg.questionDefinitionIndex)?.text ?? '';
        answer = msg.text ?? '';
      }
    }
    return { step, question, answer };
  });
  const topicWithAnsers = answersWithStep.reduce(
    (acc, answer) => {
      const tg = answer.step?.processStep.stepTopicGroup;
      if (answer.step && tg?.id) {
        if (acc[tg?.id]) {
          acc[tg?.id].answers.push({ question: answer.question ?? '', answer: answer.answer ?? '' });
        } else {
          acc[tg?.id] = {
            tg:
              tg.translations?.find((tr) => tr.language.code === (report.metadata.language ?? i18n.language))?.value ??
              tg.translations?.find((tr) => tr.language.code === i18n.language)?.value ??
              tg.translations?.[0]?.value ??
              '',
            daysFromStart: answer.step?.daysFromStart,
            answers: [{ question: answer.question ?? '', answer: answer.answer ?? '' }],
          };
        }
      }
      return acc;
    },
    {} as { [key: string]: { tg: string; daysFromStart: number; answers: { question: string; answer: string }[] } },
  );
  const sortedTopics = Object.values(topicWithAnsers).sort((a, b) => a.daysFromStart - b.daysFromStart);

  return (
    <>
      <div
        className={
          'bg-white rounded-lg shadow-sm px-8 py-5 mt-2 mb-1 d-flex align-items-center justify-content-between'
        }
      >
        <div className="d-flex align-items-center">
          {keyPoint.sentiment === Sentiment.Negative && <RedTellIcon />}
          {keyPoint.sentiment === Sentiment.Positive && <GreenTellIcon />}
          {keyPoint.sentiment === Sentiment.Neutral && <TellIcon />}
          <div className="ml-6">
            <p className="mb-0">{keyPoint.point.charAt(0).toUpperCase() + keyPoint.point.slice(1)}</p>
            <GrayText className={'font-size-xs mb-0'}>{sortedTopics.map((t) => t.tg).join(', ')}</GrayText>
          </div>
        </div>
        <div>
          <LinkButton onClick={() => setExpanded((prev) => !prev)} className="no-btn">
            <span className="mr-4">{t(expanded ? 'hideAnswers' : 'showAnswers')}</span>

            {expanded ? <UpChevron /> : <DownChevron />}
          </LinkButton>
        </div>
      </div>
      {expanded && (
        <div className="d-flex flex-wrap">
          {sortedTopics.map((topic) => (
            <div className={'col-sm-4 px-2 d-flex'} key={topic.tg}>
              <div className={'flex-grow-1 bg-white rounded-lg shadow-sm p-8 mb-2 mt-2'}>
                <p>{topic.tg}</p>
                {topic.answers.map((answer) => (
                  <div key={answer.question} className={`mb-7`}>
                    <div className={`mb-4 ml-6 text-muted font-size-xs`} title={answer.question}>
                      {formatText(answer.question)}
                    </div>
                    <Answer className={`mb-0 rounded-lg`}>{<span className={'pl-6'}>{answer.answer}</span>}</Answer>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <IntercomEventSender eventName={IntercomEvent.RespondentSummaryOpened} data={{ reportId: report.id }} />
        </div>
      )}
    </>
  );
};

export default RespondentSummary;
