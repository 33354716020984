import { Query } from '@apollo/client/react/components';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import {
  calculateValidPeriod,
  createEventData,
  logOutIntercomUser,
  pushToDataLayer,
  updateIntercomUser,
} from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { isAccessTokenValidQuery } from '../graphql/queries';
import { AccessTokenStatus, IsAccessTokenValidQuery, UserOrgRole, UserSysRole } from '../generated/hooks';
import auth, { USER_KEY } from '../lib/auth';
import { getUserHash } from '../lib/common';
import PageLayout from './PageLayout';
import { Loading } from '.';

const PrivateRouter = ({
  component: Component,
  setLocalization,
  ...rest
}: RouteProps & { setLocalization: (lang: string) => any }) => {
  const { i18n } = useTranslation();
  const isLoggedIn = rest.location ? auth.isLoggedIn(rest.location.search) : auth.isLoggedIn();
  const user = auth.getUser();
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isLoggedIn && user && Component != null) {
          return (
            <Query<IsAccessTokenValidQuery>
              query={isAccessTokenValidQuery}
              variables={{ accessToken: user.accessToken }}
              onError={() => {
                logOutIntercomUser();
              }}
              onCompleted={(responseData) => {
                if (
                  responseData?.isAccessTokenValid.status !== AccessTokenStatus.Valid ||
                  !responseData.isAccessTokenValid.consent
                ) {
                  localStorage.removeItem(USER_KEY);
                }
                const user = responseData.isAccessTokenValid.user;
                if (user) {
                  const organizationRoleForIntercom =
                    user.systemRole === UserSysRole.SysAdmin ||
                    user.respondents?.find((r) => r.organizationRole === UserOrgRole.OrgAdmin)
                      ? UserOrgRole.OrgAdmin
                      : UserOrgRole.OrgRespondent;
                  updateIntercomUser({
                    user_id: user.id,
                    user_hash: getUserHash(),
                    name: `${user.firstname} ${user.surname}`,
                    email: user.username,
                    language: i18n.language,
                    language_override: i18n.language,
                    'Organization role': organizationRoleForIntercom,
                    companies: user.respondents?.map(({ organization }) => {
                      const validPeriod =
                        organization?.productVersionHistory && calculateValidPeriod(organization.productVersionHistory);
                      return {
                        id: organization.id,
                        name: organization.name,
                        'System name': organization.systemName,
                        plan: validPeriod?.product,
                        'Primary language': organization.primaryLanguageCode,
                        'All languages': organization.languages.map(({ code }) => code).join(', '),
                        'Company ID': organization.companyId,
                        'Licence count': validPeriod?.licenceCount,
                        'Valid from': validPeriod?.validFrom,
                        'Valid to': validPeriod?.validTo,
                      };
                    }),
                  });
                  if (rest.location?.search) {
                    pushToDataLayer({
                      userId: user.id,
                      event: 'ux.talk-access-with-token',
                      ...createEventData('user', 'talk-access-with-token', 'talk-access-with-token'),
                    });
                  }
                }
              }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loading />;
                }

                if (data?.isAccessTokenValid.status !== AccessTokenStatus.Valid || !data.isAccessTokenValid.consent) {
                  return (
                    <Redirect
                      to={{
                        pathname: '/logout',
                        state: { from: props.location },
                      }}
                    />
                  );
                }

                if (error) {
                  auth.logout();
                  return (
                    <Redirect
                      to={{
                        pathname: '/logout',
                        state: { from: props.location },
                      }}
                    />
                  );
                }
                if (data) {
                  return (
                    <PageLayout setLocalization={setLocalization} user={data.isAccessTokenValid.user}>
                      <Component {...props} user={data.isAccessTokenValid.user} />
                    </PageLayout>
                  );
                }
                return null;
              }}
            </Query>
          );
        }

        auth.logout();
        return <Redirect to={{ pathname: '/logout', state: { from: props.location } }} />;
      }}
    />
  );
};

export default PrivateRouter;
