import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { SnackBar } from '../components';

const RefreshButton = styled.button`
  min-width: 223px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 41px;
  padding: 8px 32px;
  border: none;
  outline: none;
  border-radius: 23px;
  font-weight: normal;
  color: #fff;
  background-color: #04b7ef;
  cursor: pointer;

  :hover,
  :focus {
    background-color: #04a5d8;
    border-color: #04a5d8;
  }
  :active {
    background-color: #03c3ff;
    border-color: #03c3ff;
  }
`;

const Window = styled.div`
  flex: 1;
  display: flex;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ErrorMess = styled.span`
  text-align: center;
  margin: 16px;
  font-size: 22px;
  line-height: 120%;
  font-weight: normal;
  color: #3f3f3f;
  width: 90%;
`;

interface IProps extends WithTranslation {
  err: any;
  refresh: () => void;
  t: any;
}

interface IState {
  isOffline: boolean;
}

class ErrorHandling extends React.Component<IProps, IState> {
  state = {
    isOffline: false,
  };

  componentDidMount() {
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  }

  handleOnline = () => {
    this.setState({ isOffline: false });
  };

  handleOffline = () => {
    this.setState({ isOffline: true });
  };

  handleRefresh = () => {
    this.props.refresh();
  };

  renderSpecificError = (message: string, t: any, isButton?: boolean) => {
    const { isOffline } = this.state;
    return (
      <Window>
        {isOffline && <SnackBar message={t('connectError')} />}
        <ErrorMess data-cy="chat-error">{message}</ErrorMess>
        {isButton && <RefreshButton onClick={this.handleRefresh}>{t('tryAgain')}</RefreshButton>}
      </Window>
    );
  };

  render() {
    const { err } = this.props;
    if (err) {
      // tslint:disable-next-line
      if (err.networkError != null) {
        return this.renderSpecificError(this.props.t('networkError'), this.props.t);
      }
      if (err === 'noURL') {
        return this.renderSpecificError(this.props.t('noURL'), this.props.t);
      }
      switch (
        err.name // tslint:disable-line
      ) {
        case 'NotFoundError':
          return this.renderSpecificError(this.props.t('NotFoundError'), this.props.t);
        case 'ClosedChannelAccess':
          return this.renderSpecificError(this.props.t('ClosedChannelAccess'), this.props.t);
        case 'NoChannelExists':
          return this.renderSpecificError(
            `${this.props.t('Admittance:NoChannelExists')} ${this.props.t('NotFoundError')}`,
            this.props.t,
          );
        default:
          return this.renderSpecificError(this.props.t('default'), this.props.t, true);
      }
    }
    return false;
  }
}

export default withTranslation('error')(ErrorHandling);
