import { CardGrid } from '@arnold/common';
import { flatten, prop, sortBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Modals } from '../chat/ChatWindow';
import { CardType, ChannelBanner, ChannelCard } from '../components';
import { IsAccessTokenValidQuery } from '../generated/hooks';

const EmptyMessage = styled.h1`
  margin: 64px 32px;
  color: #bdbdbd;
`;

const Content = styled.div`
  max-width: 960px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 40px;
  flex: 1 0 auto;
`;

type User = IsAccessTokenValidQuery['isAccessTokenValid']['user'];
interface IProps {
  user: User;
  showModal: (modalName: Modals, show: boolean) => (e: React.MouseEvent<HTMLElement> | null) => void;
}

const Overview = (props: IProps) => {
  const { t } = useTranslation('overview');
  if (!props.user?.respondents) {
    return null;
  }

  const channels: NonNullable<NonNullable<User>['respondents']>[0]['channels'] = flatten(
    props.user.respondents.map((respondent) => respondent.channels)!,
  ) as any;

  const bannerChannel =
    sortBy(prop('endsAt'))(
      channels?.filter((channel) => channel.isOpen && !channel.isFinished && !channel.disabled)!,
    )[0] || sortBy(prop('endsAt'))(channels?.filter((channel) => !channel.isFinished && !channel.disabled)!)[0];

  const cards = sortBy(prop('name'))(
    props.user.respondents.map((respondent) => ({
      name: respondent.organization.name,
      cards: [
        ...(respondent.channels || [])
          .filter((value) => !value.disabled)
          .map((channel) => ({
            id: channel.id,
            type: CardType.Channel,
            name: channel.topicName,
            from: channel.sendAt,
            to: channel.endsAt,
          })),
        ...(respondent.reports || []).map((report) => ({
          id: report.accessKey,
          type: report.survey ? CardType.Report : CardType.ReportSequence,
          name: report.topicName,
          from: report.survey ? report.survey.sendAt : report.surveyGroup?.from,
          to: report.survey ? report.survey.endsAt : report.surveyGroup?.to,
        })),
      ].sort((c1, c2) => new Date(c2.to).getTime() - new Date(c1.to).getTime()),
    })),
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {bannerChannel && <ChannelBanner channel={bannerChannel} showModal={props.showModal} />}
      <Content>
        {cards.map((organization) => (
          <React.Fragment key={organization.name}>
            <h2>{cards.length > 1 && organization.name}</h2>
            {organization.cards.length > 0 && (
              <>
                <CardGrid>
                  {organization.cards.map((card) => (
                    <ChannelCard key={card.id} card={card} />
                  ))}
                </CardGrid>
              </>
            )}
            {organization.cards.length === 0 && <EmptyMessage className="h1">{t('noChannelsForCompany')}</EmptyMessage>}
          </React.Fragment>
        ))}
        {cards.length === 0 && (
          <EmptyMessage className="h1">
            {t('noChannels')}
            <br />
            <br />
            {t('waitForEmail')}
          </EmptyMessage>
        )}
      </Content>
    </div>
  );
};

export default Overview;
