import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { Modals } from '../../chat/ChatWindow';
import { setLanguageForRespondentMutation } from '../../graphql/mutations';
import { isAccessTokenValidQuery } from '../../graphql/queries';
import auth from '../../lib/auth';
import LanguageSelector, { ILanguageSelector } from '../LanguageSelector';
import { SetLanguageForRespondentMutationFn } from '../../generated/hooks';

interface IProps {
  t: any;
  show: boolean;
  showModal: (modal: Modals, show: boolean) => () => void;
  selectedLanguage: string;
  changeLanguage: (languageCode: string) => any;
  disabled: boolean;
  id?: string;
  finished: boolean;
  allowedLanguages: ILanguageSelector['allowedLanguages'];
  setLanguageForRespondent: SetLanguageForRespondentMutationFn;
}

const ChangeLanguage = ({
  show,
  showModal,
  disabled,
  changeLanguage,
  selectedLanguage,
  allowedLanguages,
  id,
  finished,
  setLanguageForRespondent,
}: IProps) => {
  React.useEffect(() => {
    setLanguageCode(selectedLanguage);
  }, [selectedLanguage]);

  const { t } = useTranslation('changeLanguage');
  const [languageCode, setLanguageCode] = React.useState<string>(selectedLanguage);

  if (!show) {
    return null;
  }

  const hide = showModal(Modals.CHANGE_LANGUAGE, false);

  const handleChangeLanguage = () => {
    setLanguageCode(languageCode);
    changeLanguage(languageCode);

    if (setLanguageForRespondent) {
      setLanguageForRespondent({
        variables: { language: languageCode, channelId: id! },
      });
    }
    localStorage.setItem('sessionLanguage', languageCode);
    hide();
  };

  const changeLanguageDisabled = disabled || finished || !allowedLanguages || allowedLanguages.length === 1;

  return (
    <Modal
      show={show}
      onHide={hide}
      onSubmit={handleChangeLanguage}
      title={changeLanguageDisabled ? t('errorHeader') : t('changeLanguageTitle')}
      text={disabled ? t('errorMessage') : undefined}
      buttons={{
        cancel: { title: t('close') },
        submit: { title: t('selectLanguage'), show: !changeLanguageDisabled },
      }}
      content={
        <Content className="mt-2">
          {allowedLanguages && (
            <LanguageSelector
              allowedLanguages={allowedLanguages}
              handleChange={setLanguageCode}
              selectedLanguage={languageCode}
              disabled={changeLanguageDisabled}
            />
          )}
        </Content>
      }
    />
  );
};

const withLanguageSelector = compose(
  graphql(setLanguageForRespondentMutation, {
    name: 'setLanguageForRespondent',
    options: {
      refetchQueries: auth.isLoggedIn()
        ? [
            {
              query: isAccessTokenValidQuery,
              variables: {
                accessToken: auth.getUser() !== null ? (auth.getUser() as any).accessToken : null,
              },
            },
          ]
        : [],
    },
  }),
);

export default withLanguageSelector(ChangeLanguage);

const Content = styled.div`
  margin-bottom: ${theme.spacing.h};
`;
