import { AIIcon, theme } from '@arnold/common';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Features, ReportSequenceRespondentDetailQueryQuery } from '../../generated/hooks';
import { IntercomEvent, IntercomEventSender } from '../components/ObserverEventSender';
import RateAi from '../components/RateAi';
import KeyPoint from './KeyPoint';

interface RespondentSummaryProps {
  report: ReportSequenceRespondentDetailQueryQuery['getProcessReportRespondentDetail'];
}

const AiBox = styled.div`
  border: 1px solid ${theme.colors.ai.default};
`;

const AiBoxHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RespondentSummary: React.FC<RespondentSummaryProps> = ({ report }) => {
  const { t } = useTranslation('RespondentSummary');
  if (
    !report.keyPoints ||
    !report.keyPoints.length ||
    !report.metadata.organization.featureNames?.includes(Features.AiSummaryOfRespondent)
  ) {
    return null;
  }

  return (
    <>
      <AiSection title={t('title')} description={t('description')}>
        {report.keyPoints.map((keyPoint) => {
          return <KeyPoint key={keyPoint.point} keyPoint={keyPoint} report={report} />;
        })}
      </AiSection>
      <RateAi type="RESPONDENT_SUMMARY" />
      <IntercomEventSender eventName={IntercomEvent.RespondentSummarySeen} data={{ reportId: report.id }} />
    </>
  );
};

export const AiSection = ({
  title,
  description,
  children,
  className,
}: {
  title: string;
  description: string;
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <div className={className || 'mb-9'}>
      <AiBox className={'bg-white rounded-lg shadow-sm px-8 py-6'} data-cy="report-summary-of-respondent">
        <AiBoxHeader>
          <AIIcon />
          <div className="ml-6">
            <h4 className="mb-2">{title}</h4>
            <p className={'font-size-xs mb-0'}>{description}</p>
          </div>
        </AiBoxHeader>
        {children}
      </AiBox>
    </div>
  );
};

export default RespondentSummary;
