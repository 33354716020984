import * as React from 'react';
import styled from '@emotion/styled/macro';
import { ArnoldChatting } from '../assets/images';
import { CHATTING_ANIMATION_LENGTH } from '../chat/constants';
import { View } from './StyledComponents';

function playAnimation(obj: any) {
  if (obj.contentDocument && obj.contentDocument.ks) {
    const keyShape = obj.contentDocument.ks;
    const a0 = obj.contentDocument.querySelector('#_a0');
    const a1 = obj.contentDocument.querySelector('#_a1');
    const a2 = obj.contentDocument.querySelector('#_a2');
    const a3 = obj.contentDocument.querySelector('#_a3');
    keyShape
      .animate(
        a0,
        [
          {
            p: 'scaleX',
            t: [500, 625, 750],
            v: [1, 1, 1],
            e: [[1, 0.645, 0.045, 0.355, 1], [1, 0.645, 0.045, 0.355, 1], [0]],
          },
          {
            p: 'scaleY',
            t: [500, 625, 750],
            v: [1, 1.12251, 1],
            e: [[1, 0.645, 0.045, 0.355, 1], [1, 0.645, 0.045, 0.355, 1], [0]],
          },
        ],
        a1,
        [
          {
            p: 'opacity',
            t: [0, 375, 500, 625, 750, 875],
            v: [0, 0, 1, 0, 0, 1],
            e: [[0], [0], [0], [0], [0], [0]],
          },
        ],
        a2,
        [
          {
            p: 'opacity',
            t: [0, 250, 375, 500],
            v: [0, 0, 1, 0],
            e: [[0], [0], [0], [0]],
          },
        ],
        a3,
        [
          {
            p: 'opacity',
            t: [24, 125, 250, 475, 750, 875],
            v: [0, 0, 1, 0, 0, 1],
            e: [[0], [0], [0], [0], [0], [0]],
          },
        ],
        { autoremove: true },
      )
      .range(0, CHATTING_ANIMATION_LENGTH);

    setTimeout(() => {
      playAnimation(obj);
    }, CHATTING_ANIMATION_LENGTH + 190);
  }
}

const TypingBubble = () => {
  const onSvgLoad = (e: React.SyntheticEvent<HTMLObjectElement, Event>) => {
    playAnimation(e.currentTarget);
  };

  return (
    <View left={true}>
      <Row>
        <Avatar>
          <object data={ArnoldChatting} width={35} height={35} onLoad={onSvgLoad} aria-label="Arnold chatting" />
        </Avatar>
      </Row>
    </View>
  );
};

export default TypingBubble;

const Avatar = styled.div`
  display: flex;
  width: 24px;
  justify-content: center;
  align-items: center;

  @media (min-width: 576px) {
    width: 32px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
`;
