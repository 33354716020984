import { BreadcrumbItem } from '@arnold/common';
import { forwardRef, ReactElement } from 'react';
import { ReportSubscriptionSubscription } from '../../generated/hooks';
import TopBar from './TopBar';

type ReportContainerProps = {
  title: string | null;
  about: string | null;
  topicGroupId?: string;
  date: string;
  rootTeams: NonNullable<ReportSubscriptionSubscription['getReport']['teams']>;
  children: ReactElement;
  breadcrumb?: BreadcrumbItem[];
  organizationName: string;
  exportExcel?: () => void;
  exportPowerpoint?: () => void;
  isOrganizationLevelFilterOn?: boolean;
};

const ReportContainer = forwardRef<HTMLElement, ReportContainerProps>(
  (
    {
      rootTeams,
      title,
      about,
      date,
      children,
      breadcrumb,
      topicGroupId,
      organizationName,
      exportExcel,
      exportPowerpoint,
      isOrganizationLevelFilterOn,
    },
    ref,
  ) => {
    return (
      <div
        className={'report'}
        data-cy="report-page"
        data-icom={`report-tg-${topicGroupId}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <section ref={ref} className={'section bg-white shadow-sm mb-6 py-sm-9'} style={{ marginTop: '1px' }}>
          <TopBar
            header={title}
            subHeader={about}
            breadcrumb={breadcrumb}
            date={date}
            teams={rootTeams}
            organizationName={organizationName}
            exportExcel={exportExcel}
            exportPowerpoint={exportPowerpoint}
            isOrganizationLevelFilterOn={isOrganizationLevelFilterOn}
          />
        </section>
        {children}
      </div>
    );
  },
);

export default ReportContainer;
