import { ReactNode } from 'react';
import styled from '@emotion/styled/macro';

const Image = styled.div`
  display: none;

  @media (min-width: 686px) {
    display: block;
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 50px;
  background-color: white;
  border-top: 1px solid #f9f9f9;
`;

const Content = styled.div`
  max-width: 960px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  display: flex;
  flex-direction: row;
`;

const Text = styled.div`
  flex: 1;
  text-align: justify;

  @media (min-width: 686px) {
    margin-left: 88px;
    text-align: left;
  }
`;

interface IProps {
  image: ReactNode;
  text: ReactNode;
}

const RowBanner = ({ image, text }: IProps) => {
  return (
    <Banner>
      <Content>
        <Image>{image}</Image>
        <Text>{text}</Text>
      </Content>
    </Banner>
  );
};

export default RowBanner;
