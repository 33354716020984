import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ReportDataAggregator from 'worker-loader!../workers/ReportDataAggregator'; // eslint-disable-line
import { Loading } from '../components';
import { ReportSubscriptionSubscription } from '../generated/hooks';
import { reportSubscription } from '../graphql/subscription';
import { URLs } from '../lib/common';
import { Filter } from '../lib/reportHelper';
import { AggregatedData } from '../lib/reports/aggregateData';
import { AggregatedReportDataContext } from '../lib/reports/aggregatedReportDataContext';
import { IReport, ReportData } from '../lib/reports/base';
import Alerts from './Alerts';
import { Error } from './components/Error';
import ReportPage from './components/ReportPage';
import { useReportSatismeter, useSendOpenReportToIntercom } from './components/hooks';

interface IReportProps {
  accessToken: string;
  selectedLanguage: string;
  changeLanguage: (lang: string) => any;
  setReportRespondent?: (
    respondent?: NonNullable<ReportSubscriptionSubscription['getReport']['metadata']>['respondent'],
  ) => void;
  setAllowedLanguages: (languages: string[]) => void;
  allowedLanguages?: string[];
  setNavbarContent?: (content: React.ReactNode) => void;
}

const Report = (props: IReportProps) => {
  const { t } = useTranslation('Report');
  const [data, setData] = useState<ReportData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [aggregatedDataForReport, setAggregatedDataForReport] = useState<AggregatedData | null>(null);
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { path } = useRouteMatch();

  const reportDefinitionId = params.get('report_definition_id') || undefined;
  const topicGroupId = params.get('topic_group_id') || undefined;
  const [usedFilters, setUsedFilters] = useState<Filter[]>([]);
  const [addedOnFrom, setAddedOnFrom] = useState(
    params.get('added_on_from') === 'null' ? null : params.get('added_on_from') || undefined,
  );
  const [addedOnTo, setAddedOnTo] = useState(
    params.get('added_on_to') === 'null' ? null : params.get('added_on_to') || undefined,
  );
  const unitFilters = params.get('unit-teams') || undefined;

  useEffect(() => {
    if (
      (!data?.getReport.metadata?.language?.code || data?.getReport.metadata.language?.code === 'en') &&
      (!props.allowedLanguages || !props.allowedLanguages.length)
    ) {
      props.setAllowedLanguages(['en']);
    } else if (data?.getReport.metadata?.language?.code && data?.getReport.metadata.language?.code !== 'en') {
      props.setAllowedLanguages([data?.getReport.metadata.language?.code, 'en']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const publicAccess = path === '/results/:token';

  useSendOpenReportToIntercom(publicAccess, data?.getReport.metadata?.respondent);

  useEffect(() => {
    if (
      props.selectedLanguage === data?.getReport.metadata?.language?.code &&
      (!data?.getReport.surveyGroup ||
        (addedOnFrom === params.get('added_on_from') && addedOnTo === params.get('added_on_to')))
    ) {
      return;
    }
    if (
      (data?.getReport.surveyGroup && addedOnFrom !== params.get('added_on_from')) ||
      addedOnTo !== params.get('added_on_to')
    ) {
      if (addedOnFrom) {
        params.set('added_on_from', addedOnFrom);
      } else {
        params.set('added_on_from', 'null');
      }
      if (addedOnTo) {
        params.set('added_on_to', addedOnTo);
      } else {
        params.set('added_on_to', 'null');
      }
    }
    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
    setData(null);
    setAggregatedDataForReport(null);
    setLoading(true);
    const subscription = client
      .subscribe({
        query: reportSubscription,
        variables: {
          accessToken: props.accessToken,
          reportDefinitionId,
          topicGroupId,
          addedOnFrom,
          addedOnTo,
          languageCode: props.selectedLanguage,
          publicAccess: path === '/results/:token',
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: (result) => {
          if (!result.data.getReport.responses) {
            setData(result.data);
            setLoading(false);
            return;
          }
          const worker = new ReportDataAggregator();

          worker.addEventListener('message', (event) => {
            setAggregatedDataForReport(event.data);
            worker.terminate();
          });
          setData((oldData) => {
            if (oldData) {
              const newData = {
                getReport: {
                  ...oldData.getReport,
                  responses: result.data.getReport.responses,
                },
              };

              worker.postMessage({
                report: newData,
                anonymPlaceholder: t('ANONYM'),
              });
              return newData;
            }
            return null;
          });

          setLoading(false);
          subscription.unsubscribe();
        },
        error: (err) => {
          setLoading(false);
          setError(err);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLanguage, addedOnFrom, addedOnTo]);

  useReportSatismeter(data?.getReport.metadata, publicAccess);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error>{t('errorLoading')}</Error>;
  }

  return (
    <AggregatedReportDataContext.Provider value={aggregatedDataForReport}>
      <Switch>
        <Route exact={true} path={path}>
          <ReportPage
            report={data!.getReport as IReport}
            selectedLanguage={props.selectedLanguage}
            changeLanguage={props.changeLanguage}
            setReportRespondent={props.setReportRespondent}
            accessToken={props.accessToken}
            topicGroupId={topicGroupId}
            addedOnFrom={addedOnFrom}
            addedOnTo={addedOnTo}
            unitFilters={unitFilters}
            aggregatedData={aggregatedDataForReport}
            publicAccess={publicAccess}
            setNavbarContent={props.setNavbarContent}
            setAddedOnFrom={setAddedOnFrom}
            setAddedOnTo={setAddedOnTo}
            usedFilters={usedFilters}
            setUsedFilters={setUsedFilters}
          />
        </Route>
        <Route exact={true} path={[`${path}/${URLs.REPORT_ALERT_DETAIL}/:respondentId`]}>
          <Alerts report={data!.getReport as IReport} publicAccess={publicAccess} accessToken={props.accessToken} />
        </Route>
        <Route path="*" exact={true}>
          <Error>{t('errorLoading')}</Error>
        </Route>
      </Switch>
    </AggregatedReportDataContext.Provider>
  );
};

export default Report;
