import { clone, flatten } from 'ramda';
import { getFullName, getMessagesWithQuestionIndex, IReport } from '../reports/base';
import { ReportSubscriptionSubscription } from '../../generated/hooks';

export type Respondent = {
  id: string;
  firstname: string;
  surname: string;
  teamId: string | null;
};

export type Team = {
  __typename: 'PubTeams';
  id: string;
  teamName: string;
  directMembers: string[] | null;
  memberTeams: string[] | null;
  subTeams: string[];
  teamLeader: string | null;
};

export type Message = {
  id: string;
  respondentId: string;
  respondentName: string;
};

export type TeamResults = {
  [answerIndex: string]: Message[];
};

export type TeamWithAggregatedResults = {
  team: Team;
  questions: {
    [key: string]: TeamResults;
  };
};

export type AggregatedTeams = {
  [key: string]: TeamWithAggregatedResults;
};

export type AggregatedData = {
  respondents: {
    [key: string]: Respondent;
  };
  teams: AggregatedTeams;
};

type Report = ReportSubscriptionSubscription['getReport'];

export const getTeamsByIdFromAggregatedData = (teams: AggregatedTeams, rootTeamIds: string[]) =>
  rootTeamIds.map((teamId) => teams[teamId].team);

export const getAllSubTeams = (teams: AggregatedTeams, team: NonNullable<Report['teams']>[0]): any[] => {
  if (!team.memberTeams) {
    return [];
  }
  return [team.memberTeams, ...team.memberTeams.map((memberTeamId) => getAllSubTeams(teams, teams[memberTeamId].team))];
};

export const composeRespondentsMapForAggregatedData = (
  respondents: NonNullable<Report['respondents']>,
  teams: NonNullable<Report['teams']>,
) =>
  respondents.reduce((acc, respondent) => {
    const currentTeam = teams.find((team) => team.directMembers && team.directMembers.includes(respondent.id));
    acc[respondent.id] = {
      respondent,
      teamId: currentTeam ? currentTeam.id : null,
    };
    return acc;
  }, {});

export const composeTeamsMapForAggregatedData = (teams: NonNullable<Report['teams']>) => {
  const teamsMap = teams.reduce((acc, team) => {
    acc[team.id] = {
      team: clone(team),
      questions: {},
    };
    return acc;
  }, {});

  const teamIds = Object.keys(teamsMap);
  for (let i = 0, teamLength = teamIds.length; i < teamLength; i++) {
    const team = teamsMap[teamIds[i]];
    team.team.subTeams = flatten(getAllSubTeams(teamsMap, team.team));
  }

  return teamsMap;
};
export const aggregateDataForReport = (report: IReport, anonymPlaceholder: string): AggregatedData => {
  const selectQuestions = report.questions?.filter((question) => question.type === 'SELECT') || [];

  const respondents = composeRespondentsMapForAggregatedData(report.respondents, report.teams!);

  const teams = composeTeamsMapForAggregatedData(report.teams!);

  for (let i = 0, questionsLength = selectQuestions.length; i < questionsLength; i++) {
    const question = selectQuestions[i];
    const reportIndex = question.reportIndex || question.index;
    const messages = getMessagesWithQuestionIndex(report, reportIndex);

    for (let j = 0, messagesLength = messages.length; j < messagesLength; j++) {
      const msg = messages[j];
      const respondent = respondents[msg.respondentId];
      if (msg.value && respondent && respondent.teamId) {
        const team = teams[respondent.teamId];
        if (team) {
          const answers = msg.value.split(',');
          const teamAnsers = answers.reduce((acc, answer) => {
            const respondentName = msg.anonymous
              ? anonymPlaceholder
              : getFullName(respondents[msg.respondentId].respondent);
            const liteMsg = {
              id: msg.id,
              respondentId: msg.respondentId,
              respondentName,
            };
            if (acc[answer]) {
              acc[answer].push(liteMsg);
            } else {
              acc[answer] = [liteMsg];
            }
            return acc;
          }, team.questions[reportIndex] || {});
          team.questions[reportIndex] = teamAnsers;
        }
      }
    }
  }

  return { teams, respondents };
};
