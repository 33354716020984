import {
  LinkButton,
  RoundCheckedIcon,
  TellIcon,
  ToggleButton,
  theme,
  usePreventScrollChangeOnElementResize,
  useToast,
} from '@arnold/common';
import { ReactComponent as DownChevron } from '@arnold/common/lib/assets/icons/Down-Chevron.svg';
import { ReactComponent as UpChevron } from '@arnold/common/lib/assets/icons/Up-Chevron.svg';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import styled from '@emotion/styled';
import { MD5 } from 'crypto-js';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Expand from 'react-expand-animated';
import { useTranslation } from 'react-i18next';
import { QuestionWithAnswer, Sentiment, useAiSurveySummarySubscriptionSubscription } from '../../generated/hooks';
import { GreenTellIcon, RedTellIcon } from '../sequence/KeyPoint';
import { AiSection } from '../sequence/RespondentSummary';
import { IntercomEvent, IntercomEventSender, sendEventToIntercom } from './ObserverEventSender';
import RateAi from './RateAi';

const LOCAL_STORAGE_KEY = 'AiSurveySummaryPreviouslyLoaded';

type AiSurveySummaryProps = {
  languageCode: string;
  accessToken: string;
  isProcessSurvey: boolean;
  getReportWidget: (idx: number) => ReactNode;
  answers: QuestionWithAnswer[];
};

const DEFAULT_SYSTEM_MESSAGE = `As an research assistant for Arnold, your role is to distill valuable insights from user feedback in surveys created by our clients (companies).
Your primary task is to provide a summary of the survey results below in language %LANGUAGE_CODE%.
Key guidelines to follow:
1. Summary should be in bullet points
2. Summary should include the most important insights from the survey.
3. The questions have their id in [square brackets] at the end of the question.
4. If a bullet point is specific to some questions, please add question ids separated by commas in square brackets at the end of the bullet point like this [5, 34]
5. It should be short, around 8 points.
6. If there is not enough data, feel free to enter fewer point.
7. Pay attention to answers in FREETEXT, these are usually the most important.
8. Add also a sentiment in [square brackets] at the beginning of each point. Allowed sentiment types: [NEGATIVE], [POSITIVE], [NEUTRAL].
9. Arrange the sentiment in this order [NEGATIVE], [POSITIVE], [NEUTRAL]
`;

export const AiSurveySummary = (props: AiSurveySummaryProps) => {
  const [generateSummary, setGenerateSummary] = useState(true);
  const [gpt4, setGpt4] = useState(true);
  const [systemMessage, setSystemMessage] = useState(DEFAULT_SYSTEM_MESSAGE);
  const [percentOfMessages, setPercentOfMessages] = useState(100);

  // Filter out answers based on the set percentage
  const selectedAnswers = useMemo(() => {
    return props.answers.map((questionWithAnswers) => {
      if (questionWithAnswers.answers == null) return questionWithAnswers;
      const filteredAnswers = questionWithAnswers.answers.filter((answer) => answer.length > 4);
      const sortedAnswers = filteredAnswers.sort((a, b) => {
        if (a.length === b.length) {
          // We need to sort it also alphabetically to have the same hash for the same answers
          return a.localeCompare(b);
        }
        return a.length - b.length;
      });
      // Evenly remove elements from the array based on the percentage
      const shortenedAnswers = shortenArray(sortedAnswers || [], percentOfMessages);
      if (shortenedAnswers.length < 10) {
        return { ...questionWithAnswers, answers: sortedAnswers };
      }
      return { ...questionWithAnswers, answers: shortenedAnswers };
    });
  }, [props.answers, percentOfMessages]);

  if (
    props.answers.every((question) => question.answers?.length === 0 || question.choices?.every((ch) => ch.count === 0))
  ) {
    return null;
  }

  return (
    <>
      {localStorage.getItem('AiSurveyTest') === 'true' && (
        <StyledFrame>
          <StyledHeading>AI survey summary test</StyledHeading>
          <HorizontalLayout>
            GPT-3.5 <ToggleButton value={gpt4} onChange={() => setGpt4(!gpt4)} /> GPT-4
          </HorizontalLayout>
          <h4>Percent of messages send to ChatGPT:</h4>
          <HorizontalLayout>
            <input
              type="range"
              min="20"
              max="100"
              step="5"
              value={percentOfMessages}
              onChange={(e) => setPercentOfMessages(parseInt(e.target.value, 10))}
            />{' '}
            {percentOfMessages}%
          </HorizontalLayout>
          <p>
            <b>There are some limits that may override the percentage and may include more messages:</b>
            <br />- We always send at least 10 messages per question
            <br />- Even if 100% is selected, we may discard answers due to being short or for other reasons
            <br />- Maybe some others ???
          </p>
          <h3>System message:</h3>
          <textarea
            onChange={(e) => {
              setSystemMessage(e.target.value);
              setGenerateSummary(false);
            }}
            style={{ width: '100%', height: '200px', padding: '5px' }}
          >
            {systemMessage}
          </textarea>
          <i>You can edit the system message to test what the AI is capable of.</i>
          {!generateSummary && <Button onClick={() => setGenerateSummary(true)}>Generate Summary</Button>}
          {generateSummary && <Button onClick={() => setGenerateSummary(false)}>Clear Summary</Button>}
        </StyledFrame>
      )}
      {generateSummary && (
        <SurveySummary {...props} gpt4={gpt4} systemMessage={systemMessage} answers={selectedAnswers} />
      )}
    </>
  );
};

const SurveySummary = (props: AiSurveySummaryProps & { gpt4: boolean; systemMessage: string }) => {
  const { t } = useTranslation('SurveySummary');
  const { addBeautifulToast } = useToast();
  const ref = useRef<HTMLDivElement>(null);

  const firstTimeLoading = useMemo(() => {
    const hash = hashAnswersAndLanguageCode(props.answers, props.languageCode);
    return !localStorage.getItem(LOCAL_STORAGE_KEY)?.includes(hash);
  }, [props.answers, props.languageCode]);

  const { data, loading } = useAiSurveySummarySubscriptionSubscription({
    variables: props,
    skip: props.answers.length === 0,
  });
  const text = useSmoothTextGeneration(data?.aiSurveySummary.text, data?.aiSurveySummary.done && !firstTimeLoading);
  usePreventScrollChangeOnElementResize(ref.current, text);
  const loadingOrStreaming =
    loading || !data?.aiSurveySummary?.done || text.length !== data?.aiSurveySummary?.text?.length;
  useEffect(() => {
    if (!loadingOrStreaming) {
      if (firstTimeLoading) {
        addBeautifulToast({
          icon: <RoundCheckedIcon />,
          message: t('toastMessageDone'),
          buttonText: t('toastButtonText'),
          duration: 15000,
          buttonOnClick: () => {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
            sendEventToIntercom(
              props.isProcessSurvey
                ? IntercomEvent.PhaseSummaryCompleteToastClicked
                : IntercomEvent.SurveySummaryCompleteToastClicked,
              { accessToken: props.accessToken },
            );
          },
        });
        const hash = hashAnswersAndLanguageCode(props.answers, props.languageCode);
        localStorage.setItem(LOCAL_STORAGE_KEY, (localStorage.getItem(LOCAL_STORAGE_KEY) || '') + ', ' + hash);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOrStreaming]);

  const textArray = text?.split('\n');
  return (
    <div ref={ref} className="pt-8">
      <IntercomEventSender
        eventName={props.isProcessSurvey ? IntercomEvent.PhaseSummarySeen : IntercomEvent.SurveySummarySeen}
        data={{ accessToken: props.accessToken }}
      />
      {!loadingOrStreaming && (
        <IntercomEventSender
          eventName={
            props.isProcessSurvey
              ? IntercomEvent.PhaseSummaryFullyLoadedSeen
              : IntercomEvent.SurveySummaryFullyLoadedSeen
          }
          data={{ accessToken: props.accessToken }}
        />
      )}
      <AiSection title={t('title')} description={t('description')} className="mb-4">
        {loadingOrStreaming && (
          <>
            <AnimationContainer>
              <DotLottiePlayer src={`/animations/aiLoading.lottie`} autoplay loop></DotLottiePlayer>
            </AnimationContainer>
            <p style={{ textAlign: 'center' }}>
              {t('loadingDescription1')}
              <br />
              {t('loadingDescription2')}
            </p>
          </>
        )}
      </AiSection>

      <div data-complete-ai-report-summary={!loadingOrStreaming}>
        {textArray?.map(
          (line, index) =>
            line &&
            index < 10 && (
              <KeyPoint
                key={index}
                text={line.trim()}
                getReportWidget={props.getReportWidget}
                isProcessSurvey={props.isProcessSurvey}
                accessToken={props.accessToken}
              />
            ),
        )}
      </div>
      <RateAi type={props.isProcessSurvey ? 'PROCESS_SURVEY_SUMMARY' : 'STANDALONE_SURVEY_SUMMARY'} />
    </div>
  );
};

const AnimationContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  & svg > g > g > path[fill='rgb(255,255,255)'] {
    opacity: 0;
  }
`;

const HorizontalLayout = styled.div`
  display: flex;
  gap: ${theme.spacing.f};
`;

const StyledHeading = styled.h2`
  color: ${theme.colors.ai.default};
  font-weight: bold;
`;

const StyledFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.f};
  padding: ${theme.spacing.g};
  align-items: start;
  width: 100%;
  min-height: 100px;
  border: 1px solid ${theme.colors.ai.default};
  border-radius: 5px;
  margin-top: ${theme.spacing.h};
  margin-bottom: ${theme.spacing.h};
  background-color: white;
  box-shadow: inset 0px 0px 5px ${theme.colors.ai.default};
`;

const KeyPoint = ({
  text,
  getReportWidget,
  isProcessSurvey,
  accessToken,
}: {
  text: string;
  getReportWidget: (idx: number) => ReactNode;
  isProcessSurvey: boolean;
  accessToken: string;
}) => {
  const { t } = useTranslation('SurveySummary');
  const [expanded, setExpanded] = useState(false);

  if (text[0] === '-') {
    // remove the dash at the beginning of the line if there is one
    text = text.slice(1);
  }

  text = text.replace(/]\s?- /g, '] ').trim(); // remove the dash after the bracket if there is one

  if (text.length < 14) return <></>; // skip empty lines with only sentiment on them

  let sentiment: Sentiment;
  if (text.toUpperCase().includes('[POSITIVE]')) {
    sentiment = Sentiment.Positive;
  } else if (text.toLocaleUpperCase().includes('[NEGATIVE]')) {
    sentiment = Sentiment.Negative;
  } else {
    sentiment = Sentiment.Neutral;
  }
  text = text.replace(/\[POSITIVE\]|\[NEUTRAL\]|\[NEGATIVE\]/gi, '');

  const questionIndexes: number[] = [];
  if (text.includes('[')) {
    if (text.includes(']')) {
      // get the indexes between the brackets
      questionIndexes.push(
        ...(text
          .match(/\[(.*?)\]/)?.[1]
          ?.split(',')
          .map((i) => parseInt(i, 10))
          .filter((i) => !isNaN(i)) || []),
      );
      // remove the brackets with the ID
      text = text.replace(/\s*\[(.*?)\]\s*/g, '');
    } else {
      // remove the bracket at the end of the line and anything after the bracket
      text = text.split('[')[0];
    }
  }

  return (
    <>
      <div
        style={{ minHeight: '66px' }}
        className={'bg-white rounded-lg shadow-sm px-8 py-5 mt-4 mb-4 d-flex flex-column'}
      >
        <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
          <div className="px-4">
            {sentiment === Sentiment.Negative && <RedTellIcon />}
            {sentiment === Sentiment.Positive && <GreenTellIcon />}
            {sentiment === Sentiment.Neutral && <TellIcon />}
          </div>
          <div className="ml-6">
            <p className="mb-0">{text}</p>
          </div>
          <ShowQuestionsContainer data-pptx-hide>
            {!!questionIndexes.length && (
              <LinkButton
                onClick={() => {
                  setExpanded((prev) => !prev);
                  if (!expanded) {
                    sendEventToIntercom(
                      isProcessSurvey ? IntercomEvent.PhaseSummaryOpened : IntercomEvent.SurveySummaryOpened,
                      { accessToken },
                    );
                  }
                }}
                className="no-btn"
              >
                <span className="mr-4" data-pptx-click={expanded}>
                  {t(expanded ? 'hideQuestions' : 'showQuestions')}
                </span>
                {expanded ? <UpChevron /> : <DownChevron />}
              </LinkButton>
            )}
          </ShowQuestionsContainer>
        </div>
        <Expand open={expanded} duration={500} transitions={['height', 'opacity']}>
          {questionIndexes.map((questionIndex) => {
            return <QuestionContainer key={questionIndex}>{getReportWidget(questionIndex)}</QuestionContainer>;
          })}
        </Expand>
      </div>
    </>
  );
};

const ShowQuestionsContainer = styled.div`
  min-width: 130px;
  padding-left: 8px;
  flex-grow: 1;
  text-align: right;
`;

const QuestionContainer = styled.div`
  border-top: 1px solid ${theme.colors.borderSeparator.default};
  margin-top: ${theme.spacing.g};
`;

const useSmoothTextGeneration = (text: string | null | undefined, disabled = false) => {
  const [smoothText, setSmoothText] = useState<string>(text || '');
  const [timeoutRunning, setTimeoutRunning] = useState(false);
  if (disabled) return text || '';
  if (!text) return '';
  if (text === smoothText) return text;
  if (!text.includes(smoothText)) {
    setSmoothText(text);
    return text;
  }
  if (!timeoutRunning) {
    setTimeoutRunning(true);
    let delay = 1000 / Math.pow(text.length - smoothText.length, 2);
    delay = Math.max(10, delay);
    setTimeout(() => {
      setTimeoutRunning(false);
      setSmoothText((prev) => prev + text[prev.length]);
    }, delay);
  }
  return smoothText;
};

const hashAnswersAndLanguageCode = (answers: QuestionWithAnswer[], languageCode: string) => {
  return MD5(JSON.stringify(answers) + languageCode).toString();
};

/**
 * Evenly removes elements from an array based on a percentage.
 * Examples:
 * shortenArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 50) => [1, 3, 5, 7, 9]
 * shortenArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 25) => [1, 4, 7]
 * shortenArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 10) => [1]
 */
const shortenArray = <T extends unknown>(arr: T[], percentage: number): T[] => {
  if (percentage <= 0) {
    return [];
  }

  if (percentage >= 100) {
    return arr;
  }

  // Calculate the number of elements to keep
  const numElementsToKeep = Math.ceil((percentage / 100) * arr.length);
  // Calculate the interval between elements to keep
  const interval = Math.floor(arr.length / numElementsToKeep);
  const result: T[] = [];

  // Adjust interval dynamically to distribute elements evenly
  for (let i = 0; i < numElementsToKeep; i++) {
    result.push(arr[Math.min(i * interval, arr.length - 1)]);
  }

  return result;
};
